import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { Modal, Button, Box } from "@mui/material";
import classes from "./index.module.css";
import { MenuItem, Radio } from "@mui/material";
import { Form } from "react-bootstrap";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { getLanguagedataFunction } from "../utilities/language";
import { useDispatch, useSelector } from "react-redux";

const LanguageModal = (props) => {
  const dispatch = useDispatch();
  const [modalToggle, setModalToggle] = useState(true);
  const rootRef = React.useRef(null);
  const options = [
    { label: "English (United States)", value: "en" },
    { label: "Hindi (हिंदी)", value: "hi" },
  ];

  const [selectedOption, setSelectedOption] = useState(
    localStorage.getItem("language") || "en"
  );

  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const modalClose = () => {
    props.modalClose(false);
    setModalToggle(false);
  };

  const handleLanguageChange = (value) => {
    setSelectedOption(value);
  };

  const handleSubmit = () => {
    localStorage.setItem("language", selectedOption);
    getLanguagedataFunction(dispatch);
    modalClose();
  };

  return (
    <div>
      {ln ? (
        <Modal
          open={modalToggle}
          onClose={() => modalClose()}
          aria-labelledby="server-modal-title"
          data-testid="register_modal"
          aria-describedby="server-modal-description"
          sx={{
            display: "flex",
            height: 320,
            marginTop: 10,
            justifyContent: "center",
          }}
          container={() => rootRef.current}
        >
          <Box
            sx={{
              position: "relative",
              bgcolor: "background.paper",
              borderRadius: 1,
              boxShadow: (theme) => theme.shadows[5],
            }}
            style={{ width: "30%", height: "auto" }}
            className={classes.loginModalWidth}
          >
            <div className={classes.AddMemberHeading}>
              <h4>{ln.select_ur_language}</h4>
              <Button
                variant="text"
                sx={{ minWidth: "unset" }}
                className={classes.closebtn}
                onClick={() => modalClose()}
              >
                <MdClose />
              </Button>
            </div>
            <ValidatorForm useref="form" onSubmit={handleSubmit}>
              <div className={classes.modelInputsContainer}>
                <div>
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    select
                    fullWidth
                    className={classes.inputs}
                    value={selectedOption}
                    onChange={(e) => handleLanguageChange(e.target.value)}
                    required={true}
                    size="small"
                  >
                    {options.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.value}
                        className={
                          selectedOption === option.value
                            ? `${classes.menuItem} ${classes.selectedMenu}`
                            : classes.menuItem
                        }
                      >
                        <span>{option.label}</span>
                        {/* <Radio
                          checked={selectedOption === option.value}
                          className={classes.lang_radio_input}
                        /> */}
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                          checked={selectedOption === option.value}
                        />
                      </MenuItem>
                    ))}
                  </TextValidator>
                </div>
                <div className={classes.addMemberModalbtnSection}>
                  <Button variant="outlined" onClick={() => modalClose()}>
                    {ln.cancel}
                  </Button>
                  <Button variant="contained" type="submit">
                    {ln.update}
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </Box>
        </Modal>
      ) : null}
    </div>
  );
};

export default LanguageModal;
