import React, { useState } from "react";
import ImageCarosel from "../../../components/ImageCarosel";
import classes from "./index.module.css";
import { Link } from "react-router-dom";
import AppLogo from "../../../assets/images/mainLogo.png";
import { ValidatorForm } from "react-material-ui-form-validator";
import "../../../assets/css/style.css";
import "../../../assets/css/bootstrap.min.css";
import { Card, Typography, Alert, Snackbar } from "@mui/material";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AlertBox from "../../../components/AlertBox";

const SelectLanguage = () => {
  const navigate = useNavigate();
  const [preferedLanguage, setPreferedLanguage] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const lang = localStorage.getItem("language");
  const handleSubmit = () => {
    if (preferedLanguage) {
      localStorage.setItem("language", preferedLanguage);
      navigate("/personal-details");
    } else {
      setShowAlert(true);
      setError("Please select your language");
    }
  };
  return (
    <div className={classes.maindivForlogin}>
      <Card className={classes.SelectLanguage_maindiv}>
        <div>
          <ImageCarosel />
        </div>
        <ValidatorForm useref="form" onSubmit={handleSubmit}>
          <div className="col-lg-12">
            <div style={{ padding: "3rem" }}>
              <div className="text-left">
                <span className="logo-lg mb-5">
                  <img src={AppLogo} alt="dggd" height="50" />
                </span>
                <Typography
                  className={`mt-4 ${classes.loginHeading} ${classes.selectLangHeading}`}
                >
                  Choose your Preferred Language
                </Typography>
                <Typography className={`mt-4 ${classes.loginDescription}`}>
                  Please select your language
                </Typography>
              </div>

              <div className="mt-4">
                <div id="lang-box">
                  <Link
                    to="#"
                    className="mb-4"
                    onClick={() => setPreferedLanguage("en")}
                  >
                    <div
                      style={{
                        padding: "10px 0",
                      }}
                      className={
                        preferedLanguage === "en"
                          ? `${classes.activeLAnguageBtn}`
                          : `${classes.nonActiveLanfuageBtn}`
                      }
                    >
                      English (United States)
                    </div>
                  </Link>
                  <Link
                    to="#"
                    className="mb-4"
                    onClick={() => setPreferedLanguage("hi")}
                  >
                    <div
                      style={{
                        padding: "10px 0",
                      }}
                      className={
                        preferedLanguage === "hi"
                          ? `${classes.activeLAnguageBtn} `
                          : `${classes.nonActiveLanfuageBtn}`
                      }
                    >
                      Hindi ( हिंदी )
                    </div>
                  </Link>
                </div>
                <div className="mt-4">
                  <Button
                    className={`btn btn-primary w-100 ${classes.commonbtn}`}
                    type="submit"
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </ValidatorForm>
      </Card>
      {showAlert && error && (
        <AlertBox type="error" message={error} stateName={setShowAlert} />
      )}
    </div>
  );
};

export default SelectLanguage;
