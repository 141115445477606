import { Box, Dialog, Typography, Button, Grid, MenuItem } from "@mui/material";
import "./address.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import classes from "./address.module.css";
import { Numeric } from "../../../utilities/commonFuntions";
import { useEffect, useState } from "react";
import { AddAddressCloseIcon } from "../../../components/Icons";
import { useDispatch, useSelector } from "react-redux";

function DeleteAddressPop(props) {
  const {
    open,
    handleClose,
    handleDeleteAddress,
  } = props;

  const { deleteSuccess, deleteFailure } = useSelector(
    (state) => state.reducers.address
  );
  const dispatch = useDispatch();



  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="delete-address-pop"
      >
        <Box>
          <Typography >
          Are you sure you want to delete this address ?
          </Typography>
          <Box className={classes.del_address_btns} >
                  <Button
                    variant="outlined"
                    // className={classes.add_address}
                    type="submit"
                    onClick={handleClose}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    // className={classes.add_address}
                    type="submit"
                    onClick={handleDeleteAddress}
                  >
                    Yes
                  </Button>
              </Box>
              {deleteSuccess && (
                <Typography className={classes.success_text}>
                  Successfully Deleted
                </Typography>
              )}
              {deleteFailure && (
                <Typography className={classes.failure_text}>
                  Try Again later{" "}
                </Typography>
              )}
        </Box>
      </Dialog>
    </>
  );
}

export default DeleteAddressPop;
