import React from "react";
import { MdClose } from "react-icons/md";
import classes from "./ReferEarn.module.css";
import {
  Modal,
  Box,
  Button,
  Typography,
  Avatar,
  Select,
  MenuItem,
} from "@mui/material";
import avatar from "../../../assets/images/avatar-3.jpg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import {
  MY_SUBS,
  MY_SUBSCRIPTION,
  MY_SUBS_AVAIL,
  PAYMENT_SUCCESS,
  REFERAL_SUBSCRIPTION,
  RENEW_SUBS,
  TEAM_LIST,
  UPDATE_REFERALS,
  USER_REFFERALS,
} from "../../../api-calls/api-urls";
import { SetRefferalHistory } from "../../../Redux/actions/user-actions";
import { teamsList } from "../../../Redux/actions/team-actions";
import { RoundAddIcon } from "../../../components/Icons";
import { useNavigate } from "react-router-dom";
import { getLanguagedataFunction } from "../../../utilities/language";

const ReferHistoryModal = (props) => {
  const navigate = useNavigate();
  const rootRef = React.useRef(null);
  const dispatch = useDispatch();
  const [modalToggle, setModalToggle] = useState(true);
  const [applyPage, setApplyPage] = useState(false);
  const [selectedReferal, setSelectedReferal] = useState(0);
  const [fullSubscList, setFullSubscList] = useState([]);
  const teamList = useSelector((state) => state.reducers.teams.teamsList);
  const [team, setTeam] = useState("");
  const [error, setError] = useState(false);
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const referralHistory = useSelector(
    (state) => state.reducers.allUsers.refferal_history
  );
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  function closeModal() {
    setModalToggle(false);
    props.closeModal(false);
  }

  useEffect(() => {
    getData();
    getSubscriptionListData();
  }, []);

  const getRefferalHistoryData = async () => {
    try {
      const { data } = await authApi.get(`${USER_REFFERALS}/${userInfo._id}`, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      console.log(data.Referals);
      dispatch(SetRefferalHistory(data.Referals));
    } catch (err) {
      setError(true);
    }
  };

  useEffect(() => {
    if (userInfo) {
      getRefferalHistoryData();
    }
  }, [!referralHistory]);

  const getReferalPoints = () => {
    let points = 0;
    if (referralHistory && referralHistory.length > 0) {
      referralHistory.map((item) => {
        console.log(item.referal_points);
        points += item.referal_points;
      });
    }
    console.log(points);
    return points;
  };

  const getData = async () => {
    const { data } = await authApi.get(TEAM_LIST, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    dispatch(teamsList(data));
  };

  const getMonths = (text) => {
    if (text === "monthly") {
      return "1 Month";
    } else if (text === "quarterly") {
      return "3 Months";
    } else if (text === "halfearly") {
      return "6 Months";
    } else if (text === "yearly") {
      return "1 Year";
    } else if (text === "annualy") {
      return "1 Year";
    }
  };

  const handleRenew = async (item) => {
    console.log(item);
    const userEntry = {
      subscriptionid: item.subscription_id,
      durationid: item.duration_details._id,
    };
    // console.log(userEntry);
    const { data } = await authApi.post(MY_SUBSCRIPTION, userEntry, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    if (data) {
      const userEntry2 = {
        userid: data.data.user_id,
        orderid: data.data._id,
      };
      const data2 = await authApi.post(PAYMENT_SUCCESS, userEntry2, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      // console.log(data2.data);
      const availsubs = await getSubsription();
      console.log(availsubs);
      if (data2.data && availsubs) {
        const userEntry3 = {
          team_id: item.teams[0]._id,
          avail: availsubs,
        };
        const data3 = await authApi.post(RENEW_SUBS, userEntry3, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        console.log(data3.data);
      }
    }
  };

  const getSubsription = async () => {
    var result = {};
    await authApi
      .get(MY_SUBS_AVAIL, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then(function (res) {
        //dispatch(SaveAvailableSubc(res.data));
        result = res.data[res.data.length - 1];
        console.log(result);
      });
    return result;
  };

  const getSubscriptionListData = async () => {
    const { data } = await authApi.get(`${MY_SUBS}/${userInfo._id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    setFullSubscList(data);
  };

  const handleSubmit = async () => {
    const selectedTeam = fullSubscList.find((item) => item.team_id === team);
    const no_of_days = Math.floor(
      selectedReferal.referal_points / selectedTeam.no_team_members
    );
    console.log(no_of_days);
    const { data } = await authApi.post(
      `${REFERAL_SUBSCRIPTION}/${selectedTeam._id}`,
      {
        no_of_days: no_of_days,
        referal_id: selectedReferal._id,
      },
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    console.log(data);
    const availsubs = await getSubsription();
    if (data.newcusSubs && availsubs) {
      const userEntry3 = {
        team_id: selectedTeam.team_id,
        avail: availsubs,
        type: "renew",
      };
      const data3 = await authApi.post(RENEW_SUBS, userEntry3, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      console.log(data3.data);
      MakePointsNull();
      closeModal();
    }
  };

  const handleNavigateSubsPage = async () => {
    const data = {
      referal_id: selectedReferal._id,
      referal_points: selectedReferal.referal_points,
    };
    await sessionStorage.setItem("referal_data", JSON.stringify(data));
    navigate("/referal-subscription");
  };

  const MakePointsNull = () => {
    const { data } = authApi.put(
      `${UPDATE_REFERALS}/${selectedReferal._id}`,
      {},
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    console.log(data);
  };

  return (
    <div>
      {ln && (
        <Modal
          open={modalToggle}
          onClose={() => closeModal()}
          aria-labelledby="server-modal-title"
          data-testid="register_modal"
          aria-describedby="server-modal-description"
          sx={{
            display: "flex",
            height: 480,
            marginTop: 10,
            justifyContent: "center",
          }}
          container={() => rootRef.current}
        >
          <Box
            sx={{
              position: "relative",
              bgcolor: "background.paper",
              borderRadius: 1,
              boxShadow: (theme) => theme.shadows[5],
            }}
            style={{
              width: "max-content",
              minWidth: "460px",
              height: "fit-content",
              padding: "10px 30px",
            }}
            className={classes.loginModalWidth}
          >
            <div className={classes.closebtn}>
              <Button
                variant="text"
                sx={{ minWidth: "unset" }}
                onClick={() => closeModal()}
              >
                <MdClose />
              </Button>
            </div>
            {!error &&
            !applyPage &&
            referralHistory &&
            referralHistory &&
            referralHistory.length > 0 ? (
              <div>
                <div className={classes.ModalHeaderSection}>
                  <Typography className={classes.ModalHEaderMainText}>
                    {ln.this_is_your_referral_history}
                  </Typography>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Typography
                        className={`mt-3 ${classes.ModalHEaderMainText}`}
                      >
                        {referralHistory.length > 9
                          ? referralHistory.length
                          : `0${referralHistory.length}`}
                      </Typography>
                      <Typography className={classes.ModalHEaderSubText}>
                        {ln.total_subscriptions}
                      </Typography>
                    </div>
                    {/* <div>
                    <Typography className={`mt-3 ${classes.ModalHEaderMainText}`}>
                      {getReferalPoints()}
                    </Typography>
                    <Typography className={classes.ModalHEaderSubText}>
                      Total Subscription Points
                    </Typography>
                  </div> */}
                  </div>
                </div>
                {referralHistory.map((item, index) => {
                  return (
                    <div className={`${classes.AvatarSection}`} key={index}>
                      <Avatar
                        src={avatar}
                        sx={{
                          width: "44px",
                          height: "44px",
                        }}
                      />
                      <div>
                        <Typography className={classes.ModalHEaderMainText}>
                          {`${item.refered_person.first_name} ${item.refered_person.last_name}`}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "335px",
                          }}
                        >
                          <Typography className={classes.ModalHEaderSubText}>
                            {getMonths(
                              item?.first_subscription?.duration_details
                                ?.duration
                            )}{" "}
                            |{" "}
                            {`${item?.subscription_details?.packageName} plan`}
                          </Typography>
                          <Typography className={classes.ModalHEaderSubText}>
                            {ln.points}: {item.referal_points}
                          </Typography>
                        </div>
                        <Typography className={classes.ModalHeaderStatusText}>
                          {ln.joined}
                        </Typography>
                        <Button
                          variant="contained"
                          className={classes.referal_pointsApplyButton}
                          disabled={item.referal_points === 0}
                          onClick={() => {
                            setApplyPage(true);
                            setSelectedReferal(item);
                          }}
                        >
                          {ln.apply}
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
            {error ? (
              <div className={classes.ModalHeaderSection}>
                <Typography className={classes.ModalHEaderMainText}>
                  {ln.this_is_your_referral_history}
                </Typography>
                <Typography className={`mt-3 ${classes.ModalHEaderMainText}`}>
                  00
                </Typography>
                <Typography className={classes.ModalHEaderSubText}>
                  {ln.total_subscriptions}
                </Typography>
              </div>
            ) : null}
            {error ? (
              <div>
                <Typography className={`mt-4 ${classes.NoReferalText1}`}>
                  There is no referral history yet
                </Typography>
                <Typography className={`mt-2 mb-3 ${classes.NoReferalText1}`}>
                  Please check back later
                </Typography>
              </div>
            ) : null}
            {applyPage && (
              <div style={{ height: 400, position: "relative" }}>
                <div className={classes.ModalHeaderSection}>
                  <Typography className={classes.ModalHEaderMainText}>
                    {ln.claim_you_referral_points}
                  </Typography>
                </div>
                <div className={`mb-5 mt-3`}>
                  <Typography>{ln.select_team}</Typography>
                  <Select
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    className={classes.selectMenuDepartment}
                    fullWidth
                    MenuProps={{
                      style: {
                        height: "250px",
                      },
                    }}
                    size="small"
                    value={team}
                    onChange={(e) => setTeam(e.target.value)}
                  >
                    <MenuItem
                      onClick={() => handleNavigateSubsPage()}
                      className={classes.AddNewSubsMenuItem}
                    >
                      <span
                        style={{ marginRight: "5px" }}
                        className={classes.NewSubAddIcon}
                      >
                        <RoundAddIcon />
                      </span>{" "}
                      {ln.create_new_subscription}
                    </MenuItem>
                    {teamList &&
                      teamList.length > 0 &&
                      teamList.map((item, index) => (
                        <MenuItem
                          key={index}
                          className={classes.AddMemberDepMenuItem}
                          value={item._id}
                        >
                          {item.team_name}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                <div>
                  <Button
                    variant="contained"
                    className={classes.refermodalSubmitBtn}
                    onClick={() => handleSubmit()}
                  >
                    {ln.submit}
                  </Button>
                </div>
              </div>
            )}
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default ReferHistoryModal;
