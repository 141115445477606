import React from "react";
import { Paper, Button, Typography, Snackbar, Alert } from "@mui/material";
import { TextCopyIcons } from "../../../components/Icons";
import image from "../../../assets/images/ReferEarn.png";
import steps from "../../../assets/images/refereanSteps.png";
import classes from "./ReferEarn.module.css";
import copy from "copy-to-clipboard";
import { useState } from "react";
import ReferHistoryModal from "./ReferHistoryModal";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { GET_USER } from "../../../api-calls/api-urls";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagedataFunction } from "../../../utilities/language";
import AlertBox from "../../../components/AlertBox";

const Referearn = () => {
  const dispatch = useDispatch();
  const [loggedUser, setLoggedUser] = useState();
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const [modalToggle, setModalToggle] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState();
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const modalClose = () => {
    setModalToggle(false);
  };

  const copyTrst = (text) => {
    copy(text);
    setShowAlert(true);
    setError("Code has Copied Successfully");
  };

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, 2000); // Change the duration (in milliseconds) as per your requirement

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showAlert]);

  const getCurrentLoggedUser = async () => {
    const { data } = await authApi.get(`${GET_USER}/${userInfo._id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    setLoggedUser(data);
  };
  useEffect(() => {
    getCurrentLoggedUser();
  }, [!loggedUser]);

  return (
    <div>
      {loggedUser && ln ? (
        <Paper className={classes.ReferEarnMainPaper}>
          <Typography className={`mb-4 ${classes.ReferTextMain}`}>
            {ln.earn_every_time_you_refer_someone_they_join}
          </Typography>
          <div className={`mb-4 ${classes.ReferEarnCopButton}`}>
            <Typography>{loggedUser.my_referral_code}</Typography> |{" "}
            <span onClick={() => copyTrst(loggedUser.my_referral_code)}>
              <TextCopyIcons />
            </span>
          </div>
          <div className="mb-4">
            <img src={image} alt="" />
          </div>
          <Typography className={`mb-5 ${classes.ReferTextSub}`}>
            {ln.how_it_works}
          </Typography>
          <div className="mb-5">
            <img src={steps} alt="" />
          </div>
          <Button
            variant="outlined"
            className={`${classes.ReferEarnMainButton}`}
            onClick={() => setModalToggle(true)}
          >
            {ln.your_referral_history}
          </Button>
        </Paper>
      ) : null}
      {modalToggle ? <ReferHistoryModal closeModal={modalClose} /> : null}
      {showAlert && error && (
        <AlertBox type="success" message={error} stateName={setShowAlert} />
      )}
    </div>
  );
};

export default Referearn;
