import React from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from "@mui/material";
import classes from "./index.module.css";
import moment from "moment";
import { useEffect } from "react";
import { authApi, getAuthorizationHeader } from "../api-calls/user-requests";
import { BaseUrl, DOWNLOAD_INVOICE, INVOICE_URL } from "../api-calls/api-urls";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagedataFunction } from "../utilities/language";

const data = [
  { id: 1, name: "John Doe", age: 25 },
  { id: 2, name: "Jane Smith", age: 30 },
  { id: 3, name: "Bob Johnson", age: 40 },
];

const MyTableBody = (props) => {
  const dispatch = useDispatch();
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  useEffect(() => {
    console.log(props.list);
  }, [props.list.length > 0]);

  const handleDownloadInvoice = async (item) => {
    const url = `${process.env.REACT_APP_API_URL}${DOWNLOAD_INVOICE}/${item.order_id}/${item.user_id}`;
    window.open(url, "_blank");
  };
  return (
    <div>
      {ln ? (
        <Table>
          <TableHead className={classes.TableHead}>
            <TableRow className={classes.tableHeadRow}>
              <TableCell
                className={`${classes.subscription_tableCell} ${classes.subs_heading_cell}`}
              >
                {ln.date}
              </TableCell>
              <TableCell
                className={`${classes.subscription_tableCell} ${classes.subs_heading_cell}`}
              >
                {ln.plan}
              </TableCell>
              <TableCell
                className={`${classes.subscription_tableCell} ${classes.subs_heading_cell}`}
              >
                {ln.price}
              </TableCell>
              <TableCell
                className={`${classes.subscription_tableCell} ${classes.subs_heading_cell}`}
              >
                {ln.invoice}
              </TableCell>
            </TableRow>
          </TableHead>
          {props.list && props.list.length > 0 ? (
            <TableBody className={classes.tableBody}>
              {props.list.map((item, index) => (
                <React.Fragment key={index}>
                  {item.order_id && (
                    <TableRow key={index} className={classes.tableBodyRow}>
                      <TableCell className={classes.subscription_tableCell}>
                        {moment(item.createdAt).format("DD MMM YYYY")}
                      </TableCell>
                      <TableCell className={classes.subscription_tableCell}>
                        {ln.price}{" "}
                        <span
                          style={{
                            textTransform: "capitalize",
                            fontSize: "12px",
                          }}
                        >
                          ({ln[item.duration_details.duration]}{" "}
                          {ln.subscriptions})
                        </span>
                      </TableCell>
                      <TableCell
                        className={`${classes.subscription_tableCell}`}
                      >
                        <div>
                          <span className={classes.AmountText}>
                            ₹{" "}
                            {item.duration_details.price
                              ? item.duration_details.price.$numberDecimal
                              : 0}
                          </span>
                          /{ln[item.duration_details.duration]}
                        </div>
                      </TableCell>
                      <TableCell
                        className={`${classes.subscription_tableCell} ${classes.DownloadInvoiceBtn}`}
                        onClick={() => handleDownloadInvoice(item)}
                      >
                        {ln.download_invoice}
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          ) : null}
        </Table>
      ) : null}
    </div>
  );
};

export default MyTableBody;
