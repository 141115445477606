import React, { useState } from "react";
import classes from "./index.module.css";
import image from "../../../assets/images/sucessImge.png";
import { MdClose } from "react-icons/md";
import { Modal, Typography, Box, Button } from "@mui/material";

const SuccessModal = (props) => {
  const rootRef = React.useRef(null);
  const [modalToggle, setModalToggle] = useState(true);

  const closeModal = () => {
    setModalToggle(false);
    props.modalClose(false);
  };

  return (
    <Modal
      open={modalToggle}
      onClose={() => closeModal()}
      aria-labelledby="server-modal-title"
      data-testid="register_modal"
      aria-describedby="server-modal-description"
      sx={{
        display: "flex",
        top: "10%",
        height: "fit-content",
        marginTop: 10,
        justifyContent: "center",
      }}
      container={() => rootRef.current}
    >
      <Box
        sx={{
          position: "relative",
          bgcolor: "background.paper",
          borderRadius: 1,
          boxShadow: (theme) => theme.shadows[5],
        }}
        style={{ width: "auto", height: "auto" }}
        className={classes.loginModalWidth}
      >
        <div className={classes.forgetPasswordmodalclose}>
          <Button
            variant="text"
            sx={{ minWidth: "unset" }}
            className={classes.closebtn}
            onClick={() => closeModal()}
          >
            <MdClose />
          </Button>
        </div>
        <div className="mb-4" style={{ minWidth: "450px" }}>
          <div className={classes.ModalHeadingSection}>
            <img
              src={image}
              width={150}
              height={150}
              alt="successImage"
              style={{ marginBottom: "30px", borderRadius: "50%" }}
            />
            <Typography className={classes.modalHeadind}>
              Created SuccessFully
            </Typography>
            <Typography
              className={classes.modalHeadin2}
              style={{ textAlign: "center", margin: "10px 0 25px" }}
            >
              {props.type && props.type === "feedback"
                ? "Your Feedback send Successfully"
                : "Your Request Has created SuccessFully"}
            </Typography>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default SuccessModal;
