import React, { useEffect } from "react";
import moment from "moment";
import classes from "./myteam.module.css";

const Stepper = ({ locationReport }) => {
  useEffect(() => {
    console.log("Location Report =====>", locationReport);
  }, [locationReport]);
  return (
    <div className={classes.stepper}>
      {locationReport && locationReport.length > 0 ? (
        <div className={classes.steppersMainContainer}>
          {locationReport.map((item, index) => {
            return (
              <div key={index}>
                <div
                  key={index}
                  className={`${classes.step} ${
                    item.status === "completed" ? classes.completed : ""
                  }`}
                >
                  <span className={classes.step_number}></span>
                  <div className={classes.stepLableContainer}>
                    <span className={classes.step_label}>
                      {item.Location.title}
                    </span>
                    <br />
                    <span className={classes.sub_step_label}>
                      {moment(item.Location.assigned_on).format(
                        "DD/MM/YYYY | hh:mm A"
                      )}
                    </span>
                  </div>
                </div>
                {index < locationReport.length - 1 && (
                  <div
                    className={`${classes.RouteLine} ${
                      item.status === "completed" ? classes.Completed : ""
                    }`}
                  />
                )}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default Stepper;
