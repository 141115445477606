import React, { useEffect, useState } from "react";
import {
  checkOnline,
  generateColor,
  getChipColor,
  stringAvatar,
} from "../utilities/commonFuntions";
import classes from "./index.module.css";
import {
  TableRow,
  TableCell,
  Badge,
  Chip,
  Avatar,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment";
import { useSelector } from "react-redux";

const LocationListItem = ({
  item,
  index,
  ln,
  openMenu,
  handleViewRoute,
  handleMenuclick1,
  handleClose,
}) => {
  const [isOnline, setIsOnline] = useState();
  const { teamMembersList } = useSelector((state) => state.reducers.allUsers);

  return (
    <React.Fragment key={index}>
      <TableRow key={index} className={classes.tableBodyRow}>
        <TableCell className={`${classes.tableBodyCell} ${classes.Tablecell1}`}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
            }}
          >
            <Badge
              color={
                checkOnline(item?.teamMember?._id) === "checkin"
                  ? "success"
                  : "error"
              }
              badgeContent=" "
              variant="dot"
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Avatar
                {...stringAvatar(item?.teamMember?.name || item?.user[0]?.name)}
                sx={{
                  bgcolor: `${generateColor(
                    item?.teamMember?.name || item?.user[0]?.name
                  )}`,
                  width: "37px",
                  height: "37px",
                }}
              />
            </Badge>
            <div className={classes.memberCardText}>
              {item.teamMember && item.teamMember.name
                ? item.teamMember.name
                : item.user[0].name}
            </div>
          </div>
        </TableCell>
        <TableCell className={`${classes.tableBodyCell} ${classes.Tablecell2}`}>
          {moment(item.createdAt || item.Location.createdAt).format(
            "DD MMM YYYY,hh:MM A"
          )}
        </TableCell>
        <TableCell className={`${classes.tableBodyCell} ${classes.Tablecell3}`}>
          {item.title || item.Location.title}
        </TableCell>
        <TableCell className={`${classes.tableBodyCell} ${classes.Tablecell4}`}>
          <Chip
            label={item.status || item.Location.status}
            size="small"
            style={getChipColor(item.status || item.Location.status)}
          />
        </TableCell>
        <TableCell className={`${classes.tableBodyCell} ${classes.Tablecell5}`}>
          {item.status === "rejected"
            ? item.user_location.rejection_notes
            : "NA"}
        </TableCell>
        <TableCell className={`${classes.tableBodyCell} ${classes.Tablecell6}`}>
          <Button
            variant="text"
            className={`${classes.CardMenu} ${classes.member}`}
            onClick={(e) => handleMenuclick1(e, item)}
          >
            <BsThreeDotsVertical />
          </Button>
          <Menu
            anchorEl={openMenu}
            open={Boolean(openMenu)}
            onClose={handleClose}
            MenuProps={{ classes: { paper: classes.VeiwRouteMenubar } }}
            // className={classes.VeiwRouteMenubar}
          >
            <MenuItem
              key={`edit${item.index}`}
              className={classes.EditMenuTeam}
              value={index}
              onClick={(e) => handleViewRoute(e)}
            >
              <span>View Route</span>
            </MenuItem>
            {/* {item.status === "reached" && (
                            <MenuItem
                                key={`delete${item.index}`}
                            // onClick={(e) =>
                            // }
                            >
                                <span>{ln?.delete_member}</span>
                            </MenuItem>
                        )} */}
          </Menu>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default LocationListItem;
