import { actionTypes } from "../constant/action-types";
const initialState = {
  pageLoader: false,
  togglesidebar: false,
  regUser: null,
  logedUSer: null,
  mobile_no: null,
  headerName: "home",
  language_json: null,
  refferal_history: null,
  current_user: null,
  notificationList: null,
  sortName: null,
  teamMembersList: [],
  markerList: [],
};

export const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.PAGE_LOADER:
      return { ...state, pageLoader: payload };
    case actionTypes.REGISTERED_USER:
      return { ...state, regUser: payload };
    case actionTypes.LOGGEDIN_USER:
      return { ...state, logedUSer: payload };
    case actionTypes.OTP_MOBILE:
      return { ...state, mobile_no: payload };
    case actionTypes.HEADER_NAME:
      return { ...state, headerName: payload };
    case actionTypes.LANGUAGE_JSON:
      return { ...state, language_json: payload };
    case actionTypes.REFFERAL_HISTORY:
      return { ...state, refferal_history: payload };
    case actionTypes.TEAM_MEMBERS_LIST:
      return { ...state, teamMembersList: payload };
    case actionTypes.CURRENT_USER:
      return { ...state, current_user: payload };
    case actionTypes.USER_COMPANY:
      return { ...state, company: payload };
    case actionTypes.TOGGLE_SIDEBAR:
      return { ...state, togglesidebar: payload };
    case actionTypes.MAP_MARKER_LIST:
      return { ...state, markerList: payload };
    case actionTypes.NOTIFICATION_LIST:
      return { ...state, notificationList: payload };
    case actionTypes.SORT_NAME:
      return { ...state, sortName: payload };
    default:
      return state;
  }
};
