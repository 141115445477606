import React, { useState } from "react";
import { Paper } from "@mui/material";
import classes from "./myteam.module.css";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { InputAdornment } from "@mui/material";
import {
  TeamMemberStatus,
  locationTimeFilter,
} from "../../../utilities/constants";
import { BsThreeDotsVertical } from "react-icons/bs";
import Button from "@mui/material/Button";
import {
  CardContent,
  MenuItem,
  Menu,
  Typography,
  Card,
  Avatar,
} from "@mui/material";
import { BsFilter } from "react-icons/bs";
import { stringAvatar, generateColor } from "../../../utilities/commonFuntions";
import { BiSortAlt2 } from "react-icons/bi";
import { FiChevronDown } from "react-icons/fi";
import image from "../../../assets/images/New team members-rafiki 1.png";
import { IoAddCircleOutline } from "react-icons/io5";
import AddMemberModal from "./AddMemberModal";
import { useDispatch, useSelector } from "react-redux";
import {
  CREATE_TEAM,
  DELETE_MEMBER,
  TEAM_LIST,
} from "../../../api-calls/api-urls";
import { MY_SUBS_AVAIL } from "../../../api-calls/api-urls";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { Form } from "react-bootstrap";
import { pageLoader } from "../../../Redux/actions/user-actions";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  currentEditTeam,
  saveTeamMemberStatusFilter,
} from "../../../Redux/actions/team-actions";
import { getLanguagedataFunction } from "../../../utilities/language";
import {
  ArrowDownIcon,
  BrudCrumbBackIcon,
  BrudCrumbNextIcon,
  DeleteBinIcons,
  EditPenIcon,
  FilterIcon,
  RoundAddIcon,
  SearchIcon,
} from "../../../components/Icons";
import AlertBox from "../../../components/AlertBox";
import { saveLocationTimeFilter } from "../../../Redux/actions/location-action";

const CreateTeam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [typecall, setTypeCall] = useState();
  const [selected, setSelected] = useState();
  const [teamName, setTeamName] = useState();
  const [serachValue, setSearchValue] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const [modletoggle1, setModleToggle1] = useState();
  const [error, setError] = useState();
  const [showAlert, setShowAlert] = useState();
  const [openMenu, setOpenMenu] = useState();
  const [plans, setPlans] = useState();
  const [members, setMembers] = useState([]);
  const [anchorEl1, setAnchorEl1] = useState();
  const [modletoggle, setModleToggle] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]); // State to store selected values
  const [selectedValues1, setSelectedValues1] = useState();
  const editTeam = useSelector((state) => state.reducers.teams.editTeam);
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  useEffect(() => {
    if (editTeam) {
      console.log("editTeam", editTeam);
      setTeamName(editTeam.team_name);
      setMembers(editTeam.team_members);
    }
  }, [editTeam]);
  const handleClick = (event, name) => {
    if (name === "ref1") {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl1(event.currentTarget);
    }
  };
  const handleMenuclick = (event, item) => {
    console.log(item);
    setSelected(item);
    setOpenMenu(event.currentTarget);
  };

  const getSubsription = async () => {
    if (!editTeam) {
      const { data } = await authApi.get(MY_SUBS_AVAIL, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      console.log(data);
      if (data.length > 0) {
        setPlans(data);
      } else {
        navigate("/select-subscription");
      }
    }
  };

  useEffect(() => {
    if (!editTeam) {
      getSubsription();
    }
  }, [!plans]);

  const handleStatusCheckbox = (value) => {
    const isSelected = selectedValues.includes(value);

    if (value === "all") {
      if (isSelected) {
        // Unselect all checkboxes
        setSelectedValues([]);
        dispatch(saveTeamMemberStatusFilter([]));
      } else {
        // Select all checkboxes
        setSelectedValues([...TeamMemberStatus]);
        dispatch(saveTeamMemberStatusFilter([...TeamMemberStatus]));
      }
    } else {
      // Toggle the selection of individual checkboxes
      let newSelectedValues = [];

      if (isSelected) {
        newSelectedValues = selectedValues.filter((v) => v !== value);
      } else {
        newSelectedValues = [...selectedValues, value];
      }
      setSelectedValues(newSelectedValues);
      dispatch(saveTeamMemberStatusFilter([newSelectedValues]));

      const areAllSelected = TeamMemberStatus.filter(
        (item) => item !== "all"
      ).every((item) => newSelectedValues.includes(item));

      // Check the "All" checkbox if all others are selected
      if (areAllSelected) {
        setSelectedValues((prevSelected) => [...prevSelected, "all"]);
        dispatch(saveTeamMemberStatusFilter([...newSelectedValues, "all"]));
      } else {
        // Uncheck the "All" checkbox if any other checkbox is unselected
        setSelectedValues((prevSelected) =>
          prevSelected.filter((item) => item !== "all")
        );
        dispatch(
          saveTeamMemberStatusFilter(
            newSelectedValues.filter((item) => item !== "all")
          )
        );
      }
    }
  };

  const handleTimeCheckBox = (value) => {
    setSelectedValues1(value);
    dispatch(saveLocationTimeFilter(value));
  };

  const handleMenuItemClick = async (e, name) => {
    e.stopPropagation();
    console.log(selected);
    if (name === "edit") {
      setModleToggle(true);
      setTypeCall("edit");
    } else if (name === "delete") {
      if (selected._id) {
        const entry = {
          _id: selected._id,
        };
        console.log("Entry ===>", entry);
        const { data } = await authApi.delete(
          `${TEAM_LIST}/${editTeam._id}${DELETE_MEMBER}`,
          {
            headers: { Authorization: getAuthorizationHeader() },
            data: entry,
          }
        );
        console.log(data);
        getOneTeamData(window.location.pathname);
      } else {
        const newList = members.filter(
          (item) => item.mobile_number !== selected.mobile_number
        );
        console.log(newList);
        setMembers(newList);
      }
      //getData();
    }
    handleClose();
  };
  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("/update")) {
      console.log(path);
      getOneTeamData(path.toLowerCase());
    }
  }, [!editTeam, selectedValues, selectedValues1, serachValue]);
  const getOneTeamData = async (path) => {
    const arr = path.split("/");
    console.log(arr[arr.length - 1]);
    const filterArray = {
      status: selectedValues,
    };
    const { data } = await authApi.post(
      `${TEAM_LIST}/${
        arr[arr.length - 1]
      }?search=${serachValue}&sortBy=${selectedValues1}`,
      filterArray,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    dispatch(currentEditTeam(data));
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl1(null);
    setOpenMenu(null);
  };
  const handleSubmit = async () => {
    const userEntry = {
      team_name: teamName,
      team_members: members,
    };
    console.log(userEntry);
    if (editTeam) {
      dispatch(currentEditTeam(null));
      const { data } = await authApi.put(
        `${CREATE_TEAM}/${editTeam._id}`,
        userEntry,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      console.log(data);
      navigate("/my-team");
    } else {
      const { data } = await authApi.post(CREATE_TEAM, userEntry, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      console.log(data);
      navigate("/my-team");
    }
  };
  const CloseForm = (status, data) => {
    console.log("this works", data);
    if (data) {
      const memberPResent = members.filter(
        (item) =>
          (item.id && item.id === data.id) || (item._id && item._id === data.id)
      );
      if (memberPResent.length > 0) {
        console.log("MEMber Present", memberPResent);
        const newState = members.map((item) => {
          console.log("Item", item);
          if (item.id === data.id) {
            return {
              ...item,
              user_name: data.user_name,
              mobile_number: data.mobile_number,
              department: data.department,
              department_name: data.department_name,
            };
          }
          if (item._id === data.id) {
            console.log("Match");
            return {
              ...item,
              user_name: data.user_name,
              mobile_number: data.mobile_number,
              department: data.department,
              department_name: data.department_name,
            };
          }
          console.log("Modified Item", item);
          return item;
        });
        console.log("New State", newState);
        setMembers(newState);
      } else {
        console.log("No Match");
        const check = members.filter(
          (item) => item.mobile_number === data.mobile_number
        );
        if (check.length > 0) {
          setError("There is already a member with this mobile number");
          setShowAlert(true);
        } else {
          const array = [...members, data];
          console.log(array);
          setMembers(array);
        }
      }
    }
    setModleToggle(status);
    setModleToggle1(status);
  };

  const handleAddMemberModal = () => {
    if (
      window.location.pathname.includes("create-team") &&
      plans &&
      plans.length > 0
    ) {
      if (plans && members.length < plans[0].no_team_members) {
        setModleToggle1(true);
        setSelected();
        setTypeCall("create");
      } else {
        setError("You can add only " + plans.no_team_members + " members");
        setShowAlert(true);
      }
    } else {
      if (members.length < editTeam.customer_subscription?.no_team_members) {
        setModleToggle1(true);
        setSelected();
        setTypeCall("create");
      } else {
        setError(
          "You can add only " +
            editTeam.customer_subscription?.no_team_members +
            " members"
        );
        setShowAlert(true);
      }
    }
  };

  const clickClearAll = (name) => {
    name([]);
  };

  return (
    <div>
      {ln ? (
        <div>
          <div className={`${classes.brudCrumb} mb-4 `}>
            <span onClick={() => navigate(-1)}>
              <BrudCrumbBackIcon />
            </span>
            <span onClick={() => navigate("/my-team")}>{ln.my_team}</span>
            <BrudCrumbNextIcon />
            <span style={{ color: "#6A6E8A" }}>
              {editTeam ? editTeam.team_name : "Create Team"}
            </span>
          </div>
          <Paper
            className={`${classes.CreateTeamindex} ${classes.createTeamMaindiv}`}
          >
            <ValidatorForm useref="form" onSubmit={handleSubmit}>
              <div>
                <Typography className={classes.createTeamHeading}>
                  {ln.create_team}
                </Typography>
                <div className="mb-4 mt-3">
                  <label
                    htmlFor="firstNameinput"
                    className={classes.form_lable}
                  >
                    {ln.team_name}*
                  </label>
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    id="firstNameinput"
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                    size="small"
                    validators={["required"]}
                    errorMessages={["Team Name is required"]}
                  />
                </div>
              </div>
              <hr />
              <div>
                <div
                  className={`mt-3 ${classes.teamMembersmainDiv}`}
                  style={{ alignItems: "center" }}
                >
                  <Typography className={classes.createTeamHeading}>
                    {ln.team_members}
                  </Typography>
                  <Typography className={classes.form_lable}>00/10</Typography>
                </div>
                <div
                  className={classes.teamMembersmainDiv}
                  style={{ height: "auto" }}
                >
                  <div>
                    <div className="">
                      <TextValidator
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                        placeholder="Search"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        className={classes.teamMemberSearch}
                        id="firstNameinput"
                        value={serachValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        size="small"
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", columnGap: 10 }}>
                    <div style={{ width: "auto" }}>
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        variant="outlined"
                        onClick={(e) => handleClick(e, "ref1")}
                        fullWidth
                        className={classes.filterbtns}
                      >
                        <div
                          className={classes.form_lable}
                          style={{ textTransform: "capitalize" }}
                        >
                          <FilterIcon /> All
                        </div>
                        <div>
                          <ArrowDownIcon />
                        </div>
                      </Button>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <div className={classes.filterHeading}>
                          <h5>Filter</h5>
                          <a
                            className={classes.claerBtn}
                            onClick={() => clickClearAll(setSelectedValues)}
                          >
                            clear all
                          </a>
                        </div>
                        {TeamMemberStatus.map((item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              style={{
                                padding: "6px 25px 6px 0",
                                textTransform: "capitalize",
                              }}
                            >
                              <Form.Check
                                style={{
                                  margin: 10,
                                  marginRight: 15,
                                }}
                                checked={selectedValues?.includes(item)}
                                onChange={() => handleStatusCheckbox(item)}
                                name="group1"
                                type="checkbox"
                              />
                              {/* <Checkbox
                            checked={selectedValues.includes(item)}
                            onChange={() => handleStatusCheckbox(item)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          /> */}
                              {item.replaceAll("_", " ")}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </div>
                    <div style={{ minWidth: "180px" }}>
                      <Button
                        id="basic-button1"
                        aria-controls={open1 ? "basic-menu1" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open1 ? "true" : undefined}
                        onClick={(e) => handleClick(e, "ref2")}
                        variant="outlined"
                        fullWidth
                        className={classes.filterbtns}
                      >
                        <div
                          className={classes.form_lable}
                          style={{
                            textTransform: "capitalize",
                            marginRight: "7px",
                          }}
                        >
                          <BiSortAlt2 /> Newest to Oldest
                        </div>
                        <div>
                          <ArrowDownIcon />
                        </div>
                      </Button>
                      <Menu
                        anchorEl={anchorEl1}
                        open={Boolean(anchorEl1)}
                        onClose={handleClose}
                      >
                        <div className={classes.filterHeading}>
                          <h5>Sort By</h5>
                          <a
                            className={classes.claerBtn}
                            onClick={() => clickClearAll(setSelectedValues1)}
                          >
                            clear all
                          </a>
                        </div>
                        {locationTimeFilter.map((item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              style={{
                                padding: "6px 25px 6px 0",
                                textTransform: "capitalize",
                              }}
                            >
                              <Form.Check
                                style={{
                                  margin: 10,
                                  marginRight: 15,
                                }}
                                checked={selectedValues1?.includes(item)}
                                onChange={() => handleTimeCheckBox(item)}
                                name="group1"
                                type="checkbox"
                              />
                              {/* <Checkbox
                            checked={selectedValues1 === item}
                            onChange={() => handleTimeCheckBox(item)}
                          /> */}
                              {item.replaceAll("_", " ")}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </div>
                  </div>
                </div>
                {members && members.length > 0 ? (
                  <div className={`${classes.indexpageMainBtn} mt-4`}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleAddMemberModal();
                      }}
                    >
                      <IoAddCircleOutline style={{ marginRight: "5px" }} />
                      {ln.add_member}
                    </Button>
                  </div>
                ) : null}
                <div className={classes.CreateTeamindex}>
                  {members && members.length > 0 ? (
                    <div>
                      <div>
                        <div
                          className={`${classes.GridContainer} ${classes.TeamMemberGridcontaoiner}`}
                        >
                          {members.map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                {item.user_name || item.name ? (
                                  <Card
                                    key={item.index}
                                    className={classes.teamCard}
                                  >
                                    <CardContent
                                      sx={{ paddingBottom: "16px !important" }}
                                    >
                                      <div
                                        className={`${classes.teamCardContainer} ${classes.teamMembercardContainer}`}
                                      >
                                        {item.user_name || item.name ? (
                                          <Avatar
                                            {...stringAvatar(
                                              item.user_name || item.name
                                            )}
                                            sx={{
                                              bgcolor: `${generateColor(
                                                item.user_name || item.name
                                              )}`,
                                            }}
                                          />
                                        ) : null}
                                        {item.label}
                                        <div className={classes.memberCardText}>
                                          <Typography
                                            className={classes.form_lable}
                                          >
                                            {ln.team_member}
                                          </Typography>
                                          <Typography
                                            className={classes.CardValues}
                                          >
                                            {item.user_name || item.name}
                                          </Typography>
                                        </div>
                                        <div className={classes.cardMenuButton}>
                                          <Button
                                            variant="text"
                                            className={`${classes.CardMenu} ${classes.member}`}
                                            onClick={(e) =>
                                              handleMenuclick(e, item)
                                            }
                                          >
                                            <BsThreeDotsVertical />
                                          </Button>
                                          <Menu
                                            anchorEl={openMenu}
                                            open={Boolean(openMenu)}
                                            onClose={handleClose}
                                          >
                                            <MenuItem
                                              key={`edit${item.index}`}
                                              className={classes.EditMenuTeam}
                                              value={index}
                                              onClick={(e) =>
                                                handleMenuItemClick(e, "edit")
                                              }
                                            >
                                              <EditPenIcon />
                                              <span>{ln.edit_member}</span>
                                            </MenuItem>
                                            <MenuItem
                                              key={`delete${item.index}`}
                                              onClick={(e) =>
                                                handleMenuItemClick(e, "delete")
                                              }
                                            >
                                              <DeleteBinIcons />
                                              <span>{ln.delete_member}</span>
                                            </MenuItem>
                                          </Menu>
                                        </div>
                                      </div>
                                      <hr />
                                      <div className={classes.membercardFooter}>
                                        <div
                                          className={`${classes.memberCardText} ${classes.memberTextsubContainer}`}
                                        >
                                          <Typography
                                            className={classes.form_lable}
                                          >
                                            {ln.mobile_number}
                                          </Typography>
                                          <Typography
                                            className={classes.CardValues}
                                          >
                                            +91 {item.mobile_number}
                                          </Typography>
                                        </div>
                                        <div
                                          className={`${classes.memberCardText} ${classes.memberTextsubContainer}`}
                                        >
                                          <Typography
                                            className={classes.form_lable}
                                          >
                                            {ln.departments}
                                          </Typography>
                                          <Typography
                                            className={classes.CardValues}
                                          >
                                            {item && item.department_name
                                              ? item.department_name
                                              : item &&
                                                item.department_data &&
                                                item.department_data.length >
                                                  0 &&
                                                item.department_data[0]
                                                  .department_name
                                              ? item.department_data[0]
                                                  .department_name
                                              : null}
                                          </Typography>
                                        </div>
                                      </div>
                                    </CardContent>
                                  </Card>
                                ) : null}
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.imagePagemaindiv}>
                      <img
                        src={image}
                        alt="createTeam"
                        className={classes.addmemberImage}
                      />
                      <Typography
                        className={`${classes.imagePageText} ${classes.createTeamImageText}`}
                        variant="h5"
                      >
                        {ln.no_member_data}
                      </Typography>
                      <Button
                        variant="text"
                        className={classes.imagepagebtn}
                        onClick={() => {
                          setModleToggle1(true);
                          setSelected();
                          setTypeCall("create");
                        }}
                      >
                        <RoundAddIcon />
                        {ln.add_member}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.CreateTeamSubmitSection}>
                <Button
                  variant="outlined"
                  style={{
                    textTransform: "capitalize",
                    border: "2px solid #1976d2",
                    color: "#1976d2",
                  }}
                  onClick={() => navigate("/my-team")}
                >
                  {ln.cancel}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  style={{ textTransform: "capitalize" }}
                >
                  {ln.submit}
                </Button>
              </div>
            </ValidatorForm>
          </Paper>
          {modletoggle && editTeam && selected ? (
            <AddMemberModal
              modalClose={CloseForm}
              id={editTeam._id}
              user={selected}
              name={typecall}
            />
          ) : null}
          {modletoggle && selected ? (
            <AddMemberModal
              modalClose={CloseForm}
              name={typecall}
              user={selected}
            />
          ) : null}
          {modletoggle1 ? (
            <AddMemberModal modalClose={CloseForm} name={typecall} />
          ) : null}
          {showAlert && error && (
            <AlertBox type="error" message={error} stateName={setShowAlert} />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default CreateTeam;
