import { actionTypes } from "../constant/action-types";
const initialState = {
  companyAddressList: [],
  addSuccess: false,
  addFailure: false,
  deleteSuccess: false,
  deleteFailure: false,
};

export const addressReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.COMPANY_ALL_ADDRESS:
      return { ...state, companyAddressList: payload };
      case actionTypes.ADD_ADDRESS_SUCCESS:
        return { ...state, addSuccess: payload };
        case actionTypes.ADD_ADDRESS_FAILURE:
          return { ...state, addFailure: payload };
          case actionTypes.DELETE_ADDRESS_SUCCESS:
        return { ...state, deleteSuccess: payload };
        case actionTypes.DELETE_ADDRESS_FAILURE:
          return { ...state, deleteFailure: payload };
    default:
      return state;
  }
};
