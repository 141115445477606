import React, { useState } from "react";
import {
  Modal,
  Button,
  InputAdornment,
  Select,
  Typography,
} from "@mui/material";
import { MdClose } from "react-icons/md";
import { Avatar } from "@mui/material";
import successimage from "../../../assets/images/sucessImge.png";
import { MenuItem } from "@mui/material";
import {
  Alphabetic,
  Numeric,
  generateRandomStringforMap,
} from "../../../utilities/commonFuntions";
import { IoAddCircleOutline } from "react-icons/io5";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Box from "@mui/material/Box";
import classes from "./myteam.module.css";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import {
  ADD_DEPARTMENT,
  ADD_MEMBERS,
  TEAM_LIST,
  UPDATE_MEMBER,
} from "../../../api-calls/api-urls";
import {
  currentEditTeam,
  departmentLists,
  teamsList,
} from "../../../Redux/actions/team-actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AddDepartmentModal from "./AddDepartmentModel";
import { getLanguagedataFunction } from "../../../utilities/language";
import { ContactIcon } from "../../../components/Icons";
import AlertBox from "../../../components/AlertBox";

const AddMemberModal = (props) => {
  const dispatch = useDispatch();
  const [modalToggle, setModalToggle] = useState(true);
  const [departmentModal, setDepartmentModal] = useState();
  const [fullName, setFullName] = useState("");
  const [success, setSuccess] = useState();
  const [editUSerID, setEditUSerID] = useState();
  const [team, setTeam] = useState();
  const [showAlert, setShowAlert] = useState();
  const [error, setError] = useState();
  const [mobile, setMobile] = useState();
  const rootRef = React.useRef(null);
  const [department, setDepartment] = useState();
  const departmentList = useSelector(
    (state) => state.reducers.teams.departmentList
  );
  const teamlists = useSelector((state) => state.reducers.teams.teamsList);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const userEntry = {
    user_name: fullName,
    mobile_number: mobile,
    department: department,
  };
  const getDEpartmnetData = async () => {
    const { data } = await authApi.get(ADD_DEPARTMENT, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    dispatch(departmentLists(data));
  };
  const getDEpartmnetName = (id) => {
    const dep = departmentList.find((item) => item._id === id);
    return dep.department_name;
  };
  useEffect(() => {
    getDEpartmnetData();
  }, [!departmentList]);
  const getOneTeamData = async (path) => {
    const arr = path.split("/");
    console.log(arr[arr.length - 1]);
    const { data } = await authApi.post(
      `${TEAM_LIST}/${arr[arr.length - 1]}`,
      {},
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    dispatch(currentEditTeam(data));
  };

  function modalClose() {
    if (props.name === "create") {
      if (fullName && department && mobile) {
        userEntry.id = generateRandomStringforMap();
        userEntry.department_name = getDEpartmnetName(department);
        userEntry.time = new Date();
        props.modalClose(false, userEntry);
      } else {
        props.modalClose(false, null);
      }
    } else if (props.name === "edit" && !props.id) {
      if (fullName && department && mobile) {
        userEntry.id = editUSerID;
        userEntry.department_name = getDEpartmnetName(department);
        props.modalClose(false, userEntry);
      } else {
        props.modalClose(false, null);
      }
    } else {
      props.modalClose(false, null);
    }
    setModalToggle(false);
  }
  const getData = async () => {
    const { data } = await authApi.get(TEAM_LIST, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    dispatch(teamsList(data));
  };

  useEffect(() => {
    console.log("User Send from parent", props.user);
    if (props.user && props.name === "edit") {
      setFullName(props.user.user_name);
      setMobile(props.user.mobile_number);
      setDepartment(props?.user?.department);
      setEditUSerID(props.user.id || props.user._id);
    } else if (props.user && props.name === "edit-from-map") {
      setFullName(props.user.name);
      setMobile(props.user.phone);
      setDepartment(props?.user?.department?._id);
      setEditUSerID(props.user.user_id);
      getData();
    }
  }, []);
  const closeDepModal = () => {
    setDepartmentModal(false);
    getDEpartmnetData();
  };
  const handleAddDepartMent = () => {
    setDepartmentModal(true);
  };
  const handleSubmit = async () => {
    if (fullName && mobile && department) {
      try {
        const { data } = await authApi.get(`/users/is-leader/${mobile}`, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        if (props.name === "new") {
          const userData = {
            members: [userEntry],
          };
          console.log(userEntry);
          try {
            const { data } = await authApi.post(
              `${TEAM_LIST}/${props.id}${ADD_MEMBERS}`,
              userData,
              {
                headers: { Authorization: getAuthorizationHeader() },
              }
            );
            console.log(data);
            getOneTeamData(window.location.pathname);
            getData();
            setSuccess(true);
          } catch (error) {
            setShowAlert(true);
            setError(error.response.data.message || error.response.data.error);
          }
        } else if (props.name === "edit" && props.id) {
          const userData = {
            _id: props.user._id,
            name: fullName,
            mobile_number: mobile,
            department: department,
          };
          try {
            const { data } = await authApi.put(
              `${TEAM_LIST}/${props.id}${UPDATE_MEMBER}`,
              userData,
              {
                headers: { Authorization: getAuthorizationHeader() },
              }
            );
            console.log(data);
            getData();
            getOneTeamData(window.location.pathname);
            modalClose();
          } catch (error) {
            setShowAlert(true);
            setError(error.response.data.message || error.response.data.error);
          }
        } else if (props.name === "edit-from-map" && !props.id) {
          const userData = {
            _id: props.user._id,
            name: fullName,
            mobile_number: mobile,
            department: department,
          };
          try {
            const { data } = await authApi.put(
              `${TEAM_LIST}/${team}${UPDATE_MEMBER}`,
              userData,
              {
                headers: { Authorization: getAuthorizationHeader() },
              }
            );
            console.log(data);
            getData();
            modalClose();
          } catch (error) {
            setShowAlert(true);
            setError(error.response.data.message || error.response.data.error);
          }
        } else if (props.name === "edit" && !props.id) {
          setSuccess(true);
        } else if (props.name === "create") {
          setSuccess(true);
        } else {
          modalClose();
        }
      } catch (err) {
        setError(err.response?.data?.message);
        setShowAlert(true);
      }
    }
  };
  return (
    <div>
      {ln ? (
        <div>
          <Modal
            open={modalToggle}
            onClose={() => modalClose()}
            aria-labelledby="server-modal-title"
            data-testid="register_modal"
            aria-describedby="server-modal-description"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            container={() => rootRef.current}
          >
            <Box
              sx={{
                position: "relative",
                bgcolor: "background.paper",
                borderRadius: 1,
                boxShadow: (theme) => theme.shadows[5],
              }}
              style={{
                width: success ? "500px" : "460px",
                height: "max-content",
              }}
              className={classes.loginModalWidth}
            >
              <Button
                variant="text"
                sx={{ minWidth: "unset" }}
                className={classes.closebtn}
                onClick={() => modalClose()}
              >
                <MdClose />
              </Button>
              {!success ? (
                <div>
                  <div className={classes.AddMemberHeading}>
                    <Typography>{ln.add_team_member}</Typography>
                  </div>
                  <ValidatorForm useref="form" onSubmit={handleSubmit}>
                    <div className={classes.modelInputsContainer}>
                      <div className={classes.inputs}>
                        <label
                          htmlFor="lastNameinput"
                          className={classes.form_lable}
                        >
                          {ln.name}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                          id="lastNameinput"
                          value={fullName}
                          fullWidth
                          size="small"
                          onChange={(e) => setFullName(e.target.value)}
                          onKeyPress={(e) => Alphabetic(e)}
                          validators={["required"]}
                          errorMessages={["Name is required"]}
                        />
                      </div>
                      <div className={classes.inputs}>
                        <label
                          htmlFor="phonenumberInput"
                          className={classes.form_lable}
                        >
                          {ln.mobile_number}
                          {"*"}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                          inputProps={{
                            maxLength: 10,
                          }}
                          disabled={props.name.includes("edit")}
                          id="phonenumberInput"
                          value={mobile}
                          size="small"
                          fullWidth
                          onChange={(e) => setMobile(e.target.value)}
                          onKeyPress={(e) => Numeric(e)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +91
                              </InputAdornment>
                            ),
                            // endAdornment: (
                            //   <InputAdornment position="end">
                            //     <ContactIcon />
                            //   </InputAdornment>
                            // ),
                          }}
                          validators={["required", "matchRegexp:[0-9]{10}"]}
                          errorMessages={[
                            "This field is required",
                            "Please Enter the 10 digit mobile number",
                          ]}
                          helperText={
                            props.name.includes("edit")
                              ? "Can't edit Mobile Number"
                              : ""
                          }
                        />
                      </div>
                      {props.name && props.name === "edit-from-map" && (
                        <div className={classes.inputs}>
                          <label
                            htmlFor="phonenumberInput"
                            className={classes.form_lable}
                          >
                            Select Team
                            {"*"}
                          </label>
                          <Select
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                            className={classes.selectMenuDepartment}
                            fullWidth
                            MenuProps={{
                              style: {
                                height: "250px",
                              },
                            }}
                            size="small"
                            value={team}
                            onChange={(e) => setTeam(e.target.value)}
                          >
                            {teamlists &&
                              teamlists?.length > 0 &&
                              teamlists
                                ?.filter((item) => {
                                  const isPresent = item.team_members.filter(
                                    (member) => {
                                      return (
                                        member.mobile_number ===
                                        props.user.phone
                                      );
                                    }
                                  );
                                  if (isPresent.length > 0) {
                                    return item;
                                  }
                                })
                                ?.map((item) => (
                                  <MenuItem
                                    className={classes.AddMemberDepMenuItem}
                                    value={item._id}
                                  >
                                    {item.team_name}
                                  </MenuItem>
                                ))}
                          </Select>
                        </div>
                      )}
                      <div className={classes.inputs}>
                        <label
                          htmlFor="phonenumberInput"
                          className={classes.form_lable}
                        >
                          {ln.department}
                        </label>
                        <Select
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                          className={classes.selectMenuDepartment}
                          fullWidth
                          MenuProps={{
                            style: {
                              height: "250px",
                            },
                          }}
                          size="small"
                          value={
                            department
                              ? department
                              : props.user && props.user.department
                              ? props.user.department
                              : null
                          }
                          onChange={(e) => setDepartment(e.target.value)}
                        >
                          <MenuItem
                            onClick={() => handleAddDepartMent()}
                            className={classes.AddDepMainBtn}
                          >
                            <IoAddCircleOutline
                              style={{ marginRight: "10px" }}
                            />{" "}
                            {ln.add_department}
                          </MenuItem>
                          {departmentList &&
                            departmentList.length > 0 &&
                            departmentList.map((item, index) => (
                              <MenuItem
                                key={index}
                                className={classes.AddMemberDepMenuItem}
                                value={item._id}
                              >
                                <Avatar
                                  sx={{
                                    bgcolor: `${item.colour}`,
                                    width: "15px",
                                    height: "15px",
                                    marginRight: "5px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {" "}
                                </Avatar>{" "}
                                | {item.department_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                      <div className={classes.addMemberModalbtnSection}>
                        <Button
                          variant="outlined"
                          style={{
                            border: "2px solid #1976d2",
                            color: "#1976d2",
                          }}
                          onClick={() => modalClose()}
                        >
                          cancel
                        </Button>
                        <Button variant="contained" type="submit">
                          {ln.add}
                        </Button>
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              ) : (
                <div className={classes.addMembersuccess}>
                  <img
                    src={successimage}
                    alt="Success logo"
                    width={"auto"}
                    height={"auto"}
                  />
                  <Typography className={classes.imagePageText}>
                    {ln.member_added_header}
                  </Typography>
                  <Typography className={classes.successPageSubText}>
                    {ln.member_added_subheader}
                  </Typography>
                </div>
              )}
            </Box>
          </Modal>
          {departmentModal ? (
            <AddDepartmentModal modalClose={closeDepModal} />
          ) : null}
        </div>
      ) : null}
      {showAlert && error && (
        <AlertBox type="error" message={error} stateName={setShowAlert} />
      )}
    </div>
  );
};

export default AddMemberModal;
