import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  Polyline,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import classes from "./index.module.css";
import { BsArrowLeftShort } from "react-icons/bs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  filterLocationsWithinDistance,
  generateColor,
  getChipColor,
} from "../../../utilities/commonFuntions";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  LOCATION,
  MAP_ROUTE_LIST,
  VIEW_HISTORY,
} from "../../../api-calls/api-urls";
import { Tooltip } from "react-tooltip";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  Collapse,
  Typography,
  Chip,
  Button,
} from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";
import { useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FilterContainer from "./filterContainer";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Map() {
  const navigate = useNavigate();
  const location = useLocation();
  const date = location?.state?.date || null;
  const [markerList, setMarkerList] = useState([]);
  const [stopPoints, setStopPoints] = useState([]);
  const [tooltipIndex, setTooltipIndex] = useState(null);
  const [activePointer, setActivePointer] = useState(null);
  const [fromDate, setFromDate] = useState();
  const [travelHistory, setTravelHistory] = useState([]);
  const [viewTag, setViewTag] = useState();
  const [totalDistance, setTotalDistance] = useState(0);
  const [polylinePath, setPolylinePath] = useState([]);
  const [mapcenter, setMapcenter] = useState({});
  const [startLocation, setStartLocaion] = useState();
  const [endLocation, setEndLocation] = useState();
  const markerRef = useRef(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [activeLocation, setActiveLocation] = useState();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getViewRouteData = async (date) => {
    const path = window.location.pathname.split("/");
    const { data } = await authApi.get(
      `${MAP_ROUTE_LIST}/${path[path.length - 1]}?date=${moment(date).format(
        "YYYY-MM-DD"
      )}`,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    console.log("MarkerList", data.locations);
    setMarkerList(data.locations);
    const data1 = await authApi.get(
      `${VIEW_HISTORY}/${data.user_id}?date=${moment(date).format(
        "YYYY-MM-DD"
      )}`,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    console.log(data1.data);
    console.log("RTRABELEDALL HISROER", data1.data);
    // setTravelHistory([data1?.data?.original]);
    setTravelHistory(data1?.data?.data);
    console.log(data1?.data?.data[0][0]);
    setMapcenter(data1?.data?.data[0][0]?.location_info);
    setStopPoints(data1?.data?.stopPoints);
    const totalDis = data1?.data?.distance / 1000;
    console.log(totalDis);
    setTotalDistance(totalDis.toFixed(2));
  };

  useEffect(() => {
    getViewRouteData(moment(new Date()).format("YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    // console.log(date, location);
    if (date) {
      // console.log(date);
      // const date = day
      dateObject(dayjs(date).$d);
    }
  }, [date]);

  useEffect(() => {
    getRouteDirections();
  }, [travelHistory]);

  const getRouteDirections = async () => {
    // ... existing code ...
    console.log("travelHistory", travelHistory);
    if (travelHistory.length > 0) {
      const array = [];
      travelHistory.map((item) => {
        if (item && item.length > 0) {
          const locations = item
            .filter(
              (location) =>
                typeof location === "object" &&
                !isNaN(parseFloat(location?.location_info?.lat)) &&
                !isNaN(parseFloat(location?.location_info?.lng))
            )
            .map((location) => ({
              lat: parseFloat(location?.location_info?.lat),
              lng: parseFloat(location?.location_info?.lng),
            }));

          array.push(locations);
        }
      });
      console.log("Polline Path ===> ", array, travelHistory);
      setPolylinePath(array);
      // setPolylinePath(
      //   travelHistory.map((item) => ({
      //     lat: parseFloat(item.location_info.lat),
      //     lng: parseFloat(item.location_info.lng),
      //   }))
      // );
    }
    // let waypoints = []
    // // Calculate the waypoints for all the locations except the start and end locations
    // if (travelHistory.length > 0) {
    //   const maxWaypointsPerSegment = 15;
    //   for (let i = 0; i < travelHistory.length; i += maxWaypointsPerSegment) {
    //     waypoints.push({
    //       location: {
    //         lat: parseFloat(travelHistory[i].location_info.lat),
    //         lng: parseFloat(travelHistory[i].location_info.lng),
    //       }
    //     })
    //   }
    //   console.log("Location Waypoitisfshg", waypoints);

    //   try {
    //     const response = await new Promise((resolve, reject) => {
    //       const directionsService = new window.google.maps.DirectionsService();
    //       directionsService.route(
    //         {
    //           origin: {
    //             lat: parseFloat(travelHistory[0].location_info.lat),
    //             lng: parseFloat(travelHistory[0].location_info.lng), // Corrected
    //           },
    //           destination: {
    //             lat: parseFloat(travelHistory[travelHistory.length - 1].location_info.lat),
    //             lng: parseFloat(travelHistory[travelHistory.length - 1].location_info.lng), // Corrected
    //           },
    //           waypoints: waypoints,
    //           travelMode: "DRIVING",
    //         },
    //         (result, status) => {
    //           if (status === window.google.maps.DirectionsStatus.OK) {
    //             resolve(result);
    //           } else {
    //             reject(status);
    //           }
    //         }
    //       );
    //     });
    //     console.log(response);

    //     let dis = 0;

    //     if (response && response.routes.length > 0) {
    //       response.routes[0].legs.map((item) => {
    //         dis += parseFloat(item.distance.text)
    //       });

    //       console.log(dis.toFixed(2));
    //       // setTotalDistance(dis.toFixed(2));
    //     }
    //     setDirections(response);
    //   } catch (error) {
    //     console.log("Error fetching directions:", error);
    //   }
    // } else {
    //   setDirections(null);
    // }
  };

  const getMarkerSVG = (item) => {
    if (item.status === "pending") {
      const isSelectedMarker = activeMarker && activeMarker === item.title;

      if (isSelectedMarker) {
        return `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.25" y="0.25" width="47.5" height="47.5" rx="3.75" fill="#D09D1C" stroke="#D09D1C" stroke-width="0.5"/>
      <path d="M8.66626 15.3345L8.66686 15.3339C9.22604 14.7738 9.88349 14.5007 10.6663 14.5007H17.333H17.833V14.0007V10.6673C17.833 9.88444 18.1062 9.22627 18.6666 8.66587C19.2257 8.10676 19.8832 7.83398 20.6663 7.83398H27.333C28.1159 7.83398 28.774 8.10663 29.3343 8.66569C29.8936 9.226 30.1663 9.88422 30.1663 10.6673V14.0007V14.5007H30.6663H37.333C38.1159 14.5007 38.774 14.7738 39.3345 15.3342C39.8936 15.8933 40.1663 16.5509 40.1663 17.334V35.6673C40.1663 36.4503 39.8936 37.1085 39.3345 37.6688C38.7742 38.228 38.116 38.5007 37.333 38.5007H10.6663C9.88321 38.5007 9.22567 38.2279 8.66656 37.6688C8.10616 37.1084 7.83301 36.4502 7.83301 35.6673V17.334C7.83301 16.5511 8.10613 15.8937 8.66626 15.3345ZM20.1663 14.0007V14.5007H20.6663H27.333H27.833V14.0007V10.6673V10.1673H27.333H20.6663H20.1663V10.6673V14.0007Z" fill="white" stroke="white"/>
      </svg>
      `;
      } else {
        return `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.25" y="0.25" width="47.5" height="47.5" rx="3.75" fill="#F0EEE8" stroke="#D09D1C" stroke-width="0.5"/>
        <path d="M8.66626 15.3345L8.66686 15.3339C9.22604 14.7738 9.88349 14.5007 10.6663 14.5007H17.333H17.833V14.0007V10.6673C17.833 9.88444 18.1062 9.22627 18.6666 8.66587C19.2257 8.10676 19.8832 7.83398 20.6663 7.83398H27.333C28.1159 7.83398 28.774 8.10663 29.3343 8.66569C29.8936 9.226 30.1663 9.88422 30.1663 10.6673V14.0007V14.5007H30.6663H37.333C38.1159 14.5007 38.774 14.7738 39.3345 15.3342C39.8936 15.8933 40.1663 16.5509 40.1663 17.334V35.6673C40.1663 36.4503 39.8936 37.1085 39.3345 37.6688C38.7742 38.228 38.116 38.5007 37.333 38.5007H10.6663C9.88321 38.5007 9.22567 38.2279 8.66656 37.6688C8.10616 37.1084 7.83301 36.4502 7.83301 35.6673V17.334C7.83301 16.5511 8.10613 15.8937 8.66626 15.3345ZM20.1663 14.0007V14.5007H20.6663H27.333H27.833V14.0007V10.6673V10.1673H27.333H20.6663H20.1663V10.6673V14.0007Z" fill="#D09D1C" stroke="#D09D1C"/>
        </svg>
        `;
      }
    } else if (item.status === "completed") {
      // console.log(item);
      const isSelectedMarker = activeMarker && activeMarker === item.title;

      if (isSelectedMarker) {
        return `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.25" y="0.25" width="47.5" height="47.5" rx="3.75" fill="#1A6B44" stroke="#1A6B44" stroke-width="0.5"/>
      <path d="M8.66626 15.3345L8.66686 15.3339C9.22604 14.7738 9.88349 14.5007 10.6663 14.5007H17.333H17.833V14.0007V10.6673C17.833 9.88444 18.1062 9.22627 18.6666 8.66587C19.2257 8.10676 19.8832 7.83398 20.6663 7.83398H27.333C28.1159 7.83398 28.774 8.10663 29.3343 8.66569C29.8936 9.226 30.1663 9.88422 30.1663 10.6673V14.0007V14.5007H30.6663H37.333C38.1159 14.5007 38.774 14.7738 39.3345 15.3342C39.8936 15.8933 40.1663 16.5509 40.1663 17.334V35.6673C40.1663 36.4503 39.8936 37.1085 39.3345 37.6688C38.7742 38.228 38.116 38.5007 37.333 38.5007H10.6663C9.88321 38.5007 9.22567 38.2279 8.66656 37.6688C8.10616 37.1084 7.83301 36.4502 7.83301 35.6673V17.334C7.83301 16.5511 8.10613 15.8937 8.66626 15.3345ZM20.1663 14.0007V14.5007H20.6663H27.333H27.833V14.0007V10.6673V10.1673H27.333H20.6663H20.1663V10.6673V14.0007Z" fill="white" stroke="white"/>
      </svg>
      `;
      } else {
        return `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.25" y="0.25" width="47.5" height="47.5" rx="3.75" fill="#F1F6F3" stroke="#1A6B44" stroke-width="0.5"/>
      <path d="M8.66626 15.3345L8.66686 15.3339C9.22604 14.7738 9.88349 14.5007 10.6663 14.5007H17.333H17.833V14.0007V10.6673C17.833 9.88444 18.1062 9.22627 18.6666 8.66587C19.2257 8.10676 19.8832 7.83398 20.6663 7.83398H27.333C28.1159 7.83398 28.774 8.10663 29.3343 8.66569C29.8936 9.226 30.1663 9.88422 30.1663 10.6673V14.0007V14.5007H30.6663H37.333C38.1159 14.5007 38.774 14.7738 39.3345 15.3342C39.8936 15.8933 40.1663 16.5509 40.1663 17.334V35.6673C40.1663 36.4503 39.8936 37.1085 39.3345 37.6688C38.7742 38.228 38.116 38.5007 37.333 38.5007H10.6663C9.88321 38.5007 9.22567 38.2279 8.66656 37.6688C8.10616 37.1084 7.83301 36.4502 7.83301 35.6673V17.334C7.83301 16.5511 8.10613 15.8937 8.66626 15.3345ZM20.1663 14.0007V14.5007H20.6663H27.333H27.833V14.0007V10.6673V10.1673H27.333H20.6663H20.1663V10.6673V14.0007Z" fill="#1A6B44" stroke="#1A6B44"/>
      </svg>`;
      }
    }
  };

  const dateObject = (date) => {
    console.log(date);
    getViewRouteData(date);
    setFromDate(date);
    getRouteDirections();
  };

  const handleNotReached = async (event, item) => {
    event.stopPropagation(); // Prevent card click event from firing
    const { data } = await authApi.put(
      `${LOCATION}/mark-not-reached/${item.location._id}`,
      {},
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    console.log(data);
    getViewRouteData(fromDate);
  };

  const handleActiveMarker = (marker) => {
    console.log(marker);
    if (marker.location.title === activeMarker) {
      return;
    }
    setActiveLocation(marker);
    setActiveMarker(marker.location.title);
  };

  const handleViewRoute = (event, item) => {
    console.log("trigger view Route", item);
    navigate(`/maps/location-view-route/${item?.location?.slug.toLowerCase()}`);
  };

  const getOtherIcons = (name) => {
    if (name === "start") {
      return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.5" cx="12" cy="12" r="12" fill="#22A06B"/>
      <circle cx="11.9997" cy="12.0007" r="6.66667" fill="#22A06B"/>
      </svg>`;
    } else if (name === "end") {
      return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.5" cx="12" cy="12" r="12" fill="#D40000"/>
      <circle cx="11.9997" cy="12.0007" r="6.66667" fill="#D50000"/>
      </svg>`;
    }
  };

  const getdotIcon = () => {
    return `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="4" fill="#D50000"/>
      </svg>`;
  };

  const handleMouseOver = (index) => {
    setTooltipIndex(index);
  };

  const handleMouseOut = () => {
    setTooltipIndex(null);
  };

  const getTimeDifference = (number) => {
    const hours = Math.floor(number / 3600);
    const minutes = Math.floor((number % 3600) / 60);
    const seconds = Math.floor(number % 60);

    return `${hours && hours > 0 ? hours + "hrs " : ""} ${
      minutes && minutes > 0
        ? (minutes < 10 ? "0" + minutes : minutes) + "mins "
        : ""
    } ${seconds < 10 ? "0" + seconds : seconds}sec`;
  };

  return (
    <>
      <div className={classes.brudCrumb}>
        <BsArrowLeftShort
          style={{ width: "30px", height: "30px" }}
          onClick={() => navigate(-1)}
        />
        <span onClick={() => navigate("/maps")}>Map</span>
        <NavigateNextIcon fontSize="small" />
        <span>View Route</span>
      </div>
      <div style={{ width: "100%", position: "relative" }}>
        <FilterContainer dateObject={dateObject} selectedDate={fromDate} />
      </div>
      <GoogleMap
        center={mapcenter}
        zoom={12}
        onClick={() => {
          setActiveMarker(null);
          setActiveLocation(null);
          setViewTag();
        }}
        mapContainerStyle={{ height: "100%", marginTop: "10px" }}
      >
        {activePointer ? (
          <div className={classes.mapcardContainer}>
            <Card
              key={activePointer?.item?._id}
              className={classes.CardContainer}
            >
              <CardContent sx={{ padding: "0px !important" }}>
                <Typography>
                  Start Time:
                  {moment(activePointer?.time).format("DD/MM/YYYY hh:mm:ss A")}
                </Typography>
                <Typography>
                  End Time:
                  {moment(activePointer?.endTime).format(
                    "DD/MM/YYYY hh:mm:ss A"
                  )}
                </Typography>
                <Typography>
                  Time Spent:{getTimeDifference(activePointer?.duration)}
                </Typography>
              </CardContent>
            </Card>
          </div>
        ) : activeLocation ? (
          <div className={classes.mapcardContainer}>
            <Card key={activeLocation.id} className={classes.CardContainer}>
              <CardContent sx={{ padding: "0px !important" }}>
                <div className={classes.locationDeatilsHeader}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "10px",
                    }}
                  >
                    <div className={classes.memberCardText}>
                      <h5 style={{ marginBottom: 0 }}>
                        {activeLocation.location.title}
                      </h5>
                    </div>
                  </div>
                  <Chip
                    label={activeLocation.status}
                    size="small"
                    style={getChipColor(activeLocation.status)}
                  />
                </div>
                <hr className={classes.UnderlineHr} />
                <div>
                  <div className={classes.memberCardText}>
                    <p>
                      {activeLocation.status === "completed"
                        ? "Reached ON"
                        : "Assigned on"}
                    </p>
                    <h5>
                      {moment(
                        activeLocation.status === "completed"
                          ? activeLocation.reached_date
                          : activeLocation?.location?.assigned_on
                      ).format("DD MMM YYYY,hh:mm A")}
                    </h5>
                  </div>
                  <hr className={classes.UnderlineHr} />
                  <div>
                    {activeLocation.status === "completed" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          style={{
                            textTransform: "none",
                            width: "45%",
                          }}
                          onClick={(event) =>
                            handleViewRoute(event, activeLocation)
                          }
                        >
                          view route
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          size="small"
                          style={{
                            textTransform: "none",
                            width: "45%",
                          }}
                          onClick={(e) => handleNotReached(e, activeLocation)}
                        >
                          Not reached?
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Button
                          variant="outlined"
                          size="small"
                          fullWidth
                          style={{
                            width: "100%",
                            textTransform: "none",
                          }}
                          onClick={(event) =>
                            handleViewRoute(event, activeLocation)
                          }
                        >
                          view route
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        ) : (
          <div>
            {markerList && markerList.length > 0 ? (
              <div className={classes.mapcardContainer}>
                <Paper elevation={3}>
                  <Card
                    sx={{ maxWidth: 345 }}
                    className={classes.CardContainer}
                  >
                    <CardHeader
                      title="Routes"
                      className={`${classes.CardHeaderContainer} ${classes.piechartHeading} `}
                    />
                    <hr className={classes.UnderlineHr} />
                    <CardContent
                      className={`${classes.CardContentContainer} ${classes.CardContent1} `}
                    >
                      <Typography className={classes.cardLabelText}>
                        Total Distance Travelled
                      </Typography>
                      <Typography
                        className={classes.cardImpText}
                      >{`${totalDistance} km`}</Typography>
                    </CardContent>
                    <hr className={classes.UnderlineHr} />
                    <CardActions
                      disableSpacing
                      className={classes.CardActionContainer}
                    >
                      <Typography
                        className={`${classes.cardImpText} ${classes.CollapseHeader} `}
                      >
                        View Details
                      </Typography>
                      <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>
                    <Collapse
                      in={expanded}
                      timeout="auto"
                      unmountOnExit
                      className={classes.CardCollapeContainer}
                    >
                      <div style={{ marginTop: "20px" }}>
                        {markerList
                          .filter((item) => item.status === "completed")
                          .map((item, index) => {
                            return (
                              <div key={index}>
                                <CardContent
                                  key={index}
                                  className={`${classes.CardContentContainer} ${classes.Card} `}
                                >
                                  <Typography className={classes.cardImpText}>
                                    {item.location.title}
                                  </Typography>
                                  <Typography className={classes.cardLabelText}>
                                    {moment(item?.location?.assigned_on).format(
                                      "DD/MM/YYYY | hh:mm a"
                                    )}
                                  </Typography>
                                </CardContent>
                                {index !== markerList.length - 1 ? (
                                  <hr className={classes.UnderlineHr} />
                                ) : null}
                              </div>
                            );
                          })}
                      </div>
                    </Collapse>
                  </Card>
                </Paper>
              </div>
            ) : null}
          </div>
        )}
        {markerList &&
          markerList.length > 0 &&
          markerList.map((item, index) => {
            console.log("BeforeMarker", item);
            return (
              <Marker
                key={index}
                onClick={() => handleActiveMarker(item)}
                position={{
                  lat: parseFloat(item?.location?.location_info?.lat),
                  lng: parseFloat(item?.location?.location_info?.lng),
                }}
                icon={{
                  url: `data:image/svg+xml;utf-8,${encodeURIComponent(
                    getMarkerSVG(item)
                  )}`,
                  anchor: new window.google.maps.Point(24, 24),
                  scaledSize: new window.google.maps.Size(32, 32),
                }}
                // icon={{
                //   url: `data: image / svg + xml; utf - 8, ${encodeURIComponent(
                //     getMarkerSVG(item)
                //   )} `,
                //   anchor: new window.google.maps.Point(24, 24),
                //   scaledSize: new window.google.maps.Size(32, 32),
                // }}
              />
            );
          })}
        {travelHistory &&
          travelHistory.length > 0 &&
          travelHistory[0][0]?.location_info && (
            <Marker
              position={travelHistory[0][0]?.location_info}
              onClick={() => setViewTag(travelHistory[0][0]?.location_info)}
              icon={{
                url: `data:image/svg+xml;utf-8,${encodeURIComponent(
                  getOtherIcons("start")
                )}`,
                anchor: new window.google.maps.Point(24, 24),
                scaledSize: new window.google.maps.Size(32, 32),
              }}
            >
              {viewTag === travelHistory[0][0]?.location_info ? (
                <InfoWindow onCloseClick={() => setViewTag(null)}>
                  <div>gjsdfgsjh</div>
                </InfoWindow>
              ) : null}
            </Marker>
          )}
        {travelHistory &&
          travelHistory.length > 0 &&
          travelHistory[travelHistory.length - 1][
            travelHistory[travelHistory.length - 1]?.length - 1
          ]?.location_info && (
            <Marker
              position={
                travelHistory[travelHistory.length - 1][
                  travelHistory[travelHistory.length - 1].length - 1
                ]?.location_info
              }
              onClick={() =>
                setViewTag(
                  travelHistory[travelHistory.length - 1][
                    travelHistory[travelHistory.length - 1].length - 1
                  ]?.location_info
                )
              }
              icon={{
                url: `data:image/svg+xml;utf-8,${encodeURIComponent(
                  getOtherIcons("end")
                )} `,
                anchor: new window.google.maps.Point(24, 24),
                scaledSize: new window.google.maps.Size(32, 32),
              }}
            >
              {viewTag ===
              travelHistory[travelHistory.length - 1][
                travelHistory[travelHistory.length - 1].length - 1
              ]?.location_info ? (
                <InfoWindow onCloseClick={() => setViewTag(null)}>
                  <div>gjsdfgsjh</div>
                </InfoWindow>
              ) : null}
            </Marker>
          )}
        {/* {endLocation && (
          <Marker
            position={endLocation}
            icon={{
              url: `data: image / svg + xml; utf - 8, ${
  encodeURIComponent(
    getOtherIcons("end")
  )
} `,
              anchor: new window.google.maps.Point(24, 24),
              scaledSize: new window.google.maps.Size(32, 32),
            }}
          />
        )} */}
        {/* {directions && (
          <DirectionsRenderer
            directions={directions}
            options={{
              suppressMarkers: true, // Prevents default markers from showing
              polylineOptions: {
                strokeColor: "#1C71B3", // Customize the polyline color
                strokeOpacity: 1,
                strokeWeight: 3,
              },
            }}
          />
        )} */}

        {polylinePath.length > 0 &&
          travelHistory.length > 0 &&
          polylinePath.map((item, index) => (
            <React.Fragment key={index}>
              <Polyline
                path={item}
                options={{
                  strokeColor: "#1C71B3",
                  strokeOpacity: 1,
                  strokeWeight: 3,
                }}
                mouseover={() => handleMouseOver(index)}
                // onMouseOut={handleMouseOut}
              />
            </React.Fragment>
          ))}
        {stopPoints &&
          stopPoints.length > 0 &&
          stopPoints.map((item, index) => {
            return (
              <Marker
                key={index}
                position={item?.item?.location_info}
                onClick={() => setActivePointer(item)}
              ></Marker>
            );
          })}
      </GoogleMap>
    </>
  );
}

export default function ViewRoute() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDaVncl1j0jHuHY50J0VXcMHl19Vr8p-HU", // Add your API key
    libraries: ["geometry"], // Load the geometry library
  });

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  return isLoaded ? <Map /> : <div>Loading Google Maps...</div>;
}
