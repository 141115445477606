import React, { useState } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import image from "../../../assets/images/sucessImge.png";
import classes from "./location.module.css";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagedataFunction } from "../../../utilities/language";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { LOCATION } from "../../../api-calls/api-urls";
import { useNavigate } from "react-router-dom";

const DeleteConformation = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [atFirst, setAtFirst] = useState(true);
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const [success, setSuccess] = useState(false);
  const rootRef = React.useRef(null);
  const [modalToggle, setModalToggle] = useState(true);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const modalClose = () => {
    props.modalClose(false);
    setModalToggle(false);
  };

  const handleDelete = async () => {
    const { data } = await authApi.delete(`${LOCATION}/${props.item._id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    setAtFirst(false);
    setSuccess(true);
  };

  return (
    <div>
      {ln ? (
        <div>
          <Modal
            open={modalToggle}
            onClose={() => modalClose()}
            aria-labelledby="server-modal-title"
            data-testid="register_modal"
            aria-describedby="server-modal-description"
            sx={{
              display: "flex",
              height: success ? 400 : 480,
              marginTop: 10,
              justifyContent: "center",
            }}
            container={() => rootRef.current}
          >
            <Box
              sx={{
                position: "relative",
                bgcolor: "background.paper",
                borderRadius: 1,
                boxShadow: (theme) => theme.shadows[5],
              }}
              style={{
                width: success ? "500px" : "460px",
                height: "max-content",
                top: "20%",
              }}
              className={classes.loginModalWidth}
            >
              {!atFirst ? (
                <Button
                  variant="text"
                  sx={{ minWidth: "unset" }}
                  className={classes.closebtn}
                  onClick={() => modalClose()}
                >
                  <MdClose />
                </Button>
              ) : null}
              {atFirst && !success ? (
                <div className={classes.deleteConformationFirstDiv}>
                  <Typography className={classes.successPageSubText}>
                    Are you sure you want to delete location ?
                  </Typography>
                  <div
                    className={classes.addMemberModalbtnSection}
                    style={{ marginTop: "25px" }}
                  >
                    <Button variant="outlined" onClick={() => modalClose()}>
                      No
                    </Button>
                    <Button variant="contained" onClick={() => handleDelete()}>
                      Yes
                    </Button>
                  </div>
                </div>
              ) : null}
              {!atFirst && success ? (
                <div className={classes.addMembersuccess}>
                  <img src={image} alt="success" width="auto" height="auto" />
                  <Typography className={classes.imagePageText1}>
                    Location Deleted
                  </Typography>
                  <Typography className={classes.successPageSubText}>
                    Location has been deleted successfully
                  </Typography>
                </div>
              ) : null}
            </Box>
          </Modal>
        </div>
      ) : null}
    </div>
  );
};

export default DeleteConformation;
