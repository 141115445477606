import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import classes from "./myprofile.module.css";
import axios from "axios";
import {
  Alphabetic,
  Numeric,
  fetchLocation,
} from "../../../utilities/commonFuntions";
import { styled } from "@mui/material/styles";
import { Paper, Link, Button, MenuItem, Typography } from "@mui/material";
import { MdOutlineModeEdit } from "react-icons/md";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { COMPANY } from "../../../api-calls/api-urls";
import { getLanguagedataFunction } from "../../../utilities/language";
import { EditPenIcon } from "../../../components/Icons";
import DeleteConformation from "./DeleteConformation";
import { setCompany } from "../../../Redux/actions/user-actions";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  height: "200px",
}));

const CompanyInfo = () => {
  const dispatch = useDispatch();
  const [height, setHeight] = useState(320);
  const [isEdit, setIsEdit] = useState(false);
  const [companyID, setCompanyID] = useState();
  const [companyName, setCompanyName] = useState();
  const [GST, setGST] = useState();
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [pincode, setPincode] = useState();
  const [moddleToggle, setModdleToggle] = useState(false);
  const [userEntry, setUserEntry] = useState();
  const [industry, setIndustry] = useState();
  const [industryName, setIndustryName] = useState();
  const [disabled, setDisabled] = useState("none");
  const [location, setLocation] = useState();
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  // const [company, setCompany] = useState();
  const company = useSelector((state) => state.reducers.allUsers.company);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  const Industries = [
    { value: "plumbing", label: "Plumbing" },
    { value: "electrical", label: "Electrical" },
    { value: "real_estate", label: "Real estate" },
  ];

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const handleIndustry = (value) => {
    setIndustry(value);
    if (value !== "others") {
      setIndustryName("");
      setDisabled("none");
    } else {
      setDisabled("block");
    }
  };

  useEffect(() => {
    if (pincode && pincode.length === 6) {
      const res = fetchLocation(pincode, setLocation);
    } else {
      setLocation();
    }
  }, [pincode]);

  const getCompanyData = async () => {
    const { data } = await authApi.get(`${COMPANY}/${userInfo._id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    dispatch(setCompany(data));
  };

  useEffect(() => {
    if (company) {
      setCompanyID(company.company_id);
      setCompanyName(company.company_name);
      setGST(company.gst_number);
      setAddress1(company.address_one);
      setAddress2(company.address_two);
      setPincode(company.pin);
      setIndustry(company.industry);
      const isWordPresent = Industries.some(
        (industry) =>
          industry.value.toLowerCase() === company.industry.toLowerCase()
      );
      if (!isWordPresent && isEdit) {
        setIndustryName(company.industry);
        setDisabled("block");
        setIndustry("others");
      }
    }
  }, [company, isEdit]);

  const HandleEditClick = () => {
    setIsEdit(true);
    setHeight("max-content");
  };

  const closeModal = () => {
    setModdleToggle(false);
    setIsEdit(false);
    setHeight(320);
    getCompanyData();
  };

  const handleCancel = () => {
    setIsEdit(false);
    setHeight(320);
  };

  const handleSubmit = async () => {
    console.log("clickes");
    const userEntry = {
      company_id: companyID,
      company_name: companyName,
      gst_number: GST,
      address_one: address1,
      address_two: address2,
      pin: pincode,
      industry: industry !== "others" ? industry : industryName,
    };
    setModdleToggle(true);
    setUserEntry(userEntry);
  };

  useEffect(() => {
    getCompanyData();
  }, [!company]);

  return (
    <div>
      {ln ? (
        <Grid item xs={12}>
          <Item
            sx={{
              height: { height },
              display: "grid",
              padding: "0",
              position: "relative",
            }}
          >
            <div style={{ padding: "20px 30px" }}>
              <div className={classes.Comapny_headerContainer}>
                <h5 style={{ marginBottom: "30px" }}>{ln.company_info}</h5>
                {!isEdit ? (
                  <Link
                    className={classes.LinkEdit}
                    onClick={() => HandleEditClick()}
                  >
                    <EditPenIcon />
                    <span>{ln.edit}</span>
                  </Link>
                ) : null}
              </div>
              {isEdit ? (
                <div>
                  {/* <ValidatorForm
                    useref="form"
                    onSubmit={handleOtp}
                    className={classes.InputForm}
                  ></ValidatorForm> */}
                  <ValidatorForm
                    useref="form"
                    fullwidth
                    onSubmit={handleSubmit}
                    style={{ margin: "0px auto", width: "100%" }}
                  >
                    <div className={classes.inputContainer}>
                      <div>
                        <label for="firstNameinput" className="form-label">
                          {ln.company_id}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          id="Name"
                          value={companyID}
                          onChange={(e) => setCompanyID(e.target.value)}
                          size="small"
                        />
                      </div>
                      <div>
                        <label for="firstNameinput" className="form-label">
                          {ln.company_name}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          id="Name"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                          onKeyPress={(e) => Alphabetic(e)}
                          size="small"
                          validators={["required"]}
                          errorMessages={["Company Name is required"]}
                        />
                      </div>
                      <div>
                        <label for="firstNameinput" className="form-label">
                          {ln.gst_number}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          id="Name"
                          value={GST}
                          inputProps={{
                            maxLength: 15,
                          }}
                          onChange={(e) => setGST(e.target.value)}
                          size="small"
                          validators={[
                            "matchRegexp:^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
                          ]}
                          errorMessages={["This is not a valid GST Number"]}
                        />
                      </div>
                      <div>
                        <label for="phonenumberInput" className="form-label">
                          {ln.address1}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          id="Name"
                          value={address1}
                          onChange={(e) => setAddress1(e.target.value)}
                          size="small"
                          validators={["required"]}
                          errorMessages={["Address Line 1 is required"]}
                        />
                      </div>
                      <div>
                        <label for="phonenumberInput" className="form-label">
                          {ln.address2}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          id="Name"
                          value={address2}
                          onChange={(e) => setAddress2(e.target.value)}
                          size="small"
                        />
                      </div>
                      <div style={{ position: "relative" }}>
                        <label for="phonenumberInput" className="form-label">
                          {ln.pin}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          fullWidth
                          id="address1ControlTextarea"
                          value={pincode}
                          onChange={(e) => setPincode(e.target.value)}
                          onKeyPress={(e) => Numeric(e)}
                          size="small"
                          inputProps={{
                            maxLength: 6,
                          }}
                          validators={["required"]}
                          errorMessages={["Pin code is required"]}
                        />
                        {location && (
                          <Typography
                            className={`${classes.login_form_lable} ${classes.countryPinLable}`}
                          >
                            {location}
                          </Typography>
                        )}
                      </div>
                      <div>
                        <label for="phonenumberInput" className="form-label">
                          {ln.industry}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                          select
                          fullWidth
                          value={industry}
                          onChange={(e) => handleIndustry(e.target.value)}
                          required={true}
                          size="small"
                        >
                          {Industries.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                          <MenuItem key={"others"} value={"others"}>
                            Others
                          </MenuItem>
                        </TextValidator>
                      </div>
                      <div style={{ display: disabled }}>
                        <div className="mb-3">
                          <label
                            htmlFor="address1ControlTextarea"
                            className={classes.login_form_lable}
                          >
                            {ln.industry_name}
                          </label>
                          <TextValidator
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                              width: "100%",
                            }}
                            fullWidth
                            id="address1ControlTextarea"
                            value={industryName}
                            onChange={(e) => setIndustryName(e.target.value)}
                            // onKeyPress={(e) => Alphabetic(e)}
                            size="small"
                            validators={disabled !== "none" ? ["required"] : []}
                            errorMessages={
                              disabled !== "none"
                                ? ["This field is required"]
                                : []
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        float: "right",
                        marginRight: "20px 35px 20px 20px",
                      }}
                    >
                      <Button
                        onClick={() => handleCancel()}
                        variant="outlined"
                        style={{
                          padding: "5px 30px",
                          marginRight: "10px",
                          textTransform: "none",
                        }}
                      >
                        {ln.cancel}
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        style={{ padding: "5px 30px", textTransform: "none" }}
                      >
                        {ln.update}
                      </Button>
                    </div>
                  </ValidatorForm>
                </div>
              ) : (
                <div className={classes.compantTextContainer}>
                  <div className={``}>
                    <p
                      className={`${classes.MyProfile_label} ${classes.CompanyLabel}`}
                    >
                      {ln.company_id}
                    </p>
                    <p
                      className={`${classes.myprofile_p} ${classes.company_P}`}
                    >
                      {companyID}
                    </p>
                  </div>
                  <div className={``}>
                    <p
                      className={`${classes.MyProfile_label} ${classes.CompanyLabel}`}
                    >
                      {ln.company_name}
                    </p>
                    <p
                      className={`${classes.myprofile_p} ${classes.company_P}`}
                    >
                      {companyName}
                    </p>
                  </div>
                  <div className={``}>
                    <p
                      className={`${classes.MyProfile_label} ${classes.CompanyLabel}`}
                    >
                      {ln.gst_number}
                    </p>
                    <p
                      className={`${classes.myprofile_p} ${classes.company_P}`}
                    >
                      {GST}
                    </p>
                  </div>
                  <div className={``}>
                    <p
                      className={`${classes.MyProfile_label} ${classes.CompanyLabel}`}
                    >
                      {ln.address1}
                    </p>
                    <p
                      className={`${classes.myprofile_p} ${classes.company_P}`}
                    >
                      {address1}
                    </p>
                  </div>
                  <div className={``}>
                    <p
                      className={`${classes.MyProfile_label} ${classes.CompanyLabel}`}
                    >
                      {ln.address2}
                    </p>
                    <p
                      className={`${classes.myprofile_p} ${classes.company_P}`}
                    >
                      {address2}
                    </p>
                  </div>
                  <div className={``}>
                    <p
                      className={`${classes.MyProfile_label} ${classes.CompanyLabel}`}
                    >
                      {ln.pin}
                    </p>
                    <p
                      className={`${classes.myprofile_p} ${classes.company_P}`}
                    >
                      {pincode}
                    </p>
                  </div>
                  <div className={``}>
                    <p
                      className={`${classes.MyProfile_label} ${classes.CompanyLabel}`}
                    >
                      {ln.industry}
                    </p>
                    <p
                      className={`${classes.myprofile_p} ${classes.company_P}`}
                      style={{
                        textTransform: "none",
                      }}
                    >
                      {industry?.charAt(0).toUpperCase() +
                        industry?.slice(1).replace("_", " ")}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Item>
        </Grid>
      ) : null}
      {moddleToggle ? (
        <DeleteConformation
          userEntry={userEntry}
          modalClose={closeModal}
          type={"company_details"}
        />
      ) : null}
    </div>
  );
};

export default CompanyInfo;
