import { actionTypes } from "../constant/action-types";
const initialState = {
  teamsList: null,
  editTeam: null,
  departmentList: null,
  teamMemberData: null,
  teamMemberStatusData: null,
  teamMemberAttendanceData: null,
  availbleSubs: null,
};

export const teamReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.TEAMS_LIST:
      return { ...state, teamsList: payload };
    case actionTypes.EDIT_TEAM:
      return { ...state, editTeam: payload };
    case actionTypes.DEPARTMENT_LIST:
      return { ...state, departmentList: payload };
    case actionTypes.ONE_TEAM_MEMBER:
      return { ...state, teamMemberData: payload };
    case actionTypes.SAVE_TEAM_MEMBER_STATUS_FILTER:
      return { ...state, teamMemberStatusData: payload };
    case actionTypes.TEAM_MEMBER_ATTENDANCE_FILTER:
      return { ...state, teamMemberAttendanceData: payload };
    case actionTypes.SAVE_AVAILABLE_SUBC:
      return { ...state, availbleSubs: payload };
    default:
      return state;
  }
};
