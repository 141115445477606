import { actionTypes } from "../constant/action-types";

export function SaveBlogsListDatas(data) {
  return {
    type: actionTypes.SAVE_BLOGS_LIST_DATAS,
    payload: data,
  };
}

export function SaveUserSavedBlogsListDatas(data) {
  return {
    type: actionTypes.SAVE_USER_SAVED_BLOGS_LIST_DATAS,
    payload: data,
  };
}

export function saveBlogsPageMessageList(data) {
  return {
    type: actionTypes.BLOGSPAGE_MESSAGES_SAVE,
    payload: data,
  };
}
