import React, { useState } from "react";
import classes from "./myteam.module.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { BiSortAlt2 } from "react-icons/bi";
import {
  CardContent,
  MenuItem,
  Menu,
  Typography,
  Card,
  Avatar,
  Button,
  Divider,
  Badge,
  TextField,
} from "@mui/material";
import { Form } from "react-bootstrap";
import { InputAdornment } from "@mui/material";
import { IoAddCircleOutline } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import AddMemberModal from "./AddMemberModal";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  stringAvatar,
  generateColor,
  checkOnline,
  PublishMessage,
} from "../../../utilities/commonFuntions";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import {
  DELETE_MEMBER,
  TEAM_LIST,
  TEAM_MEMBER_DETAILS,
} from "../../../api-calls/api-urls";
import { getLanguagedataFunction } from "../../../utilities/language";
import {
  ArrowDownIcon,
  BrudCrumbBackIcon,
  BrudCrumbNextIcon,
  DeleteBinIcons,
  EditPenIcon,
  FilterIcon,
  LogoutIcon,
  SearchIcon,
  TotalMembersIcon,
} from "../../../components/Icons";
import {
  TeamMemberStatus,
  locationTimeFilter,
} from "../../../utilities/constants";
import { saveTeamMemberStatusFilter } from "../../../Redux/actions/team-actions";
import { saveLocationTimeFilter } from "../../../Redux/actions/location-action";
import { setHeaderName } from "../../../Redux/actions/user-actions";
import AlertBox from "../../../components/AlertBox";
// import { TextFields } from "@mui/icons-material";
import TeamMemberCard from "./teamMemberCard";

const TeamViewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [typecall, setTypeCall] = useState();
  const [serachValue, setSearchValue] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const [openMenu, setOpenMenu] = useState();
  const [anchorEl1, setAnchorEl1] = useState();
  const [modletoggle, setModleToggle] = useState(false);
  const [editMember, setEditMember] = useState();
  const [currentTeam, setCurrentTeam] = useState();
  const [currentTeamMembers, setCurrentTeamMembers] = useState([]);
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState();
  const [selectedValues, setSelectedValues] = useState([]); // State to store selected values
  const [selectedValues1, setSelectedValues1] = useState();
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  const [onlineStatuses, setOnlineStatuses] = useState({});
  const { teamMembersList } = useSelector((state) => state.reducers.allUsers);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  useEffect(() => {
    const path = window.location.pathname;
    console.log(path);
    getOneTeamData(path.toLowerCase());
  }, [!currentTeam]);

  const getOneTeamData = async (path) => {
    const arr = path.split("/");
    const { data } = await authApi.post(
      `${TEAM_LIST}/${
        arr[arr.length - 1]
      }?search=${serachValue}&sortBy=${selectedValues1}`,
      {
        status: [],
      },
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    setCurrentTeam(data);

    const newOnlineStatuses = {};
    data.team_members.forEach((member) => {
      const status = checkOnline(member.user_date?.[0]?._id);
      newOnlineStatuses[member._id] = status;
    });
    setCurrentTeamMembers(data.team_members);
    setOnlineStatuses(newOnlineStatuses);
  };

  const handleMenuclick = (event, item) => {
    event.stopPropagation();
    console.log(item);
    setEditMember(item);
    setOpenMenu(event.currentTarget);
  };

  const handleClick = (event, name) => {
    if (name === "ref1") {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl1(event.currentTarget);
    }
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setOpenMenu(null);
    setAnchorEl(null);
    setAnchorEl1(null);
  };
  const handleMenuItemClick = async (event, name) => {
    event.stopPropagation();
    if (name === "edit") {
      setModleToggle(true);
      setTypeCall("edit");
    } else if (name === "delete") {
      if (editMember) {
        const userEntry = {
          _id: editMember._id,
        };
        const { data } = await authApi.delete(
          `${TEAM_LIST}/${currentTeam._id}${DELETE_MEMBER}`,
          {
            headers: { Authorization: getAuthorizationHeader() },
            data: userEntry,
          }
        );
        getOneTeamData(window.location.pathname.toLowerCase());
      }
      //getData();
    }

    handleClose(event);
  };

  const hadleLogoutClick = async (event) => {
    event.stopPropagation();
    const member = teamMembersList.find(
      (member) => member.phone === editMember.mobile_number
    );
    console.log(member, "editMember");
    const topic = `/App/${member.device_id}/message`;
    const status = await PublishMessage(
      topic,
      JSON.stringify({ forcelogout: 1 })
    );
    const { data } = await authApi.put(
      `/users/force-logout/${editMember.mobile_number}`,
      {},
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    getOneTeamData(window.location.pathname.toLowerCase());
    handleClose(event);
  };

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, 2000); // Change the duration (in milliseconds) as per your requirement

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showAlert]);

  const handleCardClick = async (event, id) => {
    // event.stopPropagation();
    try {
      const data = await authApi.post(
        `${TEAM_MEMBER_DETAILS}/${id}`,
        {},
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      console.log(data);
      const name = currentTeam.slug.toLowerCase();
      navigate(`/my-team/${name}/${id}`);
    } catch (error) {
      console.log(error);
      setShowAlert(true);
      setError(error.response.data.message || error.response.data.error);
    }
  };
  const CloseForm = (props) => {
    setModleToggle(props);
    setEditMember();
    setTypeCall();
    getOneTeamData(window.location.pathname.toLowerCase());
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setAnchorEl1(null);
  };

  useEffect(() => {
    if (currentTeam) {
      dispatch(setHeaderName(currentTeam?.team_name));
      if (!currentTeamMembers || currentTeamMembers.length === 0) {
        setCurrentTeamMembers(currentTeam?.team_members);
      }
    }
  }, [currentTeam]);

  const clickClearAll = (name) => {
    if (name === "TimeFilter") {
      setSelectedValues1();
      dispatch(saveLocationTimeFilter());
    } else {
      setSelectedValues([]);
      dispatch(saveTeamMemberStatusFilter([]));
    }
  };

  const handleStatusCheckbox = async (value) => {
    const isSelected = selectedValues.includes(value);

    if (value === "all") {
      if (isSelected) {
        // Unselect all checkboxes
        setSelectedValues([]);
        dispatch(saveTeamMemberStatusFilter([]));
        setCurrentTeamMembers(currentTeam?.team_members);
      } else {
        // Select all checkboxes
        setSelectedValues([...TeamMemberStatus]);
        dispatch(saveTeamMemberStatusFilter([...TeamMemberStatus]));
        setCurrentTeamMembers(currentTeam?.team_members);
      }
    } else {
      // Toggle the selection of individual checkboxes
      let newSelectedValues = [];

      if (isSelected) {
        newSelectedValues = selectedValues.filter((v) => v !== value);
      } else {
        newSelectedValues = [...selectedValues, value];
      }
      let filtered;
      if (newSelectedValues.length > 0) {
        filtered = await currentTeam?.team_members.filter((item) => {
          if (newSelectedValues.includes("online")) {
            if (checkOnline(item?.user_date?.[0]?._id) === "checkin") {
              return item;
            }
          }
          if (newSelectedValues.includes("offline")) {
            console.log(
              "beeingSuccess onluine",
              item?.user_date?.[0]?._id,
              checkOnline(item?.user_date?.[0]?._id)
            );
            if (
              checkOnline(item?.user_date?.[0]?._id) === "checkout" ||
              checkOnline(item?.user_date?.[0]?._id) === undefined ||
              item?.user_date?.[0]?._id === undefined
            ) {
              console.log(
                "check Status",
                checkOnline(item?.user_date?.[0]?._id),
                item?.user_name
              );
              console.log(item, "Offline USer");
              return item;
            }
          }
        });
      } else {
        filtered = currentTeam?.team_members;
      }
      console.log(filtered, "filtered", currentTeam?.team_members);
      setCurrentTeamMembers(filtered);
      setSelectedValues(newSelectedValues);
      dispatch(saveTeamMemberStatusFilter([newSelectedValues]));

      const areAllSelected = TeamMemberStatus.filter(
        (item) => item !== "all"
      ).every((item) => newSelectedValues.includes(item));

      // Check the "All" checkbox if all others are selected
      if (areAllSelected) {
        setSelectedValues((prevSelected) => [...prevSelected, "all"]);
        dispatch(saveTeamMemberStatusFilter([...newSelectedValues, "all"]));
      } else {
        // Uncheck the "All" checkbox if any other checkbox is unselected
        setSelectedValues((prevSelected) =>
          prevSelected.filter((item) => item !== "all")
        );
        dispatch(
          saveTeamMemberStatusFilter(
            newSelectedValues.filter((item) => item !== "all")
          )
        );
      }
    }
  };

  const handleAddMemberModal = () => {
    if (
      currentTeam.team_members.length <
      currentTeam.customer_subscription.no_team_members
    ) {
      setModleToggle(true);
      setEditMember();
      setTypeCall("new");
    } else {
      setError(
        "You can add only " +
          currentTeam.customer_subscription.no_team_members +
          " members"
      );
      setShowAlert(true);
    }
  };

  const handleTimeCheckBox = (value) => {
    setSelectedValues1(value);
    dispatch(saveLocationTimeFilter(value));
    const sortedMembersList = currentTeamMembers.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return selectedValues1?.includes("newest_to_oldest")
        ? dateA - dateB
        : dateB - dateA;
    });
    setCurrentTeamMembers(sortedMembersList);
  };

  return (
    <div style={{ margin: "0 auto 0 15px" }}>
      {currentTeam && ln ? (
        <div>
          <div className={`${classes.brudCrumb} mb-4 `}>
            <span onClick={() => navigate(-1)}>
              <BrudCrumbBackIcon />
            </span>
            <span onClick={() => navigate("/my-team")}>{ln.my_team}</span>
            <BrudCrumbNextIcon />
            <span style={{ color: "#6A6E8A" }}>
              {currentTeam ? currentTeam.team_name : ""}
            </span>
          </div>
          <Typography className={`${classes.createTeamHeading} mb-3`}>
            {currentTeam.team_name}
          </Typography>
          <Typography
            className={`${classes.TeamPageMaindivSubHeding} mb-4 mt-0`}
          >
            <TotalMembersIcon />
            <span className={classes.teamPageHeading2}>
              {currentTeam && currentTeam.team_members
                ? `${ln.total_members} ( ${currentTeam?.team_members?.length} )`
                : null}
            </span>
          </Typography>
          <div>
            <div
              className={classes.teamMembersmainDiv}
              style={{ height: "auto" }}
            >
              <div>
                <div className="">
                  <TextField
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    placeholder="Search"
                    inputprops={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    className={classes.teamMemberSearch}
                    id="firstNameinput"
                    value={serachValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    size="small"
                  />
                </div>
              </div>
              <div style={{ display: "flex", columnGap: 10 }}>
                <div style={{ width: "auto" }}>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="outlined"
                    onClick={(e) => handleClick(e, "ref1")}
                    fullWidth
                    className={classes.filterbtns}
                  >
                    <div
                      className={classes.form_lable}
                      style={{ textTransform: "capitalize" }}
                    >
                      <FilterIcon /> {ln.all}
                    </div>
                    <div>
                      <ArrowDownIcon />
                    </div>
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <div className={classes.filterHeading}>
                      <Typography className={classes.filterHeader}>
                        {ln.filter}
                      </Typography>
                      <span
                        className={classes.claerBtn}
                        onClick={() => clickClearAll("statusfilter")}
                      >
                        {ln.clear_all}
                      </span>
                    </div>
                    <Divider />
                    {TeamMemberStatus.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          style={{
                            padding: "6px 25px 6px 0",
                            textTransform: "capitalize",
                          }}
                          onClick={() => handleStatusCheckbox(item)}
                        >
                          <Form.Check
                            style={{
                              margin: 10,
                              marginRight: 15,
                            }}
                            checked={selectedValues?.includes(item)}
                            name="group1"
                            type="checkbox"
                          />
                          {/* <Checkbox
                            checked={selectedValues.includes(item)}
                            onChange={() => handleStatusCheckbox(item)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          /> */}
                          {ln[item]?.replaceAll("_", " ")}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </div>
                <div style={{ minWidth: "180px" }}>
                  <Button
                    id="basic-button1"
                    aria-controls={open1 ? "basic-menu1" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? "true" : undefined}
                    onClick={(e) => handleClick(e, "ref2")}
                    variant="outlined"
                    fullWidth
                    className={classes.filterbtns}
                  >
                    <div
                      className={classes.form_lable}
                      style={{
                        textTransform: "capitalize",
                        marginRight: "7px",
                      }}
                    >
                      <BiSortAlt2 /> {ln.newest_to_oldest}
                    </div>
                    <div>
                      <ArrowDownIcon />
                    </div>
                  </Button>
                  <Menu
                    anchorEl={anchorEl1}
                    open={Boolean(anchorEl1)}
                    onClose={handleMenuClose}
                  >
                    <div className={classes.filterHeading}>
                      <Typography className={classes.filterHeader}>
                        {ln.sort_by}
                      </Typography>
                      <span
                        className={classes.claerBtn}
                        onClick={() => clickClearAll("TimeFilter")}
                      >
                        {ln.clear_all}
                      </span>
                    </div>
                    <Divider />
                    {locationTimeFilter.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          style={{
                            padding: "6px 25px 6px 0",
                            textTransform: "capitalize",
                          }}
                          onClick={() => handleTimeCheckBox(item)}
                        >
                          <Form.Check
                            style={{
                              margin: 10,
                              marginRight: 15,
                            }}
                            checked={selectedValues1?.includes(item)}
                            name="group1"
                            type="checkbox"
                          />
                          {/* <Checkbox
                            checked={selectedValues1 === item}
                            onChange={() => handleTimeCheckBox(item)}
                          /> */}
                          {ln[item]?.replaceAll("_", " ")}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div>
        <div
          className={classes.indexpageMainBtn}
          style={{ marginRight: "20px", marginBottom: 0, marginTop: "20px" }}
        >
          {ln ? (
            <Button
              variant="contained"
              onClick={() => {
                handleAddMemberModal();
              }}
            >
              <IoAddCircleOutline style={{ marginRight: "5px" }} />
              {ln.add_member}
            </Button>
          ) : null}
        </div>
      </div>
      {currentTeamMembers && currentTeamMembers.length > 0 ? (
        <div
          className={`${classes.GridContainer} ${classes.TeamMemberGridcontaoiner}`}
        >
          {currentTeamMembers
            .filter((item) => {
              const regex = new RegExp(serachValue, "i"); // 'i' flag for case-insensitive matching
              return regex.test(item.user_name || item.name); // Assuming 'name' is the property you want to filter by
            })
            .map((item, index) => {
              return (
                <TeamMemberCard
                  item={item}
                  key={index}
                  index={index}
                  handleCardClick={handleCardClick}
                  handleMenuclick={handleMenuclick}
                  openMenu={openMenu}
                  handleClose={handleClose}
                  handleMenuItemClick={handleMenuItemClick}
                  hadleLogoutClick={hadleLogoutClick}
                />
              );
            })}
        </div>
      ) : null}
      {modletoggle ? (
        <AddMemberModal
          modalClose={CloseForm}
          id={currentTeam._id}
          user={editMember}
          name={typecall}
        />
      ) : null}
      {showAlert && error && (
        <AlertBox type="error" message={error} stateName={setShowAlert} />
      )}
    </div>
  );
};

export default TeamViewPage;
