import React, { useEffect, useState } from "react";
import classes from "./blogs.module.css";
import {
  TextField,
  InputAdornment,
  Card,
  CardContent,
  Typography,
  Avatar,
} from "@mui/material";
import avatar from "../../../assets/images/avatar-3.jpg";
import { SearchIcon } from "../../../components/Icons";
import image1 from "../../../assets/images/image1.png";
import image2 from "../../../assets/images/image2.png";
import image3 from "../../../assets/images/image3.png";
import image4 from "../../../assets/images/image4.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Search } from "@mui/icons-material";
import { BLOGS_LIST, ImageUrl } from "../../../api-calls/api-urls";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { useDispatch, useSelector } from "react-redux";
import { SaveBlogsListDatas } from "../../../Redux/actions/blogsActions";
import { generateColor, stringAvatar } from "../../../utilities/commonFuntions";

const BlogsMainPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [serachValue, setSearchValue] = useState();
  const { blogsList } = useSelector((state) => state.reducers.blogs);
  // const imageList = [
  //   {
  //     image: image1,
  //   },
  //   {
  //     image: image2,
  //   },
  //   {
  //     image: image3,
  //   },
  //   {
  //     image: image4,
  //   },
  //   {
  //     image: image1,
  //   },
  //   {
  //     image: image2,
  //   },
  // ];
  const handleCardClick = (id) => {
    navigate(`/blogs/blog-details/${id}`);
  };

  useEffect(() => {
    console.log(blogsList);
  }, [blogsList]);

  const getBlogsList = async () => {
    const { data } = await authApi.get(`${BLOGS_LIST}?search=${serachValue}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    // console.log(data);
    dispatch(SaveBlogsListDatas(data));
  };

  useEffect(() => {
    getBlogsList();
  }, [serachValue]);

  return (
    <div>
      <div className="mb-4 mt-1">
        <TextField
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
          }}
          placeholder="search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          className={classes.teamMemberSearch}
          id="firstNameinput"
          value={serachValue}
          onChange={(e) => setSearchValue(e.target.value)}
          size="small"
        />
      </div>
      <div className={classes.CardContainer}>
        {blogsList &&
          blogsList.length > 0 &&
          blogsList.map((item, index) => {
            return (
              <Card
                key={index}
                onClick={() => {
                  handleCardClick(item._id);
                }}
              >
                <CardContent className={classes.BlogsCardContent}>
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}/${item.images[0]?.destination}${item.images[0]?.filename}`}
                    alt=""
                    className="mb-4"
                  />
                  <div
                    style={{
                      display: "flex",
                      columnGap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    {item.tags &&
                      item.tags.length > 0 &&
                      item.tags.map((tag, index) => (
                        <span className={`${classes.blogChipCard}`} key={index}>
                          {tag}
                        </span>
                      ))}
                  </div>
                  <Typography
                    className={`mb-2 mt-3 ${classes.ModalHEaderMainText}`}
                  >
                    {item.heading}
                  </Typography>
                  <div className={`${classes.AvatarSection}`}>
                    {item.userInfo.profile_pic ? (
                      <Avatar
                        alt="Remy Sharp"
                        src={`${process.env.REACT_APP_IMAGE_URL}/${item.userInfo?.profile_pic?.destination}${item.userInfo?.profile_pic?.filename}`}
                        sx={{ width: 32, height: 32 }}
                      />
                    ) : (
                      <Avatar
                        {...stringAvatar(item.userInfo.name)}
                        sx={{
                          bgcolor: `${generateColor(item.userInfo.name)}`,
                          width: 32,
                          height: 32,
                          fontSize: 13,
                          fontWeight: 700,
                        }}
                      ></Avatar>
                    )}
                    <div>
                      <Typography className={classes.ModalHEaderMainText}>
                        {item.userInfo.name}
                      </Typography>
                      <Typography
                        className={`mt-1 ${classes.ModalHEaderSubText}`}
                      >
                        {moment(new Date(item.createdAt)).format(
                          "DD MMM , YYYY"
                        )}{" "}
                        | {`${item.estimation_time || 1} Min Read`}
                      </Typography>
                      {/* <Typography className={classes.ModalHeaderStatusText}>
                        joined
                    </Typography> */}
                    </div>
                  </div>
                </CardContent>
              </Card>
            );
          })}
      </div>
    </div>
  );
};

export default BlogsMainPage;
