import { Box, Dialog, Typography, Button, Grid, MenuItem } from "@mui/material";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import React, { useState, useCallback, useEffect } from "react";
import classes from './address.module.css'
import {AddressMapBackIcon,AddressMapFrontIcon} from '../../../components/Icons.js'

const containerStyle = {
    width: "100%",
    height: "400px",
  };

function AddressMaps(props) {

  const {setIsMapScreen,setInputs,inputs} = props;
    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState(null);
    const [mapUrl, setMapUrl] = useState("");
    const [locality, setLocality] = useState("");
    const [centerPoint, setCenterPoint] = useState({
      lat: null,
      lng: null,
    });
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
      });

      
  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const getLocationName = (latitude, longitude) => {
    const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        
        const state = data.address.state || data.address.state_district;
      })
      .catch((error) => {
        console.log(`Error getting location: ${error.message}`);
      });
  };

  const getGeocode = async (lat, lng) => {
    try {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("data 42 = ",data);

          let lists = data?.results[0]?.address_components;
          let selected_address = data?.results[0]?.formatted_address;

          let map_location = "";
          lists?.forEach((val) => {
            if (val.types?.includes("locality")) {
              map_location = val.long_name;
            }
          });
          let map_url = `https://www.google.com/maps?q=${lat},${lng}`
          setMapUrl(map_url);
          setLocality(map_location);
          setInputs({...inputs,maplink:map_url,lat:lat,lng:lng,address:selected_address});
        })
        .catch((error) => {
          console.log(`Error getting location: ${error.message}`);
        });
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const handleClick = (event) => {
    setMarker({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    setCenterPoint({
      lat:event.latLng.lat(),
      lng: event.latLng.lng(),
    });

    getGeocode(event.latLng.lat(),event.latLng.lng());
  };

  useEffect(() => {

    if (inputs?.lat && inputs?.lng ) {
      setMarker({
        lat: inputs?.lat,
        lng: inputs?.lng,
      });
      setCenterPoint({
        lat:inputs?.lat,
        lng: inputs?.lng,
      });
      getGeocode(inputs?.lat,inputs?.lng);
    } else {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(function (position) {
          
          const new_center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenterPoint(new_center);
        });
      } else {
        alert("Geolocation is not available in your browser.");
      }
    }
  }, []);

    return(<Box>
        <Box className={classes.add_map_header} >
            <span onClick={()=>setIsMapScreen(false)}>
                <AddressMapBackIcon />
            </span>
            <Typography>Add Address</Typography>
            <span>
                <AddressMapFrontIcon />
            </span>
            <Typography>Add from Map</Typography>
        </Box>
            <Box position={"relative"} >
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={centerPoint}
                zoom={10}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onClick={handleClick}
              >
                {marker && (
                  <Marker position={{ lat: marker.lat, lng: marker.lng }} />
                )}
              </GoogleMap>
            ) : (
              <Typography>Not found</Typography>
            )}
            <Box className={classes.add_location_box} >
                <h5>{locality ? locality : "n/a"}</h5>
                <span>{inputs.address ? inputs.address : "n/a"}</span>
                <Button variant="contained" onClick={()=>setIsMapScreen(false)} >
                    Add Location
                </Button>
            </Box>
            </Box>
    </Box>)
};

export default AddressMaps;