import { actionTypes } from "../constant/action-types";

export function pageLoader(data) {
  return {
    type: actionTypes.PAGE_LOADER,
    payload: data,
  };
}
export function RegisteredUser(data) {
  return {
    type: actionTypes.REGISTERED_USER,
    payload: data,
  };
}

export function ToggleSideBar(data) {
  return {
    type: actionTypes.TOGGLE_SIDEBAR,
    payload: data,
  };
}

export function loggedUser(data) {
  return {
    type: actionTypes.LOGGEDIN_USER,
    payload: data,
  };
}
export function loginOtpMobileNumber(data) {
  return {
    type: actionTypes.OTP_MOBILE,
    payload: data,
  };
}

export function setHeaderName(data) {
  return {
    type: actionTypes.HEADER_NAME,
    payload: data,
  };
}

export function setLanguageJsonData(data) {
  return {
    type: actionTypes.LANGUAGE_JSON,
    payload: data,
  };
}

export function SetRefferalHistory(data) {
  return {
    type: actionTypes.REFFERAL_HISTORY,
    payload: data,
  };
}
export function setCurrentUser(data) {
  return {
    type: actionTypes.CURRENT_USER,
    payload: data,
  };
}
export function setCompany(data) {
  return {
    type: actionTypes.USER_COMPANY,
    payload: data,
  };
}

export function setNotificationList(data) {
  return {
    type: actionTypes.NOTIFICATION_LIST,
    payload: data,
  };
}

export function setSortName(data) {
  return {
    type: actionTypes.SORT_NAME,
    payload: data,
  };
}
