import React, { useState, useRef, useEffect } from "react";
import classes from "./holiday.module.css";
import moment from "moment";
import { Button, Paper, Typography } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // Import interactionPlugin
import {
  EditPenIcon,
  NextCalendarIcon,
  PrevCalendarIcon,
} from "../../../components/Icons";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import ConfirmationModel from "./ConfirmationModel";
import { useDispatch, useSelector } from "react-redux";
import { HOLIDAY_LIST } from "../../../api-calls/api-urls";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { saveHolidaysList } from "../../../Redux/actions/holidayActions";
import { getLanguagedataFunction } from "../../../utilities/language";
import AlertBox from "../../../components/AlertBox";

moment.locale("en-GB");

export default function MarkHoliday() {
  const dispatch = useDispatch();
  const [selectedDates, setSelectedDates] = useState([]);
  const calendarRef = useRef(null); // Ref to access the FullCalendar component
  const [displayedMonth, setDisplayedMonth] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [modalToggle, setModalToggle] = useState(false);
  const { holidays } = useSelector((state) => state.reducers.holidays);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const events = [];

  const handlePrev = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().prev();
      const date = calendarRef.current.calendar.currentData.currentDate;
      const new_Date = moment(date).format("YYYY-MM-DD");
      setDisplayedMonth(new_Date);
    }
  };

  const handleNext = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().next();
      const date = calendarRef.current.calendar.currentData.currentDate;
      const new_Date = moment(date).format("YYYY-MM-DD");
      setDisplayedMonth(new_Date);
    }
  };

  const getHolidaysList = async () => {
    console.log("holidays", displayedMonth);
    const searchdate = displayedMonth.split("-");
    const { data } = await authApi.get(
      `${HOLIDAY_LIST}/${searchdate[0]}/${searchdate[1]}`,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    console.log("Holiday Dates ====>", data);
    dispatch(saveHolidaysList(data.holidays));
    const array = data.holidays.map((item) => {
      return new Date(item.date);
    });
    console.log(array);
    setSelectedDates(array);
  };

  useEffect(() => {
    getHolidaysList();
  }, [holidays.length <= 0, displayedMonth]);

  const closeModal = () => {
    setModalToggle(false);
    getHolidaysList();
  };

  const handleMuiDateCalendarChange = (newDate) => {
    console.log(newDate);
    const selectedYear = newDate.$y;
    console.log(selectedYear);

    if (calendarRef.current) {
      const api = calendarRef.current.getApi();
      const currentView = api.view;

      // Get the active month and day from the current view
      const activeMonth = moment(currentView.activeStart).month();
      const activeDay = moment(currentView.activeStart).date();

      // Construct a new date with the selected year, active month, and active day
      const newDisplayDate = moment()
        .year(selectedYear)
        .month(activeMonth)
        .date(activeDay);

      // Navigate FullCalendar to the selected year
      api.gotoDate(newDisplayDate.toDate());
      // api.
    }
  };

  const handleDateClick = (info) => {
    const clickedDate = info.date;
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if (new Date(yesterday) < new Date(clickedDate)) {
      if (
        selectedDates.some((date) => moment(date).isSame(clickedDate, "day"))
      ) {
        const updatedDates = selectedDates.filter(
          (date) => !moment(date).isSame(clickedDate, "day")
        );
        console.log(updatedDates);
        setSelectedDates(updatedDates);
      } else {
        console.log([...selectedDates, clickedDate]);
        setSelectedDates([...selectedDates, clickedDate]);
      }
    } else {
      setError("Please select upcoming date for mark holiday");
      setShowAlert(true);
    }
  };

  const dayCellClassNames = ({ date }) => {
    const isSelected = selectedDates.some((selectedDate) =>
      moment(selectedDate).isSame(date, "day")
    );
    return isSelected ? `selected_date_cell` : "";
  };

  const handleSubmit = () => {
    setModalToggle(true);
  };

  return (
    <div className="markholidayMaindiv">
      {ln && (
        <Paper style={{ padding: "20px" }}>
          <div className={classes.CalendarMainContainer}>
            <div className={classes.CalendarHeader1}>
              <div className={classes.NavigationButtons}>
                <Button variant="text" onClick={handlePrev}>
                  <span>
                    <PrevCalendarIcon />
                  </span>
                </Button>
                <Button variant="text" onClick={handleNext}>
                  <span>
                    <NextCalendarIcon />
                  </span>
                </Button>
              </div>
              <div
                className={`${classes.MuiDateCalendarContainer} nonModuleCss`}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={dayjs(displayedMonth)} // Pass the displayed month to Mui Date Calendar
                    onChange={handleMuiDateCalendarChange}
                    views={["month", "year"]}
                    openTo="month"
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className={classes.CalendarHeader2}>
              <Typography className={classes.CalenderSomText}>
                {ln.click_on_a_date}*
              </Typography>
              <Typography className={classes.mainDateText}>
                {moment(new Date()).format("ddd,MMM DD")}
              </Typography>
            </div>
            <div className={classes.fullCalendar}>
              <FullCalendar
                ref={calendarRef} // Set the ref for FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                className={classes.fullCalendar}
                initialView="dayGridMonth"
                weekends={true}
                events={events}
                eventRender={(info) => {
                  if (info.event.title === "Dummy") {
                    return { display: "none" };
                  }
                }}
                dateClick={handleDateClick}
                dayCellClassNames={dayCellClassNames}
                dayCellContent={(args) => {
                  const day = args.date.getDate().toString().padStart(2, "0"); // Format the date to double digits
                  return <div className="fc-daygrid-day-number1">{day}</div>;
                }}
                showNonCurrentDates={true}
              />
            </div>
          </div>
          <div className={classes.MarkerContainer}>
            <div className={classes.MarkerDescriptionBar}></div>
            <Typography className={classes.MarkerDescriptionText}>
              {ln.holidays}
            </Typography>
          </div>
          <div className={classes.HolidayPageBtnSection}>
            <Button
              variant="outlined"
              className={classes.MarkHolidayMainBtn}
              onClick={() => closeModal()}
            >
              {ln.clear}
            </Button>
            <Button
              variant="contained"
              className={classes.MarkHolidayMainBtn}
              onClick={() => handleSubmit()}
            >
              {ln.submit}
            </Button>
          </div>
          {modalToggle ? (
            <ConfirmationModel
              modalClose={closeModal}
              selectedDates={selectedDates}
            />
          ) : null}
        </Paper>
      )}
      {showAlert && error && (
        <AlertBox type="error" message={error} stateName={setShowAlert} />
      )}
    </div>
  );
}
