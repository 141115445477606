import React, { useState } from "react";
import classes from "./index.module.css";
import { MdClose } from "react-icons/md";
import { Modal, Box, Typography, Button } from "@mui/material";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { UPDATE_USER } from "../../../api-calls/api-urls";
import { api, getAuthorizationHeader } from "../../../api-calls/user-requests";
import successImage from "../../../assets/images/sucessImge.png";

const UpdateModal = (props) => {
  const rootRef = React.useRef(null);
  const [value, setValue] = useState(props.value);
  const [success, setSuccess] = useState(false);
  const [modalToggle, setModdleToggle] = useState(true);
  const user = JSON.parse(sessionStorage.getItem("CurrentUser"));

  const closeModal = () => {
    setModdleToggle(false);
    props.closeModal(false);
  };

  const handleSubmit = async () => {
    if (props.type === "number") {
      user.phone = value;
      sessionStorage.setItem("CurrentUser", JSON.stringify(user));
      const userEntry = {
        phone: value,
      };
      const { data } = await api.put(`${UPDATE_USER}/${user._id}`, userEntry);
    } else if (props.type === "email") {
      user.email = value;
      sessionStorage.setItem("CurrentUser", JSON.stringify(user));
      const userEntry = {
        email: value,
      };
      const { data } = await api.put(`${UPDATE_USER}/${user._id}`, userEntry);
      props.handleResetClick();
    }
    setSuccess(true);
  };

  return (
    <div>
      <Modal
        open={modalToggle}
        onClose={() => closeModal()}
        aria-labelledby="server-modal-title"
        data-testid="register_modal"
        aria-describedby="server-modal-description"
        sx={{
          display: "flex",
          height: 480,
          marginTop: 10,
          justifyContent: "center",
        }}
        container={() => rootRef.current}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[5],
          }}
          style={{
            width: "460px",
            height: "max-content",
            top: "20%",
          }}
          className={classes.loginModalWidth}
        >
          <div className={classes.forgetPasswordmodalclose}>
            <Button
              variant="text"
              sx={{ minWidth: "unset" }}
              className={classes.closebtn}
              onClick={() => closeModal()}
            >
              <MdClose />
            </Button>
          </div>
          {!success ? (
            <div className={classes.ModalHeadingSection}>
              <Typography className={classes.modalHeadind}>
                {props.type === "number"
                  ? "Edit Mobile Number"
                  : "Edit Email Id"}
              </Typography>
              <ValidatorForm
                useref="form"
                onSubmit={handleSubmit}
                style={{ width: "100%" }}
                className={classes.InputForm}
              >
                <div className={classes.depFormContainer}>
                  <div>
                    <div className="mb-5">
                      <Typography
                        htmlFor="emailidInput"
                        className={classes.login_form_lable}
                      >
                        {props.type === "number" ? "Mobile Number" : "Email Id"}
                      </Typography>
                      <TextValidator
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                        id="emailidInput"
                        inputProps={{
                          maxLength: props.type === "number" ? 10 : null,
                        }}
                        fullWidth
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        size="small"
                        validators={["required"]}
                        errorMessages={[
                          props.type === "number"
                            ? "Mobile Number is Required"
                            : "Email is Required",
                        ]}
                      />
                    </div>
                  </div>
                  <div className={classes.addMemberModalbtnSection}>
                    <Button
                      variant="contained"
                      type="submit"
                      fullwidth
                      style={{ width: "100%" }}
                      className={classes.commonbtn}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </ValidatorForm>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 25,
              }}
            >
              <img src={successImage} alt="" />
              <Typography className={classes.modalHeadind}>
                {props.type === "number"
                  ? "Mobile Number Updated Successfully"
                  : "Email Id Updated Successfully"}
              </Typography>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default UpdateModal;
