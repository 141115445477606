const { useDispatch, useSelector } = require("react-redux");
const { authApi, api } = require("../api-calls/user-requests");
const { LANGUAGE } = require("../api-calls/api-urls");
const { setLanguageJsonData } = require("../Redux/actions/user-actions");

export const getLanguagedataFunction = async (dispatch) => {
  let lan = localStorage.getItem("language") || "en";
  if (!localStorage.getItem("language")) {
    localStorage.setItem("language", "en");
  }
  if (lan === "undefined") {
    lan = "en";
  }
  const { data } = await api.get(`${LANGUAGE}/${lan}`);

  // console.log(data);
  dispatch(setLanguageJsonData(data));
};
