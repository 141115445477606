import React from "react";
import { Card } from "@mui/material";

const Demo = () => {
  return (
    <div>
      <Card>
        {/* <video controls autoPlay width="100%" height="">
                    <source
                        type="video/mp4"
                    // src="https://endtest-videos.s3-us-west-2.amazonaws.com/documentation/endtest_data_driven_testing_csv.mp4"
                    />
                </video> */}
        Demo Page Need to discuss with client
      </Card>
    </div>
  );
};

export default Demo;
