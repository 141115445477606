import React, { useEffect } from "react";
import classes from "./index.module.css";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Divider, Avatar, Button } from "@mui/material";
import { BrudCrumbBackIcon, BrudCrumbNextIcon, DeleteBinIcons } from "./Icons";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagedataFunction } from "../utilities/language";
import {
  GET_NOTIFICATIONS,
  ImageUrl,
  UPDATE_NOTIFICATION,
} from "../api-calls/api-urls";
import { authApi, getAuthorizationHeader } from "../api-calls/user-requests";
import { setNotificationList } from "../Redux/actions/user-actions";
import {
  generateColor,
  getNotificationUnseenCount,
  stringAvatar,
} from "../utilities/commonFuntions";
import moment from "moment";
import NotificationItem from "./notificationItem";

const NotificationsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  const { notificationList } = useSelector((state) => state.reducers.allUsers);

  const getTimeDiff = (date) => {
    const startDate = new Date(date);
    const endDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = endDate - startDate;

    // Calculate time difference in minutes, hours, and days
    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (minutes < 60) {
      return `${minutes} minutes Ago`;
    } else if (hours < 24) {
      return `${hours} hours Ago`;
    } else if (days < 7) {
      return `${days} days Ago`;
    } else {
      return moment(date).format("DD MM YYYY, hh:mm a");
    }
  };

  useEffect(() => {
    if (notificationList && notificationList.length === 0) {
      navigate("/home");
    }
  }, [notificationList]);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  useEffect(() => {
    getNotifications();
  }, []);

  const handleMarkAsRead = (id) => {
    const { data } = authApi.put(
      `${UPDATE_NOTIFICATION}/${id}`,
      {},
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    console.log(data);
    getNotifications();
  };

  const handleDeleteNotification = async (id) => {
    console.log(id);
    const { data } = await authApi.delete(`${UPDATE_NOTIFICATION}/${id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    getNotifications();
  };

  const getNotifications = async () => {
    const { data } = await authApi.get(`${GET_NOTIFICATIONS}/${userInfo._id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log("Notification List ====>", data);
    dispatch(setNotificationList(data));
  };

  return (
    <div>
      {ln && (
        <div>
          <div className={`${classes.brudCrumb} mb-4 `}>
            <span onClick={() => navigate(-1)}>
              <BrudCrumbBackIcon />
            </span>
            <span
              onClick={() => navigate("/home")}
              className={classes.form_lable}
              style={{ color: "#0A1F41" }}
            >
              {ln.home}
            </span>
            <BrudCrumbNextIcon />
            <span className={classes.form_lable}>{ln.notifications}</span>
          </div>
          <Paper>
            <div className={classes.notificationHeaderContainer}>
              <Typography className={classes.notificationHEader}>
                Notifications
              </Typography>
              <span className={classes.unseencount}>
                {getNotificationUnseenCount(notificationList)}
              </span>
            </div>
            <Divider />
            <div style={{ padding: "20px" }}>
              <Typography className={`mb-3 ${classes.notificationHEader}`}>
                New
              </Typography>
              <div>
                {notificationList &&
                  notificationList.length > 0 &&
                  notificationList
                    .filter((item) => !item.is_seen)
                    .map((item, index) => (
                      <div
                        key={index}
                        className={classes.NotificationItemMainDiv}
                        style={{ alignItems: "center" }}
                      >
                        <div>
                          {item.from_user_id.profile_pic ? (
                            <Avatar
                              alt="Remy Sharp"
                              src={`${process.env.REACT_APP_IMAGE_URL}/${item.from_user_id?.profile_pic?.destination}${item.from_user_id?.profile_pic?.filename}`}
                              sx={{ width: 32, height: 32 }}
                            />
                          ) : (
                            <Avatar
                              {...stringAvatar(item.from_user_id.name)}
                              sx={{
                                bgcolor: `${generateColor(
                                  item.from_user_id.name
                                )}`,
                                width: 32,
                                height: 32,
                                fontSize: 13,
                                fontWeight: 700,
                              }}
                            ></Avatar>
                          )}
                        </div>
                        <div style={{ width: "65%" }}>
                          <Typography
                            className={`mb-1 ${classes.subs_heading_cell}`}
                          >
                            {item.subject}
                            <span
                              className={classes.TimeTextNotifivcation}
                            >{` | ${getTimeDiff(item.createdAt)}`}</span>
                          </Typography>
                          <Typography
                            className={`${classes.selectedMenu} ${classes.mesgadeTExt}`}
                          >
                            {item.message}
                          </Typography>
                        </div>
                        <div className={classes.hover_button_container}>
                          <Button
                            variant="text"
                            className={classes.markAsReadBtn}
                            onClick={() => handleDeleteNotification(item._id)}
                          >
                            <DeleteBinIcons />
                          </Button>
                        </div>
                      </div>
                    ))}
                {notificationList &&
                  notificationList.length > 0 &&
                  notificationList.filter((item) => !item.is_seen).length ===
                    0 && (
                    <div>
                      <Typography>No Recent Notification</Typography>
                    </div>
                  )}
              </div>
            </div>
            <div style={{ padding: "20px" }}>
              <Typography className={`mb-3 ${classes.notificationHEader}`}>
                Earlier
              </Typography>
              <div>
                {notificationList &&
                  notificationList.length > 0 &&
                  notificationList
                    .filter((item) => item.is_seen)
                    .map((item, index) => (
                      <div
                        key={index}
                        className={classes.NotificationItemMainDiv}
                        style={{ alignItems: "center" }}
                      >
                        <div>
                          {item.from_user_id.profile_pic ? (
                            <Avatar
                              alt="Remy Sharp"
                              src={`${process.env.REACT_APP_IMAGE_URL}/${item.from_user_id?.profile_pic?.destination}${item.from_user_id?.profile_pic?.filename}`}
                              sx={{ width: 32, height: 32 }}
                            />
                          ) : (
                            <Avatar
                              {...stringAvatar(item.from_user_id.name)}
                              sx={{
                                bgcolor: `${generateColor(
                                  item.from_user_id.name
                                )}`,
                                width: 32,
                                height: 32,
                                fontSize: 13,
                                fontWeight: 700,
                              }}
                            ></Avatar>
                          )}
                        </div>
                        <div style={{ width: "65%" }}>
                          <Typography
                            className={`mb-1 ${classes.subs_heading_cell}`}
                          >
                            {item.subject}
                            <span
                              className={classes.TimeTextNotifivcation}
                            >{` | ${getTimeDiff(item.createdAt)}`}</span>
                          </Typography>
                          <Typography
                            className={`${classes.selectedMenu} ${classes.mesgadeTExt}`}
                          >
                            {item.message}
                          </Typography>
                        </div>
                        <div className={classes.hover_button_container}>
                          <Button
                            variant="text"
                            className={classes.markAsReadBtn}
                            onClick={() => handleDeleteNotification(item._id)}
                          >
                            <DeleteBinIcons />
                          </Button>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </Paper>
        </div>
      )}
    </div>
  );
};

export default NotificationsPage;
