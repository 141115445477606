import { combineReducers } from "redux";
import { userReducer } from "./user-reducer";
import { teamReducer } from "./team-reducer";
import { locationReducer } from "./location-reducer";
import { ChatReducers } from "./chatReducers";
import { HolidayReducers } from "./holidayReducers";
import { BlogsReducers } from "./blogsReducers";
import { addressReducer } from "./address-reducer";
const allreducers = combineReducers({
  allUsers: userReducer,
  teams: teamReducer,
  location: locationReducer,
  chats: ChatReducers,
  holidays: HolidayReducers,
  blogs: BlogsReducers,
  address: addressReducer,
});
export default allreducers;
