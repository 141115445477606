import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  InputAdornment,
  TextField,
  Button,
} from "@mui/material";
import classes from "./FaqSection.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SearchIcon } from "../../../components/Icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagedataFunction } from "../../../utilities/language";
import { useNavigate } from "react-router-dom";

const FaqSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [serachValue, setSearchValue] = useState();
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const ProblemsList = [
    {
      question: "What is lorem ipsum? ",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque commodo mi dolor, sed viverra ipsum gravida vel. Morbi gravida viverra nisi, vel rhoncus enim suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque commodo mi dolor, sed viverra ipsum gravida vel. Morbi gravida viverra nisi, vel rhoncus enim suscipit. ",
    },
    {
      question: "What is lorem ipsum? ",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque commodo mi dolor, sed viverra ipsum gravida vel. Morbi gravida viverra nisi, vel rhoncus enim suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque commodo mi dolor, sed viverra ipsum gravida vel. Morbi gravida viverra nisi, vel rhoncus enim suscipit. ",
    },
    {
      question: "What is lorem ipsum? ",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque commodo mi dolor, sed viverra ipsum gravida vel. Morbi gravida viverra nisi, vel rhoncus enim suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque commodo mi dolor, sed viverra ipsum gravida vel. Morbi gravida viverra nisi, vel rhoncus enim suscipit. ",
    },
  ];

  const FAQ_List = [
    {
      question: "What is lorem ipsum? ",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque commodo mi dolor, sed viverra ipsum gravida vel. Morbi gravida viverra nisi, vel rhoncus enim suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque commodo mi dolor, sed viverra ipsum gravida vel. Morbi gravida viverra nisi, vel rhoncus enim suscipit. ",
    },
    {
      question: "What is lorem ipsum? ",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque commodo mi dolor, sed viverra ipsum gravida vel. Morbi gravida viverra nisi, vel rhoncus enim suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque commodo mi dolor, sed viverra ipsum gravida vel. Morbi gravida viverra nisi, vel rhoncus enim suscipit. ",
    },
  ];
  return (
    <div>
      <div className="mb-4 mt-1">
        <TextField
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
          }}
          placeholder="search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          className={classes.teamMemberSearch}
          id="firstNameinput"
          value={serachValue}
          onChange={(e) => setSearchValue(e.target.value)}
          size="small"
        />
      </div>
      <div className="mb-4 mt-1">
        <div className={classes.FaqHeaderSection}>
          <Typography className={classes.FaqHeadings}>{ln.problems}</Typography>
          <Typography className={classes.FaqViewAllButtons}>
            {ln.view_all}
          </Typography>
        </div>
        <div className={classes.AccordiansContainers}>
          {ProblemsList.map((item, index) => (
            <Accordion key={index} className={classes.AccordianTab}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.AccordianHeader}>{`${
                  index + 1
                }.   ${item.question}`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.AccordianContent}>
                  {item.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
      <div className="mt-1">
        <div className={classes.FaqHeaderSection}>
          <Typography className={classes.FaqHeadings}>{ln.faqs}</Typography>
          <Typography className={classes.FaqViewAllButtons}>
            {ln.view_all}
          </Typography>
        </div>
        <div className={classes.AccordiansContainers}>
          {FAQ_List.map((item, index) => (
            <Accordion key={index} className={classes.AccordianTab}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{`${index + 1}. ${item.question}`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
      <div
        className="mt-3"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          columnGap: "20px",
        }}
      >
        <Button
          variant="outlined"
          style={{ background: "white" }}
          className={classes.faqButton}
        >
          {ln.need_more_help}
        </Button>
        <Button
          variant="contained"
          className={classes.faqButton}
          onClick={() => navigate("/contact-us")}
        >
          {ln.contact_us}
        </Button>
      </div>
    </div>
  );
};

export default FaqSection;
