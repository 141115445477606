import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classes from "./Subscription.module.css";
import "../../../assets/css/notModule.css";
import moment from "moment";
import {
  Card,
  CardContent,
  Avatar,
  Badge,
  Chip,
  Button,
  Typography,
} from "@mui/material";
import { generateColor, stringAvatar } from "../../../utilities/commonFuntions";
import MyTableBody from "../../../components/subscriptionList";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import {
  MY_SUBS,
  MY_SUBSCRIPTION,
  MY_SUBS_AVAIL,
  PAYMENT_SUCCESS,
  RENEW_SUBS,
} from "../../../api-calls/api-urls";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagedataFunction } from "../../../utilities/language";
import { SaveAvailableSubc } from "../../../Redux/actions/team-actions";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "./ConfirmationModal";

const ImageSlider = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [availSubscription, setAvailSubscription] = useState();
  const [subscriptionList, setSubsciptionList] = useState();
  const [fullSubscList, setFullSubscList] = useState([]);
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const [success, setSuccess] = useState(false);
  // const availbleSubs = useSelector(
  //   (state) => state.reducers.teams.availbleSubs
  // );
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const getSubscriptionListData = async () => {
    const { data } = await authApi.get(`${MY_SUBS}/${userInfo._id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    setFullSubscList(data);
    const filterArray = data.filter(
      (item) => new Date(item.start_date) <= new Date()
    );
    console.log(filterArray);
    setSubsciptionList(filterArray);
    if (data) {
      getMergedData();
    }
  };

  const getMergedData = () => {};

  const getExpiryDate = (item) => {
    const filteredArray = fullSubscList.filter(
      (item2) => item2.team_id === item.team_id
    );
    console.log("Filtered Arrays", filteredArray);

    return moment(filteredArray[filteredArray.length - 1].end_date).format(
      "DD MMM YYYY"
    );
  };

  const claculateRemaining = (item) => {
    //calculate remaining days as a number of days in number using created at and item.duration
    console.log(item);
    const filteredArray = fullSubscList.filter(
      (item2) => item2.team_id === item.team_id
    );
    console.log(filteredArray);
    const expiryDate = moment(filteredArray[filteredArray.length - 1].end_date); //no of remaing days as int

    const today = moment(); //today's date
    let remainingDays = expiryDate.diff(today, "days");
    return remainingDays;
  };

  const checkRenew = (item, index) => {
    console.log(`Index: ${index}`, item);
    const filteredArray = fullSubscList.filter(
      (item2) => item2.team_id === item.team_id && item2._id !== item._id
    );
    console.log(filteredArray);
    if (filteredArray && filteredArray.length > 0) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    getSubscriptionListData();
  }, [!subscriptionList]);

  const getSubsription = async () => {
    var result = {};
    await authApi
      .get(MY_SUBS_AVAIL, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then(function (res) {
        //dispatch(SaveAvailableSubc(res.data));
        result = res.data[res.data.length - 1];
        console.log(result);
      });
    return result;
  };

  useEffect(() => {
    if (subscriptionList && subscriptionList.length > 0) {
      console.log(subscriptionList);
    }
  }, [subscriptionList]);

  const handleRenew = async (item, type) => {
    console.log(item);
    const userEntry = {
      subscriptionid: item.subscription_id,
      durationid: item.duration_details._id,
      type: type,
    };
    // console.log(userEntry);
    const { data } = await authApi.post(MY_SUBSCRIPTION, userEntry, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    if (data) {
      const userEntry2 = {
        userid: data.data.user_id,
        orderid: data.data._id,
        type: type,
      };
      const data2 = await authApi.post(PAYMENT_SUCCESS, userEntry2, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      // console.log(data2.data);
      const availsubs = await getSubsription();
      console.log(availsubs);
      if (data2.data && availsubs) {
        const userEntry3 = {
          team_id: item.teams[0]?._id,
          avail: availsubs,
          type: "renew",
        };
        const data3 = await authApi.post(RENEW_SUBS, userEntry3, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        console.log(data3.data);
        setSuccess(true);
      }
    }
  };

  const handleUpgradePlan = async (item) => {
    if (item && item.teams && item.teams.length > 0) {
      sessionStorage.setItem("team_id", item.teams[0]._id);
    }
    navigate(`/upgrade-plan/${item._id}`);
  };

  const getTotalDuration = (item) => {
    const filteredArray = fullSubscList.filter(
      (item2) => item2.team_id === item.team_id
    );
    console.log(filteredArray);
    let days = 0;
    if (filteredArray && filteredArray.length > 0) {
      filteredArray.map((item2) => {
        days += item2.duration;
      });
    }
    return days;
  };

  const closeModal = () => {
    setSuccess(false);
    getSubscriptionListData();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      subscriptionList && subscriptionList.length < 3
        ? subscriptionList.length
        : 3,
    slidesToScroll: 2,
    customPaging: () => <div className={"customDots"} />, // Custom dot element using CSS module class
    prevArrow: <></>, // Empty arrow component to hide previous button
    nextArrow: <></>, // Empty arrow component to hide next button
    //customPaging: () => <div className="custom-dot" />,
  };

  return (
    <div className={classes.SubscriptionMainDiv}>
      {ln && subscriptionList && subscriptionList.length > 0 ? (
        <div>
          <Typography className={classes.subscSubHeading}>
            {ln.teams}
          </Typography>
          <Slider
            {...settings}
            className={subscriptionList.length < 3 ? classes.sliderMaindiv : ""}
          >
            {subscriptionList.map((item, index) => {
              // console.log(item);
              return (
                <div key={index} className={classes.EachSubscriptionCard}>
                  <Card
                    key={item._id}
                    className={classes.teamCard}
                    //onClick={(e) => handleCardClick(e, item)}
                  >
                    <CardContent sx={{ paddingBottom: "16px !important" }}>
                      <div className={classes.cardHeaderContainer}>
                        <div className={classes.memberCardText}>
                          <h5 style={{ marginBottom: 0 }}>
                            {item.teams && item.teams.length > 0
                              ? item.teams[0].team_name
                              : "Available Subscription"}
                          </h5>
                        </div>
                        <div>
                          <span className={classes.AmountText}>
                            ₹{" "}
                            {item.duration_details.price
                              ? item.duration_details.price.$numberDecimal
                              : 0}
                          </span>
                          /{ln[item.duration_details.duration]}
                        </div>
                      </div>
                      <div className={classes.PiechartContainerMain}>
                        <div className={classes.piechartSvgContainer}>
                          {item &&
                          item.teams &&
                          item.teams.length > 0 &&
                          item.teams[0].team_members &&
                          item.teams[0].team_members.length > 0 ? (
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 44 44"
                              className={classes.piechartSvg}
                            >
                              <circle
                                cx="50%"
                                cy="50%"
                                fill="transparent"
                                r="15.909091"
                                stroke="#eee"
                                strokeWidth={10}
                              />
                              <circle
                                show-tip="ctDonutTip"
                                className="ct-donut-slice"
                                cx="50%"
                                cy="50%"
                                fill="transparent"
                                r="15.909091"
                                stroke="#C52A38" // Completed color
                                strokeDasharray={`${
                                  (item.teams[0].team_members.length /
                                    item.no_team_members) *
                                  100
                                } ${
                                  (item.no_team_members -
                                    item.teams[0].team_members.length /
                                      item.no_team_members) *
                                  100
                                }`}
                                strokeWidth={10}
                                strokeDashoffset={0}
                                slice-title="Completed"
                                percent-value={11}
                                usage={11}
                                total-val={20}
                              >
                                <animate
                                  attributeName="stroke-dashoffset"
                                  from={100}
                                  to={0}
                                  dur="0.5s"
                                />
                              </circle>
                              <circle
                                show-tip="ctDonutTip"
                                className="ct-donut-slice"
                                cx="50%"
                                cy="50%"
                                fill="transparent"
                                r="15.909091"
                                stroke="#240051" // Pending color
                                strokeDasharray={`${
                                  (item.no_team_members -
                                    item.teams[0].team_members.length /
                                      item.no_team_members) *
                                  100
                                } ${
                                  (item.no_team_members -
                                    (item.no_team_members -
                                      item.teams[0].team_members.length) /
                                      item.no_team_members) *
                                  100
                                }`}
                                strokeWidth={10}
                                strokeDashoffset={
                                  -(
                                    item.teams[0].team_members.length /
                                    item.no_team_members
                                  ) * 100
                                } // Adjusted to start from the end point of the second slice
                                slice-title="Pending"
                                percent-value={2}
                                usage={2}
                                total-val={20}
                              >
                                <animate
                                  attributeName="stroke-dashoffset"
                                  from={100}
                                  to={-100} // Adjusted to start from the end point of the second slice
                                  dur="0.5s"
                                />
                              </circle>
                              <circle
                                cx="50%"
                                cy="50%"
                                fill="#FFFFFF"
                                r={16}
                              ></circle>
                              <text x="50%" y="55%" className="ct-donut-center">
                                {item.no_team_members}
                              </text>
                            </svg>
                          ) : (
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 44 44"
                              className={classes.piechartSvg}
                            >
                              <circle
                                cx="50%"
                                cy="50%"
                                fill="transparent"
                                r="15.909091"
                                stroke="#eee"
                                strokeWidth={10}
                              />
                              <circle
                                show-tip="ctDonutTip"
                                className="ct-donut-slice"
                                cx="50%"
                                cy="50%"
                                fill="transparent"
                                r="15.909091"
                                stroke="#C52A38" // Completed color
                                strokeDasharray={`${(0 / 100) * 100} ${
                                  (100 / 100) * 100
                                }`}
                                strokeWidth={10}
                                strokeDashoffset={0}
                                slice-title="Completed"
                                percent-value={11}
                                usage={11}
                                total-val={20}
                              >
                                <animate
                                  attributeName="stroke-dashoffset"
                                  from={100}
                                  to={0}
                                  dur="0.5s"
                                />
                              </circle>
                              <circle
                                cx="50%"
                                cy="50%"
                                fill="#FFFFFF"
                                r={16}
                              ></circle>
                              <text x="50%" y="55%" className="ct-donut-center">
                                {item.no_team_members}
                              </text>
                            </svg>
                          )}
                          <div className="ct-tip" />
                        </div>
                        <div className={classes.memberCardText}>
                          <h5 className={classes.ImportantHeading}>
                            {claculateRemaining(item)} {ln.days}
                          </h5>
                          <p className={classes.ImportantSubHeading}>
                            {ln.remaing} {getTotalDuration(item)} {ln.days}
                          </p>
                        </div>
                      </div>
                      <hr className={classes.UnderlineHr} />
                      <div
                        className={`${classes.memberCardText} ${classes.dateText}`}
                      >
                        <p>{ln.expiry_on} : </p>
                        <h5 className={classes.ImportantHeading}>
                          {getExpiryDate(item)}
                        </h5>
                      </div>
                      <div className={classes.CardBtnsSection}>
                        {item.teams && item.teams.length > 0 ? (
                          <Button
                            variant="outlined"
                            className={classes.cardBtn}
                            disabled={
                              item.duration_details.duration === "custom" ||
                              checkRenew(item, index)
                            }
                            onClick={() => {
                              handleRenew(item, "renew");
                            }}
                          >
                            {!checkRenew(item, index)
                              ? ln.renew
                              : "Already Renewed"}
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            className={classes.cardBtn}
                            onClick={() => {
                              navigate("/my-team/create-team");
                            }}
                          >
                            {ln?.create_team}
                          </Button>
                        )}

                        <Button
                          variant="contained"
                          disabled={
                            item.duration_details.duration === "custom" ||
                            checkRenew(item, index) ||
                            !item.team_id
                          }
                          className={classes.cardBtn}
                          onClick={() => handleUpgradePlan(item)}
                        >
                          {ln.upgrade_plan}
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              );
            })}
          </Slider>
        </div>
      ) : null}
      <div style={{ marginTop: "70px" }}>
        <Typography className={classes.subscSubHeading}>
          {ln?.history}
        </Typography>
        <MyTableBody list={fullSubscList} />
      </div>
      {success && <ConfirmationModal closeModal={closeModal} />}
    </div>
  );
};

export default ImageSlider;
