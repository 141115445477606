import React, { useState } from "react";
import classes from "./myteam.module.css";
import {
  CardContent,
  MenuItem,
  Menu,
  Typography,
  Card,
  Avatar,
  Button,
  Divider,
  Badge,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  checkOnline,
  generateColor,
  stringAvatar,
} from "../../../utilities/commonFuntions";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  DeleteBinIcons,
  EditPenIcon,
  LogoutIcon,
} from "../../../components/Icons";

const TeamMemberCard = ({
  item,
  index,
  handleCardClick,
  handleMenuclick,
  openMenu,
  handleClose,
  handleMenuItemClick,
  hadleLogoutClick,
}) => {
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  const [onlineStatuses, setOnlineStatuses] = useState({});
  const { teamMembersList } = useSelector((state) => state.reducers.allUsers);
  return (
    <div key={index}>
      {item.user_name || item.name ? (
        <Card
          key={item._id}
          className={classes.teamCard}
          onClick={(e) => handleCardClick(e, item._id)}
        >
          <CardContent sx={{ paddingBottom: "16px !important" }}>
            <div
              className={`${classes.teamCardContainer} ${classes.teamMembercardContainer}`}
            >
              <Badge
                color={
                  checkOnline(item?.user_date?.[0]?._id) === "checkin"
                    ? "success"
                    : "error"
                }
                badgeContent=" "
                variant="dot"
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                className={classes.LocationOnlineBadge}
              >
                {item?.user_date &&
                item?.user_date?.length > 0 &&
                item?.user_date[0]?.profile_pic &&
                item?.user_date[0]?.profile_pic?.filename ? (
                  <Avatar
                    alt={item?.user_name || item?.name}
                    src={`${process.env.REACT_APP_IMAGE_URL}/${item?.user_date[0]?.profile_pic?.destination}${item?.user_date[0]?.profile_pic?.filename}`}
                    sx={{ width: 40, height: 40 }}
                  />
                ) : item.user_name || item.name ? (
                  <Avatar
                    {...stringAvatar(item.user_name || item.name)}
                    sx={{
                      bgcolor: `${generateColor(item.user_name || item.name)}`,
                    }}
                  />
                ) : null}
              </Badge>
              {item.label}
              <div className={classes.memberCardText}>
                <Typography className={classes.form_lable}>
                  {ln.team_member}
                </Typography>
                <Typography className={classes.CardValues}>
                  {item.user_name || item.name}
                </Typography>
              </div>
              <div className={classes.cardMenuButton}>
                <Button
                  variant="text"
                  className={`${classes.CardMenu} ${classes.member}`}
                  onClick={(e) => handleMenuclick(e, item)}
                >
                  <BsThreeDotsVertical />
                </Button>
                <Menu
                  anchorEl={openMenu}
                  open={Boolean(openMenu)}
                  onClose={handleClose}
                >
                  <MenuItem
                    key={`edit${item._id}`}
                    value={index}
                    className={classes.EditMenuTeam}
                    onClick={(e) => handleMenuItemClick(e, "edit")}
                  >
                    <EditPenIcon />
                    <span>{ln.edit_member}</span>
                  </MenuItem>
                  <MenuItem
                    key={`delete${item._id}`}
                    onClick={(e) => handleMenuItemClick(e, "delete")}
                  >
                    <DeleteBinIcons />
                    <span>{ln.delete_member}</span>
                  </MenuItem>
                  <MenuItem
                    key={`logout${item._id}`}
                    onClick={(e) => hadleLogoutClick(e)}
                  >
                    <LogoutIcon />{" "}
                    <span>
                      {ln.player_logout ? ln.player_logout : "Player Logout"}
                    </span>
                  </MenuItem>
                </Menu>
              </div>
            </div>
            <hr />
            <div className={classes.membercardFooter}>
              <div
                className={`${classes.memberCardText} ${classes.memberTextsubContainer}`}
              >
                <Typography className={classes.form_lable}>
                  {ln.mobile_number}
                </Typography>
                <Typography className={classes.CardValues}>
                  +91 {item.mobile_number}
                </Typography>
              </div>
              <div
                className={`${classes.memberCardText} ${classes.memberTextsubContainer}`}
              >
                <Typography className={classes.form_lable}>
                  {ln.departments}
                </Typography>
                <Typography className={classes.CardValues}>
                  {item &&
                  item.department_data &&
                  item.department_data.length > 0 &&
                  item.department_data[0].department_name
                    ? item.department_data[0].department_name
                    : item.department}
                </Typography>
              </div>
            </div>
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
};

export default TeamMemberCard;
