import React from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

function GuestLayout() {
  const currentUser = JSON.parse(sessionStorage.getItem("userInfo"));
  const token = Cookies.get("userToken");

  if (currentUser && currentUser.token) {
    return <Navigate to="/home" />;
  } else if (token) {
    sessionStorage.setItem("userInfo", token);
    return <Navigate to="/home" />;
  }

  return (
    <div>
      <Outlet />
    </div>
  );
}

export default GuestLayout;
