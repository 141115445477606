import React, { useEffect, useState } from "react";
import {
  BlogBookMarkIcon,
  BlogShareIcon,
  BrudCrumbBackIcon,
  BrudCrumbNextIcon,
} from "../../../components/Icons";
import avatar from "../../../assets/images/bookmark (1).png";
import { Paper, Typography, Avatar, Card, CardContent } from "@mui/material";
import classes from "./blogs.module.css";
import { useNavigate } from "react-router-dom";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";
import { useDispatch, useSelector } from "react-redux";
import {
  BLOGS_DETAILS,
  GET_ONE_USER_SAVED_LOCATION,
  ImageUrl,
  SAVE_USER_BLOG,
} from "../../../api-calls/api-urls";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { generateColor, stringAvatar } from "../../../utilities/commonFuntions";
import moment from "moment";
import { getLanguagedataFunction } from "../../../utilities/language";

const BlogDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [blogId, setBlogId] = useState();
  const [pageDetails, setPageDetails] = useState();
  const [isSaved, setIsSaved] = useState(false);
  const { blogsList } = useSelector((state) => state.reducers.blogs);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);
  const actions = [
    // ... other actions
    {
      name: "Share on Facebook",
      icon: <FacebookIcon size={32} round />,
      onClick: () => {
        // Define what happens when the Facebook share action is clicked
        // For example, open a Facebook share dialog
        const shareUrl = `https://tp.apps.org.in/public/blogs/blog-details/${blogId}`;
        const quote = "Dummy text!";
        const hashtag = "#muo";
        const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}&quote=${encodeURIComponent(quote)}&hashtag=${encodeURIComponent(
          hashtag
        )}`;
        window.open(url, "_blank");
      },
    },
    {
      name: "Share on Twitter",
      icon: <TwitterIcon size={32} round />,
      onClick: () => {
        const shareUrl = `https://tp.apps.org.in/public/blogs/blog-details/${blogId}`;
        const text = "Dummy text!";
        const hashtags = ["muo"];
        const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          shareUrl
        )}&text=${encodeURIComponent(text)}&hashtags=${encodeURIComponent(
          hashtags.join(",")
        )}`;
        window.open(url, "_blank");
      },
    },
    {
      name: "Share on LinkedIn",
      icon: <LinkedinIcon size={32} round />,
      onClick: () => {
        const shareUrl = `https://tp.apps.org.in/public/blogs/blog-details/${blogId}`;
        const title = "Dummy text!";
        const summary = "Your summary here";
        const source = "Your source here";
        const url = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
          shareUrl
        )}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(
          summary
        )}&source=${encodeURIComponent(source)}`;
        window.open(url, "_blank");
      },
    },
    {
      name: "Share on WhatsApp",
      icon: <WhatsappIcon size={32} round />,
      onClick: () => {
        const shareUrl = `https://tp.apps.org.in/public/blogs/blog-details/${blogId}`;
        const text = "Dummy text!";
        const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          text + " " + shareUrl
        )}`;
        window.open(url, "_blank");
      },
    },
  ];

  const getOneBlogDetails = async (id) => {
    const path = window.location.pathname;
    const arr = path.split("/");
    setBlogId(id);
    const { data } = await authApi.get(`${BLOGS_DETAILS}/${id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    setPageDetails(data[0]);
  };

  useEffect(() => {
    const path = window.location.pathname;
    const arr = path.split("/");
    getOneBlogDetails(arr[arr.length - 1]);
    checkAlreadySaved(arr[arr.length - 1]);
  }, [window.location.pathname]);

  const handleSaveBlog = async (id) => {
    const { data } = await authApi.post(
      `${SAVE_USER_BLOG}/${id}`,
      {},
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    getOneBlogDetails(id);
    checkAlreadySaved(id);
  };

  const checkAlreadySaved = async (id) => {
    const { data } = await authApi.get(`${GET_ONE_USER_SAVED_LOCATION}/${id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    setIsSaved(data.message);
  };

  const handleCardClick = (id) => {
    console.log("It Triggers ===>", id);
    navigate(`/blogs/blog-details/${id}`);
    getOneBlogDetails(id);
    checkAlreadySaved(id);
  };

  return (
    <div>
      <div className={`${classes.brudCrumb} mb-4 `}>
        <span onClick={() => navigate(-1)}>
          <BrudCrumbBackIcon />
        </span>
        <span onClick={() => navigate("/blogs")}>{"Blogs"}</span>
        <BrudCrumbNextIcon />
        <span style={{ color: "#6A6E8A" }}>{"Blog Details"}</span>
      </div>
      {pageDetails && (
        <Paper style={{ padding: "25px" }}>
          <img
            src={`${process.env.REACT_APP_IMAGE_URL}/${pageDetails.images[0]?.destination}${pageDetails.images[0]?.filename}`}
            alt=""
            className={`mb-4 ${classes.image}`}
            width={"100%"}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography className={`mb-3 ${classes.DetailsMainHEading}`}>
              {pageDetails.heading}
            </Typography>
            <div style={{ display: "flex", columnGap: "15px" }}>
              <SpeedDial
                ariaLabel="SpeedDial basic example"
                icon={<BlogShareIcon />}
                direction={"left"}
                className={classes.BlogShareBtn}
              >
                {actions.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={action.onClick} // Attach the onClick function
                  />
                ))}
              </SpeedDial>

              {isSaved ? (
                <span onClick={() => handleSaveBlog(pageDetails._id)}>
                  <img src={avatar} alt="" width={48} height={48} />
                </span>
              ) : (
                <span onClick={() => handleSaveBlog(pageDetails._id)}>
                  <BlogBookMarkIcon />
                </span>
              )}
            </div>
          </div>
          <div className={`mb-4 ${classes.AvatarSection}`}>
            {pageDetails.userInfo.profile_pic ? (
              <Avatar
                alt="Remy Sharp"
                src={`${process.env.REACT_APP_IMAGE_URL}/${pageDetails.userInfo?.profile_pic?.destination}${pageDetails.userInfo?.profile_pic?.filename}`}
                sx={{ width: 32, height: 32 }}
              />
            ) : (
              <Avatar
                {...stringAvatar(pageDetails.userInfo.name)}
                sx={{
                  bgcolor: `${generateColor(pageDetails.userInfo.name)}`,
                  width: 32,
                  height: 32,
                  fontSize: 13,
                  fontWeight: 700,
                }}
              ></Avatar>
            )}
            <div>
              <Typography className={classes.ModalHEaderMainText}>
                {pageDetails.userInfo.name}
              </Typography>
              <Typography className={`mt-1 ${classes.ModalHEaderSubText}`}>
                {moment(pageDetails.createdAt).format("DD MMM , YYYY")} |{" "}
                {`${pageDetails.estimation_time || 1} Min Read`}
              </Typography>
              {/* <Typography className={classes.ModalHeaderStatusText}>
                        joined
                    </Typography> */}
            </div>
          </div>
          <div>
            <Typography className={`mb-4 ${classes.DetailsPAgeSubHEading}`}>
              {pageDetails.subject}
            </Typography>
            <Typography className={`mb-4 ${classes.Detailstext}`}>
              {pageDetails.message}
            </Typography>
          </div>
        </Paper>
      )}
      <Typography className={`mb-4 mt-4 ${classes.BloGPAgeSimilaRHEading}`}>
        {ln.similar_blogs}
      </Typography>
      <div className={classes.CardContainer}>
        {blogsList &&
          blogsList.length > 0 &&
          blogsList
            .filter(
              (item) =>
                item._id !== pageDetails?._id &&
                item.tags.includes(pageDetails?.tags[0])
            )
            .slice(0.3)
            .map((item, index) => (
              <Card
                key={index}
                onClick={() => {
                  handleCardClick(item._id);
                }}
              >
                <CardContent className={classes.BlogsCardContent}>
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}/${item.images[0]?.destination}${item.images[0]?.filename}`}
                    alt=""
                    className="mb-4"
                  />
                  {item.tags &&
                    item.tags.length > 0 &&
                    item.tags.map((tag, index) => (
                      <span className={`${classes.blogChipCard}`} key={index}>
                        {tag}
                      </span>
                    ))}
                  <Typography
                    className={`mb-2 mt-3 ${classes.ModalHEaderMainText}`}
                  >
                    {item.heading}
                  </Typography>
                  <div className={`${classes.AvatarSection}`}>
                    {item.userInfo.profile_pic ? (
                      <Avatar
                        alt="Remy Sharp"
                        src={`${process.env.REACT_APP_IMAGE_URL}/${item.userInfo?.profile_pic?.destination}${item.userInfo?.profile_pic?.filename}`}
                        sx={{ width: 32, height: 32 }}
                      />
                    ) : (
                      <Avatar
                        {...stringAvatar(item.userInfo.name)}
                        sx={{
                          bgcolor: `${generateColor(item.userInfo.name)}`,
                          width: 32,
                          height: 32,
                          fontSize: 13,
                          fontWeight: 700,
                        }}
                      ></Avatar>
                    )}
                    <div>
                      <Typography className={classes.ModalHEaderMainText}>
                        {item.userInfo.name}
                      </Typography>
                      <Typography className={classes.ModalHEaderSubText}>
                        {moment(new Date(item.createdAt)).format("DDMMM ,YYYY")}{" "}
                        | {`${item.estimation_time || 1} Min Read`}
                      </Typography>
                      {/* <Typography className={classes.ModalHeaderStatusText}>
                        joined
                    </Typography> */}
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
      </div>
    </div>
  );
};

export default BlogDetailsPage;
