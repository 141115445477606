import { actionTypes } from "../constant/action-types";
const initialState = {
  grouplist: [],
  contactList: [],
  messagesList: [],
  allMessageDatas: [],
  lastmessageIds: [],
  selectedChat: null,
};

export const ChatReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SAVE_GROUPS:
      return { ...state, grouplist: [...payload] };
    case actionTypes.SAVE_CONTACT_LIST:
      return { ...state, contactList: [...payload] };
    case actionTypes.SAVE_MESSAGES:
      return { ...state, messagesList: payload };
    case actionTypes.SAVE_RECEIVED_MESSAGES:
      return { ...state, allMessageDatas: payload };
    case actionTypes.SAVE_LAST_MESSAGE_IDS:
      return { ...state, lastmessageIds: payload };
    case actionTypes.SELECTED_CHAT_MEMBER:
      return { ...state, selectedChat: payload };
    default:
      return state;
  }
};
