import React, { useState } from "react";
import ImageCarosel from "../../../components/ImageCarosel";
import classes from "./index.module.css";
import AppLogo from "../../../assets/images/logo-dark.png";
import { ValidatorForm } from "react-material-ui-form-validator";
import "../../../assets/css/style.css";
import "../../../assets/css/bootstrap.min.css";
import { Card } from "@mui/material";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SelectRole = () => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/personal-details");
  };
  return (
    <div>
      <Card className={classes.SelectLanguage_maindiv}>
        <div>
          <ImageCarosel />
        </div>
        <ValidatorForm useref="form" onSubmit={handleSubmit}>
          <div className="col-lg-12">
            <div className="p-lg-5 mt-5 p-4">
              <div className="text-left">
                <span className="logo-lg mb-5">
                  <img src={AppLogo} alt="" height="50" />
                </span>
                <h5 className="mt-4 login-text">Which Describes you Best ?</h5>
                <p className="login-text1 mt-2">Register to get started</p>
              </div>

              <div className="mt-4">
                <div>
                  <div
                    className="form-check px-0 d-flex mb-2"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      <div className="d-flex align-items-start text-muted mb-4">
                        <div className="flex-shrink-0 me-3 us">
                          <svg
                            fill="#000000"
                            width="30px"
                            height="30px"
                            viewBox="0 0 1.2 1.2"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M0.786 0.636a0.3 0.3 0 1 0 -0.371 0 0.5 0.5 0 0 0 -0.311 0.409 0.05 0.05 0 0 0 0.1 0.011 0.4 0.4 0 0 1 0.795 0 0.05 0.05 0 0 0 0.05 0.045h0.006a0.05 0.05 0 0 0 0.044 -0.055 0.5 0.5 0 0 0 -0.313 -0.409ZM0.6 0.6a0.2 0.2 0 1 1 0.2 -0.2 0.2 0.2 0 0 1 -0.2 0.2Z" />
                          </svg>
                        </div>

                        <div className="flex-grow-1">
                          <h5 className="fs-16">
                            <b>Team Leader</b>
                          </h5>
                          <p
                            style={{
                              fontFamily: "Noto Sans",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "14px",
                              margin: 0,
                              color: "#6b778c",
                            }}
                          >
                            Lorem ipsum dolor sit amet,
                            <br />
                            consectetur adipiscing
                          </p>
                        </div>
                      </div>
                    </label>
                    <input type="radio" name="address" />
                    {/* <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    /> */}
                  </div>
                  <div
                    className="form-check d-flex px-0"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      <div className="d-flex align-items-start text-muted mb-4">
                        <div className="flex-shrink-0 me-3 us">
                          <svg
                            fill="#000000"
                            width="30px"
                            height="30px"
                            viewBox="-0.2 -0.15 2.4 2.4"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="xMinYMin"
                            className="jam jam-users"
                          >
                            <path d="M0.353 1.107a0.1 0.1 0 1 1 0.073 0.186A0.358 0.358 0 0 0 0.2 1.626V1.8a0.1 0.1 0 0 0 0.1 0.1h0.8a0.1 0.1 0 0 0 0.1 -0.1v-0.165a0.366 0.366 0 0 0 -0.236 -0.342 0.1 0.1 0 1 1 0.071 -0.187A0.566 0.566 0 0 1 1.4 1.635V1.8a0.3 0.3 0 0 1 -0.3 0.3H0.3a0.3 0.3 0 0 1 -0.3 -0.3v-0.174a0.558 0.558 0 0 1 0.353 -0.519zM0.7 0.1a0.4 0.4 0 0 1 0.4 0.4v0.2a0.4 0.4 0 1 1 -0.8 0V0.5a0.4 0.4 0 0 1 0.4 -0.4zm0 0.2a0.2 0.2 0 0 0 -0.2 0.2v0.2a0.2 0.2 0 1 0 0.4 0V0.5a0.2 0.2 0 0 0 -0.2 -0.2zm0.9 1.7a0.1 0.1 0 0 1 0 -0.2h0.1a0.1 0.1 0 0 0 0.1 -0.1v-0.184a0.339 0.339 0 0 0 -0.232 -0.321 0.1 0.1 0 1 1 0.063 -0.19A0.539 0.539 0 0 1 2 1.516V1.7a0.3 0.3 0 0 1 -0.3 0.3h-0.1zM1.3 0.2a0.1 0.1 0 0 1 0 -0.2 0.4 0.4 0 0 1 0.4 0.4v0.2a0.4 0.4 0 0 1 -0.4 0.4 0.1 0.1 0 0 1 0 -0.2 0.2 0.2 0 0 0 0.2 -0.2V0.4a0.2 0.2 0 0 0 -0.2 -0.2z" />
                          </svg>
                        </div>

                        <div className="flex-grow-1">
                          <h5 className="fs-16">
                            <b>Team Player</b>
                          </h5>
                          <p
                            style={{
                              fontFamily: "Noto Sans",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "14px",
                              margin: 0,
                              color: "#6b778c",
                            }}
                          >
                            Lorem ipsum dolor sit amet,
                            <br />
                            consectetur adipiscing
                          </p>
                        </div>
                      </div>
                    </label>
                    {/* <input type="radio" name="address" /> */}
                    {/* <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      checked=""
                    /> */}
                  </div>
                </div>

                <div className="mt-4">
                  <Button className="btn btn-primary w-100" type="submit">
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </ValidatorForm>
      </Card>
    </div>
  );
};

export default SelectRole;
