import React from "react";
import { client, xml, jid } from "@xmpp/client";
import debug from "@xmpp/debug";
import { XmppBaseUrl, xmppDomain } from "../api-calls/xmppUrls";
import {
  authApi,
  authImageApi,
  getAuthorizationHeader,
} from "../api-calls/user-requests";
import { UPLOAD_IMAGE, XMPP_REREGISTER } from "../api-calls/api-urls";
import moment from "moment";
import { getConversationMessages, getUserGroupList } from "./commonFuntions";

let xmpp = null;
// const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

export const initializeXmppClient = async (userInfo, dispatch) => {
  if (xmpp) {
    return;
  }
  console.log(userInfo);
  const pass = `${userInfo?._id.toString()}pass`;
  xmpp = client({
    service: XmppBaseUrl,
    domain: xmppDomain,
    username: userInfo?._id.toString(),
    password: pass,
  });
  try {
    await xmpp.start();
    console.log("Connected to XMPP server!");
    await xmpp.send(xml("presence"));

    if (xmpp) {
      xmpp.on("presence", (presence) => {
        const from = presence.attrs.from; // The JID of the user
        const type = presence.attrs.type;
      });

      xmpp.on("stanza", async (item) => {
        if (!item.is("message")) {
          return;
        }
        let msgtime = extactChatTime(item);
        let stanza = extactChatType(item)
        let msgDtls = {};
        console.log(stanza);
        let Messages;

        if (stanza?.attrs?.type === "groupchat") {
          msgDtls.group = stanza?.attrs.from.split("/")[0];
          msgDtls.from = stanza?.attrs.from.split("/")[1];
        } else if (stanza?.attrs?.type === "chat") {
          msgDtls.from = stanza?.attrs.from.split("@")[0];
          msgDtls.to = stanza?.attrs.to.split("@")[0];
        } else {
          return;
        }
        const bodyElement = stanza?.children.find((itm) => itm.name === "body");
        //FileTransfer 
        const fileElement = stanza?.children.find((itm) => itm.name === "file");

        if (bodyElement) {
          msgDtls.message = bodyElement?.children?.toString();
        } else {
          return
        }
        const delayElement = stanza?.children.find((itm) => itm.name === "delay");
        if (delayElement) {
          msgDtls.timestamp = delayElement?.attrs?.stamp?.toString();
        } else {
          msgDtls.timestamp = msgtime;
        }
        //File Transfer
        if (fileElement) {
          msgDtls.file = fileElement?.children?.toString();
        }
        msgDtls.id = stanza.attrs.id;

        let conversation = "";
        if (msgDtls.hasOwnProperty('group') && msgDtls.group !== "") {
          conversation = msgDtls.group;
        } else {
          if (msgDtls.from === userInfo?._id) {
            conversation = msgDtls.to;
          } else {
            conversation = msgDtls.from;
          }
        }
        const existMessages = JSON.parse(localStorage.getItem(conversation)) || [];
        const isIdAlreadyPresent = (messages, id) => {
          return messages.some((message) => message.id === id);
        }
        if (!isIdAlreadyPresent(existMessages, msgDtls.id)) {
          existMessages.push(msgDtls);
        }
        dispatch(getUserGroupList());
        localStorage.setItem(conversation, JSON.stringify(existMessages));
        return;
      });

      debug(xmpp, true);
      xmpp.on("status", (status) => {
        console.log(status);
      });
    }


  } catch (err) {
    console.error("Error starting XMPP client:", err);
  }

};

const extactChatTime = (stanza) => {
  const result = stanza?.children.find((itm) => itm.name === "result");
  const forwarded = result?.children.find(
    (itm) => itm.name === "forwarded"
  );
  const TimeStamp = forwarded?.children.find((itm) => itm.name === "delay");
  if (TimeStamp) {
    return new Date(TimeStamp?.attrs.stamp);
  }
}

function extactChatType(stanza) {
  let followItems = ["message", "result", "forwarded"];

  if (stanza.name === "message" && stanza.children.length > 1) {
    return stanza;
  } else if (stanza.name === "message" && stanza.children.length >= 1) {
    const checkStanza = stanza?.children?.find(
      (itm) => itm.name === "body"
    );
    parseInt('checkStanza', checkStanza)
    if (checkStanza) {
      return stanza;
    } else {
    }
  } else {
  }

  const bodyElement = stanza?.children?.find(
    (itm) => itm.name === "message"
  );
  if (typeof bodyElement !== "undefined")
    return bodyElement;

  if (typeof bodyElement === "undefined" || bodyElement.children.length < 2) {
    for (const item of stanza?.children) {
      if (followItems.includes(item.name))
        return extactChatType(item);
    }
  }
}

export const join_groups = async (user, array, dispatch) => {
  await initializeXmppClient(user, dispatch);
  xmpp.on("online", async (address) => {
    if (array && array.length > 0) {
      array.forEach(async (groupID) => {
        const uid =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);
        let xmlJoin = xml(
          "presence",
          {
            id: uid,
            to: `${groupID}/${user?._id}`,
            from: `${user?._id}@${xmppDomain}/${address}`,
          },
          xml(
            "x",
            { xmlns: "http://jabber.org/protocol/muc#user" },
            xml("item", { affiliation: "member", role: "participant" })
          )
        );
        try {
          await xmpp.send(xmlJoin);
          console.log("Presense : ", xmlJoin.toString());
        } catch (err) {
          console.log(err);
        }
      });
    }
  });
  // if (array && array.length > 0) {
  //   array.forEach(async (groupID) => {
  //     const uid =
  //       Math.random().toString(36).substring(2, 15) +
  //       Math.random().toString(36).substring(2, 15);
  //     let xmlJoin = xml(
  //       "presence",
  //       {
  //         id: uid,
  //         to: `${groupID}/${user._id}`,
  //         from: jidAddress,
  //       },
  //       xml(
  //         "x",
  //         { xmlns: "http://jabber.org/protocol/muc#user" },
  //         xml("item", { affiliation: "member", role: "participant" })
  //       )
  //     );
  //     try {
  //       await xmpp.send(xmlJoin);
  //       console.log("Presense : ", xmlJoin.toString());
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   });
  // }
};

export const setPresenceOnline = async (data, dispatch) => {
  await initializeXmppClient(data, dispatch);
};

export const setPresenceOffline = async () => {
  if (xmpp) {
    try {
      // Set presence as unavailable
      await xmpp.send(xml("presence", { type: "unavailable" }));
      xmpp.stop();
      xmpp = null;
      console.log("Offline");
    } catch (err) {
      console.error("Error setting presence:", err);
    }
  }
};

export const sendMessage = async (
  oldMessages,
  sender,
  selectedChat,
  message,
  file,
  dispatch,
  userInfo
) => {
  // Make sure the XMPP client is initialized and connected
  if (!xmpp) {
    await initializeXmppClient(sender, dispatch);
  }
  let fileUrl = null;
  if (file) {
    console.log("file", file);
    let formData = new FormData();
    formData.append("file", file);
    const { data } = await authImageApi.post(UPLOAD_IMAGE, formData, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    fileUrl = `${process.env.REACT_APP_IMAGE_URL}/${data.data.destination}${data.data.filename}`;
  }
  const random_string = Math.random().toString(36).substring(2, 15);
  let receiver_id = null;

  try {
    const uid =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    // Sends a chat message to the receiver
    if (selectedChat && selectedChat.team_id) {
      receiver_id = `${selectedChat.team_id}@conference.${xmppDomain}`;
      const groupmsgStanza = xml(
        "message",
        {
          type: "groupchat",
          to: receiver_id,
          id: uid,
        },
        xml("body", {}, message),
        xml("file", {}, fileUrl)
      );
      try {
        await xmpp.send(groupmsgStanza);
        console.log("Message sent successfully", groupmsgStanza);
        const userEntry = {
          conversation_id: selectedChat._id,
          message: message,
          file: fileUrl,
          receivers: selectedChat.receiver,
        }
        const { data } = await authApi.post(`/messages/save-message`, userEntry, {
          headers: { Authorization: getAuthorizationHeader() },
        })
      } catch (error) {
        console.error("Failed to send message:", error);
      }
    } else if (selectedChat && selectedChat.receiver && selectedChat.receiver.length < 2) {
      receiver_id = `${selectedChat.receiver[0]}@${xmppDomain}`;
      const msgStanza = xml(
        "message",
        { type: "chat", to: receiver_id, id: uid }, // Replace "receiver_id" with the dynamic receiver's JID
        xml("body", {}, message),
        xml("file", {}, fileUrl)
      );
      try {
        await xmpp.send(msgStanza);
        console.log("Message sent successfully", msgStanza);
        const userEntry = {
          conversation_id: selectedChat._id,
          message: message,
          file: fileUrl,
          receivers: selectedChat.receiver,
        }
        const { data } = await authApi.post(`/messages/save-message`, userEntry, {
          headers: { Authorization: getAuthorizationHeader() },
        })
      }
      catch (error) {
        console.error("Failed to send message:", error);
      }
    }

    if (!selectedChat.team_id) {
      const uid =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
      const user_id = receiver_id.split("@")[0];
      const messages = JSON.parse(localStorage.getItem(user_id)) || [];
      messages.push({
        id: uid,
        message: message,
        file: fileUrl,
        from: sender?._id,
        time: new Date().toISOString(),
      });
      localStorage.setItem(user_id, JSON.stringify(messages));
      // dispatch(SaveMessages(messages));
      // groupMessagesDetails[sender._id.toString()] =
      //   groupMessagesDetails[sender._id.toString()] || [];
      // const result = groupMessagesDetails[sender._id.toString()].push({
      //   id: uid,
      //   message: message,
      //   from: sender._id,
      //   time: new Date().toISOString(),
      // });
      // dispatch(saveChatReceivedMessage(result));
    }
  } catch (err) {
    console.error("Error sending message:", err);
  }
};

export const queryUserGroups = async (data, dispatch) => {
  if (!xmpp) {
    await initializeXmppClient(data, dispatch);
  }

  try {
    const result = await xmpp.iqCaller.get(xml("query", "jabber:iq:roster"));
    const rosterItems = result.getChildren("item");
    const contacts = rosterItems.map((item) => ({
      jid: item.attrs.jid,
      name: item.attrs.name,
      subscription: item.attrs.subscription,
    }));
    return contacts;
  } catch (err) {
    console.error("Error querying group data:", err.message);
    if (err.message === "not-authorized") {
      const { data } = authApi.get(XMPP_REREGISTER, {
        headers: { Authorization: getAuthorizationHeader() },
      });
    }
  }
};
export const subscribeToUserPresence = async (data, jid, dispatch) => {
  if (!xmpp) {
    await initializeXmppClient(data, dispatch);
  }
  try {
    const presence = await xmpp.sendIQ({
      to: `${jid}@${xmppDomain}`,
      type: "get",
      xmlns: "jabber:client",
      id: "check_presence",
    });

    // Process the presence response to determine if the user is online.
    const isOnline =
      presence.getChild("query")?.getChild("presence")?.attrs?.type ===
      "unavailable";

    return isOnline;
  } catch (error) {
    console.error("Error checking user presence:", error);
    return false; // Handle errors gracefully
  }
};

export const addContact = async (user, dispatch) => {
  if (!xmpp) {
    await initializeXmppClient(user, dispatch);
  }
  try {
    const jid = `64e843255f8c98043e898d93@${xmppDomain}`; // Replace with the JID of the contact you want to add
    const displayName = "SK"; // Replace with the display name of the contact

    await xmpp.send(
      xml(
        "iq",
        { type: "set" },
        xml(
          "query",
          { xmlns: "jabber:iq:roster" },
          xml("item", { jid: jid, name: displayName, subscription: "both" })
        )
      )
    );
    console.log(`Successfully added ${jid} to roster with name ${displayName}`);
  } catch (err) {
    console.error("Error adding member to contact list:", err);
  }
};

export const getMessageHistory = async (user, jid, dispatch) => {
  if (!xmpp) {
    await initializeXmppClient(user, dispatch);
  }

  try {
    // Form IQ request to query chat history for a specific JID using XEP-0313
    // await localStorage.removeItem(jid.split("@")[0]);
    const messageArchiveQuery = xml(
      "iq",
      {
        type: "set",
        id: "message-archive-query",
      },
      xml(
        "query",
        { xmlns: "urn:xmpp:mam:2" },
        xml("x", { xmlns: "jabber:x:data", type: "submit" }, [
          xml(
            "field",
            { var: "FORM_TYPE" },
            xml("value", {}, "urn:xmpp:mam:2")
          ),
          xml("field", { var: "with" }, xml("value", {}, jid)),
        ])
      )
    );
    await xmpp.send(messageArchiveQuery);
    console.log("Fetched history successfully");

    // You can parse and handle the result here to display the chat history
  } catch (err) {
    console.error("Error fetching chat history:", err);
    // Handle the error here
    // You can add custom error handling logic or display an error message to the user
  }
};
