import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { MdModeEditOutline } from "react-icons/md";
// import Profile from "../../../assets/images/avatar-3.jpg";
import InputAdornment from "@mui/material/InputAdornment";
import { BsCheckLg } from "react-icons/bs";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Paper from "@mui/material/Paper";
import { Avatar, Badge, MenuItem, Button, Menu } from "@mui/material";
import {
  Alphabetic,
  Numeric,
  generateColor,
  stringAvatar,
} from "../../../utilities/commonFuntions";
import classes from "./myprofile.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  authApi,
  authImageApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import {
  GET_USER,
  ImageUrl,
  UPDATE_USER,
  UPLOAD_IMAGE,
} from "../../../api-calls/api-urls";
import { getLanguagedataFunction } from "../../../utilities/language";
import {
  CameraIcons,
  DeleteBinIcons,
  EditPenIcon,
} from "../../../components/Icons";
import cameraimage from "../../../assets/images/camera.png";
import { setCurrentUser } from "../../../Redux/actions/user-actions";
import DeleteConformation from "./DeleteConformation";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  height: "200px",
}));
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 35,
  height: 35,
  border: `2px solid ${theme.palette.background.paper}`,
  background: "#227DC3",
}));

const PersonalDetails = () => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const [profilePic, setProfilePic] = useState();
  const [height, setHeight] = useState(320);
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const [visibility, setVisibility] = useState(true);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [referance, setReferance] = useState();
  const [mobile, setMobile] = useState();
  const [dbprofilePic, setDbprofilePic] = useState();
  const [email, setEmail] = useState();
  const [designation, setDesignation] = useState("");
  const [userEntry, setUserEntry] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [moddleToggle, setModdleToggle] = useState(false);
  // const [loggedUser, setLoggedUser] = useState();
  const loggedUser = useSelector(
    (state) => state.reducers.allUsers.current_user
  );
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const handleMenuclick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = async (event, name) => {
    event.stopPropagation();
    if (name === "edit") {
      openFileSelector();
      handleClose();
    } else if (name === "delete") {
      console.log("Comes Here");
      setDbprofilePic({});
      setProfilePic();
      setSelectedFile();
      handleClose();
      // const { data } = await authApi.delete(`${TEAM_LIST}/${selected._id}`, {
      //   headers: { Authorization: getAuthorizationHeader() },
      // });
      // console.log(data);
      // getData();
    }
  };

  const getCurrentLoggedUser = async () => {
    const { data } = await authApi.get(`${GET_USER}/${userInfo._id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    dispatch(setCurrentUser(data));
  };

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    console.log("fileObj is", fileObj);
    const objectURL = URL.createObjectURL(fileObj);
    console.log(objectURL);
    setProfilePic(objectURL);
    setSelectedFile(fileObj);
  };

  const openFileSelector = () => {
    inputRef.current.click();
  };

  const handleEdit = () => {
    setVisibility(false);
    setHeight("max-content");
  };
  const closeModal = () => {
    setModdleToggle(false);
    setVisibility(true);
    setHeight(320);
    getCurrentLoggedUser();
  };
  const CreateimageArray = async () => {
    let formData = new FormData();
    formData.append("file", selectedFile);
    const { data } = await authImageApi.post(UPLOAD_IMAGE, formData, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    return data.data;
  };

  const handleSubmit = async () => {
    if (selectedFile) {
      var Buildimages = await CreateimageArray();
    }
    const userEntry = {
      name: `${firstName} ${lastName}`,
      phone: mobile,
      email: email,
      designation: designation,
      profile_pic: Buildimages || dbprofilePic,
    };
    setModdleToggle(true);
    setUserEntry(userEntry);
  };

  useEffect(() => {
    getCurrentLoggedUser();
  }, [!loggedUser]);
  useEffect(() => {
    if (loggedUser) {
      setEmail(loggedUser.email);
      setFirstName(loggedUser.first_name);
      setLastName(loggedUser.last_name);
      setMobile(loggedUser.mobile_number);
      setReferance(loggedUser.my_referral_code);
      setDesignation(loggedUser?.designation);
      if (loggedUser.user_id.profile_pic) {
        setDbprofilePic(loggedUser.user_id.profile_pic);
        setProfilePic(
          `${process.env.REACT_APP_IMAGE_URL}/${loggedUser.user_id.profile_pic.destination}${loggedUser.user_id.profile_pic.filename}`
        );
      }
    }
  }, [loggedUser]);

  return (
    <div>
      {ln ? (
        <div>
          <Grid item xs={12}>
            <Item
              sx={{
                height: { height },
                display: "grid",
                padding: "0",
                position: "relative",
              }}
            >
              <div
                style={{ backgroundColor: "#EAF6FF", height: "125px" }}
              ></div>
              <div style={{ marginTop: "70px" }}>
                <div
                  style={{
                    position: "absolute",
                    top: "63px",
                    left: "4%",
                  }}
                >
                  <input
                    style={{ display: "none" }}
                    ref={inputRef}
                    type="file"
                    onChange={handleFileChange}
                  />
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                      <SmallAvatar alt="Remy Sharp">
                        {visibility ? (
                          <span onClick={() => handleEdit()}>
                            <MdModeEditOutline />
                          </span>
                        ) : (
                          <span onClick={(e) => handleMenuclick(e)}>
                            <img
                              src={cameraimage}
                              style={{ position: "absolute", left: 7, top: 7 }}
                              alt=""
                            />
                          </span>
                        )}
                      </SmallAvatar>
                    }
                  >
                    {profilePic ? (
                      <Avatar
                        alt="Travis Howard"
                        src={profilePic}
                        sx={{
                          width: "120px",
                          height: "120px",
                        }}
                      />
                    ) : (
                      <div>
                        {loggedUser ? (
                          <Avatar
                            {...stringAvatar(
                              `${loggedUser.first_name.trim()} ${loggedUser.last_name.trim()}`
                            )}
                            sx={{
                              bgcolor: `${generateColor(
                                `${loggedUser.first_name} ${loggedUser.last_name}`
                              )}`,
                              width: "120px",
                              height: "120px",
                            }}
                          />
                        ) : null}
                      </div>
                    )}
                  </Badge>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      key={`edit1`}
                      className={classes.EditMenuTeam}
                      onClick={(e) => handleMenuItemClick(e, "edit")}
                      style={{ paddingLeft: 22 }}
                    >
                      <EditPenIcon />
                      <span style={{ marginLeft: 5 }}>Edit Image</span>
                    </MenuItem>
                    <MenuItem
                      key={`delete2`}
                      onClick={(e) => handleMenuItemClick(e, "delete")}
                    >
                      <DeleteBinIcons />
                      <span>Delete Image</span>
                    </MenuItem>
                  </Menu>
                </div>
                {visibility ? (
                  <div style={{ padding: "0 30px" }}>
                    <div
                      className={`d-inline-flex ${classes.my_profile_Container1}`}
                    >
                      <div className={`d-inline-flex`}>
                        <p className={classes.MyProfile_label}>
                          {ln.first_name}:
                        </p>
                        <p className={classes.myprofile_p}>{firstName}</p>
                      </div>
                      <div className={`d-inline-flex`}>
                        <p className={classes.MyProfile_label}>
                          {ln.last_name}:
                        </p>
                        <p className={classes.myprofile_p}>{lastName}</p>
                      </div>
                    </div>
                    <hr className={classes.UnderlineHr} />
                    <div
                      className={`d-inline-flex ${classes.my_profile_Container1}`}
                    >
                      <div className={`d-inline-flex`}>
                        <p className={classes.MyProfile_label}>
                          {ln.designation}:
                        </p>
                        <p className={classes.myprofile_p}>
                          {loggedUser?.designation}
                        </p>
                      </div>
                      <div className={`d-inline-flex`}>
                        <p className={classes.MyProfile_label}>
                          {ln.mobile_number}:
                        </p>
                        <p className={classes.myprofile_p}>{mobile}</p>
                        {loggedUser &&
                        loggedUser.user_id.phone_verification.is_verified ? (
                          <BsCheckLg />
                        ) : null}
                      </div>
                      <div className={`d-inline-flex`}>
                        <p className={classes.MyProfile_label}>
                          {ln.email_id}:
                        </p>
                        <p
                          className={`${classes.myprofile_p} ${classes.email}`}
                        >
                          {email}
                        </p>
                        {loggedUser &&
                        loggedUser.user_id.email_verification.is_verified ? (
                          <BsCheckLg />
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
                {!visibility ? (
                  <ValidatorForm
                    useref="form"
                    fullwidth
                    onSubmit={handleSubmit}
                    style={{ margin: "30px auto", width: "95%" }}
                    className="scrollbaroverflow"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <label htmlFor="firstNameinput" className="form-label">
                          {ln.first_name}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "90%",
                          }}
                          id="Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          onKeyPress={(e) => Alphabetic(e)}
                          size="small"
                          validators={["required"]}
                          errorMessages={["First Name is required"]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <label htmlFor="firstNameinput" className="form-label">
                          {ln.last_name}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "90%",
                          }}
                          id="Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          onKeyPress={(e) => Alphabetic(e)}
                          size="small"
                          validators={["required"]}
                          errorMessages={["Last Name is required"]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <label htmlFor="firstNameinput" className="form-label">
                          {ln.designation} ({ln.optional})
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "90%",
                          }}
                          id="Name"
                          value={designation}
                          onChange={(e) => setDesignation(e.target.value)}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <label
                          htmlFor="phonenumberInput"
                          className="form-label"
                        >
                          {ln.mobile_number}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "90%",
                          }}
                          inputProps={{
                            maxLength: 10,
                          }}
                          id="phonenumberInput"
                          value={mobile}
                          disabled
                          onChange={(e) => setMobile(e.target.value)}
                          onKeyPress={(e) => Numeric(e)}
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +91
                              </InputAdornment>
                            ),
                          }}
                          validators={["required", "matchRegexp:[0-9]{10}"]}
                          errorMessages={[
                            "This field is required",
                            "Please Enter the 10 digit mobile number",
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <label htmlFor="firstNameinput" className="form-label">
                          {ln.email_id}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "90%",
                          }}
                          disabled
                          id="firstNameinput"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          size="small"
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "Customer Type is required",
                            "This is not a valid Email",
                          ]}
                        />
                      </Grid>
                    </Grid>
                    <div style={{ float: "right", marginRight: "35px" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        style={{ padding: "5px 30px", textTransform: "none" }}
                      >
                        {ln.update}
                      </Button>
                    </div>
                  </ValidatorForm>
                ) : null}
              </div>
            </Item>
          </Grid>
          {moddleToggle ? (
            <DeleteConformation
              userEntry={userEntry}
              modalClose={closeModal}
              type={"personal_details"}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default PersonalDetails;
