import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Badge,
  Chip,
  Avatar,
  Menu,
  MenuItem,
  Dialog,
  Button,
  Checkbox,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Grid,
  Popover,
} from "@mui/material";
import {
  AddressHomeIcon,
  AddressLocationIcon,
  AddressPhoneIcon,
  AddressThreeDotsIcon,
  PlusIconAdd,
  SearchIcon,
} from "../../../components/Icons";
import classes from "./address.module.css";
import classesOne from "../../../components/index.module.css";
import AddAddressPop from "./AddAddressPop";
import DeleteAddressPop from "./deleteAddressPop";
import { useEffect, useState } from "react";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { FaList } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import AddressMaps from "./AddressMaps";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import {
  AddCompanySuccess,
  addCompanyFailure,
  addCompanySuccess,
  deleteCompanyFailure,
  deleteCompanySuccess,
  getAllCompanyAddress,
} from "../../../Redux/actions/address-actions";
import { splitFirstLetter } from "../../../utilities/commonFuntions";
import { useNavigate } from "react-router-dom";

function AddressHome() {
  const [isMapScreen, setIsMapScreen] = useState(false);
  const [addressPopup, setAddressPopup] = useState(false);
  const [deleteAddressPopup, setDeleteAddressPopup] = useState(false);
  const [view, setView] = useState(false);
  const [activeButton, setActiveButton] = useState("grid");
  const [searchValue, setSearchValue] = useState();
  const [myAllAddress, setMyAllAddress] = useState([]);
  const [singleAddress, setSingleAddress] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputs, setInputs] = useState({
    name: "",
    phone: "",
    address: "",
    maplink: "",
    lat: "",
    lng: "",
  });
  const dispatch = useDispatch();
  const togglesidebar = useSelector(
    (state) => state.reducers.allUsers.togglesidebar
  );
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  const {
    companyAddressList,
    addSuccess,
    addFailure,
    deleteSuccess,
    deleteFailure,
  } = useSelector((state) => state.reducers.address);
  let company_infos = JSON.parse(sessionStorage.getItem("userInfo"))?.company;
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleInputs = (e) => {
    const { name, value } = e.target;

    setInputs({ ...inputs, [name]: value });
  };

  const handleClick = (event, datas) => {
    setAnchorEl(event.currentTarget);
    setSingleAddress(datas);
  };

  const handleClickEdit = () => {
    setInputs({
      name: singleAddress?.customer_name ?? "",
      phone: singleAddress?.mobile_number ?? "",
      address: singleAddress?.address ?? "",
      maplink:
        singleAddress?.location_info?.lat && singleAddress?.location_info?.lng
          ? `https://www.google.com/maps?q=${singleAddress?.location_info?.lat},${singleAddress?.location_info?.lng}`
          : "",
      lat: singleAddress?.location_info?.lat ?? "",
      lng: singleAddress?.location_info?.lng ?? "",
    });
    setAnchorEl(null);
    setAddressPopup(true);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
    setSingleAddress("");
  };

  const handleClose = () => {
    setAddressPopup(false);
    setDeleteAddressPopup(false);
    setInputs({
      name: "",
      phone: "",
      address: "",
      maplink: "",
      lat: "",
      lng: "",
    });
    setSingleAddress("");
  };

  const handleDeleteAddress = async () => {
    let address_id = singleAddress?._id;
    const { data } = await authApi.delete(`/addressbook/${address_id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    dispatch(deleteCompanySuccess(true));
    console.log("answer =", data);
  };

  const handleAddAddress = async () => {
    if (inputs.name.length === 0 || inputs.lat.length === 0) {
      alert("Please enter name and map link");
    } else {
      let userEntry = {
        customer_name: inputs.name,
        address: inputs.address,
        location_info: {
          lat: inputs.lat,
          lng: inputs.lng,
        },
        mobile_number: inputs.phone,
      };
      let company_id = company_infos?._id;
      const { data } = await authApi.post(
        `/addressbook/${company_id}`,
        userEntry,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      dispatch(addCompanySuccess(true));
    }
  };

  const handleUpdateAddress = async () => {
    if (inputs.name.length === 0 || inputs.lat.length === 0) {
      alert("Please enter name and map link");
    } else {
      let userEntry = {
        customer_name: inputs.name,
        address: inputs.address,
        location_info: {
          lat: inputs.lat,
          lng: inputs.lng,
        },
        mobile_number: inputs.phone,
      };
      let address_id = singleAddress?._id;
      const { data } = await authApi.put(
        `/addressbook/${address_id}`,
        userEntry,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      dispatch(addCompanySuccess(true));
    }
  };

  const handleAddressSearch = () => {
    let my_lists = [...companyAddressList];

    let search_list = my_lists.filter(
      (el) =>
        el.customer_name?.toLowerCase().includes(searchValue) ||
        el.address?.toLowerCase().includes(searchValue) ||
        el.mobile_number?.toLowerCase().includes(searchValue)
    );
    setMyAllAddress(search_list);
  };

  const handleGetAllAddress = async () => {
    let company_id = company_infos?._id;
    const { data } = await authApi.get(`/addressbook/${company_id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });

    dispatch(getAllCompanyAddress(data?.addressBook));
  };

  useEffect(() => {
    handleGetAllAddress();
  }, []);

  useEffect(() => {
    setMyAllAddress(companyAddressList);
  }, [companyAddressList]);

  useEffect(() => {
    if (searchValue?.length > 2) {
      handleAddressSearch();
    } else {
      setMyAllAddress(companyAddressList);
    }
  }, [searchValue]);

  useEffect(() => {
    if (addSuccess || deleteSuccess) {
      setTimeout(() => {
        dispatch(addCompanySuccess(false));
        dispatch(deleteCompanySuccess(false));
        handleClose();
        handleGetAllAddress();
      }, 2000);
    }
  }, [addSuccess, deleteSuccess]);

  useEffect(() => {
    if (addFailure || deleteFailure) {
      setTimeout(() => {
        dispatch(addCompanyFailure(false));
        dispatch(deleteCompanyFailure(false));
      }, 2000);
    }
  }, [addFailure, deleteFailure]);

  console.log("companyAddressList =", companyAddressList);
  return (
    <>
      {isMapScreen ? (
        <AddressMaps
          setIsMapScreen={setIsMapScreen}
          setInputs={setInputs}
          inputs={inputs}
        />
      ) : (
        <Box>
          <Box textAlign={"end"}>
            <Button
              variant="contained"
              startIcon={<PlusIconAdd />}
              className={classes.add_address}
              onClick={() => setAddressPopup(true)}
            >
              Add New Address
            </Button>
          </Box>
          <Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Typography className={classes.viewstyleText}>
                Saved Address
              </Typography>
              <div className={classes.viewbittonContainer}>
                <Button
                  className={`${classes.button} ${
                    activeButton === "grid" ? `${classes.active}` : ""
                  }`}
                  onClick={() => setActiveButton("grid")}
                >
                  <BsFillGrid3X3GapFill />
                </Button>
                <Button
                  className={`${classes.button} ${
                    activeButton === "list" ? `${classes.active}` : ""
                  }`}
                  onClick={() => setActiveButton("list")}
                >
                  <FaList />
                </Button>
              </div>
            </div>
            <div className="mb-3">
              <TextField
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  width: togglesidebar ? "290px" : "360px",
                }}
                placeholder="Search by mobile number, area, name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                className={classes.teamMemberSearch}
                id="firstNameinput"
                value={searchValue}
                onChange={(e) => {
                  let a = e?.target?.value?.toLowerCase();
                  setSearchValue(a);
                }}
                size="small"
              />
            </div>
            <Box mt={4}>
              {activeButton === "list" ? (
                <Table>
                  {ln ? (
                    <TableHead className={classes.address_thead}>
                      <TableRow>
                        <TableCell style={{ width: "13%" }}>
                          Customer Name
                        </TableCell>
                        <TableCell style={{ width: "13%" }}>
                          Mobile Number
                        </TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Google Map Location</TableCell>
                        <TableCell>{ln.action}</TableCell>
                      </TableRow>
                    </TableHead>
                  ) : null}
                  <TableBody>
                    {Array.isArray(myAllAddress) &&
                      myAllAddress.map((item, index) => {
                        return (
                          <TableRow
                            key={index}
                            className={classes.address_tbody}
                            // style={{ marginTop: "15px" }}
                            sx={{
                              "&:not(:last-child)": {
                                borderBottom: "10px solid #f5f7fb",
                                borderTop: "10px solid #f5f7fb",
                              },
                              mt: index === 0 ? 0 : 2,
                            }}
                          >
                            <TableCell>{item.customer_name}</TableCell>
                            <TableCell>
                              {item.mobile_number ? item.mobile_number : "n/a"}
                            </TableCell>
                            <TableCell>
                              {item.address ? item.address : "n/a"}
                            </TableCell>
                            <TableCell>
                              <a
                                href={`https://www.google.com/maps?q=${item.location_info?.lat},${item.location_info?.lng}`}
                                target="_blank"
                              >
                                https://www.google.com/maps?
                                {item.location_info?.lat},
                                {item.location_info?.lng}
                              </a>
                            </TableCell>
                            <TableCell>
                              <Typography onClick={(e) => handleClick(e, item)}>
                                <AddressThreeDotsIcon />
                              </Typography>
                              <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handlePopperClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                className="address-popover"
                              >
                                <Typography
                                  sx={{ p: 2, pt: 1, pb: 1, cursor: "pointer" }}
                                  onClick={handleClickEdit}
                                >
                                  Edit
                                </Typography>
                                <Typography
                                  sx={{ px: 2, pb: 1, cursor: "pointer" }}
                                  onClick={() => {
                                    setAnchorEl(null);
                                    setDeleteAddressPopup(true);
                                  }}
                                >
                                  Delete
                                </Typography>
                                <Typography
                                  sx={{ px: 2, pb: 1, cursor: "pointer" }}
                                  onClick={() => {
                                    navigate("/location/create-location", {
                                      state: { loc_details: singleAddress },
                                    });
                                  }}
                                >
                                  Assign
                                </Typography>
                              </Popover>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              ) : (
                <Grid container spacing={2}>
                  {Array.isArray(myAllAddress) &&
                    myAllAddress.map((datas) => {
                      return (
                        <Grid item xs={12} sm={12} md={4}>
                          <Box className={classes.grid_address}>
                            <Box
                              display={"flex"}
                              alignItems={"center "}
                              gap={"12px"}
                              mb={2}
                            >
                              {/* <Avatar>
                                {splitFirstLetter(datas.customer_name)}
                              </Avatar> */}
                              <Typography>
                                <b>{datas.customer_name}</b>
                              </Typography>
                            </Box>
                            <hr />
                            <Box my={2}>
                              <Typography className={classes.address_part}>
                                <AddressPhoneIcon />
                                {datas.mobile_number
                                  ? datas.mobile_number
                                  : "n/a"}
                              </Typography>
                              <Typography className={classes.address_part}>
                                <AddressHomeIcon />
                                {datas.address ? datas.address : "n/a"}
                              </Typography>
                              <Typography className={classes.address_part}>
                                <AddressLocationIcon />
                                <a
                                  href={`https://www.google.com/maps?q=${datas.location_info?.lat},${datas.location_info?.lng}`}
                                  target="_blank"
                                >
                                  https://www.google.com/maps?
                                  {datas.location_info?.lat},
                                  {datas.location_info?.lng}
                                </a>
                              </Typography>
                            </Box>
                            <Typography
                              position={"absolute"}
                              top={"10px"}
                              right={"7px"}
                              onClick={(e) => handleClick(e, datas)}
                            >
                              <AddressThreeDotsIcon />
                            </Typography>
                            <Popover
                              id={id}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handlePopperClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              className="address-popover"
                            >
                              <Typography
                                sx={{ p: 2, pt: 1, pb: 1, cursor: "pointer" }}
                                onClick={handleClickEdit}
                              >
                                Edit
                              </Typography>
                              <Typography
                                sx={{ px: 2, pb: 1, cursor: "pointer" }}
                                onClick={() => {
                                  setAnchorEl(null);
                                  setDeleteAddressPopup(true);
                                }}
                              >
                                Delete
                              </Typography>
                              <Typography
                                sx={{ px: 2, pb: 1, cursor: "pointer" }}
                                onClick={() => {
                                  navigate("/location/create-location", {
                                    state: { loc_details: singleAddress },
                                  });
                                }}
                              >
                                Assign
                              </Typography>
                            </Popover>
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              )}
            </Box>
          </Box>

          <AddAddressPop
            open={addressPopup}
            handleClose={handleClose}
            inputs={inputs}
            setInputs={setInputs}
            handleInputs={handleInputs}
            setIsMapScreen={setIsMapScreen}
            handleAddAddress={handleAddAddress}
            singleAddress={singleAddress}
            handleUpdateAddress={handleUpdateAddress}
          />
          <DeleteAddressPop
            open={deleteAddressPopup}
            handleClose={handleClose}
            handleDeleteAddress={handleDeleteAddress}
          />
        </Box>
      )}
    </>
  );
}

export default AddressHome;
