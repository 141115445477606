import BreadCrumbs from "../../../components/BreadCrumbs";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import classes from "./myprofile.module.css";
import { styled } from "@mui/material/styles";
import { Paper, Button, InputAdornment, Typography } from "@mui/material";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { CHANGE_PASSWORD } from "../../../api-calls/api-urls";
import { json, useNavigate } from "react-router-dom";
import { CloseEyeIcon, OpenEyeIcon } from "../../../components/Icons";
import DeleteConformation from "./DeleteConformation";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagedataFunction } from "../../../utilities/language";
import { setPresenceOffline } from "../../../utilities/Xmpp";
import Cookies from "js-cookie";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  height: "200px",
}));

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [conformNewPassword, setConformNewPassword] = useState();
  const [passMatch, SetPassMatch] = useState();
  const [oldPassNewPassMatch, SetOldPassNewPassMatch] = useState();
  const [errorMessaage, setErrorMessage] = useState(null);
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [moddleToggle, setModdleToggle] = useState(false);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  function changePasswordFunction() {
    if (newPassword != conformNewPassword) {
      SetPassMatch(true);
    } else {
      SetPassMatch(false);
    }

    if (
      oldPassword != conformNewPassword &&
      newPassword === conformNewPassword
    ) {
      handleSubmit();
    } else if (
      oldPassword != newPassword &&
      oldPassword != conformNewPassword
    ) {
      SetOldPassNewPassMatch(true);
    } else {
      SetOldPassNewPassMatch(false);
    }
  }

  const handleLogout = () => {
    setPresenceOffline(userInfo?._id);
    Cookies.remove("userToken");
    sessionStorage.removeItem("userInfo");
    localStorage.clear();
    navigate("/login");
  };

  const closeModal = () => {
    setModdleToggle(false);
    setOldPassword("");
    setNewPassword("");
    setConformNewPassword("");
    handleLogout();
  };

  function oldPassFun(e) {
    setOldPassword(e.target.value);
    SetPassMatch(false);
    setErrorMessage(null);
    SetOldPassNewPassMatch(true);
  }
  function newPassFun(e) {
    setNewPassword(e.target.value);
    SetPassMatch(false);
    setErrorMessage(null);
    SetOldPassNewPassMatch(true);
  }
  function confirmPassFun(e) {
    setConformNewPassword(e.target.value);
    SetPassMatch(false);
    setErrorMessage(null);
    SetOldPassNewPassMatch(true);
  }

  const handleSubmit = async () => {
    const userEntry = {
      user_id: userInfo._id,
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: conformNewPassword,
    };
    console.log(userEntry);
    const { data } = await authApi.post(CHANGE_PASSWORD, userEntry, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    if (data.error) {
      console.log(data);
      setErrorMessage(data.error);
    } else {
      setModdleToggle(true);
    }
  };

  return (
    <div>
      <BreadCrumbs />
      {ln && (
        <Grid item xs={12}>
          <Item
            sx={{
              height: "auto",
              display: "grid",
              padding: "0",
              position: "relative",
            }}
          >
            <div style={{ padding: "20px 30px" }}>
              <div className={classes.Comapny_headerContainer}>
                <Typography
                  className={classes.ResetPasswordTitle}
                  style={{ marginBottom: "30px" }}
                >
                  {ln.change_password}
                </Typography>
              </div>
              <ValidatorForm
                useref="form"
                fullwidth
                onSubmit={changePasswordFunction}
                style={{ margin: "0px auto 30px" }}
                className={classes.InputForm}
              >
                <div className={classes.inputContainer}>
                  <div className={classes.passwordInputContainer}>
                    <Typography className={classes.form_lable}>
                      {ln.old_password}
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      fullWidth
                      size="small"
                      value={oldPassword}
                      onChange={(e) => oldPassFun(e)}
                      validators={["required"]}
                      errorMessages={["Old Password is required"]}
                    />
                    <div className={classes.showPassword}>
                      {showPassword ? (
                        <Button
                          variant="text"
                          onClick={() => setShowPassword(false)}
                        >
                          <OpenEyeIcon />
                        </Button>
                      ) : (
                        <Button onClick={() => setShowPassword(true)}>
                          <CloseEyeIcon />
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className={classes.passwordInputContainer}>
                    <Typography className={classes.form_lable}>
                      {ln.new_password}
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      id="outlined-adornment-password"
                      type={showPassword1 ? "text" : "password"}
                      fullWidth
                      size="small"
                      value={newPassword}
                      onChange={(e) => newPassFun(e)}
                      validators={[
                        "required",
                        "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$",
                      ]}
                      errorMessages={[
                        "Password is required",
                        "Password should contain 1 capital letter, 1 small letter, 1 number, 1 special characters  and should be 8 digits",
                      ]}
                    />
                    <div className={classes.showPassword}>
                      {showPassword1 ? (
                        <Button
                          variant="text"
                          onClick={() => setShowPassword1(false)}
                        >
                          <OpenEyeIcon />
                        </Button>
                      ) : (
                        <Button onClick={() => setShowPassword1(true)}>
                          <CloseEyeIcon />
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className={classes.passwordInputContainer}>
                    <Typography className={classes.form_lable}>
                      {ln.confirm_new_password}
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      id="outlined-adornment-password"
                      type={showPassword2 ? "text" : "password"}
                      fullWidth
                      size="small"
                      value={conformNewPassword}
                      onChange={(e) => confirmPassFun(e)}
                      validators={[
                        "required",
                        "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$",
                      ]}
                      errorMessages={[
                        "Password is required",
                        "Password should contain 1 capital letter, 1 small letter, 1 number, 1 special characters  and should be 8 digits",
                      ]}
                    />
                    <div className={classes.showPassword}>
                      {showPassword2 ? (
                        <Button
                          variant="text"
                          onClick={() => setShowPassword2(false)}
                        >
                          <OpenEyeIcon />
                        </Button>
                      ) : (
                        <Button onClick={() => setShowPassword2(true)}>
                          <CloseEyeIcon />
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    float: "right",
                    marginTop: "30px",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ padding: "5px 30px", textTransform: "none " }}
                  >
                    {ln.update}
                  </Button>
                </div>
              </ValidatorForm>
              {passMatch == true ? (
                <p style={{ color: "red" }}>
                  {ln.password_and_confirm_password_not_matches}
                </p>
              ) : null}
              {oldPassNewPassMatch == false ? (
                <p style={{ color: "red" }}>
                  {ln.old_password_and_new_password_should_not_be_same}
                </p>
              ) : null}
              {errorMessaage ? (
                <p style={{ color: "red", textTransform: "capitalize" }}>
                  {errorMessaage}
                </p>
              ) : null}
            </div>
          </Item>
        </Grid>
      )}
      {moddleToggle ? (
        <DeleteConformation
          success={true}
          type={"password"}
          modalClose={closeModal}
        />
      ) : null}
    </div>
  );
};

export default ResetPassword;
