import React, { useEffect, useState } from "react";
import classes from "./index.module.css";
import {
  Avatar,
  Badge,
  Button,
  Typography,
  Snackbar,
  Alert,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
} from "@mui/material";
import { Form } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DatePicker from "react-datepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { BsFilter } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { saveLocationStatusFilter } from "../../../Redux/actions/location-action";
import { locationStatusFilter, range } from "../../../utilities/constants";
import {
  ArrowDownIcon,
  DatePickerCalendarIcon,
  SearchIcon,
} from "../../../components/Icons";
import moment from "moment";

const FilterContainer = ({ dateObject, selectedDate }) => {
  const dispatch = useDispatch();
  const [serachValue, setSearchValue] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);
  // const [fromDate, setFromDate] = useState();
  const [date, setDate] = useState();
  // const [toDate, setToDate] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const { locationFilteredStatus } = useSelector(
    (state) => state.reducers.location
  );

  useEffect(() => {
    setDate(selectedDate);
  }, [selectedDate]);

  const handleMenuclick = (event, name) => {
    name(event.currentTarget);
  };

  const handleStatusCheckbox = (value) => {
    const isSelected = selectedValues.includes(value);

    if (value === "all") {
      if (isSelected) {
        // Unselect all checkboxes
        setSelectedValues([]);
        dispatch(saveLocationStatusFilter([]));
      } else {
        // Select all checkboxes
        setSelectedValues([...locationStatusFilter]);
        dispatch(saveLocationStatusFilter([...locationStatusFilter]));
      }
    } else {
      // Toggle the selection of individual checkboxes
      let newSelectedValues = [];

      if (isSelected) {
        newSelectedValues = selectedValues.filter((v) => v !== value);
      } else {
        newSelectedValues = [...selectedValues, value];
      }
      setSelectedValues(newSelectedValues);
      dispatch(saveLocationStatusFilter([newSelectedValues]));

      const areAllSelected = locationStatusFilter
        .filter((item) => item !== "all")
        .every((item) => newSelectedValues.includes(item));

      // Check the "All" checkbox if all others are selected
      if (areAllSelected) {
        setSelectedValues((prevSelected) => [...prevSelected, "all"]);
        dispatch(saveLocationStatusFilter([...newSelectedValues, "all"]));
      } else {
        // Uncheck the "All" checkbox if any other checkbox is unselected
        setSelectedValues((prevSelected) =>
          prevSelected.filter((item) => item !== "all")
        );
        dispatch(
          saveLocationStatusFilter(
            newSelectedValues.filter((item) => item !== "all")
          )
        );
      }
    }
  };

  const clickClearAll = (name) => {
    if (name === "statusfilter") {
      console.log("hsdgfjgsdjhf");
      setSelectedValues([]);
      dispatch(saveLocationStatusFilter([]));
    } else {
      name();
    }
  };

  useEffect(() => {
    if (date !== undefined) {
      // setSelectedDate(`${moment(fromDate).format("YYYY-MM-DD")} to ${moment(toDate).format("YYYY-MM-DD")}`);
      dateObject(date, serachValue, selectedValues);
    }
  }, [date, serachValue, selectedValues]);

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
    dateObject(date, e.target.value, selectedValues);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={`${classes.MapFiltersContainer}`}>
      {!window.location.pathname.includes("view-route") && (
        <div>
          <div className="">
            <TextField
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              placeholder="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              className={classes.teamMemberSearch}
              id="firstNameinput"
              value={serachValue}
              onChange={(e) => handleSearchValue(e)}
              size="small"
            />
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          columnGap: 20,
          alignItems: "center",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        {window.location.pathname.includes("view-route") && (
          <div className={classes.datePicker1}>
            <DatePicker
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              size="small"
              selected={date}
              onChange={(date) => setDate(date)}
              placeholderText="Today"
              dateFormat="yyyy-MM-dd"
            ></DatePicker>
            <span
              className={classes.datePickerCalendarIcon}
              style={{ right: "10px" }}
            >
              <DatePickerCalendarIcon />
            </span>
          </div>
        )}
        {/* {!window.location.pathname.includes("view-route") && (
          <div>
            <Button
              variant="outlined"
              className={classes.filterbtns}
              onClick={(e) => handleMenuclick(e, setAnchorEl)}
              style={{ background: "white" }}
            >
              <div>
                <BsFilter /> All
              </div>
              <div>
                <ArrowDownIcon />
              </div>
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <div className={classes.filterHeading}>
                <h5>Filter</h5>
                <span
                  className={classes.claerBtn}
                  onClick={() => clickClearAll("statusfilter")}
                >
                  clear all
                </span>
              </div>
              {locationStatusFilter.map((item, index) => {
                return (
                  <MenuItem
                    key={`sort${index}`}
                    style={{
                      padding: "6px 25px 6px 0",
                      textTransform: "capitalize",
                    }}
                  >
                    <Form.Check
                      style={{
                        margin: 10,
                        marginRight: 15,
                      }}
                      checked={locationFilteredStatus?.includes(item)}
                      onChange={() => handleStatusCheckbox(item)}
                      name="group1"
                      type="checkbox"
                    />
                    {item.replaceAll("_", " ")}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default FilterContainer;
