module.exports = {
  // BaseUrl: `https://api.teamplayer.apps.org.in/api`,
  // ImageUrl: `https://api.teamplayer.apps.org.in`,
  // BaseUrl: `http://localhost:8080/api`,
  // ImageUrl: `http://localhost:8080`,
  // XmppBaseUrl: "http://192.168.29.111:9090/plugins",
  REGISTER_XMPP: "/restapi/v1/users",
  SIGNUP_URL: `/users/register`,
  VERIFY_MOBILE: `/users/verifyphone`,
  CHECK_EMAIL: `/users/emailcheck`,
  VERIFY_EMAIL: `/users/verifyEmail`,
  UPDATE_WORK_INFO: `/users/updateworkinfo`,
  SET_PASSWORD: `/users/createpassword`,
  LOGIN: `/users/login`,
  OTP_LOGIN: `/users/otp_login`,
  UPDATE_USER: `/users`,
  CHANGE_PASSWORD: `/users/changepassword`,
  GET_USER: `/users/user`,
  VERIFY_LOGIN_OTP: `/users/verifyotp`,
  RESET_PASSWORD: `/users/resetpassword`,
  GENERATE_FORGET_PASSWORD_OTP: `/users/generateOtp`,
  SUBSCRIPTION_LIST: `/subscriptions/list`,
  MY_SUBSCRIPTION: `/my-subscriptions/select`,
  PAYMENT_SUCCESS: "/my-subscriptions/payment-success",
  CREATE_TEAM: "/teams",
  TEAM_LIST: "/teams",
  ADD_MEMBERS: "/addMembers",
  UPDATE_MEMBER: "/updateMember",
  DELETE_MEMBER: "/removeMember",
  MY_SUBS_AVAIL: "/my-subscriptions/availableSubscription",
  RENEW_SUBS: "/my-subscriptions/renewSubscription",
  MY_SUBS: "/my-subscriptions",
  ADD_DEPARTMENT: "/departments",
  UPLOAD_IMAGE: "/locations/upload/image",
  LOCATION: "/locations",
  USER_LOCATION: "/locations/userlocation",
  COMPANY: "/company",
  INVOICE_URL: "/my-subscriptions/invoice",
  TEAM_MEMBERS_LIST: "/users/teammembers",
  MAP_MARKER_TEAM: "/map/marker/list",
  MAP_ROUTE_LIST: "/map/marker/view-routes",
  TEAM_MEMBER_DETAILS: "/teams/teamMember",
  DOWNLOAD_INVOICE: "/invoices/downloadInvoice",
  SEND_NOTIFICATION: "/map/send-notification",
  LANGUAGE: "/language",
  PASSMATCH: "/users/passmatch",
  USER_REFFERALS: "/referrals",
  CREATE_FEEDBACK: "/feedback/create",
  GET_USER_GROUPS: "/messages/convo-list",
  GET_ONE_GROUP: "/messages/conversation-details",
  UPDATE_REFERALS: "/referrals/update",
  REFERAL_SUBSCRIPTION: "/my-subscriptions/referal_subsciption",
  HOLIDAY_LIST: "/holiday/list",
  MARK_A_HOLIDAY: "/holiday/mark-holiday",
  VERIFY_HOLIDAY: "/holiday",
  GET_NOTIFICATIONS: "/notifications/user",
  UPDATE_NOTIFICATION: "/notifications",
  LOCATION_PIECHART: "/piechart/location",
  ATTENDANCE_PIECHART: "/piechart/attendance",
  CREATE_CONTACTUS: "/contact-us/create",
  BLOGS_LIST: "/blogs/list",
  BLOGS_DETAILS: "/blogs/view",
  SAVE_USER_BLOG: "/blogs/saveblog",
  GET_SAVED_USER_BLOGS: "/blogs/getsavedblog/list",
  GET_ONE_USER_SAVED_LOCATION: "/blogs/getsavedblog",
  VIEW_HISTORY: "/map/get-location-history",
  XMPP_REREGISTER: "/users/re-register/xmpp",
};
