import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import classes from "./myprofile.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import PersonalDetails from "./PersonalDetails";
import CompanyInfo from "./CompanyInfo";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagedataFunction } from "../../../utilities/language";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MyProfileIndex() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {ln ? (
        <div>
          <div className={classes.brudCrumb}>
            <BsArrowLeftShort
              style={{ width: "30px", height: "30px" }}
              onClick={() => navigate(-1)}
            />
            <span onClick={() => navigate("/my-profile")}>{ln.my_profile}</span>
          </div>
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={ln.personal_info}
                  {...a11yProps(0)}
                  style={{ textTransform: "capitalize" }}
                />
                <Tab
                  label={ln.company_info}
                  {...a11yProps(1)}
                  style={{ textTransform: "capitalize" }}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0} className={classes.TabPanel}>
              <PersonalDetails />
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.TabPanel}>
              <CompanyInfo />
            </TabPanel>
          </Box>
        </div>
      ) : null}
    </div>
  );
}
