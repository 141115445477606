import { actionTypes } from "../constant/action-types";

export function teamsList(data) {
  return {
    type: actionTypes.TEAMS_LIST,
    payload: data,
  };
}
export function currentEditTeam(data) {
  return {
    type: actionTypes.EDIT_TEAM,
    payload: data,
  };
}

export function departmentLists(data) {
  return {
    type: actionTypes.DEPARTMENT_LIST,
    payload: data,
  };
}

export function TeamMemberData(data) {
  return {
    type: actionTypes.ONE_TEAM_MEMBER,
    payload: data,
  };
}

export function saveTeamMemberStatusFilter(data) {
  return {
    type: actionTypes.SAVE_TEAM_MEMBER_STATUS_FILTER,
    payload: data,
  };
}

export function TeamMemberAttendanceFilter(data) {
  return {
    type: actionTypes.TEAM_MEMBER_ATTENDANCE_FILTER,
    payload: data,
  };
}

export function SaveAvailableSubc(data) {
  return {
    type: actionTypes.SAVE_AVAILABLE_SUBC,
    payload: data,
  };
}
