import React, { useEffect, useState } from "react";
import image from "../../../assets/images/NoTeam.png";
import Paper from "@mui/material/Paper";
import { Avatar } from "@mui/material";
import { Grid, Card, Snackbar, Alert } from "@mui/material";
import classes from "./myteam.module.css";
import { CardContent, MenuItem, Menu, Typography } from "@mui/material";
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { IoAddCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { ADD_DEPARTMENT, TEAM_LIST } from "../../../api-calls/api-urls";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import CircleIcon from "@mui/icons-material/Circle";
import { departmentLists } from "../../../Redux/actions/team-actions";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import AddDepartmentModal from "./AddDepartmentModel";
import { getLanguagedataFunction } from "../../../utilities/language";
import {
  DeleteBinIcons,
  EditPenIcon,
  RoundAddIcon,
} from "../../../components/Icons";
import DeleteConformation from "./DeleteConformation";
import AlertBox from "../../../components/AlertBox";

const DepartMentIndex = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState();
  const [checked, setChecked] = useState(false);
  const [selected, setSelected] = useState();
  const [selectedValues, setSelectedValues] = useState([]);
  const departmentList = useSelector(
    (state) => state.reducers.teams.departmentList
  );
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [userEntry, setUserEntry] = useState({});
  const [checkedItems, setCheckedItems] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const handleCheckboxChange = (value, name) => {
    if (name === "single") {
      const isSelected = selectedValues.includes(value);
      let newSelectedValues = [];

      if (isSelected) {
        newSelectedValues = selectedValues.filter((v) => v !== value);
      } else {
        newSelectedValues = [...selectedValues, value];
      }
      console.log(newSelectedValues);
      setSelectedValues(newSelectedValues);
      setSelected();
    } else if (name === "all") {
      if (value) {
        let ids = departmentList.map((item) => item._id);
        console.log(ids);
        setSelectedValues(ids);
        setSelected();
      } else {
        setSelectedValues([]);
        setChecked(!checked);
        setSelected();
      }
    }
  };

  const handleClick = () => {
    setModal(true);
    setSelected(null);
  };
  const CloseForm = () => {
    getDEpartmnetData();
    setModal(false);
    setSelected(null);
  };
  const handleMenuclick = (event, item) => {
    event.stopPropagation();
    setSelected(item);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setSelected(null);
  };

  const closeModal = () => {
    setDeleteModal(false);
    getDEpartmnetData();
  };

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Change the duration (in milliseconds) as per your requirement

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showAlert]);

  const handleMenuItemClick = async (event, name) => {
    if (name === "edit") {
      setModal(true);
      handleClose();
    } else if (name === "deleteAtTop") {
      console.log("Comes HEar At 1");
      if (selectedValues.length > 0) {
        const userEntry = {};
        if (selected) {
          userEntry.departmentIds = [selected._id];
        } else {
          userEntry.departmentIds = selectedValues;
        }
        setUserEntry(userEntry);
        setDeleteModal(true);
        handleClose();
      } else {
        setShowAlert(true);
        setError("Please select atleast one department");
      }
    } else if (name === "delete") {
      console.log("Comes HEar At 2");
      if (selected || selectedValues.length > 0) {
        const userEntry = {};
        if (selected) {
          userEntry.departmentIds = [selected._id];
        } else {
          userEntry.departmentIds = selectedValues;
        }
        setUserEntry(userEntry);
        setDeleteModal(true);
        handleClose();
      } else {
        setShowAlert(true);
        setError("Please select atleast one department");
      }
    }
  };

  const getDEpartmnetData = async () => {
    const { data } = await authApi.get(ADD_DEPARTMENT, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    dispatch(departmentLists(data));
  };

  useEffect(() => {
    getDEpartmnetData();
  }, [!departmentList]);

  return (
    <div>
      {ln ? (
        <div>
          {departmentList && departmentList.length > 0 ? (
            <div className={classes.indexpageMainBtn}>
              <Button
                variant="contained"
                className={`${classes.noDataImagePAge}`}
                onClick={() => handleClick()}
              >
                <RoundAddIcon />
                <span>{ln.create_department}</span>
              </Button>
            </div>
          ) : null}
          <Paper
            className={classes.CreateTeamindex}
            style={{ padding: "5px 20px" }}
          >
            {departmentList && departmentList.length > 0 ? (
              <div>
                <div>
                  <div className={classes.TaskContainerheader}>
                    <div>
                      <Form.Check
                        className={classes.taskCheckbox}
                        inline
                        checked={
                          selectedValues.length === departmentList.length
                        }
                        onChange={(e) =>
                          handleCheckboxChange(e.target.checked, "all")
                        }
                        label={`${selectedValues.length}/${departmentList.length} departments selected`}
                        name="group1"
                        type="checkbox"
                      />
                    </div>
                    <div className={classes.deleteIcon}>
                      <Button
                        variant="text"
                        style={{ padding: "0px", minWidth: "0px" }}
                        onClick={(e) => handleMenuItemClick(e, "deleteAtTop")}
                      >
                        <DeleteBinIcons />
                      </Button>
                    </div>
                  </div>
                  <div>
                    <div className={classes.GridContainer}>
                      {departmentList.map((item, index) => {
                        return (
                          <Card key={item._id} className={classes.teamCard}>
                            <CardContent
                              className={`${classes.teamCardContainer} ${classes.departmentCerdContainer}`}
                            >
                              <Form.Check
                                type="checkbox"
                                key={index}
                                className={classes.taskCheckbox}
                                checked={selectedValues.includes(item._id)}
                                onChange={() =>
                                  handleCheckboxChange(item._id, "single")
                                }
                              />
                              <div className={classes.departmentcaedText}>
                                <Typography>{item.department_name}</Typography>
                                <CircleIcon
                                  style={{ color: `${item.colour}` }}
                                />
                              </div>
                              <div className={classes.cardMenuButton}>
                                <Button
                                  variant="text"
                                  className={classes.CardMenu}
                                  onClick={(e) => handleMenuclick(e, item)}
                                >
                                  <BsThreeDotsVertical />
                                </Button>
                                <Menu
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={handleClose}
                                >
                                  <MenuItem
                                    key={`edit${item._id}`}
                                    className={`${classes.EditMenuTeam}`}
                                    onClick={(e) =>
                                      handleMenuItemClick(e, "edit")
                                    }
                                  >
                                    <EditPenIcon />{" "}
                                    <span>{ln.edit_department}</span>
                                  </MenuItem>
                                  <MenuItem
                                    key={`delete${item._id}`}
                                    className={classes.TeamMenuItem}
                                    onClick={(e) =>
                                      handleMenuItemClick(e, "delete")
                                    }
                                  >
                                    <DeleteBinIcons />{" "}
                                    <span>{ln.delete_department}</span>
                                  </MenuItem>
                                </Menu>
                              </div>
                            </CardContent>
                          </Card>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {deleteModal ? (
                  <DeleteConformation
                    modalClose={closeModal}
                    type={"Department"}
                    selected={selected}
                    idsList={userEntry}
                  />
                ) : null}
              </div>
            ) : (
              <div className={classes.imagePagemaindiv}>
                <img
                  src={image}
                  alt="createTeam"
                  className={classes.CreateTeamimage}
                />
                <Typography className={classes.imagePageText} variant="h5">
                  You don't have any departments currently. Please create a
                  departments to view here.
                </Typography>
                <Button
                  variant="contained"
                  className={`${classes.imagepagebtn} ${classes.noDataImagePAge}`}
                  onClick={() => handleClick()}
                >
                  <RoundAddIcon />
                  <span>{ln.create_department}</span>
                </Button>
              </div>
            )}
          </Paper>
          {modal ? (
            <AddDepartmentModal modalClose={CloseForm} department={selected} />
          ) : null}
        </div>
      ) : null}
      {showAlert && error && (
        <AlertBox type="error" message={error} stateName={setShowAlert} />
      )}
    </div>
  );
};

export default DepartMentIndex;
