import React, { useState, useRef, useEffect } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import {
  generateColor,
  getInitialName,
  getMapCenter,
  getMqttData,
  stringAvatar,
} from "../../../utilities/commonFuntions";
import classes from "./index.module.css";
import "../../../assets/css/notModule.css";
import {
  Avatar,
  Badge,
  Button,
  Typography,
  Card,
  CardContent,
  Chip,
} from "@mui/material";
import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { MAP_MARKER_TEAM } from "../../../api-calls/api-urls";
import { useNavigate } from "react-router-dom";
import FilterContainer from "./filterContainer";
import AlertBox from "../../../components/AlertBox";
import AddMemberModal from "../MyTeam/AddMemberModal";
import store from "../../../Redux/store";
import { useDispatch, useSelector } from "react-redux";

// var options = {
//   protocol: "wss",
//   keepalive: 20,
//   // clientId uniquely identifies client
//   // choose any string you wish
//   clientId: "mqttjs_" + Math.random().toString(16).substr(2, 8),
// };
// var client = mqtt.connect("mqtt://broker.hivemq.com:8000", options);

function Map() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(false);
  const [isTimeoutSet, setIsTimeoutSet] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [modletoggle, setModletoggle] = useState(false);
  const [activeUser, setActiveUser] = useState();
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [mapcenter, setMapcenter] = useState();
  const { markerList, teamMembersList } = useSelector(
    (state) => state.reducers.allUsers
  );
  const regex = new RegExp(searchValue, "i");

  const CloseForm = (props) => {
    setModletoggle(false);
  };

  useEffect(() => {
    // getMapCenter(setMapcenter);
    if (markerList && markerList.length > 0) {
      getMapCenter(setMapcenter, markerList);
    }
  }, [markerList]);

  useEffect(() => {
    if (
      markerList.length > 0 &&
      window.location.pathname.includes("map") &&
      !trigger
    ) {
      // Initial trigger
      getMqttData(userInfo._id, teamMembersList, dispatch, "map");
      console.log("initial trigger");

      // Set up interval for recurring triggers
      const intervalId = setInterval(() => {
        getMqttData(userInfo._id, teamMembersList, dispatch, "map");
        console.log("interval trigger");
      }, 2 * 60 * 1000); // 2 minutes in milliseconds

      setTrigger(true);

      // Cleanup interval on component unmount or when dependencies change
      return () => clearInterval(intervalId);
    }
  }, [markerList]);

  const handleActiveMarker = (marker) => {
    if (marker.name === activeMarker) {
      return;
    }
    const { teamMembersList } = store.getState()?.reducers?.allUsers;
    const findOneUser = markerList.find(
      (player) => player?.user_id === marker?.user_id?._id
    );
    console.log(findOneUser, "findOneUser");
    setActiveMarker(marker.name);
    setActiveUser(findOneUser);
    setMapcenter(marker.position);
  };

  const getMapMArkerList = async (search) => {
    try {
      const userEntry = {};
      const { data } = await authApi.get(
        `${MAP_MARKER_TEAM}/${userInfo._id}?search=${search ? search : ""}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
          data: userEntry,
        }
      );
      dispatch({ type: "MAP_MARKER_LIST", payload: data });
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.log(error.response);
        setShowAlert(true);
        setError(error.response.data.message);
      }
    }
  };

  const dateObject = (date, search, selcetedValue) => {
    console.log(search);
    getMapMArkerList(search);
    setSearchValue(search);
  };

  useEffect(() => {
    if (trigger) {
      const timeout = setTimeout(() => {
        setTrigger(false);
      }, 2000 * 60); // Change the duration (in milliseconds) as per your requirement

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [trigger]);

  // Sets default React state

  useEffect(() => {
    try {
      getMapMArkerList();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.log(error.response);
        setShowAlert(true);
        setError(error.response.data.message);
      }
    }
  }, [markerList.length === 0]);

  const handleViewRoute = (item) => {
    console.log(item);
    navigate(`/maps/view-route/${item._id}`);
  };

  const getMarkerSVG = (name, color) => {
    const isSelectedMarker = activeMarker && activeMarker === name;
    const fillColor = isSelectedMarker ? "white" : color;
    const strokeColor = color;

    return `<svg width="36px" height="36px" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18.138" cy="8.4625" r="4.8801" fill="${fillColor}" stroke="${strokeColor}" stroke-width="2"/>
        <rect x="9.0039" y="15.0443" width="18.1395" height="17.6213" rx="6.2222" fill="${fillColor}" stroke="${strokeColor}" stroke-width="2"/>
    
        <text x="50%" y="67%" dominant-baseline="middle" text-anchor="middle" fill="${
          isSelectedMarker ? "black" : "white"
        }" font-size="6px" font-family="Arial">
          ${getInitialName(name).toUpperCase()}
        </text>
      </svg>`;
  };

  return (
    <>
      <div style={{ width: "100%", position: "relative" }}>
        <FilterContainer dateObject={dateObject} />
      </div>
      <GoogleMap
        center={mapcenter}
        zoom={12}
        onClick={() => setActiveMarker(null)}
        mapContainerStyle={{ height: "100%", marginTop: "0px" }}
      >
        {teamMembersList.map((location, index) => (
          <Marker
            key={index}
            position={location?.position}
            onClick={() => handleActiveMarker(location)}
            icon={{
              url: `data:image/svg+xml;utf-8,${encodeURIComponent(
                getMarkerSVG(location.name, location?.department?.colour)
              )}`,
              anchor: new window.google.maps.Point(20, 40), // Adjust the anchor values as needed
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          >
            {activeMarker === location.name ? (
              <InfoWindow
                position={activeUser?.position}
                onCloseClick={() => setActiveMarker(null)}
              >
                <div className={classes.infoWindowContainer}>
                  <div className={classes.infoWindowContainerHEader}>
                    <div>
                      <Badge
                        color={
                          location.status === "checkin" ? "success" : "error"
                        }
                        badgeContent=" "
                        variant="dot"
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                      >
                        {location &&
                        location?.user_id &&
                        location?.user_id?.profile_pic &&
                        location?.user_id?.profile_pic?.filename ? (
                          <Avatar
                            alt={location.name}
                            src={`${process.env.REACT_APP_IMAGE_URL}/${location?.user_id?.profile_pic?.destination}${location?.user_id?.profile_pic?.filename}`}
                            sx={{ width: 40, height: 40 }}
                          />
                        ) : (
                          <Avatar
                            {...stringAvatar(location.name)}
                            sx={{
                              bgcolor: `${generateColor(location.name)}`,
                            }}
                          />
                        )}
                      </Badge>
                    </div>
                    <div className={classes.nameContainer}>
                      <Typography className={classes.cardLabelText}>
                        Team Member
                      </Typography>
                      <Typography className={classes.cardImpText}>
                        {location.name}
                      </Typography>
                      <Button
                        variant="outlined"
                        style={{ textTransform: "none", marginTop: "10px" }}
                        onClick={() => handleViewRoute(location)}
                      >
                        View route
                      </Button>
                    </div>
                    <div>
                      <span onClick={() => setModletoggle(true)}>
                        <MdOutlineModeEdit />
                      </span>
                      {/* <RiDeleteBin6Line /> */}
                    </div>
                  </div>
                  <div
                    className={`${classes.infoWindowContainerHEader} ${classes.infoSubHeader}`}
                  >
                    <div>
                      <Typography className={classes.cardLabelText}>
                        Team
                      </Typography>
                      <Typography className={classes.cardImpText}>
                        {activeUser.team_name}
                      </Typography>
                    </div>
                    <div>
                      <Typography className={classes.cardLabelText}>
                        DepartMent
                      </Typography>
                      <Typography
                        className={`${classes.cardImpText} ${classes.departmentCard}`}
                      >
                        <Avatar
                          sx={{
                            bgcolor: `${activeUser.department.colour}`,
                            width: "13px",
                            height: "13px",
                            marginRight: "5px",
                            marginTop: "5px",
                          }}
                        >
                          {" "}
                        </Avatar>{" "}
                        | {activeUser.department.department_name}
                      </Typography>
                    </div>
                    <div>
                      <Typography className={classes.cardLabelText}>
                        Battery
                      </Typography>
                      <Typography className={classes.cardImpText}>
                        {location && location.batteryLevel
                          ? `${location?.batteryLevel}%`
                          : `offline`}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.piechartContainer}>
                    <div className={classes.infoWindowContainerHEader}>
                      <Typography className={classes.piechartHeading}>
                        Total Task
                      </Typography>
                      <Typography
                        className={`${classes.linkBtntoAssign} ${classes.piechartHeading}`}
                        onClick={() => navigate("/location/create-location")}
                      >
                        Assign a Location
                      </Typography>
                    </div>
                    <div
                      className={`${classes.infoWindowContainerHEader} ${classes.piechartHeading1}`}
                    >
                      <div>
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 44 44"
                          className={classes.piechartSvg}
                        >
                          <circle
                            cx="50%"
                            cy="50%"
                            fill="transparent"
                            r="15.909091"
                            stroke="#eee"
                            strokeWidth={10}
                          />
                          <circle
                            show-tip="ctDonutTip"
                            className="ct-donut-slice"
                            cx="50%"
                            cy="50%"
                            fill="transparent"
                            r="15.909091"
                            stroke="#1273B8" // Completed color
                            strokeDasharray={`${
                              (activeUser?.location_datas?.completed /
                                activeUser?.location_datas?.total) *
                              100
                            } ${
                              (activeUser?.location_datas?.total -
                                activeUser?.location_datas?.completed /
                                  activeUser?.location_datas?.total) *
                              100
                            }`}
                            strokeWidth={10}
                            strokeDashoffset={0}
                            slice-title="Completed"
                            percent-value={11}
                            usage={11}
                            total-val={20}
                          >
                            <animate
                              attributeName="stroke-dashoffset"
                              from={100}
                              to={0}
                              dur="0.5s"
                            />
                          </circle>
                          <circle
                            show-tip="ctDonutTip"
                            className="ct-donut-slice"
                            cx="50%"
                            cy="50%"
                            fill="transparent"
                            r="15.909091"
                            stroke="#240051" // Pending color
                            strokeDasharray={`${
                              (activeUser?.location_datas?.pending /
                                activeUser?.location_datas?.total) *
                              100
                            } ${
                              (activeUser?.location_datas?.total -
                                activeUser?.location_datas?.pending /
                                  activeUser?.location_datas?.total) *
                              100
                            }`}
                            strokeWidth={10}
                            strokeDashoffset={
                              -(
                                activeUser?.location_datas?.completed /
                                activeUser?.location_datas?.total
                              ) * 100
                            } // Adjusted to start from the end point of the second slice
                            slice-title="Pending"
                            percent-value={2}
                            usage={2}
                            total-val={20}
                          >
                            <animate
                              attributeName="stroke-dashoffset"
                              from={100}
                              to={-100} // Adjusted to start from the end point of the second slice
                              dur="0.5s"
                            />
                          </circle>
                          <circle
                            show-tip="ctDonutTip"
                            className="ct-donut-slice"
                            cx="50%"
                            cy="50%"
                            fill="transparent"
                            r="15.909091"
                            stroke="#C52A38" // Rejected color
                            strokeDasharray={`${
                              (activeUser?.location_datas?.rejected /
                                activeUser?.location_datas?.total) *
                              100
                            } ${
                              (activeUser?.location_datas?.total -
                                activeUser?.location_datas?.rejected /
                                  activeUser?.location_datas?.total) *
                              100
                            }`}
                            strokeWidth={10}
                            strokeDashoffset={
                              -(
                                (activeUser?.location_datas?.completed +
                                  activeUser?.location_datas?.pending) /
                                activeUser?.location_datas?.total
                              ) * 100
                            } // Adjusted to start from the end point of the second slice
                            slice-title="Rejected"
                            percent-value={7}
                            usage={7}
                            total-val={20}
                          >
                            <animate
                              attributeName="stroke-dashoffset"
                              from={100}
                              to={-100} // Adjusted to start from the end point of the second slice
                              dur="0.5s"
                            />
                          </circle>
                          <circle
                            cx="50%"
                            cy="50%"
                            fill="#FFFFFF"
                            r={16}
                          ></circle>
                          <text x="50%" y="55%" className="ct-donut-center">
                            {activeUser?.location_datas?.total}
                          </text>
                        </svg>
                        <div className="ct-tip" />
                      </div>
                      <div
                        className={`${classes.infoWindowContainerHEader} ${classes.piechartSubContainer}`}
                      >
                        <div>
                          <Typography
                            className={`${classes.cardImpText} ${classes.departmentCard}`}
                          >
                            <Avatar
                              sx={{
                                bgcolor: `#1273B8`,
                                width: "13px",
                                height: "13px",
                                marginRight: "5px",
                                marginTop: "5px",
                              }}
                            >
                              {" "}
                            </Avatar>{" "}
                            {activeUser?.location_datas?.completed}
                          </Typography>
                          <Typography>Completed</Typography>
                        </div>
                        <div>
                          <Typography
                            className={`${classes.cardImpText} ${classes.departmentCard}`}
                          >
                            <Avatar
                              sx={{
                                bgcolor: `#240051`,
                                width: "13px",
                                height: "13px",
                                marginRight: "5px",
                                marginTop: "5px",
                              }}
                            >
                              {" "}
                            </Avatar>{" "}
                            {activeUser?.location_datas?.pending}
                          </Typography>
                          <Typography>Pending</Typography>
                        </div>
                        <div>
                          <Typography
                            className={`${classes.cardImpText} ${classes.departmentCard}`}
                          >
                            <Avatar
                              sx={{
                                bgcolor: `#C52A38`,
                                width: "13px",
                                height: "13px",
                                marginRight: "5px",
                                marginTop: "5px",
                              }}
                            >
                              {" "}
                            </Avatar>{" "}
                            {activeUser?.location_datas?.rejected}
                          </Typography>
                          <Typography>Rejected</Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </InfoWindow>
            ) : null}
          </Marker>
        ))}
        {modletoggle && activeUser ? (
          <AddMemberModal
            modalClose={CloseForm}
            user={activeUser}
            name={"edit-from-map"}
          />
        ) : null}
        {showAlert && error && (
          <AlertBox type="error" message={error} stateName={setShowAlert} />
        )}
      </GoogleMap>
    </>
  );
}

export default function GoogleApiWrapper() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDaVncl1j0jHuHY50J0VXcMHl19Vr8p-HU", // Add your API key
  });

  return isLoaded ? <Map /> : null;
}
