import React, { useEffect, useState } from "react";
import classes from "./location.module.css";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { BaseUrl, ImageUrl, LOCATION } from "../../../api-calls/api-urls";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { Link, Paper, Chip, Button, Typography, Tooltip } from "@mui/material";
import { MdOutlineModeEdit } from "react-icons/md";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import { getLanguagedataFunction } from "../../../utilities/language";
import { useDispatch, useSelector } from "react-redux";
import { BsArrowLeftShort } from "react-icons/bs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { checkOnline, getChipColor } from "../../../utilities/commonFuntions";
import RecorderCard from "./audioRecorder";
import {
  BrudCrumbBackIcon,
  BrudCrumbNextIcon,
  DeleteBinIcons,
  EditPenIcon,
  ViewRouteIcon,
} from "../../../components/Icons";
import DeleteConformation from "./DeleteConformation";
import SmallViewRoutes from "./SmallViewRoute";
// import ViewRoute from "../Maps/viewRoute";

const LocationDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentLocation, setCurrentLocation] = useState();
  const [status, setStatus] = useState();
  const [modleToggle, setModalToggle] = useState(false);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  const { teamMembersList } = useSelector((state) => state.reducers.allUsers);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  useEffect(() => {
    const path = window.location.pathname;
    getOneLocationdata(path.toLowerCase());
  }, [!currentLocation]);

  const handleDeleteLocation = async (item) => {
    setModalToggle(true);
  };

  const modalClose = () => {
    setModalToggle(false);
    navigate("/location");
  };

  const getOneLocationdata = async (path) => {
    const arr = path.split("/");
    console.log(arr[arr.length - 1]);
    const { data } = await authApi.get(`${LOCATION}/${arr[arr.length - 1]}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data.data);
    setCurrentLocation(data.data);
  };

  const handleViewonGoogleMap = () => {
    console.log("this functions triffers");
    if (
      currentLocation.location_info &&
      currentLocation.location_info.lat &&
      currentLocation.location_info.lng
    ) {
      window.open(
        `https://www.google.com/maps/place/${currentLocation.location_info.lat},${currentLocation.location_info.lng}/@${currentLocation.location_info.lat},${currentLocation.location_info.lng},16z`,
        "_blank"
      );
    }
  };

  const handleViewRoute = () => {
    navigate(`/maps/location-view-route/${currentLocation.slug.toLowerCase()}`);
  };

  const handleDeleteImage = async () => {
    const userEntry = {
      images: [],
    };
    const { data } = await authApi.put(
      `${LOCATION}/${currentLocation._id}`,
      userEntry,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    getOneLocationdata(window.location.pathname.toLowerCase());
  };

  const handleEditClick = (item) => {
    navigate(`/location/update/${item.slug.toLowerCase()}`);
  };

  const handleReassign = (item) => {
    navigate(`/location/reassign/${item.slug.toLowerCase()}`);
  };

  return (
    <div>
      {currentLocation && ln ? (
        <div>
          <div className={`${classes.brudCrumb} mb-4 `}>
            <span onClick={() => navigate(-1)}>
              <BrudCrumbBackIcon />
            </span>
            <span
              onClick={() => navigate("/location")}
              className={classes.form_lable}
              style={{ color: "#0A1F41" }}
            >
              {ln.location}
            </span>
            <BrudCrumbNextIcon />
            <span className={classes.form_lable}>
              {currentLocation ? currentLocation.title : ""}
            </span>
          </div>
          <Paper className={`${classes.DetailPageMainDiv}`}>
            <div className={classes.imageText}>
              <Typography className={classes.createLocationheading1}>
                {ln.location_details}
              </Typography>
              <Link
                className={classes.LinkEdit}
                onClick={() => handleEditClick(currentLocation)}
              >
                <EditPenIcon /> {ln.edit}
              </Link>
            </div>
            <div className="mt-3">
              <div className={classes.memberCardText}>
                <Typography className={classes.memberCardTextHeading}>
                  {ln.location}
                </Typography>
              </div>
              <div className={`${classes.imageText} ${classes.TextContainer1}`}>
                <div>
                  <Typography className={classes.form_lable}>
                    {ln.location_id}
                  </Typography>
                  <Typography
                    className={`${classes.form_lable} ${classes.detailsText1}`}
                  >
                    {currentLocation._id.substring(0, 6)}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.form_lable}>
                    {ln.title}
                  </Typography>
                  <Typography
                    className={`${classes.form_lable} ${classes.detailsText1}`}
                  >
                    {currentLocation.title}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.form_lable}>
                    {ln.assigned_on}
                  </Typography>
                  <Typography
                    className={`${classes.form_lable} ${classes.detailsText1}`}
                  >
                    {moment(currentLocation.assigned_on).format(
                      "DD MMM YYYY,hh:mm A"
                    )}
                  </Typography>
                </div>
                <div style={{ minWidth: "18%", maxWidth: "300px" }}>
                  <Typography className={classes.form_lable}>
                    {ln.description}
                  </Typography>
                  <Typography
                    className={`${classes.form_lable} ${classes.detailsText1}`}
                  >
                    {currentLocation.description}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.form_lable}>
                    {ln.status}
                  </Typography>
                  <Typography
                    className={`${classes.form_lable} ${classes.detailsText1}`}
                  >
                    <Chip
                      className={classes.locationStatusChip}
                      label={currentLocation.status}
                      size="small"
                      style={getChipColor(currentLocation.status)}
                    />
                  </Typography>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className={classes.memberCardText}>
                <Typography className={classes.memberCardTextHeading}>
                  {ln.customer_details}
                </Typography>
              </div>
              <div className={classes.customerDetails1}>
                <div>
                  <Typography className={classes.form_lable}>
                    {ln.customer_address}
                  </Typography>
                  <Tooltip title={currentLocation.address}>
                    <Typography
                      className={`${classes.form_lable} ${classes.detailsText1}`}
                      noWrap
                      style={{
                        overflow: "hidden",
                        width: "150px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {currentLocation.address}
                    </Typography>
                  </Tooltip>
                </div>
                <div>
                  <Typography className={classes.form_lable}>
                    {ln.location}
                  </Typography>
                  <Link
                    className={classes.LinkbtnViewGooleMap}
                    onClick={() => handleViewonGoogleMap()}
                  >
                    {ln.view_google_map}
                  </Link>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div
                className={`${classes.memberCardText} ${classes.viewRoutContainer}`}
              >
                <Typography className={classes.memberCardTextHeading}>
                  {ln.team_member_details}
                </Typography>
                <Typography
                  className={classes.viewRouteText}
                  onClick={() => handleViewRoute()}
                >
                  <ViewRouteIcon />
                  <span>View route</span>
                </Typography>
              </div>
              <div className={`${classes.imageText} ${classes.TextContainer2}`}>
                <div>
                  <Typography className={classes.form_lable}>
                    {ln.assigned_to}
                  </Typography>
                  <Typography
                    className={`${classes.form_lable} ${classes.detailsText1}`}
                  >
                    {currentLocation.employee.name} (
                    {checkOnline(currentLocation.employee?.user_id) ===
                    "checkin"
                      ? "Online"
                      : "Offline"}
                    )
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.form_lable}>
                    {ln.team}
                  </Typography>
                  <Typography
                    className={`${classes.form_lable} ${classes.detailsText1}`}
                  >
                    {currentLocation.employee.department.department_name}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.form_lable}>
                    {ln.reached_on}
                  </Typography>
                  <Typography
                    className={`${classes.form_lable} ${classes.detailsText1}`}
                  >
                    {currentLocation.status === "completed"
                      ? moment(
                          currentLocation?.userLocation?.reached_date
                        ).format("DD MMM YYYY,hh:mm A")
                      : "-"}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className={classes.memberCardText}>
                <Typography className={classes.memberCardTextHeading}>
                  {ln.other_details}
                </Typography>
              </div>
              <div
                className={`${classes.mapandImageContainer} ${classes.TextContainer3}`}
              >
                <div>
                  <RecorderCard audio={currentLocation} />
                </div>
                {currentLocation &&
                currentLocation.images &&
                currentLocation.images.length > 0 ? (
                  <div className={classes.subImageContainer}>
                    <img
                      src={`${process.env.REACT_APP_IMAGE_URL}/${currentLocation.images[0]?.destination}${currentLocation.images[0]?.filename}`}
                      width="250px"
                      height="150px"
                      style={{ maxHeight: "250px" }}
                      alt={currentLocation.images[0]?.originalname}
                    />
                    <div className={classes.imageText}>
                      <Tooltip title={currentLocation.images[0]?.originalname}>
                        <Typography
                          className={`${classes.form_lable} ${classes.detailsText1}`}
                          noWrap
                          style={{
                            overflow: "hidden",
                            width: "150px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {currentLocation.images[0]?.originalname}
                        </Typography>
                      </Tooltip>

                      <div style={{ display: "flex", columnGap: "10px" }}>
                        {/* {item.size ? (
                            <Typography className={classes.form_lable}>
                              {formatBytes(currentLocation.images[0].size)}
                            </Typography>
                          ) : null} */}
                        <Typography
                          className={`${classes.form_lable} ${classes.ImageDeleteBtn}`}
                          // onClick={() =>
                          //   remove(currentLocation.images[0].filename)
                          // }
                        >
                          <span onClick={() => handleDeleteImage()}>
                            <DeleteBinIcons />
                          </span>
                        </Typography>
                      </div>
                    </div>
                  </div>
                ) : null}
                {currentLocation.location_info ? <SmallViewRoutes /> : null}
              </div>
            </div>
            <div className="mt-4 mb-3">
              <div className={classes.createLocationbtnsectin}>
                <Button
                  variant="outlined"
                  onClick={() => handleDeleteLocation(currentLocation)}
                >
                  {ln.delete_location}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleReassign(currentLocation)}
                >
                  {ln.reassign_location}
                </Button>
              </div>
            </div>
          </Paper>
          {modleToggle ? (
            <DeleteConformation
              item={currentLocation}
              modalClose={modalClose}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default LocationDetailsPage;
