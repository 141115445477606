import React, { useEffect } from "react";
import classes from "./myteam.module.css";
import { BsArrowLeftShort } from "react-icons/bs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Tabs, Tab, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TeamMemberLocaions from "./tammemberLocaions";
import TeamMemberDetails from "./teamMemberDetails";
import TeamMemberRepots from "./teamMemberRepots";
import { useDispatch, useSelector } from "react-redux";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { TEAM_MEMBER_DETAILS } from "../../../api-calls/api-urls";
import { TeamMemberData } from "../../../Redux/actions/team-actions";
import { getLanguagedataFunction } from "../../../utilities/language";
import { setHeaderName } from "../../../Redux/actions/user-actions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    className: classes.TabHeader,
  };
}

const TeamMemberViewPageIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const { teamMemberData } = useSelector((state) => state.reducers.teams);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const getTeamMemberData = async () => {
    const path = window.location.pathname.split("/");
    const { data } = await authApi.post(
      `${TEAM_MEMBER_DETAILS}/${path[path.length - 1]}`,
      {},
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    dispatch(TeamMemberData(data));
  };

  useEffect(() => {
    getTeamMemberData();
  }, [!teamMemberData]);

  useEffect(() => {
    if (teamMemberData) {
      dispatch(setHeaderName(teamMemberData.name));
    }
  }, [teamMemberData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {ln ? (
        <div className={classes.TeamMemberViewPageIndexmaindiv}>
          <div className={classes.brudCrumb}>
            <BsArrowLeftShort
              style={{ width: "30px", height: "30px" }}
              onClick={() => navigate(-1)}
            />
            <span onClick={() => navigate("/my-team")}>{ln.my_team}</span>
            <NavigateNextIcon fontSize="small" />
            <span>{teamMemberData ? teamMemberData.name : ""}</span>
          </div>
          <Box sx={{ marginTop: "12px" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={`${ln.location}(${3})`}
                  {...a11yProps(0)}
                  style={{ textTransform: "capitalize" }}
                />
                <Tab
                  label={ln.details}
                  {...a11yProps(1)}
                  style={{ textTransform: "capitalize" }}
                />
                <Tab
                  label={ln.reports}
                  {...a11yProps(2)}
                  style={{ textTransform: "capitalize" }}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0} className={classes.TabPanel}>
              <TeamMemberLocaions />
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.TabPanel}>
              <TeamMemberDetails />
            </TabPanel>
            <TabPanel
              value={value}
              index={2}
              className={`${classes.TabPanel} ${classes.TabPanelReport}`}
            >
              <TeamMemberRepots />
            </TabPanel>
          </Box>
        </div>
      ) : null}
    </div>
  );
};

export default TeamMemberViewPageIndex;
