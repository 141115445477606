import React, { useEffect, useState } from "react";
import ImageCarosel from "../../../components/ImageCarosel";
import classes from "../Registration/index.module.css";
import { GrFormEdit } from "react-icons/gr";
import OtpInput from "react-otp-input";
import { OTP_LOGIN, VERIFY_LOGIN_OTP } from "../../../api-calls/api-urls";
import AppLogo from "../../../assets/images/mainLogo.png";
import { ValidatorForm } from "react-material-ui-form-validator";
import "../../../assets/css/style.css";
import { loggedUser, pageLoader } from "../../../Redux/actions/user-actions";
import "../../../assets/css/bootstrap.min.css";
import {
  Card,
  Typography,
  Snackbar,
  Alert,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  api,
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { getLanguagedataFunction } from "../../../utilities/language";
import { setPresenceOnline } from "../../../utilities/Xmpp";
import Cookies from "js-cookie";
import AlertBox from "../../../components/AlertBox";
import { Numeric } from "../../../utilities/commonFuntions";
const OtpLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Otp, setOtp] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const [time, setTime] = useState(15);
  const [isEdit, setIsEdit] = useState(false);
  const otp = JSON.parse(sessionStorage.getItem("loginOtp"));
  const phone = JSON.parse(sessionStorage.getItem("mobile"));
  const [mobile, setMobile] = useState(phone);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  const [disable, setDisable] = useState({
    name: ln ? `${ln.resend_in} ${time}${ln.seconds}` : null,
    state: true,
  });

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Change the duration (in milliseconds) as per your requirement

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showAlert]);

  useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const handleSubmit = async () => {
    const userEntry = {
      otp: Otp,
    };
    try {
      dispatch(pageLoader(true));
      const { data } = await api.post(VERIFY_LOGIN_OTP, userEntry);
      if (data.user_type === "team_player") {
        setShowAlert(true);
        setError("Team Leader Only has a Access");
      } else {
        await sessionStorage.setItem("userInfo", JSON.stringify(data));
        dispatch(loggedUser(data));
        const rememberMe = sessionStorage.getItem("rememberMe");
        if (rememberMe && data && data.token) {
          Cookies.set("userToken", JSON.stringify(data), { expires: 7 });
          sessionStorage.removeItem("rememberMe");
        }
        if (data) {
          await setPresenceOnline(data, dispatch);
        }
        navigate("/home");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setShowAlert(true);
        setError(error.response.data.message);
      } else {
        // Handle other errors
        // ...
      }
    }
  };
  // useEffect(() => {
  //   if (!localStorage.getItem("CurrentUser")) {
  //     navigate("/personal-details");
  //   }
  // }, []);
  const handleOnchange = (e) => {
    const re = /^\d*$/;
    if (e === "" || re.test(e)) {
      setOtp(e);
    }
  };
  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevCount) => prevCount - 1);
      setDisable({
        name: `${ln.resend_in} ${time - 1}${ln.seconds}`,
        state: true,
      });
    }, 1000);

    if (time === 0) {
      clearInterval(timer);
      setDisable({
        name: ln.resend_otp,
        state: false,
      });
    }
    return () => {
      clearInterval(timer);
    };
  }, [time, ln]);

  const handleResetClick = () => {
    setOtp(null);
    setTime(15);
    handleOtp();
  };

  const handleOtp = async () => {
    const userEntry = {
      phone: mobile,
    };
    const { data } = await authApi.post(OTP_LOGIN, userEntry, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    sessionStorage.setItem("loginOtp", JSON.stringify(data));
    // setOtpReceived(true);
  };

  const handleOtpLogin = async () => {
    const userEntry = {
      phone: mobile,
    };
    try {
      dispatch(pageLoader(true));
      const { data } = await api.post(OTP_LOGIN, userEntry);
      sessionStorage.setItem("mobile", JSON.stringify(mobile));
      sessionStorage.setItem("loginOtp", JSON.stringify(data));
      navigate("/otp_login");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setShowAlert(true);
        setError(error.response.data.message);
      }
    }
  };

  const HandleNumberChange = () => {
    setIsEdit(false);
    handleOtpLogin();
  };

  useEffect(() => {}, []);
  return (
    <div className={classes.maindivForlogin}>
      {ln ? (
        <Card className={classes.SelectLanguage_maindiv}>
          <div>
            <ImageCarosel />
          </div>
          <ValidatorForm useref="form" onSubmit={handleSubmit}>
            <div className="col-lg-12">
              <div className="p-lg-5 mt-5 mb-5 p-4">
                <div className="text-left">
                  <span className="logo-lg mb-5">
                    <img src={AppLogo} alt="" height="50" />
                  </span>
                  <Typography className={`mt-4 ${classes.loginHeading}`}>
                    {ln.verify_otp}
                  </Typography>
                  <Typography className={`mt-4 ${classes.loginDescription}`}>
                    {ln.enter_otp_mobile} <br />
                    {isEdit ? (
                      <div className="mb-2 mt-1 d-flex">
                        <TextField
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "30%",
                          }}
                          inputProps={{
                            maxLength: 10,
                          }}
                          id="phonenumberInput"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                          onKeyPress={(e) => Numeric(e)}
                          variant="standard"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +91
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Button
                          variant="contained"
                          onClick={() => HandleNumberChange()}
                          className={classes.otpNumberUpdateBytn}
                        >
                          update
                        </Button>
                      </div>
                    ) : (
                      <b className={classes.boldContainer}>
                        <span className={classes.mobileNumber}>
                          +91 {mobile}
                        </span>{" "}
                        <span onClick={() => setIsEdit(true)}>
                          <GrFormEdit />
                        </span>
                      </b>
                    )}
                  </Typography>
                  {otp ? (
                    <Typography>
                      Your Phone OTP is: {otp.otp || otp.phone_otp}
                    </Typography>
                  ) : null}
                </div>

                <div className="mt-4">
                  <div className={`otp ${classes.OtpFeildsContainer}`}>
                    <OtpInput
                      value={Otp}
                      onChange={(e) => handleOnchange(e)}
                      numInputs={4}
                      renderInput={(props) => <input {...props} />}
                      isInputNum={true}
                    />
                  </div>
                  <div className="mt-4">
                    <p className={`mb-0 ${classes.login_form_lable}`}>
                      {ln.didnt_recieve_otp}
                      <Button
                        disabled={disable.state}
                        className={`${classes.login_form_lable} ${classes.remember_input} ${classes.forgotbtn}`}
                        variant="text"
                        sx={{
                          "&.Mui-disabled": {
                            color: "#0000ff73 !important",
                          },
                        }}
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          border: "none",
                          textTransform: "none",
                        }}
                        onClick={() => handleResetClick()}
                      >
                        {disable.name}
                      </Button>
                    </p>
                  </div>
                  <div className="mt-4">
                    <Button
                      disabled={Otp && Otp.length === 4 ? false : true}
                      variant="contained"
                      type="submit"
                      className={classes.commonbtn}
                      fullWidth
                    >
                      {ln.continue}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </ValidatorForm>
        </Card>
      ) : null}
      {showAlert && error && (
        <AlertBox type="error" message={error} stateName={setShowAlert} />
      )}
    </div>
  );
};

export default OtpLogin;
