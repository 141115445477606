import React, { useEffect, useState } from "react";
import ImageCarosel from "../../../components/ImageCarosel";
import classes from "./index.module.css";
import { Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import AppLogo from "../../../assets/images/mainLogo.png";
import { Alphabetic, Numeric } from "../../../utilities/commonFuntions";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import "../../../assets/css/style.css";
import "../../../assets/css/bootstrap.min.css";
import {
  RegisteredUser,
  pageLoader,
} from "../../../Redux/actions/user-actions";
import { Card, Snackbar, Alert } from "@mui/material";
import { api } from "../../../api-calls/user-requests";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CHECK_EMAIL,
  REGISTER_XMPP,
  SIGNUP_URL,
  XmppBaseUrl,
} from "../../../api-calls/api-urls";
import { getLanguagedataFunction } from "../../../utilities/language";
import axios from "axios";
import AlertBox from "../../../components/AlertBox";

const PresonalDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState();
  const [email, setEmail] = useState("");
  const [referralcode, setReferralCode] = useState("");

  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  // useEffect(() => {
  //   if (localStorage.getItem("CurrentUser")) {
  //     navigate("/otp1");
  //   }
  // }, []);
  const handleSubmit = async () => {
    const userEntry = {
      first_name: firstName,
      last_name: lastName,
      phone: mobile,
      email: email,
      referral_code: referralcode,
      user_type: "team_leader",
    };
    const isPresent = await api.post(CHECK_EMAIL, userEntry);
    if (isPresent.data.error) {
      setShowAlert(true);
      setError(isPresent.data.error);
    }
    if (isPresent.data.message === true) {
      try {
        dispatch(pageLoader(true));
        const { data } = await api.post(SIGNUP_URL, userEntry);
        sessionStorage.setItem("CurrentUser", JSON.stringify(data));
        dispatch(RegisteredUser(data));
        navigate("/otp1");
      } catch (error) {
        setShowAlert(true);
        console.log(error.response);
        setError(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Change the duration (in milliseconds) as per your requirement

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showAlert]);
  return (
    <div className={classes.maindivForlogin}>
      {ln ? (
        <Card className={classes.SelectLanguage_maindiv}>
          <div>
            <ImageCarosel />
          </div>
          <ValidatorForm useref="form" onSubmit={handleSubmit}>
            <div className="col-lg-12">
              <div style={{ padding: "3rem" }}>
                <div className="text-left">
                  <span className="logo-lg mb-5">
                    <img src={AppLogo} alt="" height="50" />
                  </span>
                  <Typography className={`mt-5 ${classes.loginHeading}`}>
                    {ln.register}
                  </Typography>
                  <Typography className={`mt-4 ${classes.loginDescription}`}>
                    {ln.register_your_basic_details}
                  </Typography>
                </div>

                <div className="mt-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="firstNameinput"
                          className={classes.login_form_lable}
                        >
                          {ln.first_name}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          id="firstNameinput"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          onKeyPress={(e) => Alphabetic(e)}
                          size="small"
                          validators={["required"]}
                          errorMessages={["First Name is required"]}
                        />
                      </div>
                    </div>
                    {/* <!--end col--> */}
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="" className={classes.login_form_lable}>
                          {ln.last_name}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          id="lastNameinput"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          onKeyPress={(e) => Alphabetic(e)}
                          size="small"
                          validators={["required"]}
                          errorMessages={["Last Name is required"]}
                        />
                      </div>
                    </div>
                    {/* <!--end col-->

                                <!--end col--> */}
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="phonenumberInput"
                          className={classes.login_form_lable}
                        >
                          {ln.mobile_number}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          inputProps={{
                            maxLength: 10,
                          }}
                          id="phonenumberInput"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                          onKeyPress={(e) => Numeric(e)}
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +91
                              </InputAdornment>
                            ),
                          }}
                          validators={["required", "matchRegexp:[0-9]{10}"]}
                          errorMessages={[
                            "Mobile Number is required",
                            "Please Enter the 10 digit mobile number",
                          ]}
                        />
                      </div>
                    </div>
                    {/* <!--end col--> */}
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="emailidInput"
                          className={classes.login_form_lable}
                        >
                          {ln.email_id}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          id="emailidInput"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          size="small"
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "Email Id is required",
                            "email is not valid",
                          ]}
                        />
                      </div>
                    </div>
                    {/* <!--end col--> */}
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="address1ControlTextarea"
                          className={classes.login_form_lable}
                        >
                          {ln.referral_code} ({ln.optional})
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          id="address1ControlTextarea"
                          value={referralcode}
                          onChange={(e) => setReferralCode(e.target.value)}
                          size="small"
                        />
                      </div>
                    </div>
                    {/* <!--end col--> */}

                    {/* <!--end col--> */}
                    <div className="mt-4">
                      <Button
                        className={`btn btn-primary w-100 ${classes.commonbtn}`}
                        type="submit"
                      >
                        {ln.send_otp}
                      </Button>
                    </div>
                    {/* {error ? <p style={{ color: "red" }}>{error}</p> : null} */}
                    {/* <!--end col--> */}
                  </div>
                  {/* <!--end col--> */}
                </div>
              </div>
            </div>
          </ValidatorForm>
          {showAlert && error && (
            <AlertBox type="error" message={error} stateName={setShowAlert} />
          )}
        </Card>
      ) : null}
    </div>
  );
};

export default PresonalDetails;
