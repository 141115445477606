import React, { useState } from "react";
import classes from "./index.module.css";
import aboutus1 from "../../../assets/images/Aboutus1.png";
import aboutusBanner from "../../../assets/images/aboutusBanner.png";
import { BrudCrumbBackIcon } from "../../../components/Icons";
import { Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagedataFunction } from "../../../utilities/language";

const Aboutus = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [secondPage, setSecondPage] = useState(false);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  return (
    <div>
      <div className={`${classes.brudCrumb} mb-4 `}>
        <span onClick={() => navigate(-1)}>
          <BrudCrumbBackIcon />
        </span>
        <span
          onClick={() => navigate("/")}
          className={classes.form_lable}
          style={{ color: "#0A1F41" }}
        >
          {ln.about_us}
        </span>
      </div>
      {!secondPage ? (
        <Paper
          className={classes.PaperContainerAboutus1}
          onClick={() => setSecondPage(!secondPage)}
        >
          <div>
            <Typography className={`mb-3 ${classes.mainText}`}>
              Welcome to Team Player
            </Typography>
            <Typography className={`mb-3 ${classes.form_lable}`}>
              Managing your team well is half the job done when it comes to
              managing a business. TeamPlayer Application is a progressive and
              very efficient software for medium and small businesses to manage
              their workforce and hence business in a better and technologically
              friendly way.
            </Typography>
            <Typography className={`mb-3 ${classes.form_lable}`}>
              With TeamPlayer every business owner can create the best
              management module for their team. With features like Employee
              Attendance Management, Sales Employee Tracking, Employee Time
              Tracking, Employee Location Tracking, Sales and Field Staff
              Tracking and more - it is a one stop place to manage your
              workforce the right way.
            </Typography>
            <Typography className={`${classes.form_lable}`}>
              The application is an easy-to-learn and easy-to-use, employee
              friendly software that helps the business reach its best
              efficiency.
            </Typography>
          </div>
          <img
            src={aboutus1}
            alt="aboutus1"
            className={classes.aboutus1image}
          />
        </Paper>
      ) : (
        <Paper
          onClick={() => setSecondPage(!secondPage)}
          style={{ cursor: "pointer" }}
        >
          <img
            src={aboutusBanner}
            alt="SecondPageImage"
            className={classes.aboutus2image}
          />
          <div style={{ padding: "30px" }}>
            <Typography className={`mb-3 ${classes.mainText}`}>
              Welcome to Team Player
            </Typography>
            <Typography className={`mb-3 ${classes.form_lable}`}>
              Managing your team well is half the job done when it comes to
              managing a business. TeamPlayer Application is a progressive and
              very efficient software for medium and small businesses to manage
              their workforce and hence business in a better and technologically
              friendly way.
            </Typography>
            <Typography className={`mb-3 ${classes.form_lable}`}>
              With TeamPlayer every business owner can create the best
              management module for their team. With features like Employee
              Attendance Management, Sales Employee Tracking, Employee Time
              Tracking, Employee Location Tracking, Sales and Field Staff
              Tracking and more - it is a one stop place to manage your
              workforce the right way.
            </Typography>
            <Typography className={`${classes.form_lable}`}>
              The application is an easy-to-learn and easy-to-use, employee
              friendly software that helps the business reach its best
              efficiency.
            </Typography>
          </div>
        </Paper>
      )}
    </div>
  );
};

export default Aboutus;
