import React from "react";
import classes from "./index.module.css";
import { BrudCrumbBackIcon } from "../../../components/Icons";
import { Box, Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TermConditions from "./TermConditions";
import { getLanguagedataFunction } from "../../../utilities/language";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PoliciesIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  return (
    <div>
      <div className={`${classes.brudCrumb} mb-4 `}>
        <span onClick={() => navigate(-1)}>
          <BrudCrumbBackIcon />
        </span>
        <span
          onClick={() => navigate("/location")}
          className={classes.form_lable}
          style={{ color: "#0A1F41" }}
        >
          {ln.policies}
        </span>
      </div>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className={classes.Tabs}
          >
            <Tab
              label={ln.terms_conditions}
              {...a11yProps(0)}
              style={{ textTransform: "capitalize" }}
            />
            <Tab
              label={ln.privacy_policy}
              {...a11yProps(1)}
              style={{ textTransform: "capitalize" }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} className={classes.TabPanel}>
          {/* <PersonalDetails /> */} <TermConditions />
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.TabPanel}>
          {/* <CompanyInfo /> */} <TermConditions />
        </TabPanel>
      </Box>
    </div>
  );
};

export default PoliciesIndex;
