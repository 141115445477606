import React, { useEffect, useState } from "react";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import classes from "./index.module.css";
import { Link } from "react-router-dom";
import "../assets/css/bootstrap.min.css";
import "../assets/css/index.css";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderName } from "../Redux/actions/user-actions";
import { getLanguagedataFunction } from "../utilities/language";
import {
  AddressBookIcon,
  BlogIcon,
  ChatIcon,
  DemoIcon,
  Help_FAQ_Icon,
  HomeIcon,
  LocationIcon,
  MapIcon,
  MarkHolidayCalendarIcon,
  MyTeamIcon,
  ReferEarnIcon,
  ReportPageIcon,
  SubscriptionIcon,
} from "./Icons";

const SideNavBar = () => {
  const dispatch = useDispatch();
  const [currentRoute, setCurrentRoute] = useState(
    window.location.pathname.toLowerCase()
  );
  const togglesidebar = useSelector(
    (state) => state.reducers.allUsers.togglesidebar
  );

  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  useEffect(() => {
    if (ln) {
      dispatch(setHeaderName(ln.home));
    }
  }, []);

  const handleNavigate = (name, route) => {
    console.log(name, route);
    dispatch(setHeaderName(name));
    setCurrentRoute(route);
  };

  useEffect(() => {
    if (window.location.pathname.includes("my-profile")) {
      setCurrentRoute("home");
      return;
    }
    const path = window.location.pathname;
    const Arr = path.split("/");
    const name = Arr[Arr.length - 1].toString();
    setCurrentRoute(name);
  }, [window.location.pathname]);

  return (
    <div id="scrollbar">
      {ln ? (
        <div>
          <div
            className={classes.nabBarContainer}
            style={{ height: window.outerHeight, minHeight: "750px" }}
          >
            <Link
              className={
                currentRoute.includes("home") ? `${classes.activebtn}` : ``
              }
            >
              <li className={togglesidebar ? classes.sideNaveBarli : ``}>
                <Button
                  variant="text"
                  onClick={() => handleNavigate(ln.home, "/home")}
                  className={classes.linkburron}
                >
                  <HomeIcon /> {togglesidebar ? ln.home : null}
                </Button>
              </li>
            </Link>
            <Link
              to="/location"
              className={
                currentRoute.includes("location") ? `${classes.activebtn}` : ``
              }
              onClick={() => handleNavigate(ln.location, "/location")}
            >
              <li className={togglesidebar ? classes.sideNaveBarli : ``}>
                <Button variant="text" className={classes.linkburron}>
                  <LocationIcon /> {togglesidebar ? ln.location : null}
                </Button>
              </li>
            </Link>
            <Link
              to="/my-team"
              className={
                currentRoute.includes("my-team") ? `${classes.activebtn}` : ``
              }
              onClick={() => handleNavigate(ln.my_team, "/my-team")}
            >
              <li className={togglesidebar ? classes.sideNaveBarli : ``}>
                <Button variant="text" className={classes.linkburron}>
                  <MyTeamIcon /> {togglesidebar ? ln.my_team : null}
                </Button>
              </li>
            </Link>
            <Link
              to="/maps"
              className={
                currentRoute.includes("maps") ? `${classes.activebtn}` : ``
              }
              onClick={() => handleNavigate(ln.map, "/maps")}
            >
              <li className={togglesidebar ? classes.sideNaveBarli : ``}>
                <Button
                  variant="text"
                  // onClick={() => handleNavigate(ln.map, "/home")}
                  className={classes.linkburron}
                >
                  <MapIcon /> {togglesidebar ? ln.map : null}
                </Button>
              </li>
            </Link>
            <Link
              to="/chat"
              className={
                currentRoute.includes("chat") ? `${classes.activebtn}` : ``
              }
              onClick={() => handleNavigate(ln.chat, "/chat")}
            >
              <li className={togglesidebar ? classes.sideNaveBarli : ``}>
                <Button variant="text" className={classes.linkburron}>
                  <ChatIcon /> {togglesidebar ? ln.chat : null}
                </Button>
              </li>
            </Link>
            <Link
              to="/reports"
              className={
                currentRoute.includes("reports") ? `${classes.activebtn}` :  ""
              }
              onClick={() => handleNavigate("Reports", "/reports")}
            >
              <li className={togglesidebar ? classes.sideNaveBarli : "" }>
                <Button
                  variant="text"
                  // onClick={() => handleNavigate(ln.map, "/home")}
                  className={classes.linkburron}
                >
                  <ReportPageIcon /> {togglesidebar ? "Reports" : null}
                </Button>
              </li>
            </Link>
            <Link
              to="/address"
              className={
                currentRoute.includes("address") ? `${classes.activebtn}` : ``
              }
              onClick={() => handleNavigate(ln.chat, "/address")}
            >
              <li className={togglesidebar ? classes.sideNaveBarli : ``}>
                <Button variant="text" className={classes.linkburron}>
                  <AddressBookIcon /> {togglesidebar ? ln.address : null}
                </Button>
              </li>
            </Link>
            <Link
              to="/subscriptions"
              className={
                currentRoute.includes("subscription")
                  ? `${classes.activebtn}`
                  : ``
              }
              onClick={() => handleNavigate(ln.subscriptions, "/subscriptions")}
            >
              <li className={togglesidebar ? classes.sideNaveBarli : ``}>
                <Button variant="text" className={classes.linkburron}>
                  <SubscriptionIcon /> {togglesidebar ? ln.subscriptions : null}
                </Button>
              </li>
            </Link>
            <Link
              to="/mark-a-holiday"
              className={
                currentRoute.includes("holiday") ? `${classes.activebtn}` : ``
              }
              onClick={() =>
                handleNavigate(ln.subscriptions, "/mark-a-holiday")
              }
            >
              <li className={togglesidebar ? classes.sideNaveBarli : ``}>
                <Button variant="text" className={classes.linkburron}>
                  <MarkHolidayCalendarIcon />{" "}
                  {togglesidebar ? ln.mark_a_holiday : null}
                </Button>
              </li>
            </Link>
            <Link
              to="/blogs"
              className={
                currentRoute.includes("blogs") ? `${classes.activebtn}` : ``
              }
              onClick={() => handleNavigate(ln.blogs, "/blogs")}
            >
              <li className={togglesidebar ? classes.sideNaveBarli : ``}>
                <Button variant="text" className={classes.linkburron}>
                  <BlogIcon /> {togglesidebar ? ln.blogs : null}
                </Button>
              </li>
            </Link>
            <Link
              to="/refer-earn"
              className={
                currentRoute.includes("refer-earn")
                  ? `${classes.activebtn}`
                  : ``
              }
              onClick={() => handleNavigate(ln.refer_earn, "/refer-earn")}
            >
              <li className={togglesidebar ? classes.sideNaveBarli : ``}>
                <Button variant="text" className={classes.linkburron}>
                  <ReferEarnIcon /> {togglesidebar ? ln.refer_earn : null}
                </Button>
              </li>
            </Link>
            <Link
              to="/help-faq"
              className={
                currentRoute.includes("help-faq") ? `${classes.activebtn}` : ``
              }
              onClick={() => handleNavigate(ln.help_faq, "/help-faq")}
            >
              <li className={togglesidebar ? classes.sideNaveBarli : ``}>
                <Button variant="text" className={classes.linkburron}>
                  <Help_FAQ_Icon /> {togglesidebar ? ln.help_faq : null}
                </Button>
              </li>
            </Link>
            <Link
              to="/demo"
              className={
                currentRoute.includes("demo") ? `${classes.activebtn}` : ``
              }
              onClick={() => handleNavigate(ln.demo, "/demo")}
            >
              <li className={togglesidebar ? classes.sideNaveBarli : ``}>
                <Button
                  variant="text"
                  // onClick={() => handleNavigate("", "/home")}
                  className={classes.linkburron}
                >
                  <DemoIcon /> {togglesidebar ? ln.demo : null}
                </Button>
              </li>
            </Link>
          </div>
        </div>
      ) : null}
      {/* Sidebar */}
    </div>
  );
};

export default SideNavBar;
