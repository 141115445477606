import React, { useEffect, useState } from "react";
import ImageCarosel from "../../../components/ImageCarosel";
import classes from "./index.module.css";
import AppLogo from "../../../assets/images/mainLogo.png";
import { pageLoader } from "../../../Redux/actions/user-actions";
import { api } from "../../../api-calls/user-requests";
import { UPDATE_WORK_INFO } from "../../../api-calls/api-urls";
import {
  Alphabetic,
  Numeric,
  fetchLocation,
} from "../../../utilities/commonFuntions";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import "../../../assets/css/style.css";
import "../../../assets/css/bootstrap.min.css";
import { Card, MenuItem, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagedataFunction } from "../../../utilities/language";

const RegisterCompany = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [companyName, setCompanyName] = useState("");
  const [designation, setDegignation] = useState("");
  const [GSTnumber, setGSTnumber] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [disabled, setDisabled] = useState("none");
  const [pincode, setPincode] = useState("");
  const [industry, setIndustry] = useState("plumbing");
  const [industryName, setIndustryName] = useState("");
  const [location, setLocation] = useState();
  const regUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
  const Industries = [
    { value: "plumbing", label: "Plumbing" },
    { value: "electrical", label: "Electrical" },
    { value: "realestate", label: "Real estate" },
  ];

  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  useEffect(() => {
    if (pincode && pincode.length === 6) {
      const res = fetchLocation(pincode, setLocation);
    } else {
      setLocation();
    }
  }, [pincode]);

  const handleSubmit = async () => {
    console.log("triggered");
    if (companyName !== "" && address1 !== "" && pincode !== "") {
      const userEntry = {
        user_id: regUser._id,
        company_name: companyName,
        designation: designation,
        gst_number: GSTnumber,
        address_one: address1,
        address_two: address2,
        pin: pincode,
        industry: industry !== "others" ? industry : industryName,
      };
      dispatch(pageLoader(true));
      const { data } = await api.post(UPDATE_WORK_INFO, userEntry);
      sessionStorage.setItem("CompanyDatas", JSON.stringify(data));
      navigate("/set-password");
    }
  };
  const handleIndustry = (value) => {
    setIndustry(value);
    if (value !== "others") {
      setIndustryName("");
      setDisabled("none");
    } else {
      setDisabled("block");
    }
  };
  return (
    <div className={classes.maindivForlogin}>
      {ln ? (
        <Card className={classes.SelectLanguage_maindiv}>
          <div>
            <ImageCarosel />
          </div>
          <div className="col-lg-12 scrollbaroverflow">
            <div style={{ padding: "3rem" }}>
              <div className="text-left">
                <span className="logo-lg mb-4">
                  <img src={AppLogo} alt="" height="50" />
                </span>
                <Typography className={`mt-3 ${classes.activeLAnguageBtn}`}>
                  {ln.verified_email_and_mobile}
                </Typography>
                <div className="d-flex">
                  <p className={`mt-2 ${classes.boldContainer}`}>
                    <span className={classes.loginDescription}>
                      +91 {regUser?.phone}
                    </span>{" "}
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z"
                        fill="#0A1F41"
                      />
                    </svg>
                  </p>
                  &nbsp; &nbsp;
                  {regUser ? (
                    <p className={`mt-2 ${classes.boldContainer}`}>
                      <span className={classes.loginDescription}>
                        {regUser?.email}
                      </span>{" "}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z"
                          fill="#0A1F41"
                        />
                      </svg>
                    </p>
                  ) : null}
                </div>
              </div>
              <Typography className={`mt-4 ${classes.loginHeading}`}>
                {ln.company_details}
              </Typography>
              <Typography className={`mt-4 ${classes.loginDescription}`}>
                {ln.enter_companydetails_to_continue}
              </Typography>
              <ValidatorForm useref="form" onSubmit={console.log("triggered")}>
                <div className="mt-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="firstNameinput"
                          className={classes.login_form_lable}
                        >
                          {ln.company_name}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          id="firstNameinput"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                          onKeyPress={(e) => Alphabetic(e)}
                          size="small"
                          validators={["required"]}
                          errorMessages={["Company Name is required"]}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="lastNameinput"
                          className={classes.login_form_lable}
                        >
                          {ln.designation} ({ln.optional})
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          id="lastNameinput"
                          value={designation}
                          onChange={(e) => setDegignation(e.target.value)}
                          onKeyPress={(e) => Alphabetic(e)}
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="phonenumberInput"
                          className={classes.login_form_lable}
                        >
                          {ln.gst_number} ({ln.optional})
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          id="phonenumberInput"
                          value={GSTnumber}
                          onChange={(e) => setGSTnumber(e.target.value)}
                          size="small"
                          validators={[
                            "matchRegexp:^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
                          ]}
                          errorMessages={["This is not a valid GST Number"]}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="emailidInput"
                          className={classes.login_form_lable}
                        >
                          {ln.address1}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          id="emailidInput"
                          value={address1}
                          onChange={(e) => setAddress1(e.target.value)}
                          size="small"
                          validators={["required"]}
                          errorMessages={["Address 1 is required"]}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="address1ControlTextarea"
                          className={classes.login_form_lable}
                        >
                          {ln.address2} ({ln.optional})
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          id="address1ControlTextarea"
                          value={address2}
                          onChange={(e) => setAddress2(e.target.value)}
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3" style={{ position: "relative" }}>
                        <label
                          htmlFor="address1ControlTextarea"
                          className={classes.login_form_lable}
                        >
                          {ln.pin}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          id="address1ControlTextarea"
                          value={pincode}
                          onChange={(e) => setPincode(e.target.value)}
                          onKeyPress={(e) => Numeric(e)}
                          size="small"
                          inputProps={{
                            maxLength: 6,
                          }}
                          validators={["required"]}
                          errorMessages={["PinCode is required"]}
                        />
                        {location && (
                          <Typography
                            className={`${classes.login_form_lable} ${classes.countryPinLable}`}
                          >
                            {location}
                          </Typography>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="address1ControlTextarea"
                          className={classes.login_form_lable}
                        >
                          {ln.industry}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          select
                          value={industry}
                          onChange={(e) => handleIndustry(e.target.value)}
                          required={true}
                          size="small"
                        >
                          {Industries.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                          <MenuItem key={"others"} value={"others"}>
                            Others
                          </MenuItem>
                        </TextValidator>
                      </div>
                    </div>
                    <div className="col-md-6" style={{ display: disabled }}>
                      <div className="mb-3">
                        <label
                          htmlFor="address1ControlTextarea"
                          className={classes.login_form_lable}
                        >
                          {ln.industry_name}
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "100%",
                          }}
                          id="address1ControlTextarea"
                          value={industryName}
                          onChange={(e) => setIndustryName(e.target.value)}
                          onKeyPress={(e) => Alphabetic(e)}
                          size="small"
                          validators={["required"]}
                          errorMessages={["Industry Name is is required"]}
                        />
                      </div>
                    </div>
                    {/* <!--end col--> */}
                  </div>
                  {/* <!--end col--> */}
                </div>
                <div className="mt-4">
                  <Button
                    type="submit"
                    variant="contained"
                    className={`${classes.commonbtn}`}
                    onClick={() => handleSubmit()}
                  >
                    {ln.continue}
                  </Button>
                </div>
              </ValidatorForm>
            </div>
          </div>
        </Card>
      ) : null}
    </div>
  );
};

export default RegisterCompany;
