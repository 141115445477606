import React, { useState, useEffect } from "react";
import classes from "./index.module.css";
import { Breadcrumbs, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const BreadCrumbs = (props) => {
  const navigate = useNavigate();
  const [breadcrumbs, setBrudcrumbs] = useState();
  useEffect(() => {
    const path = window.location.pathname;
    const pathSegments = path.split("/").filter((segment) => segment !== "");
    const arr = [];
    pathSegments.forEach((segment, index) => {
      // Create a breadcrumb item
      const breadcrumb = {
        label: segment, // Use the segment as the label
        url: `/${pathSegments.slice(0, index + 1).join("/")}`, // Build the URL for the breadcrumb
      };
      arr.push(breadcrumb);
    });
    setBrudcrumbs(arr);
  }, []);
  return (
    <div>
      {breadcrumbs && breadcrumbs.length > 0 ? (
        <div className={classes.breadcrumMaindiv}>
          <BsArrowLeftShort
            onClick={() => navigate(-1)}
            className={classes.backicon}
          />
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{ marginTop: "10px" }}
          >
            {breadcrumbs.map((item, index) => {
              return (
                <Link
                  underline="hover"
                  key={index}
                  color="inherit"
                  href={`${item.url}`}
                  style={{ textTransform: "capitalize" }}
                >
                  {item?.label?.replaceAll("-", " ")}
                </Link>
              );
            })}
          </Breadcrumbs>
        </div>
      ) : null}
    </div>
  );
};

export default BreadCrumbs;
