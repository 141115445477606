import React from "react";
import classes from "./index.module.css";
import { Avatar, Typography, Button } from "@mui/material";
import { generateColor, stringAvatar } from "../utilities/commonFuntions";
import {
  GET_NOTIFICATIONS,
  ImageUrl,
  UPDATE_NOTIFICATION,
} from "../api-calls/api-urls";
import { authApi, getAuthorizationHeader } from "../api-calls/user-requests";
import { setNotificationList } from "../Redux/actions/user-actions";
import { useDispatch } from "react-redux";

const NotificationItem = ({ item, handleMarkAsRead }) => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const getTimeDiff = () => {
    const startDate = new Date(item.createdAt);
    const endDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = endDate - startDate;

    // Calculate time difference in minutes, hours, and days
    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (minutes < 60) {
      return `${minutes} minutes`;
    } else if (hours < 24) {
      return `${hours} hours`;
    } else {
      return `${days} days`;
    }
  };
  return (
    <div className={classes.NotificationItemMainDiv}>
      <div>
        {item.from_user_id.profile_pic ? (
          <Avatar
            alt="Remy Sharp"
            src={`${process.env.REACT_APP_IMAGE_URL}/${item.from_user_id?.profile_pic?.destination}${item.from_user_id?.profile_pic?.filename}`}
            sx={{ width: 32, height: 32 }}
          />
        ) : (
          <Avatar
            {...stringAvatar(item.from_user_id.name)}
            sx={{
              bgcolor: `${generateColor(item.from_user_id.name)}`,
              width: 32,
              height: 32,
              fontSize: 13,
              fontWeight: 700,
            }}
          ></Avatar>
        )}
      </div>
      <div style={{ width: "65%" }}>
        <Typography className={classes.HeaderMenuItem}>
          {item.subject}
        </Typography>
        <Typography className={classes.notificationMessgeText}>
          {item.message}
        </Typography>
      </div>
      <Typography
        className={`${classes.notificationMessgeText} ${classes.notificationTime}`}
      >{`${getTimeDiff()} Ago`}</Typography>
      {/* {item.is_seen === false && (
        <Button
          variant="text"
          className={`${classes.TooltipmarkText} ${classes.markAsReadBtn}`}
          onClick={() => handleMarkAsRead(item._id)}
        >
          Mark as read
        </Button>
      )} */}
      {item.is_seen === false && (
        <Button
          variant="contained"
          className={`${classes.TooltipmarkText} ${classes.NewBtn}`}
          onClick={() => handleMarkAsRead(item._id)}
        >
          New
        </Button>
      )}
    </div>
  );
};

export default NotificationItem;
