import React, { useState, useEffect } from "react";
import ImageCarosel from "../../../components/ImageCarosel";
import classes from "./index.module.css";
import { GrFormEdit } from "react-icons/gr";
import lineDraw from "../../../assets/images/Wizardemail.png";
import OtpInput from "react-otp-input";
import AppLogo from "../../../assets/images/mainLogo.png";
import { ValidatorForm } from "react-material-ui-form-validator";
import "../../../assets/css/style.css";
import "../../../assets/css/bootstrap.min.css";
import { api } from "../../../api-calls/user-requests";
import { pageLoader } from "../../../Redux/actions/user-actions";
import {
  GENERATE_FORGET_PASSWORD_OTP,
  VERIFY_EMAIL,
} from "../../../api-calls/api-urls";
import { Card, TextField, Typography, Snackbar, Alert } from "@mui/material";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Numeric } from "../../../utilities/commonFuntions";
import { getLanguagedataFunction } from "../../../utilities/language";
import UpdateModal from "./updateModal";
import AlertBox from "../../../components/AlertBox";

const Otp2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Otp, setOtp] = useState("");
  const [time, setTime] = useState(15);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const regUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
  const [moddleToggle, setModdleToggle] = useState(false);
  const [email_otp, setEmail_otp] = useState();
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  const [disable, setDisable] = useState({
    name: ln ? `${ln.resend_in} ${time}${ln.seconds}` : null,
    state: true,
  });

  useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Change the duration (in milliseconds) as per your requirement

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showAlert]);

  const handleSubmit = async () => {
    const userEntry = {
      //userid: regUser._id,
      email_otp: Otp,
    };
    try {
      dispatch(pageLoader(true));
      const { data } = await api.post(VERIFY_EMAIL, userEntry);
      navigate("/register-company");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setShowAlert(true);
        setError(error.response.data.message);
      } else {
        // Handle other errors
        // ...
      }
    }
  };
  const handleOtp = async () => {
    const userEntry = {
      email: regUser.email,
      user_id: regUser._id,
    };
    const { data } = await api.post(GENERATE_FORGET_PASSWORD_OTP, userEntry);
    //sessionStorage.setItem("otp", JSON.stringify(data));
    setEmail_otp(data.email_otp);
  };
  const handleResetClick = async () => {
    setOtp(null);
    handleOtp();
    setTime(15);
  };
  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevCount) => prevCount - 1);
      setDisable({
        name: ln ? `${ln.resend_in} ${time}${ln.seconds}` : null,
        state: true,
      });
    }, 1000);

    if (time === 0) {
      clearInterval(timer);
      setDisable({
        name: `${ln.resend_otp}`,
        state: false,
      });
    }

    return () => {
      clearInterval(timer);
    };
  }, [time]);
  const handleOnchange = (e) => {
    const re = /^\d*$/;
    if (e === "" || re.test(e)) {
      setOtp(e);
    }
  };

  const closeModal = () => {
    setModdleToggle(false);
  };

  useEffect(() => {
    if (regUser) {
      setEmail_otp(regUser.email_otp);
    }
  }, []);

  return (
    <div className={classes.maindivForlogin}>
      {ln ? (
        <Card className={classes.SelectLanguage_maindiv}>
          <div>
            <ImageCarosel />
          </div>
          <ValidatorForm useref="form" onSubmit={handleSubmit}>
            <div className="col-lg-12">
              <div style={{ padding: "3rem" }}>
                <div className="text-left">
                  <span className="logo-lg mb-5">
                    <img src={AppLogo} alt="" height="50" />
                  </span>
                </div>
                {/* <!-- multistep form --> */}

                <div className="wrapper">
                  <div className="header1 mt-4">
                    <img
                      src={lineDraw}
                      alt=""
                      className={classes.wizardImage}
                    />
                    {/* <ul>
                      <li className="active form_1_progessbar">
                        <div>
                          <p className="ule"></p>
                          <span className={`mt-2 ${classes.mobiunactive}`}>
                            {ln.verify_mobile}
                          </span>
                        </div>
                      </li>
                      <li className="form_2_progessbar">
                        <div>
                          <p></p>
                          <span
                            className={`mt-2 ${classes.mobiunactive} ${classes.emailActive}`}
                          >
                            {ln.verify_email}
                          </span>
                        </div>
                      </li>
                    </ul> */}
                  </div>
                  <div className="form_wrap">
                    <div className="form_1 data_info">
                      <div>
                        <div className="mb-4">
                          <Typography
                            className={`mt-4 ${classes.loginHeading}`}
                          >
                            {ln.verify_otp}
                          </Typography>
                          <Typography
                            className={`mt-4 ${classes.loginDescription}`}
                          >
                            {ln.enter_otp_email} <br />
                            <b className={classes.boldContainer}>
                              <span className={classes.mobileNumber}>
                                {regUser.email}
                              </span>{" "}
                              <span onClick={() => setModdleToggle(true)}>
                                <GrFormEdit />
                              </span>
                            </b>
                          </Typography>
                          {regUser ? (
                            <Typography>
                              Your Email OTP is: {email_otp}
                            </Typography>
                          ) : null}
                        </div>
                        <div className={`otp ${classes.OtpFeildsContainer}`}>
                          <OtpInput
                            value={Otp}
                            onChange={(e) => handleOnchange(e)}
                            numInputs={4}
                            renderInput={(props) => <input {...props} />}
                            isInputNum={true}
                          />
                        </div>
                        <div className="mt-4">
                          <p className={`mb-0 ${classes.login_form_lable}`}>
                            {ln.didnt_recieve_otp}
                            <Button
                              disabled={disable.state}
                              className={`${classes.login_form_lable} ${classes.remember_input} ${classes.forgotbtn}`}
                              variant="text"
                              sx={{
                                "&.Mui-disabled": {
                                  color: "#0000ff73 !important",
                                },
                              }}
                              style={{
                                color: "blue",
                                cursor: "pointer",
                                border: "none",
                                textTransform: "none",
                                height: "56px",
                              }}
                              onClick={() => handleResetClick()}
                            >
                              {disable.name}
                            </Button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    {/* <div className="common_btns form_1_btns">
                    <button
                      type="submit"
                      className="btn btn-primary btn_next w-100"
                    >
                      Continue
                      <span className="icon">
                        <ion-icon name="arrow-forward-sharp"></ion-icon>
                      </span>
                    </button>
                  </div> */}
                    <div className="common_btns form_2_btns">
                      <Button
                        disabled={Otp && Otp.length === 4 ? false : true}
                        type="submit"
                        fullwidth
                        className={`btn btn-primary w-100 ${classes.commonbtn}`}
                      >
                        {ln.continue}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ValidatorForm>
        </Card>
      ) : null}
      {moddleToggle && (
        <UpdateModal
          type={"email"}
          closeModal={closeModal}
          handleResetClick={handleResetClick}
          value={regUser.email}
        />
      )}
      {showAlert && error && (
        <AlertBox type="error" message={error} stateName={setShowAlert} />
      )}
    </div>
  );
};

export default Otp2;
