import React, { useEffect, useState } from "react";
import {
  Paper,
  TextField,
  Typography,
  Button,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import classes from "./location.module.css";
import { MdClose } from "react-icons/md";
import { Avatar } from "@mui/material";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { Form, NavItem } from "react-bootstrap";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Basic from "./DragAndDrop";
import { useDispatch, useSelector } from "react-redux";
import {
  api,
  authApi,
  authImageApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import {
  GET_USER,
  LOCATION,
  TEAM_MEMBERS_LIST,
  UPLOAD_IMAGE,
} from "../../../api-calls/api-urls";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SaveAudio,
  saveLocationList,
} from "../../../Redux/actions/location-action";
import RecorderCard from "./audioRecorder";
import AssignLaterModel from "./AssignLaterModal";
import { getLanguagedataFunction } from "../../../utilities/language";
import { BsArrowLeftShort } from "react-icons/bs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  AboutusIcon,
  BrudCrumbBackIcon,
  BrudCrumbNextIcon,
} from "../../../components/Icons";
import AlertBox from "../../../components/AlertBox";
import { CheckBox } from "@mui/icons-material";

const CreateLocation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalToggle, setModalToggle] = useState(false);
  const [checked, setChecked] = useState();
  const [title, setTitle] = useState();
  const [teamMember, setTeamMember] = useState("");
  const [teamMemberName, setTeamMemberName] = useState();
  const [address, setAddress] = useState();
  const [discription, setDiscription] = useState();
  const [success, setSuccess] = useState(false);
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const [showAlert, setShowAlert] = useState(false);
  const [isSaveAddress, setIsSaveAddress] = useState(false);
  const [mapLocation, setMapLocation] = useState();
  const [latitude, setLatitude] = useState();
  const [update, setUpdate] = useState();
  const [data, setData] = useState();
  const [langitude, setLangitude] = useState();
  const [error, setError] = useState();
  const [teamMembersList, setTeamMembersList] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState();
  const [editLocation, setEditLocation] = useState();
  const [images, setImages] = useState();
  const [loggedUser, setLoggedUser] = useState();
  const imageList = useSelector((state) => state.reducers.location.imageList);
  const audioFile = useSelector((state) => state.reducers.location.audioList);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  const use_location = useLocation();
  const loc_detail = use_location?.state?.loc_details;


  console.log("loc_detail =",loc_detail)

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const getCurrentLoggedUser = async () => {
    const { data } = await authApi.get(`${GET_USER}/${userInfo._id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    setLoggedUser(data);
  };

  useEffect(() => {
    getTeamMemberName();
  }, [teamMember, editLocation]);

  console.log(isSaveAddress,'98')
  useEffect(() => {
    if (loc_detail) {
      
      let map_link = `https://www.google.com/maps/place/@${loc_detail?.location_info?.lat},${loc_detail?.location_info?.lng}/@${loc_detail?.location_info?.lat},${loc_detail?.location_info?.lng},16z`
      setTitle(loc_detail?.customer_name ?? null);
      setAddress(loc_detail?.address ?? null);
      setMapLocation(map_link ?? null);
      setIsSaveAddress(true);
    }
  }, []);

  const getTeamMemberName = async () => {
    if ((teamMember && teamMember !== "") || editLocation) {
      const teamMemberData = await teamMembersList.find(
        (item) => item.user_id === teamMember
      );
      console.log(teamMemberData);
      setTeamMemberName(teamMemberData?.name);
    }
  };

  useEffect(() => {
    getCurrentLoggedUser();
  }, [!loggedUser]);

  useEffect(() => {}, [audioFile]);

  useEffect(() => {
    if (loggedUser) {
      getTeamMembersData();
    }
  }, [!teamMembersList, loggedUser]);

  const getTeamMembersData = async () => {
    const { data } = await authApi.get(
      `${TEAM_MEMBERS_LIST}/${loggedUser.company}`,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    if (data.length > 0) {
      setTeamMembersList(data);
    }
  };

  const getOneLocationdata = async (path) => {
    const arr = path.split("/");
    const { data } = await authApi.get(`${LOCATION}/${arr[arr.length - 1]}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    setEditLocation(data.data);
  };

  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("/update")) {
      getOneLocationdata(path.toLowerCase());
      setUpdate(true);
    } else {
      dispatch(saveLocationList([]));
    }
  }, [!editLocation]);

  useEffect(() => {
    if (editLocation) {
      console.log(editLocation.employee.user_id);
      setTitle(editLocation.title);
      setTeamMember(editLocation?.employee?.user_id);
      setDiscription(editLocation.description);
      setAddress(editLocation.address);
      setChecked(editLocation.required_confirmation);
      setImages(editLocation?.images);
      //   // setSelectedFiles(editLocation.images);
      // if (editLocation.images && editLocation.images.length > 0) {
      //   // setImages(editLocation?.images);
      //   // setSelectedFiles(editLocation.images);
      // }
      // else {
      // setImages();
      // setSelectedFiles();
      // }
      if (
        editLocation.location_info &&
        editLocation.location_info.lat &&
        editLocation.location_info.lng
      ) {
        setMapLocation(
          `https://www.google.com/maps/place/@${editLocation.location_info.lat},${editLocation.location_info.lng}/@${editLocation.location_info.lat},${editLocation.location_info.lng},16z`
        );
      }
    }
  }, [editLocation]);

  const handlemapLocation = (value) => {
    let data = {};
    //setMapLocation(value);
    const regex = /@([-+]?\d+\.\d+),([-+]?\d+\.\d+)/;
    const match = value.match(regex);

    if (match && match.length === 3) {
      const extractedLatitude = parseFloat(match[1]);
      const extractedLongitude = parseFloat(match[2]);

      data.lat = extractedLatitude;
      data.lng = extractedLongitude;
    } else {
      data.error = "This Map Url is not a Correct Url Please Enter Proper Url";
    }
    return data;
  };

  useEffect(() => {
    dispatch(SaveAudio());
  }, []);

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Change the duration (in milliseconds) as per your requirement

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showAlert]);

  const getLocationData = async () => {
    const userEntry = {};
    const { data } = await authApi.post(LOCATION, userEntry, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    dispatch(saveLocationList(data));
    navigate("/location");
  };

  const closeModal = (status1, status2) => {
    console.log(status1, status2);
    setModalToggle(false);
    if (status2) {
      getLocationData();
      navigate("/location");
    }
  };
  const CreateimageArray = async (items) => {
    const filterArray1 = await items?.filter(
      (item) => !item.destination && !item.filename
    );
    const filterArray2 =
      (await items?.filter((item) => item.destination && item.filename)) || [];
    const array = filterArray1?.map(async (file) => {
      try {
        let formData = new FormData();
        formData.append("file", file);
        const { data } = await authImageApi.post(UPLOAD_IMAGE, formData, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        return data.data;
      } catch (err) {
        console.log("error ====> ", err);
      }
    });
    if (array && array.length > 0) {
      const results = await Promise.all(array);
      const finalArray = [...filterArray2, ...results];
      return finalArray;
    } else {
      return [...filterArray2];
    }
    //return results;
    // setFiles([...files, ...results]);
    // dispatch(saveImageList([...files, ...results]));
  };
  console.log("mapLocation =",mapLocation)
  const handleAssignNow = async ()=>{
    const data = await handlemapLocation(mapLocation.toString());
    var Buildimages = await CreateimageArray(selectedFiles);
    if (data.error) {
      setError(data.error);
      setShowAlert(true);
    } else {
      if (teamMember) {
        const userEntry = {
          title: title,
          address: address,
          assigned_on: new Date(),
          description: discription,
          location_info: {
            lat: data.lat,
            lng: data.lng,
          },
          required_confirmation: checked,
          audio_note: audioFile,
          assigned_to: teamMember,
          images: Buildimages,
        };
        if (editLocation) {
          try {
            const { data } = await authApi.put(
              `${LOCATION}/${editLocation._id}`,
              userEntry,
              {
                headers: { Authorization: getAuthorizationHeader() },
              }
            );
            setModalToggle(true);
            setSuccess(true);
          } catch (error) {
            setShowAlert(true);
            console.log(error);
            setError(error?.response?.data?.message);
          }
        } else {
          try {
            console.log(userEntry);
            const { data } = await authApi.post(
              `${LOCATION}/create`,
              userEntry,
              {
                headers: { Authorization: getAuthorizationHeader() },
              }
            );
            console.log(data);
            setModalToggle(true);
            setSuccess(true);
          } catch (error) {
            console.log("error happens");
            setShowAlert(true);
            if (error.response) {
              // Log the response status code and data
              console.error("Response Status:", error.response.status);
              console.error("Response Data:", error.response.data);
              setError(error.response.data.message); // You can set a more specific error message here
            } else if (error.request) {
              // The request was made, but no response was received
              console.error("Request Error:", error.request);
              setError("No response from the server.");
            } else {
              // Something else happened while setting up the request
              console.error("Other Error:", error.message);
              setError("An error occurred while making the request.");
            }
          }
        }
        // getLocationData();
      } else {
        setShowAlert(true);
        setError("Please Select Team Member");
      }
    }
  }

  const handleSubmit = async () => {
    if (loc_detail && !isSaveAddress) {
      let address_id = loc_detail?._id;
      const { data } = await authApi.delete(`/addressbook/${address_id}`, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      handleAssignNow();
    } else {
      handleAssignNow();
    }
  };

  const onDropFunction = (acceptedFiles) => {
    setSelectedFiles(acceptedFiles);
  };

  const handleAssignLater = async () => {
    var Buildimages =
      selectedFiles && selectedFiles.length > 0
        ? await CreateimageArray(selectedFiles)
        : null;
    const data = await handlemapLocation(mapLocation);
    const userEntry = {
      title: title,
      address: address,
      assigned_on: new Date(),
      description: discription,
      location_info: {
        lat: data.lat,
        lng: data.lng,
      },
      required_confirmation: checked,
      audio_note: audioFile,
      assigned_to: teamMember,
      images: Buildimages,
    };
    console.log(userEntry);
    setData(userEntry);
    setModalToggle(true);
  };
  return (
    <div>
      {ln ? (
        <div>
          <div>
            <div className={`${classes.brudCrumb} mb-4 `}>
              <span onClick={() => navigate(-1)}>
                <BrudCrumbBackIcon />
              </span>
              <span
                onClick={() => navigate("/location")}
                className={classes.form_lable}
                style={{ color: "#0A1F41" }}
              >
                {ln.location}
              </span>
              <BrudCrumbNextIcon />
              <span className={classes.form_lable}>
                {editLocation ? editLocation.title : ln.assign_a_location}
              </span>
            </div>
          </div>
          {loc_detail ?
        <Paper className={classes.CreateTeamindex}>
        <Typography className={classes.createLocationheading1}>
          Address
        </Typography>
        <ValidatorForm useref="form" onSubmit={handleSubmit}>
          <div>
          <div className={classes.locationTopGrid}>
              <div className="mb-3">
                <label
                  htmlFor="firstNameinput"
                  className={classes.form_lable}
                >
                  {ln.title}
                </label>
                <h6><b>{loc_detail?.customer_name}</b></h6>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="firstNameinput"
                  className={classes.form_lable}
                >
                  Mobile Number
                </label>
                <h6><b>{loc_detail?.mobile_number || "n/a"}</b></h6>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="firstNameinput"
                  className={classes.form_lable}
                >
                   {ln.address}
                </label>
                <h6><b>{loc_detail?.address ?? "n/a"}</b></h6>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="firstNameinput"
                  className={classes.form_lable}
                >
                 {ln.map_location}  
                </label>
                <a href={`https://www.google.com/maps?q=${loc_detail?.location_info?.lat},${loc_detail?.location_info?.lng}`} target="_blank" className={classes.map_links}>{mapLocation}</a>
              </div>
             </div>
             <hr />
             <div style={{marginTop:"25px"}}>
                  <Form.Check
                    style={{ marginRight: "5px" }}
                    className={classes.RequireConfirmCheckbox}
                    inline
                    checked={isSaveAddress}
                    onClick={(e) => setIsSaveAddress(!isSaveAddress)}
                    label={"Save to address book "}
                    name="group1"
                    type="checkbox"
                  />
              </div>
             <Typography fontWeight={500} fontSize={"18px"} mt={4}>
          Other Details
        </Typography>
              <div className={classes.locationMainGrid}>
              <div className="mb-3">
                    <label
                      htmlFor="firstNameinput"
                      className={classes.form_lable}
                    >
                      {ln.team_member}
                    </label>
                    <Select
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      className={classes.selectMenuDepartment}
                      fullWidth
                      MenuProps={{
                        style: {
                          height: "250px",
                        },
                      }}
                      size="small"
                      disabled={editLocation ? true : false}
                      value={
                        editLocation && editLocation.employee
                          ? editLocation.employee.user_id
                          : teamMember
                      }
                      onChange={(e) => {
                        setTeamMember(e.target.value);
                        console.log(e.target.value);
                      }}
                    >
                      {teamMembersList && teamMembersList.length === 0 && (
                        <MenuItem value="">No Member Found</MenuItem>
                      )}
                      {teamMembersList &&
                        teamMembersList.length > 0 &&
                        teamMembersList.map((item, index) => (
                          <MenuItem
                            className={classes.AddMemberDepMenuItem}
                            value={item.user_id}
                            key={index}
                            //onClick={(e) => setTeamMember(item.department_name)}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
              <div className="mb-3">
                <label
                  htmlFor="firstNameinput"
                  className={classes.form_lable}
                >
                  {ln.description}({ln.optional})
                </label>
                <TextField
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  id="outlined-multiline-static"
                  value={discription}
                  multiline
                  rows={3}
                  fullWidth
                  onChange={(e) => setDiscription(e.target.value)}
                />
              </div>
              <div className="mb-3" style={{marginTop:"25px"}}>
                <div  >
                  <Form.Check
                    style={{ marginRight: "5px" }}
                    className={classes.RequireConfirmCheckbox}
                    inline
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                    label={ln.required_confirmation}
                    name="group1"
                    type="checkbox"
                  />
                  <AboutusIcon />
                </div>
              </div>
            </div>
            <div>
              <p
                className={`${classes.createLocationheading1} ${classes.createLocationheading2}`}
              >
                {ln.add_audio_note}
              </p>
              <div style={{ height: "auto", marginBottom: "30px" }}>
                <RecorderCard audio={""} />
              </div>
            </div>
            <div>
              <p
                className={`${classes.createLocationheading1} ${classes.createLocationheading2}`}
              >
                {ln.add_images}
              </p>
              <div>
                <div className={classes.DropZoneContainer}>
                  {update ? (
                    <div>
                      {images && images.length > 0 ? (
                        <Basic
                          images={images}
                          onDropFunction={onDropFunction}
                        />
                      ) : (
                        <Basic onDropFunction={onDropFunction} />
                      )}
                    </div>
                  ) : (
                    <Basic onDropFunction={onDropFunction} />
                  )}
                  {/* <input
                type="file"
                onChange={(e) => console.log(e.target.files[0])}
              /> */}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.createLocationbtnsectin}>
            <Button variant="outlined" onClick={() => handleAssignLater()}>
              {ln.assign_later}
            </Button>
            <Button variant="contained" type="submit">
              {ln.assign_now}
            </Button>
          </div>
        </ValidatorForm>
      </Paper> :
      
      <Paper className={classes.CreateTeamindex}>
            <Typography className={classes.createLocationheading1}>
              {ln.assign_a_location}
            </Typography>
            <ValidatorForm useref="form" onSubmit={handleSubmit}>
              <div>
                <div className={classes.locationMainGrid}>
                  <div className="mb-3">
                    <label
                      htmlFor="firstNameinput"
                      className={classes.form_lable}
                    >
                      {ln.title}
                    </label>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      id="title"
                      value={title}
                      fullWidth
                      onChange={(e) => setTitle(e.target.value)}
                      size="small"
                      validators={["required"]}
                      errorMessages={["Title is required"]}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="firstNameinput"
                      className={classes.form_lable}
                    >
                      {ln.team_member}
                    </label>
                    <Select
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      className={classes.selectMenuDepartment}
                      fullWidth
                      MenuProps={{
                        style: {
                          height: "250px",
                        },
                      }}
                      size="small"
                      disabled={editLocation ? true : false}
                      value={
                        editLocation && editLocation.employee
                          ? editLocation.employee.user_id
                          : teamMember
                      }
                      onChange={(e) => {
                        setTeamMember(e.target.value);
                        console.log(e.target.value);
                      }}
                    >
                      {teamMembersList && teamMembersList.length === 0 && (
                        <MenuItem value="">No Member Found</MenuItem>
                      )}
                      {teamMembersList &&
                        teamMembersList.length > 0 &&
                        teamMembersList.map((item, index) => (
                          <MenuItem
                            className={classes.AddMemberDepMenuItem}
                            value={item.user_id}
                            key={index}
                            //onClick={(e) => setTeamMember(item.department_name)}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                    {/* <Select
                      fullWidth
                      size="small"
                      value={editLocation && editLocation.employee ? editLocation.employee.user_id : teamMember}
                      onChange={(e) => { setTeamMember(e.target.value); console.log(e.target.value); }}
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                      {/* <div className={classes.CommunicationDropDownmain}> */}

                    {/* </div> */}
                    {/* </Select> */}
                    {/* <Select
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      className={classes.selectMenuDepartment}
                      fullWidth
                      MenuProps={{
                        style: {
                          height: "250px",
                        },
                      }}
                      size="small"
                      value={teamMember}
                      onChange={(e) => { setTeamMember(e.target.value); console.log(e.target.value); }}
                      validators={["required"]}
                      errorMessages={["Team Member is required"]}
                    >
                      {teamMembersList && teamMembersList.length === 0 &&
                        <MenuItem value="">No Member Found</MenuItem>
                      }
                      {teamMembersList && teamMembersList.length > 0 && teamMembersList.map((item) => (
                        <MenuItem
                          className={classes.AddMemberDepMenuItem}
                          value={item.user_id}
                        //onClick={(e) => setTeamMember(item.department_name)}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select> */}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="firstNameinput"
                      className={classes.form_lable}
                    >
                      {ln.address}({ln.optional})
                    </label>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      id="address"
                      value={address}
                      fullWidth
                      onChange={(e) => setAddress(e.target.value)}
                      size="small"
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="firstNameinput"
                      className={classes.form_lable}
                    >
                      {ln.description}({ln.optional})
                    </label>
                    <TextField
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      id="outlined-multiline-static"
                      value={discription}
                      multiline
                      rows={3}
                      fullWidth
                      onChange={(e) => setDiscription(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="firstNameinput"
                      className={classes.form_lable}
                    >
                      {ln.map_location}
                    </label>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      id="outlined-multiline-static"
                      multiline
                      rows={3}
                      value={mapLocation}
                      fullWidth
                      onChange={(e) => setMapLocation(e.target.value)}
                      validators={["required"]}
                      errorMessages={["Google Map is required"]}
                    />
                  </div>
                  <div className="mb-3" style={{marginTop:"25px"}}>
                    <div>
                      <Form.Check
                        style={{ marginRight: "5px" }}
                        className={classes.RequireConfirmCheckbox}
                        inline
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                        label={ln.required_confirmation}
                        name="group1"
                        type="checkbox"
                      />
                      <AboutusIcon />
                    </div>
                  </div>
                </div>
                <div>
                  <p
                    className={`${classes.createLocationheading1} ${classes.createLocationheading2}`}
                  >
                    {ln.add_audio_note}
                  </p>
                  <div style={{ height: "auto", marginBottom: "30px" }}>
                    <RecorderCard audio={""} />
                  </div>
                </div>
                <div>
                  <p
                    className={`${classes.createLocationheading1} ${classes.createLocationheading2}`}
                  >
                    {ln.add_images}
                  </p>
                  <div>
                    <div className={classes.DropZoneContainer}>
                      {update ? (
                        <div>
                          {images && images.length > 0 ? (
                            <Basic
                              images={images}
                              onDropFunction={onDropFunction}
                            />
                          ) : (
                            <Basic onDropFunction={onDropFunction} />
                          )}
                        </div>
                      ) : (
                        <Basic onDropFunction={onDropFunction} />
                      )}
                      {/* <input
                    type="file"
                    onChange={(e) => console.log(e.target.files[0])}
                  /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.createLocationbtnsectin}>
                <Button variant="outlined" onClick={() => handleAssignLater()}>
                  {ln.assign_later}
                </Button>
                <Button variant="contained" type="submit">
                  {ln.assign_now}
                </Button>
              </div>
            </ValidatorForm>
          </Paper>
        }
          {modalToggle ? (
            <AssignLaterModel
              closeModal={closeModal}
              UserEntry={data}
              success={success}
              editLocation={editLocation}
              title={title}
              teamMemberName={teamMemberName}
            />
          ) : null}
          {showAlert && error && (
            <AlertBox type="error" message={error} stateName={setShowAlert} />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default CreateLocation;
