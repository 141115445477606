import { actionTypes } from "../constant/action-types";
const initialState = {
  imageList: [],
  locationList: [],
  locationFilteredStatus: [],
  locationFilterTime: "newest_to_oldest",
  audioList: null,
  locationFilterMembers: [],
  locationFilterRange: null,
  viewAll: false,
  totalcount: 0,
};

export const locationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.IMAGE_LIST:
      return { ...state, imageList: [...payload] };
    case actionTypes.LOCATION_LIST:
      return {
        ...state,
        locationList: payload.locations,
        totalcount: payload.totalCount,
      };
    case actionTypes.LOCATION_STATUS_FILTER:
      return { ...state, locationFilteredStatus: payload };
    case actionTypes.LOCATION_TIME_FILTER:
      return { ...state, locationFilterTime: payload };
    case actionTypes.LOCATION_MEMBERS_FILTER:
      return { ...state, locationFilterMembers: payload };
    case actionTypes.LOCATION_RANGE_FILTER:
      return { ...state, locationFilterRange: payload };
    case actionTypes.AUDIO_LIST:
      return { ...state, audioList: payload };
    case actionTypes.VIEW_ALL:
      return { ...state, viewAll: payload };
    default:
      return state;
  }
};
