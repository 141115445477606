import React, { useRef, useState } from "react";
import { Paper, Typography, TextField, Button } from "@mui/material";
import classes from "./index.module.css";
import image from "../../../assets/images/feedback.png";
import {
  authApi,
  authImageApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useNavigate } from "react-router-dom";
import { BrudCrumbBackIcon, DeleteBinIcons } from "../../../components/Icons";
import { useEffect } from "react";
import { setHeaderName } from "../../../Redux/actions/user-actions";
import { useDispatch, useSelector } from "react-redux";
import poor from "../../../assets/images/poor.png";
import unhappy from "../../../assets/images/unhappy.png";
import apathy from "../../../assets/images/apathy.png";
import happy from "../../../assets/images/happy.png";
import love from "../../../assets/images/love.png";
import { CREATE_FEEDBACK, UPLOAD_IMAGE } from "../../../api-calls/api-urls";
import { getLanguagedataFunction } from "../../../utilities/language";
import SuccessModal from "./successModal";
import AlertBox from "../../../components/AlertBox";

const Feedback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [subject, setSubject] = useState("");
  const [feedbackImage, setFeedBackImage] = useState("");
  const [error, setError] = useState("");
  const [modalToggle, setModalToggle] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const inputFile = useRef(null);
  const [feedback, setFeedback] = useState("");
  const [ratings, setRatings] = useState();
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    if (!ln) {
      getLanguagedataFunction(dispatch);
    }
  }, [ln, dispatch]);

  useEffect(() => {
    dispatch(setHeaderName(ln.feedback));
  }, [dispatch]);

  const onButtonClick = () => {
    inputFile.current.click();
  };
  const handleFileUpload = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      const filename = files[0].name;
      var parts = filename.split(".");
      const fileType = parts[parts.length - 1];
      console.log("fileType", fileType); //ex: zip, rar, jpg, svg etc.
      console.log(files);
      setFeedBackImage([...files]);
    }
  };

  const CreateimageArray = async (items) => {
    if (items && items.length > 0) {
      console.log(items);
      const array = items?.map(async (file) => {
        let formData = new FormData();
        formData.append("file", file);
        const { data } = await authImageApi.post(UPLOAD_IMAGE, formData, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        return data.data;
      });
      const results = await Promise.all(array);
      const finalArray = [...results];
      return finalArray;
    }
    //return results;
    // setFiles([...files, ...results]);
    // dispatch(saveImageList([...files, ...results]));
  };

  const handleSubmit = async () => {
    var Buildimages = await CreateimageArray(feedbackImage);
    const userEntry = {
      rating: ratings,
      subject: subject,
      message: feedback,
      images: Buildimages,
    };
    console.log(userEntry);
    try {
      const { data } = await authApi.post(CREATE_FEEDBACK, userEntry, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      console.log(data);
      setModalToggle(true);
      // clearData();
    } catch (err) {
      setError(err.response.data.message || err.response.data.error);
      setShowAlert(true);
    }
  };

  const closeForm = () => {
    setModalToggle(false);
    clearData();
  };

  const deleteFile = () => {
    setFeedBackImage([]);
  };

  const clearData = () => {
    setFeedBackImage();
    setFeedback("");
    setSubject("");
    setRatings();
  };

  return (
    <div>
      {ln && (
        <div>
          <div className={`${classes.brudCrumb} mb-4 `}>
            <span onClick={() => navigate(-1)}>
              <BrudCrumbBackIcon />
            </span>
            <span
              onClick={() => navigate("/location")}
              className={classes.form_lable}
              style={{ color: "#0A1F41" }}
            >
              {ln.feedback}
            </span>
          </div>
          <Paper className={classes.ContactusMaindiv}>
            <div>
              <Typography className={`mb-3 ${classes.mainText}`}>
                {ln.we_read_every_feedback_and_take_it_very_seriously}
              </Typography>
              <img src={image} alt="Avatar" width={"95%"} />
            </div>
            <Paper>
              <ValidatorForm
                useref="form"
                fullwidth
                onSubmit={handleSubmit}
                className={classes.ContactusForm}
              >
                <Typography className={`mb-4 ${classes.mainText}`}>
                  {ln.how_you_feel_app_today}
                </Typography>
                <div className={`mb-4 ${classes.feedbackEmojiContainer}`}>
                  <img
                    src={poor}
                    alt="Poor"
                    onClick={() => setRatings(1)}
                    className={ratings === 1 ? "" : classes.feedbackEmoji}
                  />
                  <img
                    src={unhappy}
                    alt="unhappy"
                    onClick={() => setRatings(2)}
                    className={ratings === 2 ? "" : classes.feedbackEmoji}
                  />
                  <img
                    src={apathy}
                    alt="apathy"
                    onClick={() => setRatings(3)}
                    className={ratings === 3 ? "" : classes.feedbackEmoji}
                  />
                  <img
                    src={happy}
                    alt="happy"
                    onClick={() => setRatings(4)}
                    className={ratings === 4 ? "" : classes.feedbackEmoji}
                  />
                  <img
                    src={love}
                    alt="love"
                    onClick={() => setRatings(5)}
                    className={ratings === 5 ? "" : classes.feedbackEmoji}
                  />
                </div>
                <div className="mb-4">
                  <Typography className={`mb-3 ${classes.mainText}`}>
                    {ln.would_you_like_to_share_something_with_us}?
                  </Typography>
                  <TextField
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    id="address"
                    value={subject}
                    fullWidth
                    className="mb-3"
                    placeholder="Subject"
                    onChange={(e) => setSubject(e.target.value)}
                    size="small"
                  />
                  <TextField
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    id="outlined-multiline-static"
                    value={feedback}
                    multiline
                    rows={4}
                    fullWidth
                    placeholder="Message"
                    onChange={(e) => setFeedback(e.target.value)}
                  />
                </div>
                <div>
                  <div className={`mb-1 ${classes.contactformdropSection}`}>
                    <input
                      style={{ display: "none" }}
                      // accept=".zip,.rar"
                      ref={inputFile}
                      onChange={handleFileUpload}
                      type="file"
                      multiple
                    />
                    <Typography
                      className={`${classes.contactdropText} ${classes.CuntactUsFormHeading}`}
                      onClick={onButtonClick}
                      style={{ cursor: "pointer" }}
                    >
                      {ln.add_image_and_documents}{" "}
                      <span className={classes.browseFileTexr}>
                        {ln.browse_files}
                      </span>
                    </Typography>
                  </div>
                  {feedbackImage && feedbackImage.length > 0 && (
                    <div className="d-flex justify-content-between align-items-center">
                      <Typography className={classes.fileText}>
                        {feedbackImage[0]?.name}
                      </Typography>
                      <span onClick={deleteFile}>
                        <DeleteBinIcons />{" "}
                      </span>
                    </div>
                  )}
                </div>
                <div className={`${classes.FeedbackBtnSection}`}>
                  <Button
                    variant="outlined"
                    className={classes.ContactusFormButton}
                    onClick={() => clearData()}
                  >
                    {ln.cancel}
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    className={classes.ContactusFormButton}
                  >
                    {ln.submit}
                  </Button>
                </div>
              </ValidatorForm>
            </Paper>
          </Paper>
        </div>
      )}
      {modalToggle ? (
        <SuccessModal modalClose={closeForm} type={"feedback"} />
      ) : null}
      {showAlert && error && (
        <AlertBox type="error" message={error} stateName={setShowAlert} />
      )}
    </div>
  );
};

export default Feedback;
