import { actionTypes } from "../constant/action-types";
const initialState = {
  holidays: [],
};

export const HolidayReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SAVE_HOLIDAYS_LIST:
      return { ...state, holidays: payload };
    default:
      return state;
  }
};
