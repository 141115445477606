import React, { useEffect, useState } from "react";
import classes from "./blogs.module.css";
import { api } from "../../../api-calls/user-requests";
import { Paper, Typography, Avatar } from "@mui/material";
import { BLOGS_DETAILS, ImageUrl } from "../../../api-calls/api-urls";
import { generateColor, stringAvatar } from "../../../utilities/commonFuntions";
import moment from "moment";

const GuestBlogsPage = () => {
  const [pageDetails, setPageDetails] = useState();

  useEffect(() => {
    getOneBlogDetails();
  }, [!pageDetails]);

  const getOneBlogDetails = async () => {
    const path = window.location.pathname;
    const arr = path.split("/");
    const { data } = await api.get(`${BLOGS_DETAILS}/${arr[arr.length - 1]}`);
    setPageDetails(data[0]);
  };
  return (
    <div>
      {pageDetails && (
        <Paper style={{ padding: "25px" }}>
          <img
            src={`${process.env.REACT_APP_IMAGE_URL}/${pageDetails?.images[0]?.destination}${pageDetails?.images[0]?.filename}`}
            alt=""
            className={`mb-4 ${classes.image}`}
            width={"100%"}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography className={`mb-3 ${classes.DetailsMainHEading}`}>
              {pageDetails.heading}
            </Typography>
            {/* <div style={{ display: "flex", columnGap: "15px" }}>
                        <BlogShareIcon />
                        {isSaved ? (
                            <span onClick={() => handleSaveBlog(pageDetails._id)}>
                                <img src={avatar} alt="" width={48} height={48} />
                            </span>
                        ) : (
                            <span onClick={() => handleSaveBlog(pageDetails._id)}>
                                <BlogBookMarkIcon />
                            </span>
                        )}
                    </div> */}
          </div>
          <div className={`mb-4 ${classes.AvatarSection}`}>
            {pageDetails.userInfo.profile_pic ? (
              <Avatar
                alt="Remy Sharp"
                src={`${process.env.REACT_APP_IMAGE_URL}/${pageDetails.userInfo?.profile_pic?.destination}${pageDetails.userInfo?.profile_pic?.filename}`}
                sx={{ width: 32, height: 32 }}
              />
            ) : (
              <Avatar
                {...stringAvatar(pageDetails.userInfo.name)}
                sx={{
                  bgcolor: `${generateColor(pageDetails.userInfo.name)}`,
                  width: 32,
                  height: 32,
                  fontSize: 13,
                  fontWeight: 700,
                }}
              ></Avatar>
            )}
            <div>
              <Typography className={classes.ModalHEaderMainText}>
                {pageDetails.userInfo.name}
              </Typography>
              <Typography className={classes.ModalHEaderSubText}>
                {moment(pageDetails.createdAt).format("DDMMM ,YYYY")} |{" "}
                {`${pageDetails.estimation_time || 1} Min Read`}
              </Typography>
              {/* <Typography className={classes.ModalHeaderStatusText}>
                        joined
                    </Typography> */}
            </div>
          </div>
          <div>
            <Typography className={`mb-4 ${classes.DetailsPAgeSubHEading}`}>
              {pageDetails.subject}
            </Typography>
            <Typography className={`mb-4 ${classes.Detailstext}`}>
              {pageDetails.message}
            </Typography>
          </div>
        </Paper>
      )}
    </div>
  );
};

export default GuestBlogsPage;
