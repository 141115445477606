import { actionTypes } from "../constant/action-types";
const initialState = {
  blogsList: [],
  savedBlogsList: [],
  messagesList: [],
};

export const BlogsReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SAVE_BLOGS_LIST_DATAS:
      return { ...state, blogsList: payload.blogs };
    case actionTypes.SAVE_USER_SAVED_BLOGS_LIST_DATAS:
      return { ...state, savedBlogsList: payload.blogs };
    case actionTypes.BLOGSPAGE_MESSAGES_SAVE:
      return { ...state, messagesList: payload };
    default:
      return state;
  }
};
