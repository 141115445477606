import { Select, Menu, MenuItem, TextField, Button, Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./reports.module.css";
import { Row, Col } from "react-bootstrap";
import { StyledMenu } from "../../../components/styledMenu";
import {
  ExcelIcon,
  PdfIcon,
  DatePickerCalendarIcon,
} from "../../../components/Icons";
import { TEAM_LIST } from "../../../api-calls/api-urls";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { teamsList } from "../../../Redux/actions/team-actions";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import AlertBox from "../../../components/AlertBox";

const ReportIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reportType, setReportType] = useState("0");
  const [selectedTeam, setSelectedTeam] = useState("0");
  const [teamMember, setTeamMember] = useState("0");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const teamList = useSelector((state) => state.reducers.teams.teamsList);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getData = async () => {
    const { data } = await authApi.get(TEAM_LIST, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    dispatch(teamsList(data));
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = async (event) => {
    setReportType(event.target.value);
  };

  const handleViewReport = () => {
    if (teamMember.playerDetails) {
      if (reportType === "history") {
        navigate(`/maps/view-route/${teamMember?.playerDetails?._id}`, {
          state: { date: dayjs(fromDate).format("MM-DD-YYYY") },
        });
      } else {
        navigate(
          `/reports/view-report?mb=${teamMember.mobile_number}&id=${
            selectedTeam?.user_id
          }&sd=${dayjs(fromDate).format("MM-DD-YYYY")}&ed=${dayjs(
            toDate
          ).format("MM-DD-YYYY")}&tp=${reportType}`
        );
      }
    } else {
      setErrorMessage("Player Still Not Registered");
      setError(true);
    }
  };

  const handleChangeTeam = async (event) => {
    setSelectedTeam(event.target.value);
  };

  const handleChangeTeamMember = async (event) => {
    console.log(event.target.value);
    setTeamMember(event.target.value);
  };

  const handleDownload = async (value) => {
    if (teamMember.playerDetails) {
      const { data } = await authApi.get(
        `report/${reportType}-report/download/${teamMember.mobile_number}/${
          selectedTeam?.user_id
        }?startDate=${dayjs(fromDate).format("MM-DD-YYYY")}&endDate=${dayjs(
          toDate
        ).format("MM-DD-YYYY")}&type=${value}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      // console.log(data);
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      console.log(blob);

      // Create a URL for the blob
      const backendUrl = `${
        process.env.REACT_APP_API_URL
      }/report/${reportType}-report/download/${teamMember.mobile_number}/${
        selectedTeam?.user_id
      }?startDate=${dayjs(fromDate).format("MM-DD-YYYY")}&endDate=${dayjs(
        toDate
      ).format("MM-DD-YYYY")}&type=${value}`;

      // Create a link element
      const a = document.createElement("a");
      a.href = backendUrl;
      a.download =
        value === "pdf"
          ? `${reportType}Report(${teamMember.user_name}).pdf`
          : `${reportType}Report(${teamMember.user_name}).xlsx`; // Optional: Set the desired file name
      document.body.appendChild(a);

      // Trigger a click event on the link to initiate the download
      a.click();
    } else {
      setErrorMessage("Player Still Not Registered");
      setError(true);
    }
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Row>
          <Col>
            <Select
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                width: "100%",
                background: "white",
                maxWidth: reportType && reportType !== "0" ? "unset" : "20%",
                color: reportType !== "0" ? "black" : "#b3b3b3",
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={reportType}
              size="small"
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value={"0"} style={{ display: "none" }}>
                Select Report
              </MenuItem>
              <MenuItem value={"attendance"}>Attendance Report</MenuItem>
              <MenuItem value={"route"}>Task Report</MenuItem>
              <MenuItem value={"history"}>Route Report</MenuItem>
            </Select>
          </Col>
          {reportType && reportType !== "0" && (
            <>
              <Col>
                <Select
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    width: "100%",
                    background: "white",
                    color: selectedTeam !== "0" ? "black" : "#b3b3b3",
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedTeam}
                  size="small"
                  label="Age"
                  onChange={handleChangeTeam}
                >
                  <MenuItem value={"0"} style={{ display: "none" }}>
                    Select Team
                  </MenuItem>
                  {teamList &&
                    teamList.length > 0 &&
                    teamList.map((team) => {
                      return <MenuItem value={team}>{team.team_name}</MenuItem>;
                    })}
                </Select>
              </Col>
              <Col>
                <Select
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    width: "100%",
                    background: "white",
                    color: teamMember !== "0" ? "black" : "#b3b3b3",
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={teamMember}
                  size="small"
                  label="Age"
                  onChange={handleChangeTeamMember}
                >
                  <MenuItem value={"0"} style={{ display: "none" }}>
                    Select Player
                  </MenuItem>
                  {selectedTeam &&
                    selectedTeam !== "0" &&
                    selectedTeam.team_members.length > 0 &&
                    selectedTeam.team_members.map((member) => {
                      return (
                        <MenuItem value={member}>{member?.user_name}</MenuItem>
                      );
                    })}
                </Select>
              </Col>
              <Col>
                <div className="datePickerContainer">
                  <DesktopDatePicker
                    className={`${classes.datePickerInput}`}
                    slots={{
                      openPickerIcon: DatePickerCalendarIcon,
                    }}
                    value={fromDate}
                    onChange={(newValue) => setFromDate(newValue)}
                    required
                  />
                </div>
              </Col>
              {reportType !== "history" ? (
                <Col>
                  <div className="datePickerContainer">
                    <DesktopDatePicker
                      className={`${classes.datePickerInput}`}
                      slots={{
                        openPickerIcon: DatePickerCalendarIcon,
                      }}
                      value={toDate}
                      onChange={(value) => setToDate(value)}
                      required
                    />
                  </div>
                </Col>
              ) : (
                <Col></Col>
              )}
            </>
          )}
        </Row>
      </LocalizationProvider>

      <Card className={classes.reportBtnsContainer}>
        <div>
          <Button
            className={classes.viewReportBtn}
            variant="contained"
            disabled={
              reportType === "0" ||
              selectedTeam === "0" ||
              teamMember === "0" ||
              !fromDate
            }
            fullWidth
            style={{
              backgroundColor: "#227dc3",
            }}
            onClick={handleViewReport}
          >
            View Report
          </Button>
        </div>
        {reportType && reportType !== "history" && (
          <div>
            <Button
              className={classes.viewReportBtn}
              fullWidth
              variant="outlined"
              disabled={
                reportType === "0" ||
                selectedTeam === "0" ||
                teamMember === "0" ||
                !fromDate ||
                !toDate
              }
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              Download Report
            </Button>
            <StyledMenu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                width: "100%",
              }}
            >
              <MenuItem onClick={() => handleDownload("pdf")}>
                <PdfIcon /> Download report as PDF
              </MenuItem>
              <MenuItem onClick={() => handleDownload("excel")}>
                <ExcelIcon /> Download report as Excel
              </MenuItem>
            </StyledMenu>
          </div>
        )}
      </Card>
      {errorMessage && error && (
        <AlertBox type="error" message={errorMessage} stateName={setError} />
      )}
    </div>
  );
};

export default ReportIndex;
