import { actionTypes } from "../constant/action-types";

export function SaveGroupsList(data) {
  return {
    type: actionTypes.SAVE_GROUPS,
    payload: data,
  };
}

export function SaveContactList(data) {
  return {
    type: actionTypes.SAVE_CONTACT_LIST,
    payload: data,
  };
}

export function SaveMessages(data) {
  return {
    type: actionTypes.SAVE_MESSAGES,
    payload: data,
  };
}

export function saveChatReceivedMessage(data) {
  console.log("saveChatReceivedMessage", data);
  return {
    type: actionTypes.SAVE_RECEIVED_MESSAGES,
    payload: data,
  };
}

export function saveLastMessageList(data) {
  return {
    type: actionTypes.SAVE_LAST_MESSAGE_IDS,
    payload: data,
  };
}

export function selectedChatMember(data) {
  return {
    type: actionTypes.SELECTED_CHAT_MEMBER,
    payload: data,
  };
}
