import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import SelectLanguage from "./Pages/Authentications/Registration/selectLanguage";
import GuestLayout from "./components/guestLayout";
import DefaultLayout from "./components/DefaultLayout";
import SelectRole from "./Pages/Authentications/Registration/SelectRole";
import PresonalDetails from "./Pages/Authentications/Registration/PresonalDetails";
import Otp1 from "./Pages/Authentications/Registration/Otp1";
import Otp2 from "./Pages/Authentications/Registration/Otp2";
import Dummy from "./Pages/Home/DashBoard/Dummy";
import PaymentSuccess from "./Pages/Authentications/Registration/subscriptionSuccess";
import SetPassword from "./Pages/Authentications/Registration/SetPassword";
import RegisteredSuccessfully from "./Pages/Authentications/Registration/registeredSuccessfully";
import RegisterCompany from "./Pages/Authentications/Registration/RegisterCompany";
import EmailLogin from "./Pages/Authentications/Login/EmailLogin";
import OtpLogin from "./Pages/Authentications/Login/OtpLogin";
import MyTeam from "./Pages/Home/MyTeam/myTeam";
import CreateTeam from "./Pages/Home/MyTeam/CreateTeam";
import TeamViewPage from "./Pages/Home/MyTeam/TeamViewPage";
import Index from "./Pages/Home/Location";
import CreateLocation from "./Pages/Home/Location/CreateLocation";
import FuntioalLayout from "./components/funtionalLayout";
import LocationDetailsPage from "./Pages/Home/Location/LocationDetailsPage";
import MyProfileIndex from "./Pages/Home/MyProfile";
import ResetPassword from "./Pages/Home/MyProfile/ResetPassword";
import LocationReassign from "./Pages/Home/Location/LocationReassign";
import ImageSlider from "./Pages/Home/Subscription/Imagecorosel";
import GoogleApiWrapper from "./Pages/Home/Maps/index";
import TeamMemberViewPageIndex from "./Pages/Home/MyTeam/teamMemberViewPage";
import ViewRoute from "./Pages/Home/Maps/viewRoute";
import Contactus from "./Pages/Home/HeaderNavPages/Contactus";
import Referearn from "./Pages/Home/Refer&Earn/Referearn";
import FaqSection from "./Pages/Home/FAQ section/FaqSection";
import BlogsIndex from "./Pages/Home/Blogs/blogs";
import BlogDetailsPage from "./Pages/Home/Blogs/BlogDetailsPage";
import Chatindex from "./Pages/Home/Chat/chatindex";
import Feedback from "./Pages/Home/HeaderNavPages/Feedback";
import Aboutus from "./Pages/Home/HeaderNavPages/Aboutus";
import PoliciesIndex from "./Pages/Home/HeaderNavPages/PoliciesIndex";
import MarkHoliday from "./Pages/Home/HolidayMarker/MarkHoliday";
import ViewLocationRoute from "./Pages/Home/Maps/viewLocationRoute";
import NotificationsPage from "./components/notificationsPage";
import GuestBlogsPage from "./Pages/Home/Blogs/GuestBlogsPage";
import Demo from "./Pages/Home/Demo/demo";
import AddressHome from "./Pages/Home/address/AddressHome";
import ReportIndex from "./Pages/Home/Reports";
import ViewReport from "./Pages/Home/Reports/viewReport";
import AddressMaps from "./Pages/Home/address/AddressMaps";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <GuestLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/login" />,
      },
      {
        path: "/login",
        element: <EmailLogin />,
      },
      {
        path: "/otp_login",
        element: <OtpLogin />,
      },
      {
        path: "/signup",
        element: <SelectLanguage />,
      },
      {
        path: "/your-best",
        element: <SelectRole />,
      },
      {
        path: "/personal-details",
        element: <PresonalDetails />,
      },
      {
        path: "/otp1",
        element: <Otp1 />,
      },
      {
        path: "/otp2",
        element: <Otp2 />,
      },
      {
        path: "/register-company",
        element: <RegisterCompany />,
      },
      {
        path: "/set-password",
        element: <SetPassword />,
      },
      {
        path: "/public/blogs/blog-details/:id",
        element: <GuestBlogsPage />,
      },
    ],
  },
  {
    path: "/",
    element: <FuntioalLayout />,
    children: [
      {
        path: "/register-success",
        element: <RegisteredSuccessfully />,
      },
      {
        path: "/payment_success",
        element: <PaymentSuccess />,
      },
    ],
  },
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/home" />,
      },
      {
        path: "/contact-us",
        element: <Contactus />,
      },
      {
        path: "/notifications",
        element: <NotificationsPage />,
      },
      {
        path: "/home",
        element: <Dummy />,
      },
      {
        path: "/refer-earn",
        element: <Referearn />,
      },
      {
        path: "/blogs",
        element: <BlogsIndex />,
      },
      {
        path: "/blogs/blog-details/:id",
        element: <BlogDetailsPage />,
      },
      {
        path: "/help-faq",
        element: <FaqSection />,
      },
      {
        path: "/feedback",
        element: <Feedback />,
      },
      {
        path: "/my-team",
        element: <MyTeam />,
      },
      {
        path: "/chat",
        element: <Chatindex />,
      },
      {
        path: "/about-us",
        element: <Aboutus />,
      },
      {
        path: "/my-team/update/:name",
        element: <CreateTeam />,
      },
      {
        path: "/my-team/create-team",
        element: <CreateTeam />,
      },
      {
        path: "/location",
        element: <Index />,
      },
      {
        path: "/mark-a-holiday",
        element: <MarkHoliday />,
      },
      {
        path: "/policies",
        element: <PoliciesIndex />,
      },
      {
        path: "/location/create-location",
        element: <CreateLocation />,
      },
      {
        path: "/location/update/:name",
        element: <CreateLocation />,
      },
      {
        path: "/location/:name",
        element: <LocationDetailsPage />,
      },
      {
        path: "/location/reassign/:name",
        element: <LocationReassign />,
      },
      {
        path: "/subscriptions",
        element: <ImageSlider />,
      },
      {
        path: "/maps",
        element: <GoogleApiWrapper />,
      },
      {
        path: "/maps/view-route/:id",
        element: <ViewRoute />,
      },
      {
        path: "/maps/location-view-route/:id",
        element: <ViewLocationRoute />,
      },
      {
        path: "/select-subscription",
        element: <RegisteredSuccessfully />,
      },
      {
        path: "/referal-subscription",
        element: <RegisteredSuccessfully />,
      },
      {
        path: "/upgrade-plan/:id",
        element: <RegisteredSuccessfully />,
      },
      {
        path: "/my-team/:name/:id",
        element: <TeamMemberViewPageIndex />,
      },
      {
        path: "/my-team/:name",
        element: <TeamViewPage />,
      },
      {
        path: "/reports",
        element: <ReportIndex />,
      },
      {
        path: "/reports/view-report",
        element: <ViewReport />,
      },
      {
        path: "/my-profile",
        element: <MyProfileIndex />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/demo",
        element: <Demo />,
      },
      {
        path: "/address",
        element: <AddressHome />,
      },
      {
        path: "/address-maps",
        element: <AddressMaps />,
      },
    ],
  },
]);
export default Router;
