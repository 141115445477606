import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  InputAdornment,
  Link,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { MdClose } from "react-icons/md";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import image from "../../../assets/images/restpwd.png";
import OtpInput from "react-otp-input";
import Box from "@mui/material/Box";
import classes from "../Registration/index.module.css";
import Visibility from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  api,
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { useDispatch, useSelector } from "react-redux";
import {
  GENERATE_FORGET_PASSWORD_OTP,
  RESET_PASSWORD,
  SET_PASSWORD,
  VERIFY_LOGIN_OTP,
} from "../../../api-calls/api-urls";
import { getLanguagedataFunction } from "../../../utilities/language";
import {
  CloseEyeIcon,
  EditPenIcon,
  OpenEyeIcon,
} from "../../../components/Icons";
import AlertBox from "../../../components/AlertBox";

const ForgetPassword = (props) => {
  const dispatch = useDispatch();
  const [modalToggle, setModalToggle] = useState(true);
  const [time, setTime] = useState(15);
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [maxlength, setMaxLength] = useState(10);
  const [otpReceived, setOtpReceived] = useState();
  const [successModal, setSuccessModal] = useState();
  const [setpassword, setSetPassword] = useState();
  const [Otp, setOtp] = useState("");
  const otp = JSON.parse(sessionStorage.getItem("otp"));
  const [email, setEmail] = useState("");
  const rootRef = React.useRef(null);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  const [disable, setDisable] = useState({
    name: `Resend in ${time}s`,
    state: true,
  });

  useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  function closeModal(data) {
    sessionStorage.removeItem("otp");
    setSuccessModal(false);
    props.modalClose(false);
    setModalToggle(false);
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevCount) => prevCount - 1);
      setDisable({
        name: `${ln.resend_in} ${time - 1}${ln.seconds}`,
        state: true,
      });
    }, 1000);

    if (time === 0) {
      clearInterval(timer);
      setDisable({
        name: ln.resend_otp,
        state: false,
      });
    }
    return () => {
      clearInterval(timer);
    };
  }, [time]);
  const handleResetClick = () => {
    console.log("reset");
    setOtp(null);
    setTime(15);
    handleOtp();
  };

  const handleOtp = async () => {
    try {
      const userEntry = {
        email: email,
      };
      const { data } = await authApi.post(
        GENERATE_FORGET_PASSWORD_OTP,
        userEntry,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      sessionStorage.setItem("otp", JSON.stringify(data));
      setOtpReceived(true);
      setOtp();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setShowAlert(true);
        setError(error.response.data.message);
      }
    }
  };
  const handleVerify = async () => {
    try {
      const userEntry = {
        otp: Otp,
      };
      const { data } = await api.post(VERIFY_LOGIN_OTP, userEntry);
      setOtpReceived(false);
      setSetPassword(true);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setShowAlert(true);
        setError(error.response.data.message);
      }
    }
  };
  const handleResetPassword = async () => {
    try {
      const userEntry = {
        password: password,
        confirmPassword: confirmPassword,
      };
      const { data } = await api.post(RESET_PASSWORD, userEntry);
      setSetPassword(false);
      setSuccessModal(true);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setShowAlert(true);
        setError(error.response.data.message);
      }
    }
  };
  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Change the duration (in milliseconds) as per your requirement

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showAlert]);
  const handleOnchange = (e) => {
    const re = /^\d*$/;
    if (e === "" || re.test(e)) {
      setOtp(e);
    }
  };

  const handleclick = (value) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (re.test(value)) {
      setMaxLength(10);
      setEmail(value);
    } else {
      setMaxLength(null);
      setEmail(value);
    }
  };

  const otpFunctionality = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    let isEmail = emailRegex.test(email);
    if (isEmail) {
      return otp.email_otp;
    } else {
      return otp.phone_otp;
    }
  };

  const EditAgainProcess = () => {
    setEmail();
    setOtpReceived(false);
  };

  return (
    <div>
      {ln ? (
        <Modal
          open={modalToggle}
          onClose={() => closeModal()}
          aria-labelledby="server-modal-title"
          data-testid="register_modal"
          aria-describedby="server-modal-description"
          sx={{
            display: "flex",
            top: "10%",
            height: "fit-content",
            marginTop: 10,
            justifyContent: "center",
          }}
          container={() => rootRef.current}
        >
          <Box
            sx={{
              position: "relative",
              bgcolor: "background.paper",
              borderRadius: 1,
              boxShadow: (theme) => theme.shadows[5],
            }}
            style={{ width: "auto", height: "auto" }}
            className={classes.loginModalWidth}
          >
            <div className={classes.forgetPasswordmodalclose}>
              <Button
                variant="text"
                sx={{ minWidth: "unset" }}
                className={classes.closebtn}
                onClick={() => closeModal()}
              >
                <MdClose />
              </Button>
            </div>
            {!otpReceived && !setpassword && !successModal ? (
              <div style={{ minWidth: "450px" }}>
                <div className={classes.ModalHeadingSection}>
                  <Typography className={classes.modalHeadind}>
                    {ln.forgot_password}
                  </Typography>
                  <Typography
                    className={`mt-1 ${classes.modalHeadin2} ${classes.forgotsubHeading}`}
                  >
                    {ln.enter_register_email}
                  </Typography>
                  <ValidatorForm
                    useref="form"
                    onSubmit={handleOtp}
                    style={{ width: "100%" }}
                    className={classes.InputForm}
                  >
                    <div className={classes.depFormContainer}>
                      <div>
                        <div className="mb-5">
                          <Typography
                            htmlFor="emailidInput"
                            className={classes.login_form_lable}
                          >
                            {`${ln.email_id} / ${ln.mobile_number}`}
                          </Typography>
                          <TextValidator
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                            id="emailidInput"
                            fullWidth
                            value={email}
                            inputProps={{
                              maxLength: maxlength,
                            }}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              handleclick(e.target.value);
                            }}
                            size="small"
                            validators={["required"]}
                            errorMessages={["Email/Mobile Number is required"]}
                          />
                        </div>
                      </div>
                      <div className={classes.addMemberModalbtnSection}>
                        <Button
                          variant="contained"
                          type="submit"
                          fullwidth
                          style={{ width: "100%" }}
                          className={classes.commonbtn}
                        >
                          {ln.send_otp}
                        </Button>
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            ) : null}
            {otpReceived && !setpassword && !successModal ? (
              <div style={{ minWidth: "490px" }}>
                <div
                  className={`${classes.ModalHeadingSection} ${classes.OtpSectionforgot}`}
                >
                  <Typography className={classes.modalHeadind}>
                    {email.match(
                      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                    )
                      ? ln.otp_to_reg_email
                      : ln.otp_to_reg_mobile}
                  </Typography>
                  <Typography className={classes.modalHeadin2}>
                    <span style={{ marginRight: "8px" }}>
                      {ln.enter_4digit_code} {email}{" "}
                    </span>
                    <span onClick={() => EditAgainProcess()}>
                      <EditPenIcon />
                    </span>
                  </Typography>
                  {otp ? (
                    <Typography>OTP : {otpFunctionality()}</Typography>
                  ) : null}
                  <ValidatorForm
                    useref="form"
                    onSubmit={handleVerify}
                    style={{ width: "80%", textAlign: "center" }}
                  >
                    <div className="mt-4 mb-3">
                      <div className={`otp ${classes.OtpFeildsContainer}`}>
                        <OtpInput
                          value={Otp}
                          onChange={(e) => handleOnchange(e)}
                          numInputs={4}
                          renderInput={(props) => <input {...props} />}
                          isInputNum={true}
                        />
                      </div>
                      <div className="mt-4">
                        <p className="mb-0 login-text3">
                          {ln.didnt_recieve_otp}
                          <Button
                            disabled={disable.state}
                            className="fw-medium"
                            variant="text"
                            sx={{
                              "&.Mui-disabled": {
                                color: "#0000ff73 !important",
                              },
                            }}
                            style={{
                              color: "blue",
                              cursor: "pointer",
                              textTransform: "none",
                              border: "none",
                            }}
                            onClick={() => handleResetClick()}
                          >
                            {disable.name}
                          </Button>
                        </p>
                      </div>
                      <div className="mt-4">
                        <Button
                          disabled={Otp && Otp.length === 4 ? false : true}
                          variant="contained"
                          type="submit"
                          fullwidth
                          style={{ width: "100%" }}
                          className={classes.commonbtn}
                        >
                          {ln.verify}
                        </Button>
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            ) : null}
            {!otpReceived && setpassword && !successModal ? (
              <div>
                <div
                  className={`${classes.ModalHeadingSection} ${classes.setPasswordForgetContainer}`}
                >
                  <Typography className={classes.modalHeadind}>
                    {ln.set_new_password}
                  </Typography>
                  <Typography
                    className={classes.modalHeadin2}
                    style={{ textAlign: "center", width: "85%" }}
                  >
                    {ln.forget_pass_desc}
                  </Typography>
                  <ValidatorForm
                    useref="form"
                    onSubmit={handleResetPassword}
                    style={{ width: "80%", marginBottom: "10px" }}
                  >
                    <div className="mt-4 mb-3">
                      <div className="mb-4">
                        <Typography
                          htmlFor="userpassword"
                          className={classes.login_form_lable}
                        >
                          {ln.new_password}
                        </Typography>
                        <div className={classes.passwordInputContainer}>
                          <TextValidator
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            fullWidth
                            size="small"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            validators={[
                              "required",
                              "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$",
                            ]}
                            errorMessages={[
                              "Password is required",
                              "Password should contain 1 capital letter, 1 small letter, 1 number, 1 special characters  and should be 8 digits",
                            ]}
                          />
                          <div className={classes.showPassword}>
                            {showPassword ? (
                              <Button
                                variant="text"
                                onClick={() => setShowPassword(false)}
                              >
                                <OpenEyeIcon />
                              </Button>
                            ) : (
                              <Button onClick={() => setShowPassword(true)}>
                                <CloseEyeIcon />
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="mb-2">
                        <Typography
                          htmlFor="userpassword"
                          className={classes.login_form_lable}
                        >
                          {ln.confirm_new_password}
                        </Typography>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <div className={classes.passwordInputContainer}>
                            <TextValidator
                              sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                              }}
                              id="outlined-adornment-password"
                              type={showPassword1 ? "text" : "password"}
                              fullWidth
                              size="small"
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                              validators={[
                                "required",
                                "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$",
                              ]}
                              errorMessages={[
                                "Confirm password is required",
                                "Password should contain 1 capital letter, 1 small letter, 1 number, 1 special characters  and should be 8 digits",
                              ]}
                            />
                            <div className={classes.showPassword}>
                              {showPassword1 ? (
                                <Button
                                  variant="text"
                                  onClick={() => setShowPassword1(false)}
                                >
                                  <OpenEyeIcon />
                                </Button>
                              ) : (
                                <Button onClick={() => setShowPassword1(true)}>
                                  <CloseEyeIcon />
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <Button
                          variant="contained"
                          type="submit"
                          fullwidth
                          style={{ width: "100%" }}
                          className={classes.commonbtn}
                        >
                          {ln.reset_password}
                        </Button>
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            ) : null}
            {!otpReceived && !setpassword && successModal ? (
              <div className="mb-4" style={{ minWidth: "450px" }}>
                <div className={classes.ModalHeadingSection}>
                  <img
                    src={image}
                    width={150}
                    height={150}
                    alt="successImage"
                    style={{ marginBottom: "30px", borderRadius: "50%" }}
                  />
                  <Typography className={classes.modalHeadind}>
                    {ln.success_reset}
                  </Typography>
                  <Typography
                    className={classes.modalHeadin2}
                    style={{ textAlign: "center", margin: "10px 0 25px" }}
                  >
                    {ln.sucesss_message_reset}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => closeModal()}
                    fullwidth
                    style={{ width: "100%", marginBottom: "10px" }}
                    className={classes.commonbtn}
                  >
                    {ln.login}
                  </Button>
                </div>
              </div>
            ) : null}
          </Box>
        </Modal>
      ) : null}
      {showAlert && error && (
        <AlertBox type="error" message={error} stateName={setShowAlert} />
      )}
    </div>
  );
};

export default ForgetPassword;
