import React, { useEffect, useState } from "react";
import classes from "./holiday.module.css";
import { MdClose } from "react-icons/md";
import successImage from "../../../assets/images/sucessImge.png";
import { Modal, Box, Button, Typography, Snackbar, Alert } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { MARK_A_HOLIDAY } from "../../../api-calls/api-urls";

const ConfirmationModel = (props) => {
  const { holidays } = useSelector((state) => state.reducers.holidays);
  const [modalToggle, setModalToggle] = useState(true);
  const [removedDates, setRemovedDates] = useState([]);
  const [newDates, setNewDates] = useState([]);
  const [allDates, setAllDates] = useState([]);
  const [success, setSuccess] = useState(false);
  const rootRef = React.useRef(null);

  useEffect(() => {
    findRemovedAddes();
  });

  const findRemovedAddes = () => {
    const dates2 = holidays.map((item) => new Date(item.date));

    const initialData = dates2;
    const modifiedData = props.selectedDates.map((date) => new Date(date));

    // Find Removed Dates (dates in initialData that are not in modifiedData)
    const removedDates = initialData.filter(
      (date) =>
        !modifiedData.some(
          (modifiedDate) => date.getTime() === modifiedDate.getTime()
        )
    );

    // Find New Dates (dates in modifiedData that are not in initialData)
    const newDates = modifiedData.filter(
      (date) =>
        !initialData.some(
          (initialDate) => date.getTime() === initialDate.getTime()
        )
    );
    const array = [...newDates, ...removedDates];
    console.log(array);
    setRemovedDates(removedDates);
    setNewDates(newDates);
    setAllDates(array);
  };

  const handleSubmit = async () => {
    const { data } = await authApi.post(
      `${MARK_A_HOLIDAY}`,
      { dates: allDates },
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    console.log(data);
    // modalClose();
    setSuccess(true);
  };

  const modalClose = (data) => {
    props.modalClose(false, data);
    setModalToggle(false);
  };
  return (
    <div>
      <Modal
        open={modalToggle}
        onClose={() => modalClose()}
        aria-labelledby="server-modal-title"
        data-testid="register_modal"
        aria-describedby="server-modal-description"
        sx={{
          display: "flex",
          height: 480,
          marginTop: 10,
          justifyContent: "center",
        }}
        container={() => rootRef.current}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[5],
          }}
          style={{
            width: "500px",
            height: "max-content",
            top: "20%",
          }}
          className={classes.loginModalWidth}
        >
          {success && (
            <Button
              variant="text"
              sx={{ minWidth: "unset" }}
              className={classes.closebtn}
              onClick={() => modalClose()}
            >
              <MdClose />
            </Button>
          )}
          {!success ? (
            <div className={classes.deleteConformationFirstDiv}>
              <Typography
                className={`mb-2 ${classes.ConfirmationModalHeading}`}
              >
                Save Changes
              </Typography>
              <Typography className={`mb-3 ${classes.successPageSubText}`}>
                Are you sure you want to save changes?
              </Typography>
              {removedDates.length > 0 && (
                <div className="mb-3">
                  <Typography className={classes.MarkAHolidaySubHeading}>
                    Unmark Holidays
                  </Typography>
                  <ul className={classes.DateList}>
                    {removedDates.map((item, index) => (
                      <li className={classes.DateListItems} key={index}>
                        <Typography className={classes.TextInsideDateListItems}>
                          {moment(item).format("DD MMM, YYYY")}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {newDates.length > 0 && (
                <div>
                  <Typography className={classes.MarkAHolidaySubHeading}>
                    Mark Holidays
                  </Typography>
                  <ul className={classes.DateList}>
                    {newDates.map((item, index) => (
                      <li className={classes.DateListItems} key={index}>
                        <Typography className={classes.TextInsideDateListItems}>
                          {moment(item).format("DD MMM, YYYY")}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div className={classes.addMemberModalbtnSection}>
                <Button variant="outlined" onClick={() => modalClose()}>
                  No
                </Button>
                <Button variant="contained" onClick={() => handleSubmit()}>
                  Yes
                </Button>
              </div>
            </div>
          ) : (
            <div className={classes.successImageContainer}>
              <img src={successImage} alt="" />
              {removedDates && removedDates.length > 0 ? (
                <Typography className={classes.succesImageText}>
                  Updates has been changed successfully
                </Typography>
              ) : (
                <Typography className={classes.succesImageText}>
                  Holidays has been marked successfully
                </Typography>
              )}
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmationModel;
