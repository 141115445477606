import React, { useEffect, useState } from "react";
import ImageCarosel from "../../../components/ImageCarosel";
import classes from "./index.module.css";
import { GrFormEdit } from "react-icons/gr";
import OtpInput from "react-otp-input";
import {
  GENERATE_FORGET_PASSWORD_OTP,
  VERIFY_MOBILE,
} from "../../../api-calls/api-urls";
import AppLogo from "../../../assets/images/mainLogo.png";
import { ValidatorForm } from "react-material-ui-form-validator";
import "../../../assets/css/style.css";
import {
  RegisteredUser,
  pageLoader,
} from "../../../Redux/actions/user-actions";
import "../../../assets/css/bootstrap.min.css";
import { Card, Typography, Snackbar, Alert } from "@mui/material";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authApi, api } from "../../../api-calls/user-requests";
import { getLanguagedataFunction } from "../../../utilities/language";
import { Update } from "@mui/icons-material";
import UpdateModal from "./updateModal";
import { set } from "lodash";
import AlertBox from "../../../components/AlertBox";
import lineDraw from "../../../assets/images/Wizardmobile.png";
const Otp1 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const [Otp, setOtp] = useState("");
  const [time, setTime] = useState(15);
  const regUser = JSON.parse(sessionStorage.getItem("CurrentUser"));

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Change the duration (in milliseconds) as per your requirement

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showAlert]);

  const handleSubmit = async () => {
    const userEntry = {
      //userid: regUser._id,
      phone_otp: Otp,
    };
    try {
      dispatch(pageLoader(true));
      const { data } = await api.post(VERIFY_MOBILE, userEntry);
      if (regUser.email) {
        navigate("/otp2");
      } else {
        navigate("/register-company");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setShowAlert(true);
        setError(error.response.data.message);
      } else {
        // Handle other errors
        // ...
      }
    }
  };

  const [phone_otp, setPhone_Otp] = useState();
  const [moddleToggle, setModdleToggle] = useState(false);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  const [disable, setDisable] = useState({
    name: ln ? `${ln.resend_in} ${time}${ln.seconds}` : null,
    state: true,
  });

  useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const handleOtp = async () => {
    const userEntry = {
      email: regUser.phone,
      user_id: regUser._id,
    };
    const { data } = await api.post(GENERATE_FORGET_PASSWORD_OTP, userEntry);
    //sessionStorage.setItem("otp", JSON.stringify(data));
    setPhone_Otp(data.phone_otp);
  };

  const handleResetClick = async () => {
    setOtp(null);
    handleOtp();
    setTime(15);
  };
  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevCount) => prevCount - 1);
      setDisable({
        name: ln ? `${ln.resend_in} ${time}${ln.seconds}` : null,
        state: true,
      });
    }, 1000);

    if (time === 0) {
      clearInterval(timer);
      setDisable({
        name: `${ln.resend_otp}`,
        state: false,
      });
    }

    return () => {
      clearInterval(timer);
    };
  }, [time]);
  // useEffect(() => {
  //   if (!localStorage.getItem("CurrentUser")) {
  //     navigate("/personal-details");
  //   }
  // }, []);
  useEffect(() => {
    if (regUser) {
      setPhone_Otp(regUser.phone_otp);
    }
  }, []);
  const handleOnchange = (e) => {
    const re = /^\d*$/;
    if (e === "" || re.test(e)) {
      setOtp(e);
    }
  };

  const closeModal = () => {
    setModdleToggle(false);
  };
  return (
    <div className={classes.maindivForlogin}>
      {ln ? (
        <Card className={classes.SelectLanguage_maindiv}>
          <div>
            <ImageCarosel />
          </div>
          <ValidatorForm useref="form" onSubmit={handleSubmit}>
            <div className="col-lg-12">
              <div style={{ padding: "3rem" }}>
                <div className="text-left">
                  <span className="logo-lg mb-5">
                    <img src={AppLogo} alt="" height="50" />
                  </span>
                  <div className="header1 mt-4">
                    <img
                      src={lineDraw}
                      alt=""
                      className={classes.wizardImage}
                    />
                  </div>
                  <Typography className={`mt-3 ${classes.loginHeading}`}>
                    {ln.verify_otp}
                  </Typography>
                  <Typography className={`mt-4 ${classes.loginDescription}`}>
                    {ln.enter_otp_mobile} <br />
                    <b className={classes.boldContainer}>
                      <span className={classes.mobileNumber}>
                        +91 {regUser.phone}
                      </span>{" "}
                      <span onClick={() => setModdleToggle(true)}>
                        <GrFormEdit />
                      </span>
                    </b>
                  </Typography>
                  {regUser ? (
                    <Typography>Your Phone OTP is: {phone_otp}</Typography>
                  ) : null}
                </div>

                <div className="mt-4">
                  <div className={`otp ${classes.OtpFeildsContainer}`}>
                    <OtpInput
                      value={Otp}
                      onChange={(e) => handleOnchange(e)}
                      numInputs={4}
                      renderInput={(props) => <input {...props} />}
                      isInputNum={true}
                    />
                  </div>
                  <div className="mt-4">
                    <p className={`mb-0 ${classes.login_form_lable}`}>
                      {ln.didnt_recieve_otp}
                      <Button
                        disabled={disable.state}
                        className={`${classes.login_form_lable} ${classes.remember_input} ${classes.forgotbtn}`}
                        variant="text"
                        sx={{
                          "&.Mui-disabled": {
                            color: "#0000ff73 !important",
                          },
                        }}
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          border: "none",
                          textTransform: "none",
                        }}
                        onClick={() => handleResetClick()}
                      >
                        {disable.name}
                      </Button>
                    </p>
                  </div>
                  <div className="mt-3">
                    <Button
                      disabled={Otp && Otp.length === 4 ? false : true}
                      className={`btn btn-primary w-100 ${classes.commonbtn}`}
                      type="submit"
                    >
                      {ln.continue}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </ValidatorForm>
        </Card>
      ) : null}
      {moddleToggle && (
        <UpdateModal
          type={"number"}
          closeModal={closeModal}
          value={regUser.phone}
        />
      )}
      {showAlert && error && (
        <AlertBox type="error" message={error} stateName={setShowAlert} />
      )}
    </div>
  );
};

export default Otp1;
