import React, { useEffect, useState } from "react";
import classes from "./reports.module.css";
import classesOne from "../MyTeam/myteam.module.css";
import { useLocation } from "react-router-dom";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { VictoryPie } from "victory";
import { VictoryLabel } from "victory-core";
import dayjs from "dayjs";
import { StyledMenu } from "../../../components/styledMenu";
import { GoDotFill } from "react-icons/go";
import {
  Typography,
  Button,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Menu,
  Box
} from "@mui/material";
import { Form } from "react-bootstrap";
import { ArrowDownIcon, ExcelIcon, FilterIcon, PdfIcon, ReportFilterIcon } from "../../../components/Icons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import moment from "moment";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ViewReport = () => {
  const [queryParams, setQueryParams] = useState({});
  const [reportData, setReportData] = useState({});
  const [pieChartData, setPieChartData] = useState();
  const location = useLocation();
  const query = useQuery();
  const [expanded, setExpanded] = React.useState("panel1");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filteredDatas, setFilteredDatas] = React.useState([]);
  const [anchorElTwo, setAnchorElTwo] = React.useState(null);
  const [anchorElThree, setAnchorElThree] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedSortValues, setSelectedSortValues] = useState(["Oldest to Newest"]);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);


  console.log("selectedValues =",selectedValues)

  useEffect(() => {
    if (queryParams?.tp === "route") {
      const data = [
        { x: `${reportData?.completed}%`, y: reportData?.completed },
        { x: `${reportData?.pending}%`, y: reportData?.pending },
        { x: `${reportData?.rejected}%`, y: reportData?.rejected },
      ];
      setPieChartData(data);
    } else {
      const data = [
        { x: `${reportData?.number_of_daysPresent}%`, y: reportData?.number_of_daysPresent,caption: "5 звезд"  },
        { x: `${reportData?.number_of_daysAbsent}%`, y: reportData?.number_of_daysAbsent,caption: "4 звезды"  },
        { x: `${reportData?.number_of_daysholiday}%`, y: reportData?.number_of_daysholiday ,caption: "3 звезды"},
      ];

      let a = [
        { x: "50%", y: 50, caption: "5 звезд" },
        { x: "25%", y: 25, caption: "4 звезды" },
        { x: "25%", y: 25, caption: "3 звезды" }
      ];
      setPieChartData(data);
    }
  }, [reportData]);
  console.log("setFilteredDatas =",filteredDatas)


  const handleStatusCheckbox = (value) => {
    setSelectedValues([value]);
    let arrays;
    // let _attendance = [...reportData?.daysReport];
    // let _routes = [...reportData?.list];
    if (queryParams?.tp === "route") {
      arrays = [...reportData?.list];
    } else {
      arrays = [...reportData?.daysReport];
    }
    console.log("arrays =",arrays)
    if (arrays) {
      if (value === "all") {
        setFilteredDatas(arrays);
      } else {
        let _filters = arrays.filter((val)=>val.status.toLowerCase() === value?.toLowerCase());
        setFilteredDatas(_filters);
      }
    }


    // const isSelected = selectedValues.includes(value);
    // console.log(selectedValues, isSelected);
    // if (isSelected) {
    //   if (value === "all") {
    //     setSelectedValues([]);
    //   } else {
    //     setSelectedValues(selectedValues.filter((item) => item !== value));
    //   }
    // } else {
    //   if (value === "all") {
    //     setSelectedValues([value]);
    //   } else {
    //     setSelectedValues([value]);
    //   }
    // }
  };

  const handleSortStatusCheckbox = (value) => {
    setSelectedSortValues([value]);

    let arrays = [...filteredDatas];
    let _fields = "";
    if (queryParams?.tp === "route") {
      _fields = "createdAt";
    } else {
      _fields = "date";
    }
    if (arrays) {
      if (value === "Newest to Oldest") {
        let date_sortings = arrays.sort((a,b)=>{
          return b[_fields].localeCompare(a[_fields]);
        })
        setFilteredDatas(date_sortings);
      } else if (value === "Oldest to Newest") {
        let date_sortings = arrays.sort((a,b)=>{
          return a[_fields].localeCompare(b[_fields]);
        })
        setFilteredDatas(date_sortings);
      } else if (value === "all") {
        let date_sortings = arrays.sort((a,b)=>{
          return a[_fields].localeCompare(b[_fields]);
        })
        setFilteredDatas(date_sortings);
      }
    }
  };

  const handleDownload = async (value) => {
    const { data } = await authApi.get(
      `report/${queryParams.tp}-report/download/${queryParams.mb}/${
        queryParams?.id
      }?startDate=${dayjs(queryParams.sd).format("MM-DD-YYYY")}&endDate=${dayjs(
        queryParams.ed
      ).format("MM-DD-YYYY")}&type=${value}`,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    // console.log(data);
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    console.log(blob);

    // Create a URL for the blob
    const backendUrl = `${process.env.REACT_APP_API_URL}/report/${
      queryParams.tp
    }-report/download/${queryParams.mb}/${queryParams?.id}?startDate=${dayjs(
      queryParams.sd
    ).format("MM-DD-YYYY")}&endDate=${dayjs(queryParams.ed).format(
      "MM-DD-YYYY"
    )}&type=${value}`;

    // Create a link element
    const a = document.createElement("a");
    a.href = backendUrl;
    a.download =
      value === "pdf"
        ? `${queryParams.tp}Report.pdf`
        : `${queryParams.tp}Report.xlsx`; // Optional: Set the desired file name
    document.body.appendChild(a);

    // Trigger a click event on the link to initiate the download
    a.click();
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    const params = {};
    for (let [key, value] of query.entries()) {
      params[key] = value;
    }
    setQueryParams(params);
  }, [location.search]);

  const handleMenuclick = (event, name) => {
    console.log(event.currentTarget);
    setAnchorElTwo(event.currentTarget);
  };

  const handleSortMenuclick = (event, name) => {
    console.log(event.currentTarget);
    setAnchorElThree(event.currentTarget);
  };

  const getReportDatas = async () => {
    const { data } = await authApi.get(
      `report/${queryParams?.tp}-report/download/${queryParams?.mb}/${
        queryParams?.id
      }?startDate=${dayjs(queryParams?.sd).format(
        "MM-DD-YYYY"
      )}&endDate=${dayjs(queryParams?.ed).format("MM-DD-YYYY")}&type=`,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    setReportData(data);
    console.log(data);
  };

  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      getReportDatas();
    }
  }, [queryParams]);

  useEffect(()=>{
    if (reportData?.daysReport || reportData?.list) {
      
      handleStatusCheckbox("all")
    }
  },[reportData])
  console.log("reportData =",reportData)
  return (
    <div>
      <div className={classes.downloadBtnContainer}>
        <Button
          className={`${classes.viewReportBtn} ${classes.downloadFlexBtn}`}
          variant="outlined"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <span>Download Report</span>
          <ArrowDownIcon />
        </Button>
        <StyledMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            width: "100%",
          }}
        >
          <MenuItem onClick={() => handleDownload("pdf")}>
            <PdfIcon /> PDF
          </MenuItem>
          <MenuItem onClick={() => handleDownload("excel")}>
            <ExcelIcon /> Excel
          </MenuItem>
        </StyledMenu>
      </div>
      <div className={classes.AccordiansContainer}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
           <b>
           {queryParams?.tp === "route" ? "Tasks" : "Attendance"} Summary
           </b>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.TextNumberCardContainer}>
              <div className={classes.TextNumberCard}>
                <Typography className={classes.textCardLable}>
                  {queryParams?.tp === "route" ? "Total Tasks" : "Total Days"}
                </Typography>
                <Typography className={classes.textCardLable}>
                  {queryParams?.tp === "route"
                    ? reportData?.total
                    : reportData?.workingDays}
                </Typography>
              </div>
              <div className={classes.TextNumberCard}>
                <Typography
                  className={`${classes.textCardLable} ${classes.completedcolor}`}
                >
                  <GoDotFill />
                  {queryParams?.tp !== "route" ? "Present" : "Completed"}
                </Typography>
                <Typography>{queryParams?.tp !== "route"
                    ? reportData?.number_of_daysPresent
                    : reportData?.completed}</Typography>
              </div>
              <div className={classes.TextNumberCard}>
                <Typography
                  className={`${classes.textCardLable} ${classes.pendingcolor}`}
                >
                  <GoDotFill />
                  {queryParams?.tp !== "route" ? "Absent" : "Pending"}
                </Typography>
                <Typography>{queryParams?.tp !== "route"
                    ? reportData?.number_of_daysAbsent
                    : reportData?.pending}</Typography>
              </div>
              <div className={classes.TextNumberCard}>
                <Typography
                  className={`${classes.textCardLable} ${classes.rejectedcolor}`}
                >
                  <GoDotFill />
                  {queryParams?.tp !== "route" ? "Holidays" : "Rejected"}
                </Typography>
                <Typography>{queryParams?.tp !== "route"
                    ? reportData?.number_of_daysholiday
                    : reportData?.rejected}</Typography>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
           <b> {queryParams?.tp === "route" ? "Overall Tasks" : "Overall Attendance"} Distribution</b>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.pieChartContainer}>
              <svg viewBox="0 0 450 450" width="170px">
                <VictoryPie
                  standalone={false}
                  width={450}
                  height={450}
                  data={pieChartData}
                  innerRadius={125}
                  colorScale={["#240051","#fbc23f","#C52A38" ]}
                  labels={() => null} // Hide labels
                  style={{ labels: { fontSize: 20, fill: "white" } }}
                />
                <VictoryLabel
                  textAnchor="middle"
                  verticalAnchor="middle"
                  x={225}
                  y={250}
                  text={[queryParams?.tp === "route" ? reportData?.total+"" : reportData?.workingDays+"", "Total"]}
                  style={[
                    { fontSize: 65, fontWeight: 600 },
                    {
                      fontSize: "30px",
                      fontWeight: 400,
                      fill: "#6B778C",
                    },
                  ]}
                />
              </svg>
              <div>
                <Typography
                  className={`${classes.textCardLable} ${classes.completedcolor}`}
                >
                  <GoDotFill />
                  {queryParams?.tp === "route"
                    ? `${reportData?.completed} completed`
                    : `${reportData?.number_of_daysPresent} Present`}
                </Typography>
                <Typography
                  className={`${classes.textCardLable} ${classes.pendingcolor}`}
                >
                  <GoDotFill />
                  {queryParams?.tp === "route"
                    ? `${reportData?.pending} pending`
                    : `${reportData?.number_of_daysAbsent} Absent`}
                </Typography>
                <Typography
                  className={`${classes.textCardLable} ${classes.rejectedcolor}`}
                >
                  <GoDotFill />
                  {queryParams?.tp === "route"
                    ? `${reportData?.rejected} rejected`
                    : `${reportData?.number_of_daysholiday} Holidays`}
                </Typography>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
           <b>{queryParams?.tp === "route" ? "Tasks Information" : "Attendance Details"}</b>
          </AccordionSummary>
          <AccordionDetails>
                  { queryParams?.tp === "route" ? 
                <>  
                <div className={classes.Reports_fields}>
                         
                          <div>
                            <Button
                              variant="outlined"
                              className={classesOne.filterbtns}
                              onClick={(e) => handleMenuclick(e, setAnchorElTwo)}
                              style={{ background: "white" }}
                            >
                              <div>
                                <FilterIcon /> {selectedValues[0] ?? "All"} ({filteredDatas && filteredDatas?.length})
                              </div>
                              <div>
                                <ArrowDownIcon />
                              </div>
                            </Button>
                            <Menu
                              anchorEl={anchorElTwo}
                              open={Boolean(anchorElTwo)}
                              onClose={()=>setAnchorElTwo(null)}
                            >
                              <div className={classesOne.filterHeading}>
                                <h5>Filter</h5>
                                <span
                                  className={classesOne.claerBtn}
                                  onClick={() => handleStatusCheckbox("all")}
                                >
                                  clear all
                                </span>
                              </div>
                              <MenuItem
                                    style={{
                                      padding: "6px 25px 6px 0",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    <Form.Check
                                      style={{
                                        margin: 10,
                                        marginRight: 15,
                                      }}
                                      checked={selectedValues.includes("completed")}
                                      onChange={() => handleStatusCheckbox("completed")}
                                      label={"Completed"}
                                    />
                                  </MenuItem>
                                  <MenuItem
                                    style={{
                                      padding: "6px 25px 6px 0",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    <Form.Check
                                      style={{
                                        margin: 10,
                                        marginRight: 15,
                                      }}
                                      checked={selectedValues.includes("pending")}
                                      onChange={() => handleStatusCheckbox("pending")}
                                      label={"Pending"}
                                    />
                                  </MenuItem>
                                  <MenuItem
                                    style={{
                                      padding: "6px 25px 6px 0",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    <Form.Check
                                      style={{
                                        margin: 10,
                                        marginRight: 15,
                                      }}
                                      checked={selectedValues.includes("rejected")}
                                      onChange={() => handleStatusCheckbox("rejected")}
                                      label={"Rejected"}
                                    />
                                  </MenuItem>
                              {/* {reportData?.daysReport?.map((item, index) => {
                                return (
                                  <MenuItem
                                    key={`sort${index}`}
                                    style={{
                                      padding: "6px 25px 6px 0",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    <Form.Check
                                      style={{
                                        margin: 10,
                                        marginRight: 15,
                                      }}
                                      checked={selectedValues.includes(item)}
                                      onChange={() => handleStatusCheckbox(item)}
                                      label={item.replaceAll("_", " ")}
                                    />
                                  </MenuItem>
                                );
                              })} */}
                            </Menu>
                          </div>
                          <div>
                            <Button
                              variant="outlined"
                              className={classesOne.sortingbtns}
                              onClick={(e) => handleSortMenuclick(e, setAnchorElThree)}
                              style={{ background: "white" }}
                            >
                              <div>
                                <ReportFilterIcon /> {selectedSortValues && selectedSortValues[0]}
                              </div>
                              <div>
                                <ArrowDownIcon />
                              </div>
                            </Button>
                            <Menu
                              anchorEl={anchorElThree}
                              open={Boolean(anchorElThree)}
                              onClose={()=>setAnchorElThree(null)}
                            >
                              <div className={classesOne.filterHeading}>
                                <h5>Filter</h5>
                                <span
                                  className={classesOne.claerBtn}
                                  onClick={() => handleSortStatusCheckbox("Oldest to Newest")}
                                >
                                  clear all
                                </span>
                              </div>
                              <MenuItem
                                    style={{
                                      padding: "6px 25px 6px 0",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    <Form.Check
                                      style={{
                                        margin: 10,
                                        marginRight: 15,
                                      }}
                                      checked={selectedSortValues.includes("Newest to Oldest")}
                                      onChange={() => handleSortStatusCheckbox("Newest to Oldest")}
                                      label={"Newest to Oldest"}
                                    />
                                  </MenuItem>
                                  <MenuItem
                                    style={{
                                      padding: "6px 25px 6px 0",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    <Form.Check
                                      style={{
                                        margin: 10,
                                        marginRight: 15,
                                      }}
                                      checked={selectedSortValues.includes("Oldest to Newest")}
                                      onChange={() => handleSortStatusCheckbox("Oldest to Newest")}
                                      label={"Oldest to Newest"}
                                    />
                                  </MenuItem>
                            </Menu>
                          </div>
                        </div>
                        {filteredDatas &&
                        filteredDatas?.length > 0 ? (
                          <div className={classes.TaskContainer}>
                            {filteredDatas?.map((item, index) => {
                              return (
                                <Paper
                                  key={`report${index}`}
                                  className={classes.TaskCard}
                                >
                                <Typography sx={{color:"black",fontSize:"16px",marginBottom:"12px",fontWeight:"500"}} >{item.Location?.title}</Typography>
                                <Box className={classes.bottom_box} >
                                  <Box>
                                    <Typography mb={1} fontSize={"15px"} >Status</Typography>
                                    <Typography  sx={{fontSize:"15px",color:item.status === "pending" ? "#836824" :item.status === "completed" ?  "#1A6B44" :"#AF0808",backgroundColor:item.status === "pending" ? "#F8F3E7":item.status === "completed" ?  "#E6F9F0":"#FEE2E2",padding:"3px 15px",borderRadius:"14px", textTransform:"capitalize"}} >{item.status}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography mb={1} fontSize={"15px"} >Assigned On:</Typography>
                                    <Typography sx={{color:"black",fontSize:"15px"}}> 
                                      {moment(item.createdAt).format('DD-MMMM-YYYY')},
                                      {moment(item.createdAt).format('hh:mm:ss A')}
                                    </Typography>
                                  </Box>
                                </Box>
                                </Paper>
                              );
                            })}
                          </div>
                        ) : null} 
                        </> :
                        <>  
                        <div className={classes.Reports_fields}>
                                 
                                  <div>
                                    <Button
                                      variant="outlined"
                                      className={classesOne.filterbtns}
                                      onClick={(e) => handleMenuclick(e, setAnchorElTwo)}
                                      style={{ background: "white" }}
                                    >
                                      <div>
                                        <FilterIcon /> {selectedValues[0] ?? "All"} ({filteredDatas && filteredDatas?.length})
                                      </div>
                                      <div>
                                        <ArrowDownIcon />
                                      </div>
                                    </Button>
                                    <Menu
                                      anchorEl={anchorElTwo}
                                      open={Boolean(anchorElTwo)}
                                      onClose={()=>setAnchorElTwo(null)}
                                    >
                                      <div className={classesOne.filterHeading}>
                                        <h5>Filter</h5>
                                        <span
                                          className={classesOne.claerBtn}
                                          onClick={() => handleStatusCheckbox("all")}
                                        >
                                          clear all
                                        </span>
                                      </div>
                                      <MenuItem
                                            style={{
                                              padding: "6px 25px 6px 0",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <Form.Check
                                              style={{
                                                margin: 10,
                                                marginRight: 15,
                                              }}
                                              checked={selectedValues.includes("Absent")}
                                              onChange={() => handleStatusCheckbox("Absent")}
                                              label={"Absent"}
                                            />
                                          </MenuItem>
                                          <MenuItem
                                            style={{
                                              padding: "6px 25px 6px 0",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <Form.Check
                                              style={{
                                                margin: 10,
                                                marginRight: 15,
                                              }}
                                              checked={selectedValues.includes("Present")}
                                              onChange={() => handleStatusCheckbox("Present")}
                                              label={"Present"}
                                            />
                                          </MenuItem>
                                          <MenuItem
                                            style={{
                                              padding: "6px 25px 6px 0",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <Form.Check
                                              style={{
                                                margin: 10,
                                                marginRight: 15,
                                              }}
                                              checked={selectedValues.includes("Holiday")}
                                              onChange={() => handleStatusCheckbox("Holiday")}
                                              label={"Holiday"}
                                            />
                                          </MenuItem>
                                      {/* {reportData?.daysReport?.map((item, index) => {
                                        return (
                                          <MenuItem
                                            key={`sort${index}`}
                                            style={{
                                              padding: "6px 25px 6px 0",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <Form.Check
                                              style={{
                                                margin: 10,
                                                marginRight: 15,
                                              }}
                                              checked={selectedValues.includes(item)}
                                              onChange={() => handleStatusCheckbox(item)}
                                              label={item.replaceAll("_", " ")}
                                            />
                                          </MenuItem>
                                        );
                                      })} */}
                                    </Menu>
                                  </div>
                                  <div>
                                    <Button
                                      variant="outlined"
                                      className={classesOne.sortingbtns}
                                      onClick={(e) => handleSortMenuclick(e, setAnchorElThree)}
                                      style={{ background: "white" }}
                                    >
                                      <div>
                                        <ReportFilterIcon /> {selectedSortValues && selectedSortValues[0]}
                                      </div>
                                      <div>
                                        <ArrowDownIcon />
                                      </div>
                                    </Button>
                                    <Menu
                                      anchorEl={anchorElThree}
                                      open={Boolean(anchorElThree)}
                                      onClose={()=>setAnchorElThree(null)}
                                    >
                                      <div className={classesOne.filterHeading}>
                                        <h5>Filter</h5>
                                        <span
                                          className={classesOne.claerBtn}
                                          onClick={() => handleSortStatusCheckbox("Oldest to Newest")}
                                        >
                                          clear all
                                        </span>
                                      </div>
                                      <MenuItem
                                            style={{
                                              padding: "6px 25px 6px 0",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <Form.Check
                                              style={{
                                                margin: 10,
                                                marginRight: 15,
                                              }}
                                              checked={selectedSortValues.includes("Newest to Oldest")}
                                              onChange={() => handleSortStatusCheckbox("Newest to Oldest")}
                                              label={"Newest to Oldest"}
                                            />
                                          </MenuItem>
                                          <MenuItem
                                            style={{
                                              padding: "6px 25px 6px 0",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <Form.Check
                                              style={{
                                                margin: 10,
                                                marginRight: 15,
                                              }}
                                              checked={selectedSortValues.includes("Oldest to Newest")}
                                              onChange={() => handleSortStatusCheckbox("Oldest to Newest")}
                                              label={"Oldest to Newest"}
                                            />
                                          </MenuItem>
                                    </Menu>
                                  </div>
                                </div>
                                {filteredDatas &&
                                filteredDatas?.length > 0 ? (
                                  <div className={classesOne.AttendanceContainer}>
                                    {filteredDatas?.map((item, index) => {
                                      return (
                                        <Paper
                                          key={`report${index}`}
                                          className={classesOne.AttendanceCard}
                                        >
                                          <div className={classesOne.attendanceCardName}>
                                            <p>{moment(item.date).format("D")}</p>
                                            <p>{moment(item.date).format("MMM")}</p>
                                          </div>
                                          <div className={classesOne.attendanceCardName2}>
                                            <Typography
                                              style={{
                                                display: "flex",
                                                textTransform: "capitalize",
                                              }}
                                              className={classesOne.step_label}
                                            >
                                              <div
                                                style={{
                                                  background:
                                                    item.status === "Present"
                                                      ? "#22A06B"
                                                      : "#EA3A3A",
                                                  width: "13px",
                                                  height: "13px",
                                                  borderRadius: "50%",
                                                  marginTop: "5px",
                                                  marginRight: "5px",
                                                }}
                                              ></div>{" "}
                                              {item.status}
                                            </Typography>
                                            <Typography
                                              className={classesOne.sub_step_label}
                                              style={{ marginTop: "5px" }}
                                            >
                                              {ln.work_duration} :{" "}
                                              {/* {getDurationDetails(item.duration)} */}
                                              {item.duration}
                                            </Typography>
                                          </div>
                                        </Paper>
                                      );
                                    })}
                                  </div>
                                ) : null} 
                                </>  
                }
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default ViewReport;
