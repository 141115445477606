import React, { useEffect, useState } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import image from "../../../assets/images/sucessImge.png";
import classes from "./myprofile.module.css";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagedataFunction } from "../../../utilities/language";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import {
  ADD_DEPARTMENT,
  COMPANY,
  PASSMATCH,
  TEAM_LIST,
  UPDATE_USER,
} from "../../../api-calls/api-urls";
import { CloseEyeIcon, OpenEyeIcon } from "../../../components/Icons";

const DeleteConformation = (props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const [success, setSuccess] = useState(false);
  const rootRef = React.useRef(null);
  const [modalToggle, setModalToggle] = useState(true);
  const company = useSelector((state) => state.reducers.allUsers.company);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const modalClose = (data) => {
    props.modalClose(false);
    setModalToggle(false);
  };

  useEffect(() => {
    if (props.type !== "company_details" && props.type !== "personal_details") {
      console.log("it trifggers");
      setSuccess(true);
    }
  }, [props.type === "password"]);

  const gotoSecondScrren = async () => {
    if (props.type === "personal_details") {
      const { data } = await authApi.put(
        `${UPDATE_USER}/${userInfo._id}`,
        props.userEntry,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      userInfo.profile_pic = props.userEntry.profile_pic;
      sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
      setSuccess(true);
    } else {
      const { data } = await authApi.put(
        `${COMPANY}/${company._id}`,
        props.userEntry,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      setSuccess(true);
    }
  };

  return (
    <div>
      {ln && props ? (
        <div>
          <Modal
            open={modalToggle}
            onClose={() => modalClose()}
            aria-labelledby="server-modal-title"
            data-testid="register_modal"
            aria-describedby="server-modal-description"
            sx={{
              display: "flex",
              height: success ? 400 : 480,
              marginTop: 10,
              justifyContent: "center",
            }}
            container={() => rootRef.current}
          >
            <Box
              sx={{
                position: "relative",
                bgcolor: "background.paper",
                borderRadius: 1,
                boxShadow: (theme) => theme.shadows[5],
              }}
              style={{
                width: success ? "500px" : "460px",
                height: "max-content",
                top: "20%",
              }}
              className={classes.loginModalWidth}
            >
              {success ? (
                <Button
                  variant="text"
                  sx={{ minWidth: "unset" }}
                  className={classes.closebtn}
                  onClick={() => modalClose()}
                >
                  <MdClose />
                </Button>
              ) : null}
              {!success && props.type ? (
                <div className={classes.deleteConformationFirstDiv}>
                  <Typography className={classes.successPageSubText}>
                    Are you sure you want to update{" "}
                    {props.type.replace("_", " ")}
                  </Typography>
                  <div className={classes.addMemberModalbtnSection}>
                    <Button variant="outlined" onClick={() => modalClose()}>
                      No
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => gotoSecondScrren()}
                    >
                      Yes
                    </Button>
                  </div>
                </div>
              ) : (
                <div className={classes.addMembersuccess}>
                  <img src={image} alt="success" width="auto" height="auto" />
                  <Typography className={classes.imagePageText}>
                    {props.type !== "password"
                      ? `${
                          props.type.charAt(0).toUpperCase() +
                          props.type.slice(1).replace("_", " ")
                        } has been updated`
                      : "Password has been changed"}
                  </Typography>
                  <Typography className={classes.successPageSubText}>
                    {props.type !== "password"
                      ? `Your ${props.type.replace(
                          "_",
                          " "
                        )} has been updated successfully !`
                      : "Your password has been changed successfully !"}
                  </Typography>
                </div>
              )}
            </Box>
          </Modal>
        </div>
      ) : null}
    </div>
  );
};

export default DeleteConformation;
