import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classes from "./location.module.css";
import WaveSurfer from "wavesurfer.js";
import { RiPlayCircleFill, RiPauseCircleFill } from "react-icons/ri";
import { Button, Typography } from "@mui/material";
import { FaPlay, FaPause } from "react-icons/fa";
import { AudioPlayIcon } from "../../../components/Icons";

const Waveform = ({ audio }) => {
  const containerRef = useRef();
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const waveSurferRef = useRef({
    isPlaying: () => false,
  });
  const [isPlaying, toggleIsPlaying] = useState(false);

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: "#waveform",
      hideScrollbar: true,
      waveColor: "#CDD5E5",
      progressColor: "blue",
      cursorColor: "black",
      barWidth: 3, // Adjust the width of the bar as per your preference
      barHeight: 10,
      minPxPerSec: 10, // Adjust the height of the bar as per your preference
      barGap: 2, // Adjust the gap between bars as per your preference
      height: 18,
    });
    waveSurfer.load(audio);
    // waveSurfer.on("ready", () => {
    //   waveSurferRef.current = waveSurfer;
    // });

    waveSurfer.on("ready", () => {
      setDuration(waveSurfer.getDuration());
      waveSurferRef.current = waveSurfer;
    });

    waveSurfer.on("audioprocess", () => {
      setCurrentTime(waveSurfer.getCurrentTime());
    });

    return () => {
      waveSurfer.destroy();
    };
  }, [audio]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div className={classes.AudioPlayerContainer}>
      <Button
        className={classes.AudioPlayerBtn}
        onClick={() => {
          waveSurferRef.current.playPause();
          toggleIsPlaying(waveSurferRef.current.isPlaying());
        }}
        type="button"
        style={{ height: "18px" }}
      >
        {isPlaying ? <FaPause /> : <AudioPlayIcon />}
      </Button>
      <div id="waveform" className={classes.waveForm} />
      <Typography className={classes.audioTimerText}>
        {formatTime(currentTime)}/{formatTime(duration)}
      </Typography>
    </div>
  );
};

Waveform.propTypes = {
  audio: PropTypes.string.isRequired,
};

export default Waveform;
