import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import classes from "./myteam.module.css";
import IndexTab from "./Index";
import Box from "@mui/material/Box";
import DepartmentIndex from "./departmentIndex.js";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagedataFunction } from "../../../utilities/language";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index, value) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    className:
      index === value
        ? `${classes.selectedTab} ${classes.TabsLableHeader}`
        : classes.TabsLableHeader,
  };
}

export default function MyTeam() {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {ln ? (
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: {
                  height: "4px",
                },
              }}
            >
              <Tab
                label={ln.teams}
                {...a11yProps(0, value)}
                style={{ textTransform: "capitalize" }}
              />
              <Tab
                label={ln.departments}
                {...a11yProps(1, value)}
                style={{ textTransform: "capitalize" }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} className={classes.TabPanel}>
            <IndexTab name={"team"} />
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.TabPanel}>
            <DepartmentIndex />
          </TabPanel>
        </Box>
      ) : null}
    </div>
  );
}
