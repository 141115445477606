import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import classes from "./myteam.module.css";
import Select from "@mui/material/Select";
import moment from "moment";
import dayjs from "dayjs";
import { Form } from "react-bootstrap";
import {
  Button,
  Paper,
  Typography,
  FormControl,
  Menu,
  Checkbox,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Stepper from "./stepper";
import { TEAM_MEMBER_DETAILS } from "../../../api-calls/api-urls";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagedataFunction } from "../../../utilities/language";
import {
  ArrowDownIcon,
  DatePickerCalendarIcon,
  FilterIcon,
  SearchIcon,
} from "../../../components/Icons";
import {
  TeamMemberAttendanceFilter,
  TeamMemberData,
} from "../../../Redux/actions/team-actions";
import { attendaceFilter } from "../../../utilities/constants";
import { useEffect } from "react";
import { setHeaderName } from "../../../Redux/actions/user-actions";

const TeamMemberRepots = () => {
  const [reportType, setReportType] = React.useState("");
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [showReport, setShowReport] = useState(false);
  const [routeData, setRouteData] = useState([]);
  const [downloadType, setDownloadType] = useState("downloadas");
  const [anchorEl, setAnchorEl] = useState(null);
  const [attendanceReport, setAttendanceReport] = useState();
  const [selectedValues, setSelectedValues] = useState([]);
  const { teamMemberData } = useSelector((state) => state.reducers.teams);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const getTeamMemberData = async () => {
    const path = window.location.pathname.split("/");
    const { data } = await authApi.post(
      `${TEAM_MEMBER_DETAILS}/${path[path.length - 1]}`,
      {},
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    dispatch(TeamMemberData(data));
  };

  useEffect(() => {
    getTeamMemberData();
  }, [!teamMemberData]);

  const handleStatusCheckbox = (value) => {
    const isSelected = selectedValues.includes(value);
    console.log(selectedValues, isSelected);
    if (isSelected) {
      if (value === "all") {
        setSelectedValues([]);
      } else {
        setSelectedValues(selectedValues.filter((item) => item !== value));
      }
    } else {
      if (value === "all") {
        setSelectedValues([...attendaceFilter]);
      } else {
        setSelectedValues([value]);
      }
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const clickClearAll = (name) => {
    name([]);
  };

  useEffect(() => {
    if (teamMemberData) {
      dispatch(setHeaderName(teamMemberData.name));
    }
  }, [teamMemberData]);

  const handleMenuclick = (event, name) => {
    console.log(event.currentTarget);
    name(event.currentTarget);
  };

  const handleChange = async (event) => {
    setReportType(event.target.value);
    setShowReport(false);
  };

  const SearchReport = async () => {
    const path = window.location.pathname.split("/");
    // console.log(event.target.value);
    if (reportType === "attendance") {
      console.log("Clicked for Attendance");
      const { data } = await authApi.get(
        `attendance/${teamMemberData.user_id._id}?startDate=${moment(
          startDate
        ).format("YYYY-MM-DD")}&endDate=${moment(endDate).format(
          "YYYY-MM-DD"
        )}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      console.log(data);
      setAttendanceReport(data);
    } else if (reportType === "route") {
      const { data } = await authApi.get(
        `${TEAM_MEMBER_DETAILS}/${
          path[path.length - 1]
        }/routes?start_date=${moment(startDate).format(
          "YYYY-MM-DD"
        )}&end_date=${moment(endDate).format("YYYY-MM-DD")}&view_all=${true}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      console.log(data.locations);
      setRouteData(data.locations);
    }
    setShowReport(true);
  };

  const getDurationDetails = (duration) => {
    if (duration >= 0 && duration < 1) {
      // Convert duration to seconds
      const seconds = Math.round(duration * 60);
      return `${seconds} sec`;
    } else if (duration >= 1 && duration < 60) {
      // Convert duration to minutes and seconds
      const minutes = Math.floor(duration);
      const seconds = Math.round((duration - minutes) * 60);
      return `${minutes} min ${seconds} sec`;
    } else {
      // Convert duration to hours, minutes, and seconds
      const hours = Math.floor(duration / 60);
      const remainingMinutes = Math.floor(duration % 60);
      const seconds = Math.round((duration % 1) * 60);
      return `${hours} hr ${remainingMinutes} min ${seconds} sec`;
    }
  };

  const filterAttendanceData = () => {
    if (selectedValues.length === 0 || selectedValues.includes("all")) {
      return attendanceReport.daysReport;
    }

    // Filter the data based on the selected filter values
    return attendanceReport.daysReport.filter((item) => {
      return selectedValues.includes(item.status);
    });
  };

  const handleChangeDownloadType = async (value) => {
    const { data } = await authApi.get(
      `report/${reportType}-report/download/${teamMemberData.phone}/${
        userInfo._id
      }?startDate=${dayjs(startDate).format("MM-DD-YYYY")}&endDate=${dayjs(
        endDate
      ).format("MM-DD-YYYY")}&type=${value}`,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    // console.log(data);
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    console.log(blob);

    // Create a URL for the blob
    const backendUrl = `${
      process.env.REACT_APP_API_URL
    }/report/${reportType}-report/download/${teamMemberData.phone}/${
      userInfo._id
    }?startDate=${dayjs(startDate).format("MM-DD-YYYY")}&endDate=${dayjs(
      endDate
    ).format("MM-DD-YYYY")}&type=${value}`;

    // Create a link element
    const a = document.createElement("a");
    a.href = backendUrl;
    a.download =
      value === "pdf"
        ? `${reportType}Report(${teamMemberData.user_id.name}).pdf`
        : `${reportType}Report(${teamMemberData.user_id.name}).xlsx`; // Optional: Set the desired file name
    document.body.appendChild(a);

    // Trigger a click event on the link to initiate the download
    a.click();
  };

  return (
    <div>
      {ln ? (
        <div>
          <div className={classes.ReportTextFieldsContainer}>
            <div>
              <Typography
                className={`${classes.teamPageHeading2} ${classes.textLable}`}
              >
                {ln.report_type}
              </Typography>
              <Select
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  width: "100%",
                  background: "white",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={reportType}
                size="small"
                label="Age"
                onChange={handleChange}
              >
                <MenuItem value={"attendance"}>Attendance</MenuItem>
                <MenuItem value={"route"}>Route Report</MenuItem>
              </Select>
            </div>
            <div>
              <Typography
                className={`${classes.teamPageHeading2} ${classes.textLable}`}
              >
                {ln.start_date}
              </Typography>
              <div className={classes.datePicker1}>
                <DatePicker
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  size="small"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                  dateFormat="yyyy-MM-dd"
                  maxDate={new Date()}
                />
                <span
                  className={classes.datePickerCalendarIcon}
                  style={{ top: "7px" }}
                >
                  <DatePickerCalendarIcon />
                </span>
              </div>
            </div>
            <div>
              <Typography
                className={`${classes.teamPageHeading2} ${classes.textLable}`}
              >
                {ln.end_date}
              </Typography>
              <div className={classes.datePicker1}>
                <DatePicker
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  size="small"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  placeholderText="End Date"
                  dateFormat="yyyy-MM-dd"
                  maxDate={new Date()}
                />
                <span
                  className={classes.datePickerCalendarIcon}
                  style={{ top: "7px" }}
                >
                  <DatePickerCalendarIcon />
                </span>
              </div>
            </div>
            <div>
              <Typography
                className={`${classes.teamPageHeading2} ${classes.textLable}`}
              ></Typography>
              <Button
                variant="contained"
                className={classes.teamMemberBtn}
                onClick={() => SearchReport()}
              >
                <span
                  className={classes.RepotsearchIcon}
                  style={{ width: "16px", marginRight: "7px" }}
                >
                  <SearchIcon />{" "}
                </span>{" "}
                {ln.search}
              </Button>
            </div>
          </div>
          <div className={classes.DownloadTypeBtn}>
            <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
              {/* <InputLabel style={{}}>Download As</InputLabel> */}
              <Select
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                value={downloadType}
                label="Download As"
                size="small"
                onChange={(e) => handleChangeDownloadType(e.target.value)}
              >
                <MenuItem value={"downloadas"}>Download As</MenuItem>
                <MenuItem value={"pdf"}>PDF</MenuItem>
                <MenuItem value={"excel"}>Excel</MenuItem>
              </Select>
            </FormControl>
          </div>
          {reportType && reportType === "route" && showReport ? (
            <div>
              <Typography>LocationRoute</Typography>

              <Paper
                className={`${classes.TeamMemberDetailsPage1} ${classes.TeamMemberReport}`}
              >
                {routeData && routeData.length > 0 ? (
                  <Stepper locationReport={routeData} />
                ) : null}
              </Paper>
            </div>
          ) : null}
          {reportType && reportType === "attendance" && showReport ? (
            <div>
              <Paper
                className={`${classes.TeamMemberDetailsPage1} ${classes.TeamMemberReport}`}
              >
                <div>
                  <div className={classes.TeamMemberReportHEadContaimner}>
                    <Typography className={classes.teamPageHeading}>
                      {startDate?.getMonth() === endDate?.getMonth()
                        ? moment(startDate).format("MMM YYYY")
                        : `${moment(startDate).format("MMM YYYY")} - ${moment(
                            endDate
                          ).format("MMM YYYY")}`}
                    </Typography>
                    <div>
                      <Button
                        variant="outlined"
                        className={classes.filterbtns}
                        onClick={(e) => handleMenuclick(e, setAnchorEl)}
                        style={{ background: "white" }}
                      >
                        <div>
                          <FilterIcon /> All
                        </div>
                        <div>
                          <ArrowDownIcon />
                        </div>
                      </Button>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                        <div className={classes.filterHeading}>
                          <h5>Filter</h5>
                          <span
                            className={classes.claerBtn}
                            onClick={() => clickClearAll(setSelectedValues)}
                          >
                            clear all
                          </span>
                        </div>
                        {attendaceFilter.map((item, index) => {
                          return (
                            <MenuItem
                              key={`sort${index}`}
                              style={{
                                padding: "6px 25px 6px 0",
                                textTransform: "capitalize",
                              }}
                            >
                              <Form.Check
                                style={{
                                  margin: 10,
                                  marginRight: 15,
                                }}
                                checked={selectedValues.includes(item)}
                                onChange={() => handleStatusCheckbox(item)}
                                label={item.replaceAll("_", " ")}
                              />
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </div>
                  </div>
                  {attendanceReport &&
                  attendanceReport.daysReport.length > 0 ? (
                    <div className={classes.AttendanceContainer}>
                      {filterAttendanceData()?.map((item, index) => {
                        return (
                          <Paper
                            key={`report${index}`}
                            className={classes.AttendanceCard}
                          >
                            <div className={classes.attendanceCardName}>
                              <p>{moment(item.date).format("D")}</p>
                              <p>{moment(item.date).format("MMM")}</p>
                            </div>
                            <div className={classes.attendanceCardName2}>
                              <Typography
                                style={{
                                  display: "flex",
                                  textTransform: "capitalize",
                                }}
                                className={classes.step_label}
                              >
                                <div
                                  style={{
                                    background:
                                      item.status === "Present"
                                        ? "#22A06B"
                                        : "#EA3A3A",
                                    width: "13px",
                                    height: "13px",
                                    borderRadius: "50%",
                                    marginTop: "5px",
                                    marginRight: "5px",
                                  }}
                                ></div>{" "}
                                {item.status}
                              </Typography>
                              <Typography
                                className={classes.sub_step_label}
                                style={{ marginTop: "5px" }}
                              >
                                {ln.work_duration} :{" "}
                                {getDurationDetails(item.duration)}
                              </Typography>
                            </div>
                          </Paper>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </Paper>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default TeamMemberRepots;
