import React, { useState } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import image from "../../../assets/images/sucessImge.png";
import classes from "./location.module.css";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagedataFunction } from "../../../utilities/language";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { LOCATION } from "../../../api-calls/api-urls";
import { useNavigate } from "react-router-dom";

const ReassignConfirmationModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [atFirst, setAtFirst] = useState(true);
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const [success, setSuccess] = useState(false);
  const rootRef = React.useRef(null);
  const [modalToggle, setModalToggle] = useState(true);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const modalClose = (name) => {
    if (name === "no") {
      props.modalClose(false, false);
      setModalToggle(false);
    } else {
      props.modalClose(false, true);
      setModalToggle(false);
    }
  };

  const handleDelete = async () => {
    const { data } = await authApi.post(
      `${LOCATION}/${props.userEntry.location_id}/assign`,
      props.userEntry,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    console.log(data);
    setAtFirst(false);
    setSuccess(true);
  };

  return (
    <div>
      {ln ? (
        <div>
          <Modal
            open={modalToggle}
            onClose={() => modalClose("")}
            aria-labelledby="server-modal-title"
            data-testid="register_modal"
            aria-describedby="server-modal-description"
            sx={{
              display: "flex",
              marginTop: 10,
              justifyContent: "center",
            }}
            container={() => rootRef.current}
          >
            <Box
              sx={{
                position: "relative",
                bgcolor: "background.paper",
                borderRadius: 1,
                boxShadow: (theme) => theme.shadows[5],
              }}
              style={{
                width: success ? "500px" : "460px",
                height: "max-content",
                top: "20%",
              }}
              className={classes.loginModalWidth}
            >
              {!atFirst ? (
                <Button
                  variant="text"
                  sx={{ minWidth: "unset" }}
                  className={classes.closebtn}
                  onClick={() => modalClose("")}
                >
                  <MdClose />
                </Button>
              ) : null}
              {atFirst && !success ? (
                <div
                  className={classes.deleteConformationFirstDiv}
                  style={{ height: "max-content", textAlign: "center" }}
                >
                  <Typography
                    className={classes.ReassignConfirmationModalHeading}
                  >
                    Reassign Location
                  </Typography>
                  <Typography className={classes.successPageSubText}>
                    Are you sure you want to reassign the location to{" "}
                    {props.teamMember} ?
                  </Typography>
                  <div
                    className={classes.addMemberModalbtnSection}
                    style={{ marginTop: "25px" }}
                  >
                    <Button variant="outlined" onClick={() => modalClose("no")}>
                      No
                    </Button>
                    <Button variant="contained" onClick={() => handleDelete()}>
                      Yes
                    </Button>
                  </div>
                </div>
              ) : null}
              {!atFirst && success ? (
                <div className={classes.addMembersuccess}>
                  <img src={image} alt="success" width="auto" height="auto" />
                  <Typography className={classes.imagePageText1}>
                    Location Reassigned
                  </Typography>
                  <Typography className={classes.successPageSubText}>
                    Location has been Reassigned successfully to{" "}
                    {props.teamMember}
                  </Typography>
                </div>
              ) : null}
            </Box>
          </Modal>
        </div>
      ) : null}
    </div>
  );
};

export default ReassignConfirmationModal;
