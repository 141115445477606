import React from "react";
import classes from "./index.module.css";
import { Paper, Typography } from "@mui/material";

const TermConditions = () => {
  return (
    <div style={{ marginTop: "20px" }}>
      <Paper style={{ padding: "30px" }}>
        <Typography className={`mb-3 ${classes.mainText}`}>
          1. What is lorem ipsum?
        </Typography>
        <Typography className={`mb-4 ${classes.form_lable}`}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
          commodo mi dolor, sed viverra ipsum gravida vel. Morbi gravida viverra
          nisi, vel rhoncus enim suscipit. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Pellentesque commodo mi dolor, sed
          viverra ipsum gravida vel. Morbi gravida viverra nisi, vel rhoncus
          enim suscipit.
        </Typography>
        <Typography className={`mb-3 ${classes.mainText}`}>
          2. What is lorem ipsum?
        </Typography>
        <Typography className={`mb-4 ${classes.form_lable}`}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
          commodo mi dolor, sed viverra ipsum gravida vel. Morbi gravida viverra
          nisi, vel rhoncus enim suscipit. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Pellentesque commodo mi dolor, sed
          viverra ipsum gravida vel. Morbi gravida viverra nisi, vel rhoncus
          enim suscipit.
        </Typography>
        <Typography className={`mb-3 ${classes.mainText}`}>
          3. What is lorem ipsum?
        </Typography>
        <Typography className={`mb-4 ${classes.form_lable}`}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
          commodo mi dolor, sed viverra ipsum gravida vel. Morbi gravida viverra
          nisi, vel rhoncus enim suscipit. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Pellentesque commodo mi dolor, sed
          viverra ipsum gravida vel. Morbi gravida viverra nisi, vel rhoncus
          enim suscipit.
        </Typography>
        <Typography className={`mb-3 ${classes.mainText}`}>
          4. What is lorem ipsum?
        </Typography>
        <Typography className={`mb-4 ${classes.form_lable}`}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
          commodo mi dolor, sed viverra ipsum gravida vel. Morbi gravida viverra
          nisi, vel rhoncus enim suscipit. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Pellentesque commodo mi dolor, sed
          viverra ipsum gravida vel. Morbi gravida viverra nisi, vel rhoncus
          enim suscipit.
        </Typography>
        <Typography className={`mb-3 ${classes.mainText}`}>
          5. What is lorem ipsum?
        </Typography>
        <Typography className={`${classes.form_lable}`}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
          commodo mi dolor, sed viverra ipsum gravida vel. Morbi gravida viverra
          nisi, vel rhoncus enim suscipit. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Pellentesque commodo mi dolor, sed
          viverra ipsum gravida vel. Morbi gravida viverra nisi, vel rhoncus
          enim suscipit.
        </Typography>
      </Paper>
    </div>
  );
};

export default TermConditions;
