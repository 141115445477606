import { actionTypes } from "../constant/action-types";

export function saveImageList(data) {
  // const serializedImageList = data.map((file) => ({
  //   name: file.name,
  //   size: file.size,
  //   type: file.type,
  //   // Add more properties if needed
  // }));
  return {
    type: actionTypes.IMAGE_LIST,
    payload: data,
  };
}

export function saveLocationList(data) {
  return {
    type: actionTypes.LOCATION_LIST,
    payload: data,
  };
}

export function saveLoctionRangeFilter(data) {
  return {
    type: actionTypes.LOCATION_RANGE_FILTER,
    payload: data[0],
  };
}

export function SaveAudio(data) {
  return {
    type: actionTypes.AUDIO_LIST,
    payload: data,
  };
}

export function saveLocationStatusFilter(data) {
  return {
    type: actionTypes.LOCATION_STATUS_FILTER,
    payload: data,
  };
}

export function saveLocationTimeFilter(data) {
  return {
    type: actionTypes.LOCATION_TIME_FILTER,
    payload: data,
  };
}

export function saveLocationMembersFilter(data) {
  return {
    type: actionTypes.LOCATION_MEMBERS_FILTER,
    payload: data,
  };
}

export const SaveViewAll = (data) => ({
  type: actionTypes.VIEW_ALL,
  payload: data,
});
