import React, { useEffect, useState } from "react";
import image from "../../../assets/images/NoTeam.png";
import Paper from "@mui/material/Paper";
import { Avatar } from "@mui/material";
import { Grid, Card } from "@mui/material";
import classes from "./myteam.module.css";
import { CardContent, MenuItem, Menu, Typography } from "@mui/material";
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { IoAddCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { TEAM_LIST } from "../../../api-calls/api-urls";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import { generateColor, stringAvatar } from "../../../utilities/commonFuntions";
import {
  currentEditTeam,
  teamsList,
} from "../../../Redux/actions/team-actions";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { getLanguagedataFunction } from "../../../utilities/language";
import {
  DeleteBinIcons,
  EditPenIcon,
  RoundAddIcon,
} from "../../../components/Icons";
import DeleteConformation from "./DeleteConformation";

const IndexTab = (props) => {
  const navigate = useNavigate();
  const [dataList, setDataList] = useState();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState();
  const [selected, setSelected] = useState();
  const teamList = useSelector((state) => state.reducers.teams.teamsList);
  const [deleteModal, setDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  const [btnName, setBtnName] = useState(ln ? ln.add_team : null);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const handleMenuclick = (event, item) => {
    event.stopPropagation();
    setSelected(item);
    setAnchorEl(event.currentTarget);
  };

  const handleCardClick = (event, item) => {
    console.log("cards click");
    const name = item.slug.toLowerCase();
    console.log(name);
    navigate(`/my-team/${name}`);
    event.preventDefault();
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuItemClick = async (event, name) => {
    event.stopPropagation();
    if (name === "edit") {
      dispatch(currentEditTeam(selected));
      navigate(`/my-team/update/${selected.slug.toLowerCase()}`);
      handleClose(event);
    } else if (name === "delete") {
      setDeleteModal(true);
      handleClose(event);
      // const { data } = await authApi.delete(`${TEAM_LIST}/${selected._id}`, {
      //   headers: { Authorization: getAuthorizationHeader() },
      // });
      // console.log(data);
      // getData();
    }
  };
  const handleClick = () => {
    dispatch(currentEditTeam());
    navigate("/my-team/create-team");
  };
  useEffect(() => {
    if (props && (props.name !== "team") & ln) {
      setBtnName(ln.create_department);
    }
  }, []);
  useEffect(() => {
    if (props && props.name === "team") {
      getData();
    } else {
      dispatch(teamsList(null));
    }
  }, [!teamList]);

  const closeModal = () => {
    setDeleteModal(false);
    getData();
  };

  const getData = async () => {
    const { data } = await authApi.get(TEAM_LIST, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    dispatch(teamsList(data));
  };
  return (
    <div>
      {ln ? (
        <div>
          {teamList && teamList.length > 0 ? (
            <div className={classes.indexpageMainBtn}>
              <Button variant="contained" onClick={() => handleClick()}>
                <RoundAddIcon />
                <span style={{ marginLeft: "7px" }}>{btnName}</span>
              </Button>
            </div>
          ) : null}
          <Paper
            className={classes.CreateTeamindex}
            style={{ padding: "0 20px" }}
          >
            {teamList && teamList.length > 0 ? (
              <div>
                {/* <div className={classes.TaskContainerheader}>
                  <div>
                    <Form.Check
                      className={classes.taskCheckbox}
                      inline
                      value={checked}
                      onChange={(e) => setChecked(e.target.checked)}
                      label={`0/${teamList.length} teams selected`}
                      name="group1"
                      type="checkbox"
                    />
                  </div>
                  <div className={classes.deleteIcon}>
                    <RiDeleteBin6Line />
                  </div>
                </div> */}
                <div>
                  <div className={classes.GridContainer}>
                    {teamList.map((item, index) => {
                      return (
                        <Card
                          key={index}
                          className={classes.teamCard}
                          onClick={(e) => handleCardClick(e, item)}
                        >
                          <CardContent className={classes.teamCardContainer}>
                            <Avatar
                              {...stringAvatar(item.team_name)}
                              style={{
                                backgroundColor: generateColor(item.team_name),
                              }}
                            />
                            <div className={classes.TeamcardText}>
                              <Typography className={classes.TeamcardMainText}>
                                {item.team_name}
                              </Typography>
                              <Typography
                                className={`${classes.TeamcardSubText} ${classes.teamPageHeading2}`}
                              >
                                {ln.total_members}:{" "}
                                <span style={{ fontWeight: 600 }}>
                                  {item.team_members &&
                                  item.team_members[0].user_name
                                    ? item.team_members.length
                                    : 0}
                                </span>
                              </Typography>
                            </div>
                            <div className={classes.cardMenuButton}>
                              <Button
                                variant="text"
                                className={classes.CardMenu}
                                onClick={(e) => handleMenuclick(e, item)}
                              >
                                <BsThreeDotsVertical />
                              </Button>
                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                              >
                                <MenuItem
                                  key={`edit${item._id}`}
                                  className={classes.EditMenuTeam}
                                  onClick={(e) =>
                                    handleMenuItemClick(e, "edit")
                                  }
                                >
                                  <EditPenIcon />
                                  <span>{ln.edit_team}</span>
                                </MenuItem>
                                <MenuItem
                                  key={`delete${item._id}`}
                                  onClick={(e) =>
                                    handleMenuItemClick(e, "delete")
                                  }
                                >
                                  <DeleteBinIcons />
                                  <span>{ln.delete_team}</span>
                                </MenuItem>
                              </Menu>
                            </div>
                          </CardContent>
                        </Card>
                      );
                    })}
                  </div>
                </div>
                {deleteModal ? (
                  <DeleteConformation
                    modalClose={closeModal}
                    type={"Team"}
                    selected={selected}
                  />
                ) : null}
              </div>
            ) : (
              <div className={classes.imagePagemaindiv}>
                <img
                  src={image}
                  alt="createTeam"
                  className={classes.CreateTeamimage}
                />
                <Typography className={classes.imagePageText} variant="h5">
                  You don't have any teams currently. Please create a team to
                  view here.
                </Typography>
                <Button
                  variant="contained"
                  className={`${classes.imagepagebtn} ${classes.noDataImagePAge}`}
                  onClick={() => handleClick()}
                >
                  <RoundAddIcon />
                  <span>{btnName}</span>
                </Button>
              </div>
            )}
          </Paper>
        </div>
      ) : null}
    </div>
  );
};

export default IndexTab;
