import React, { useEffect, useState } from "react";
import axios from "axios";
import ImageCarosel from "../../../components/ImageCarosel";
import classes from "./index.module.css";
import AppLogo from "../../../assets/images/mainLogo.png";
import { api } from "../../../api-calls/user-requests";
import { pageLoader } from "../../../Redux/actions/user-actions";
import {
  REGISTER_XMPP,
  SET_PASSWORD,
  XmppBaseUrl,
} from "../../../api-calls/api-urls";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import "../../../assets/css/style.css";
import "../../../assets/css/bootstrap.min.css";
import { Card } from "@mui/material";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loggedUser } from "../../../Redux/actions/user-actions";
import { getLanguagedataFunction } from "../../../utilities/language";
import { CloseEyeIcon, OpenEyeIcon } from "../../../components/Icons";
import { setPresenceOnline } from "../../../utilities/Xmpp";

const SetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const regUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = async () => {
    try {
      const userEntry = {
        user_id: regUser._id,
        password: password,
        confirm_password: confirmPassword,
      };
      dispatch(pageLoader(true));
      const { data } = await api.post(SET_PASSWORD, userEntry);
      dispatch(loggedUser(data));
      await sessionStorage.setItem("userInfo", JSON.stringify(data));
      if (data) {
        await setPresenceOnline(data, dispatch);
      }
      navigate("/register-success");
    } catch (err) {
      console.log(err);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPasswordValue = e.target.value;
    setConfirmPassword(confirmPasswordValue);
    setPasswordsMatch(password === confirmPasswordValue);
  };

  return (
    <div className={classes.maindivForlogin}>
      {ln ? (
        <Card className={classes.SelectLanguage_maindiv}>
          <div>
            <ImageCarosel />
          </div>
          <ValidatorForm
            useref="form"
            onSubmit={handleSubmit}
            className={classes.InputForm}
          >
            <div className="col-lg-12">
              <div style={{ padding: "3rem" }}>
                <div className="text-left">
                  <span className="logo-lg mb-5">
                    <img src={AppLogo} alt="" height="50" />
                  </span>
                  <h5 className={`mt-5 ${classes.loginHeading}`}>
                    {ln.set_password}
                  </h5>
                  <p className={`mt-4 ${classes.loginDescription}`}>
                    {ln.please_set_your_password}
                  </p>
                </div>

                <div className="mt-4">
                  <div className="mb-4">
                    <label
                      htmlFor="userpassword"
                      className={classes.login_form_lable}
                    >
                      {ln.new_password}
                    </label>
                    <div className={classes.passwordInputContainer}>
                      <TextValidator
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        size="small"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        validators={[
                          "required",
                          "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$",
                        ]}
                        errorMessages={[
                          "Password is required",
                          "Password should contain 1 capital letter, 1 small letter, 1 number, 1 special characters  and should be 8 digits",
                        ]}
                      />
                      <div
                        className={classes.showPassword}
                        style={{ top: "-2px" }}
                      >
                        {showPassword ? (
                          <Button
                            variant="text"
                            onClick={() => setShowPassword(false)}
                          >
                            <OpenEyeIcon />
                          </Button>
                        ) : (
                          <Button
                            variant="text"
                            onClick={() => setShowPassword(true)}
                          >
                            <CloseEyeIcon />
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mb-2">
                    <label
                      htmlFor="userpassword"
                      className={classes.login_form_lable}
                    >
                      {ln.confirm_new_password}
                    </label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <div className={classes.passwordInputContainer}>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                          id="outlined-adornment-password"
                          type={showPassword1 ? "text" : "password"}
                          fullWidth
                          size="small"
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                          validators={[
                            "required",
                            "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$",
                          ]}
                          errorMessages={[
                            "Confirm password is required",
                            "Password should contain 1 capital letter, 1 small letter, 1 number, 1 special characters  and should be 8 digits",
                          ]}
                          error={!passwordsMatch} // Set an error state if passwords don't match
                          helperText={
                            !passwordsMatch && "Passwords do not match"
                          }
                        />
                        <div
                          className={classes.showPassword}
                          style={{ top: "-2px" }}
                        >
                          {showPassword1 ? (
                            <Button
                              variant="text"
                              onClick={() => setShowPassword1(false)}
                            >
                              <OpenEyeIcon />
                            </Button>
                          ) : (
                            <Button
                              variant="text"
                              onClick={() => setShowPassword1(true)}
                            >
                              <CloseEyeIcon />
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <Button
                      className={`btn btn-primary w-100 ${classes.commonbtn}`}
                      type="submit"
                    >
                      {ln.continue}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </ValidatorForm>
        </Card>
      ) : null}
    </div>
  );
};

export default SetPassword;
