import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import {
  GET_USER,
  LOCATION,
  TEAM_MEMBERS_LIST,
  USER_LOCATION,
} from "../../../api-calls/api-urls";
import classes from "./location.module.css";
import Form from "react-bootstrap/Form";
import BreadCrumbs from "../../../components/BreadCrumbs";
import {
  Card,
  Paper,
  CardContent,
  Avatar,
  Button,
  Typography,
} from "@mui/material";
import { generateColor, stringAvatar } from "../../../utilities/commonFuntions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLanguagedataFunction } from "../../../utilities/language";
import { BsArrowLeftShort } from "react-icons/bs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  BrudCrumbBackIcon,
  BrudCrumbNextIcon,
} from "../../../components/Icons";
import ReassignConfirmationModal from "./ReassignModal";
import DeleteConformation from "./DeleteConformation";

const LocationReassign = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reassignModal, setReassignModal] = useState(false);
  const [deleConfir, setDeleConfir] = useState(false);
  const [currentLocation, setCurrentLocation] = useState();
  const [currentLocationUSer, setCurrentLocationUSer] = useState();
  const [teamMemberName, setTeamMemberName] = useState();
  const [loggedUser, setLoggedUser] = useState();
  const [teamMembersList, setTeamMembersList] = useState([]);
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const [checked, setChecked] = useState();
  const [selectedId, setSelectedId] = useState();
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  const userEntry = {
    assigned_to: selectedId,
    location_id: currentLocation ? currentLocation._id : null,
  };

  const closeModal = (status1, status2) => {
    setReassignModal(false);
    setDeleConfir(false);
    if (status2) {
      navigate("/location");
    }
  };

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const getTeamMembersData = async () => {
    const { data } = await authApi.get(
      `${TEAM_MEMBERS_LIST}/${loggedUser.company}`,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    console.log(data);
    setTeamMembersList(data);
  };

  const handleSubmit = async () => {
    setReassignModal(true);
  };

  const handleCheckboxChange = (event) => {
    const id = event.target.id;
    setSelectedId(id);
    const data = teamMembersList.find((item) => item.user_id === id);
    setTeamMemberName(data?.name);
  };

  useEffect(() => {
    if (loggedUser) {
      getTeamMembersData();
    }
  }, [!teamMembersList, loggedUser]);

  useEffect(() => {
    const path = window.location.pathname;
    //console.log(path);
    getOneLocationdata(path.toLowerCase());
  }, [!currentLocation]);

  useEffect(() => {
    getCurrentLoggedUser();
  }, [!loggedUser]);

  const getCurrentLoggedUser = async () => {
    const { data } = await authApi.get(`${GET_USER}/${userInfo._id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    //console.log(data);
    setLoggedUser(data);
  };

  const getOneUserLocation = async (item) => {
    console.log(item);
    // const { data } = await authApi.get(`${USER_LOCATION}/${item._id}`, {
    //   headers: { Authorization: getAuthorizationHeader() },
    // });
    console.log(item.userLocation.user_id);
    setSelectedId(item.userLocation.user_id);
  };
  const handleDeleteLocation = () => {
    setDeleConfir(true);
  };

  const getOneLocationdata = async (path) => {
    const arr = path.split("/");
    //console.log(arr[arr.length - 1]);
    const { data } = await authApi.get(`${LOCATION}/${arr[arr.length - 1]}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    //console.log(data.data);
    setCurrentLocation(data.data);
    getOneUserLocation(data.data);
  };
  return (
    <div>
      {ln ? (
        <div>
          <div>
            <div className={`${classes.brudCrumb} mb-4 `}>
              <span onClick={() => navigate(-1)}>
                <BrudCrumbBackIcon />
              </span>
              <span
                onClick={() => navigate("/location")}
                className={classes.form_lable}
                style={{ color: "#0A1F41" }}
              >
                {ln.location}
              </span>
              <BrudCrumbNextIcon />
              <span className={classes.form_lable}>
                {currentLocation ? currentLocation.title : ""}
              </span>
            </div>
          </div>
          <div>
            <Paper className={classes.CreateTeamindex}>
              {teamMembersList && teamMembersList.length > 0 ? (
                <div>
                  <Typography className={classes.viewstyleText}>
                    Reassign Location
                  </Typography>
                  <div
                    className={classes.GridContainer}
                    style={{ padding: "0px", marginTop: 20 }}
                  >
                    {teamMembersList.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Card
                            key={item._id}
                            className={`${classes.teamCard} ${classes.AssignMemberCard}`}
                          >
                            <CardContent
                              className={`${classes.teamCardContainer} ${classes.AssignMemberCard}`}
                            >
                              <Avatar
                                {...stringAvatar(item.name)}
                                style={{
                                  backgroundColor: generateColor(item.name),
                                }}
                              />
                              <div className={classes.TeamcardText}>
                                <Typography
                                  className={`${classes.form_lable} ${classes.detailsText2}`}
                                >
                                  {item.name}
                                </Typography>
                                <Typography
                                  className={classes.assignMemberTeamText}
                                >
                                  Team 1
                                </Typography>
                              </div>
                              <div>
                                <Form.Check
                                  type="checkbox"
                                  id={item.user_id}
                                  checked={selectedId === item.user_id}
                                  onChange={handleCheckboxChange}
                                />
                              </div>
                            </CardContent>
                          </Card>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              ) : null}
              {teamMembersList && teamMembersList.length > 0 ? (
                <div className="mt-4 mb-3">
                  <div className={classes.createLocationbtnsectin}>
                    <Button
                      variant="outlined"
                      onClick={() => handleDeleteLocation()}
                    >
                      {ln.delete_location}
                    </Button>
                    <Button variant="contained" onClick={() => handleSubmit()}>
                      {ln.reassign_location}
                    </Button>
                  </div>
                </div>
              ) : null}
              {reassignModal ? (
                <ReassignConfirmationModal
                  modalClose={closeModal}
                  userEntry={userEntry}
                  teamMember={teamMemberName}
                />
              ) : null}
              {deleConfir ? (
                <DeleteConformation
                  item={currentLocation}
                  modalClose={closeModal}
                />
              ) : null}
            </Paper>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default LocationReassign;
