import React, { useEffect, useState } from "react";
import { Modal, Box, Button, Typography, Snackbar, Alert } from "@mui/material";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import image from "../../../assets/images/sucessImge.png";
import classes from "./myteam.module.css";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagedataFunction } from "../../../utilities/language";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import {
  ADD_DEPARTMENT,
  PASSMATCH,
  TEAM_LIST,
} from "../../../api-calls/api-urls";
import { CloseEyeIcon, OpenEyeIcon } from "../../../components/Icons";
import AlertBox from "../../../components/AlertBox";

const DeleteConformation = (props) => {
  const dispatch = useDispatch();
  const [atFirst, setAtFirst] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState(false);
  const [atSecond, setAtSecond] = useState();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const [success, setSuccess] = useState();
  const rootRef = React.useRef(null);
  const [modalToggle, setModalToggle] = useState(true);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  useEffect(() => {
    console.log("Props ===>", props);
  }, [props]);

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, 2000); // Change the duration (in milliseconds) as per your requirement

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showAlert]);

  const modalClose = (data) => {
    props.modalClose(false, data);
    setModalToggle(false);
  };

  const gotoSecondScrren = () => {
    setAtFirst(false);
    setAtSecond(true);
  };

  const handleSubmit = async () => {
    try {
      const userDetails = {
        user_id: userInfo._id,
        password: password,
      };
      const { data } = await authApi.post(PASSMATCH, userDetails, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      console.log(data);
      if (data.message === true) {
        if (props.type === "Team") {
          const { data } = await authApi.delete(
            `${TEAM_LIST}/${props.selected._id}`,
            {
              headers: { Authorization: getAuthorizationHeader() },
            }
          );
          console.log(data);
          setAtSecond(false);
          setSuccess(true);
        } else if (props.type === "Department") {
          const { data } = await authApi.delete(`${ADD_DEPARTMENT}`, {
            headers: { Authorization: getAuthorizationHeader() },
            data: props.idsList,
          });
          console.log(data);
          setAtSecond(false);
          setSuccess(true);
        }
      }
    } catch (err) {
      console.log(err.response.data.message);
      setShowAlert(true);
      setError(err.response.data.message);
    }
  };

  return (
    <div>
      {ln && props ? (
        <div>
          <Modal
            open={modalToggle}
            onClose={() => modalClose()}
            aria-labelledby="server-modal-title"
            data-testid="register_modal"
            aria-describedby="server-modal-description"
            sx={{
              display: "flex",
              height: success ? 400 : 480,
              marginTop: 10,
              justifyContent: "center",
            }}
            container={() => rootRef.current}
          >
            <Box
              sx={{
                position: "relative",
                bgcolor: "background.paper",
                borderRadius: 1,
                boxShadow: (theme) => theme.shadows[5],
              }}
              style={{
                width: success ? "500px" : "460px",
                height: "max-content",
                top: "20%",
              }}
              className={classes.loginModalWidth}
            >
              {!atFirst ? (
                <Button
                  variant="text"
                  sx={{ minWidth: "unset" }}
                  className={classes.closebtn}
                  onClick={() => modalClose()}
                >
                  <MdClose />
                </Button>
              ) : null}
              {atFirst && !atSecond && !success ? (
                <div className={classes.deleteConformationFirstDiv}>
                  <Typography
                    className={classes.imagePageText}
                    style={{ width: "100%" }}
                  >
                    Delete a {props.type}
                  </Typography>
                  <Typography className={classes.successPageSubText}>
                    Are you sure you want to delete{" "}
                    {props.type === "Team"
                      ? props.selected && props.selected.team_name
                        ? props.selected.team_name
                        : ""
                      : props.selected && props.selected.department_name
                      ? `department ${props.selected.department_name}`
                      : "Departments"}
                    ?
                  </Typography>
                  <div className={classes.addMemberModalbtnSection}>
                    <Button variant="outlined" onClick={() => modalClose()}>
                      No
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => gotoSecondScrren()}
                    >
                      Yes
                    </Button>
                  </div>
                </div>
              ) : null}
              {!atFirst && atSecond && !success ? (
                <div
                  className={classes.deleteSecondScreenDiv}
                  style={{ height: "300px" }}
                >
                  <div className={classes.AddMemberHeading}>
                    <Typography>{ln.please_enter_password}</Typography>
                  </div>
                  <ValidatorForm
                    useref="form"
                    onSubmit={handleSubmit}
                    className={classes.InputForm}
                  >
                    <div className="mt-4">
                      <div className="mb-2">
                        <Typography
                          htmlFor="userpassword"
                          className={classes.login_form_lable}
                        >
                          {ln.password}
                        </Typography>
                        <div className={classes.passwordInputContainer}>
                          <TextValidator
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            fullWidth
                            size="small"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            validators={["required"]}
                            errorMessages={["Password is required"]}
                          />
                          <div className={classes.showPassword}>
                            {showPassword ? (
                              <Button
                                variant="text"
                                onClick={() => setShowPassword(false)}
                              >
                                <OpenEyeIcon />
                              </Button>
                            ) : (
                              <Button onClick={() => setShowPassword(true)}>
                                <CloseEyeIcon />
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className={classes.addMemberModalbtnSection}>
                          <Button
                            variant="outlined"
                            onClick={() => modalClose()}
                          >
                            Cancel
                          </Button>
                          <Button variant="contained" type="submit">
                            Delete
                          </Button>
                        </div>
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              ) : null}
              {!atFirst && !atSecond && success ? (
                <div className={classes.addMembersuccess}>
                  <img src={image} alt="success" width="auto" height="auto" />
                  <Typography className={classes.imagePageText}>
                    {props.type} has been deleted
                  </Typography>
                  <Typography className={classes.successPageSubText}>
                    {props.selected &&
                    props.selected.team_name &&
                    props.type === "Team"
                      ? props.selected.team_name
                      : "Department"}{" "}
                    has been deleted successfully
                  </Typography>
                </div>
              ) : null}
            </Box>
          </Modal>
          {showAlert && error && (
            <AlertBox type="error" message={error} stateName={setShowAlert} />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default DeleteConformation;
