import React, { useEffect, useState } from "react";
import image from "../../../assets/images/Current location-pana 1.png";
import { BsFilter } from "react-icons/bs";
import { Form } from "react-bootstrap";
import { Paper } from "@mui/material";
import classes from "./location.module.css";
import { Typography, TextField, InputAdornment, Divider } from "@mui/material";
import {
  locationStatusFilter,
  locationTimeFilter,
  range,
} from "../../../utilities/constants";
import { Button, Menu, MenuItem } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import {
  saveLocationList,
  saveLocationStatusFilter,
  saveLocationTimeFilter,
} from "../../../Redux/actions/location-action";
import { LOCATION } from "../../../api-calls/api-urls";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { getLanguagedataFunction } from "../../../utilities/language";
import {
  ArrowDownIcon,
  DatePickerCalendarIcon,
  FilterIcon,
  RoundAddIcon,
  SearchIcon,
} from "../../../components/Icons";
import LocattionBoxContainer from "./LocattionBoxContainer";
import AlertBox from "../../../components/AlertBox";
const label = { inputProps: { "aria-label": "Switch demo" } };

const Index = () => {
  const navigate = useNavigate();
  const [serachValue, setSearchValue] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const [anchorEl, setAnchorEl] = useState(null); // State to anchor the menu
  const [anchorEl1, setAnchorEl1] = useState(null); // State to anchor the menu
  const [selectedValues, setSelectedValues] = useState([]); // State to store selected values
  const [selectedValues1, setSelectedValues1] = useState("newest_to_oldest"); // State to store selected values
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const togglesidebar = useSelector(
    (state) => state.reducers.allUsers.togglesidebar
  );
  const dispatch = useDispatch();
  const {
    locationList,
    locationFilteredStatus,
    locationFilterTime,
    locationFilterMembers,
    viewAll,
    totalcount,
  } = useSelector((state) => state.reducers.location);
  const { sortName } = useSelector((state) => state.reducers.allUsers);

  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const handleClick = () => {
    navigate("/location/create-location");
  };

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Change the duration (in milliseconds) as per your requirement

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showAlert]);

  const handleMenuclick = (event, name) => {
    name(event.currentTarget);
  };

  const handleStatusCheckbox = (value) => {
    const isSelected = selectedValues.includes(value);

    if (value === "all") {
      if (isSelected) {
        // Unselect all checkboxes
        setSelectedValues([]);
        dispatch(saveLocationStatusFilter([]));
      } else {
        // Select all checkboxes
        setSelectedValues([...locationStatusFilter]);
        dispatch(saveLocationStatusFilter([...locationStatusFilter]));
      }
    } else {
      // Toggle the selection of individual checkboxes
      let newSelectedValues = [];

      if (isSelected) {
        newSelectedValues = selectedValues.filter((v) => v !== value);
      } else {
        newSelectedValues = [...selectedValues, value];
      }
      setSelectedValues(newSelectedValues);
      dispatch(saveLocationStatusFilter([newSelectedValues]));

      const areAllSelected = locationStatusFilter
        .filter((item) => item !== "all")
        .every((item) => newSelectedValues.includes(item));

      // Check the "All" checkbox if all others are selected
      if (areAllSelected) {
        setSelectedValues((prevSelected) => [...prevSelected, "all"]);
        dispatch(saveLocationStatusFilter([...newSelectedValues, "all"]));
      } else {
        // Uncheck the "All" checkbox if any other checkbox is unselected
        setSelectedValues((prevSelected) =>
          prevSelected.filter((item) => item !== "all")
        );
        dispatch(
          saveLocationStatusFilter(
            newSelectedValues.filter((item) => item !== "all")
          )
        );
      }
    }
  };

  const clickClearAll = (name) => {
    if (name === "statusfilter") {
      console.log("hsdgfjgsdjhf");
      setSelectedValues([]);
      dispatch(saveLocationStatusFilter([]));
    } else if (name === "TimeFilter") {
      setSelectedValues1();
      dispatch(saveLocationTimeFilter());
    } else {
      name();
    }
  };

  const handleEvent = (event, picker) => {
    console.log("start: ", picker.startDate._d);
    console.log("end: ", picker.endDate._d);
    setFromDate(picker.startDate._d.toISOString());
    setToDate(picker.endDate._d.toISOString());
  };

  const handleTimeCheckBox = (value) => {
    setSelectedValues1(value);
    dispatch(saveLocationTimeFilter(value));
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setAnchorEl1(null);
  };

  const getLocationData = async (userEntry) => {
    console.log("All Status ===? ", viewAll);
    try {
      console.log(fromDate, toDate);
      if (fromDate !== undefined && toDate !== undefined) {
        const { data } = await authApi.post(
          `${LOCATION}?sort=${sortName}&search=${serachValue}&start_date=${moment(
            fromDate
          ).format("YYYY-MM-DD")}&end_date=${moment(toDate).format(
            "YYYY-MM-DD"
          )}&view_all=${true}`,
          userEntry,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );
        dispatch(saveLocationList(data));
      } else {
        const { data } = await authApi.post(
          `${LOCATION}?sort=${sortName}&search=${serachValue}&view_all=${true}`,
          userEntry,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );
        dispatch(saveLocationList(data));
      }
    } catch (error) {
      setShowAlert(true);
      setError(error.response.data.message);
    }
  };

  useEffect(() => {
    if (fromDate !== undefined && toDate !== undefined) {
      setSelectedDate(
        `${moment(fromDate).format("YYYY-MM-DD")} to ${moment(toDate).format(
          "YYYY-MM-DD"
        )}`
      );
    }
    const filterArray = {
      status: locationFilteredStatus,
      sortby: locationFilterTime,
      members: locationFilterMembers,
    };
    console.log(filterArray);
    getLocationData(filterArray);
  }, [
    !locationList,
    locationFilteredStatus,
    locationFilterTime,
    locationFilterMembers,
    fromDate,
    toDate,
    viewAll,
    serachValue,
    sortName,
  ]);
  return (
    <div>
      {ln && totalcount && totalcount > 0 ? (
        <div className={classes.indexpageMainBtn}>
          <Button
            variant="contained"
            style={{ textTransform: "none" }}
            onClick={() => handleClick()}
          >
            <RoundAddIcon style={{ marginRight: "10px" }} />
            {ln.assign_a_location}
          </Button>
        </div>
      ) : null}
      {ln && totalcount && totalcount > 0 ? (
        <div>
          <div
            className={`${classes.locationsmainDiv} ${classes.teampageMaindiv}`}
          >
            <div>
              <div className="mb-3 mt-1">
                <TextField
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    width: togglesidebar ? "290px" : "360px",
                  }}
                  placeholder="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  className={classes.teamMemberSearch}
                  id="firstNameinput"
                  value={serachValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  size="small"
                />
              </div>
            </div>
            <div
              style={{ display: "flex", columnGap: 10, alignItems: "center" }}
            >
              <div>
                <Button
                  variant="outlined"
                  className={classes.filterbtns}
                  onClick={(e) => handleMenuclick(e, setAnchorEl)}
                  style={{ background: "white" }}
                >
                  <div>
                    <BsFilter /> {ln.all}
                  </div>
                  <div>
                    <ArrowDownIcon />
                  </div>
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <div className={classes.filterHeading}>
                    <Typography className={classes.filterHeader}>
                      {ln.filter}
                    </Typography>
                    <span
                      className={classes.claerBtn}
                      onClick={() => clickClearAll("statusfilter")}
                    >
                      {ln.clear_all}
                    </span>
                  </div>
                  <Divider />
                  {locationStatusFilter.map((item, index) => {
                    return (
                      <MenuItem
                        key={`sort${index}`}
                        style={{
                          padding: "6px 25px 6px 0",
                          textTransform: "capitalize",
                        }}
                        onClick={() => handleStatusCheckbox(item)}
                      >
                        <Form.Check
                          style={{
                            margin: 10,
                            marginRight: 15,
                          }}
                          checked={locationFilteredStatus?.includes(item)}
                          name="group1"
                          type="checkbox"
                        />
                        {ln[item]?.replaceAll("_", " ")}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
              <div>
                <Button
                  variant="outlined"
                  className={classes.filterbtns}
                  onClick={(e) => handleMenuclick(e, setAnchorEl1)}
                  style={{ background: "white" }}
                >
                  <div>
                    <FilterIcon />{" "}
                    {selectedValues1
                      ? selectedValues1?.replaceAll("_", " ")
                      : ln.newest_to_oldest}
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <ArrowDownIcon />
                  </div>
                </Button>
                <Menu
                  anchorEl={anchorEl1}
                  open={Boolean(anchorEl1)}
                  onClose={handleMenuClose}
                >
                  <div className={classes.filterHeading}>
                    <Typography className={classes.filterHeader}>
                      {ln.sort_by}
                    </Typography>
                    <span
                      className={classes.claerBtn}
                      onClick={() => clickClearAll("TimeFilter")}
                    >
                      {ln.clear_all}
                    </span>
                  </div>
                  {locationTimeFilter.map((item, index) => {
                    return (
                      <MenuItem
                        key={`filter${index}`}
                        style={{
                          padding: "6px 25px 6px 0",
                          textTransform: "capitalize",
                        }}
                        onClick={() => handleTimeCheckBox(item)}
                      >
                        <Form.Check
                          style={{
                            margin: 10,
                            marginRight: 15,
                          }}
                          checked={selectedValues1?.includes(item)}
                          name="group1"
                          type="checkbox"
                          label={ln[item]?.replaceAll("_", " ")}
                        />
                        {/* <Checkbox
                          checked={selectedValues1 === item}
                          onChange={() => handleTimeCheckBox(item)}
                        /> */}
                        {/* {ln[item]?.replaceAll("_", " ")} */}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
              <div>
                <div className="mb-3 mt-1">
                  <div className={classes.datePicker1}>
                    <DateRangePicker
                      initialSettings={{ ranges: range }}
                      alwaysShowCalendars={true}
                      onApply={handleEvent}
                    >
                      <div>
                        <TextField
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                          size="small"
                          placeholder="Today"
                          value={selectedDate}
                        />
                        <span
                          className={classes.datePickerCalendarIcon}
                          style={{ right: "10px" }}
                        >
                          <DatePickerCalendarIcon />
                        </span>
                      </div>
                    </DateRangePicker>
                  </div>
                  {/* <DatePicker
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      width: "200px",
                    }}
                    value={date}
                    onChange={(e) => console.log(e)}
                    placeholder={"Today"}
                    slotProps={{ textField: { size: "small" } }}
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <LocattionBoxContainer
            locationList={locationList}
            viewAll={viewAll}
            fromDate={fromDate}
            toDate={toDate}
          />
        </div>
      ) : (
        <Paper className={classes.CreateTeamindex}>
          {ln ? (
            <div className={classes.imagePagemaindiv}>
              <img
                src={image}
                alt="createTeam"
                className={classes.CreateTeamimage}
              />
              <Typography className={classes.imagePageText} variant="h5">
                {ln.no_location_data}
              </Typography>
              <Button
                variant="contained"
                className={`${classes.imagepagebtn} ${classes.noDataImagePAge}`}
                onClick={() => handleClick()}
              >
                <RoundAddIcon />
                <span style={{ marginLeft: "8px" }}>
                  {ln.assign_a_location}
                </span>
              </Button>
            </div>
          ) : null}
        </Paper>
      )}
      {showAlert && error && (
        <AlertBox type="error" message={error} stateName={setShowAlert} />
      )}
    </div>
  );
};

export default Index;
