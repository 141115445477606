import React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import classes from "./blogs.module.css";
import { useNavigate } from "react-router-dom";
import BlogsMainPage from "./BlogsMainPage";
import SavedBlogsPage from "./SavedBlogsPage";
import { getLanguagedataFunction } from "../../../utilities/language";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BlogsIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      {ln && (
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={ln.blogs}
                {...a11yProps(0)}
                style={{ textTransform: "capitalize" }}
              />
              <Tab
                label={ln.saved_blogs}
                {...a11yProps(1)}
                style={{ textTransform: "capitalize" }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} className={classes.TabPanel}>
            <BlogsMainPage />
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.TabPanel}>
            <SavedBlogsPage />
          </TabPanel>
        </Box>
      )}
    </div>
  );
};

export default BlogsIndex;
