import { actionTypes } from "../constant/action-types";

export function getAllCompanyAddress(data) {
  return {
    type: actionTypes.COMPANY_ALL_ADDRESS,
    payload: data,
  };
};

export function addCompanySuccess(data) {
  return {
    type: actionTypes.ADD_ADDRESS_SUCCESS,
    payload: data,
  };
}

export function addCompanyFailure(data) {
  return {
    type: actionTypes.ADD_ADDRESS_FAILURE,
    payload: data,
  };
}

export function deleteCompanySuccess(data) {
  return {
    type: actionTypes.DELETE_ADDRESS_SUCCESS,
    payload: data,
  };
}

export function deleteCompanyFailure(data) {
  return {
    type: actionTypes.DELETE_ADDRESS_FAILURE,
    payload: data,
  };
}