import React, { useState } from "react";
import { Modal, Button, Typography } from "@mui/material";
import { MdClose } from "react-icons/md";
import Box from "@mui/material/Box";
import successimage from "../../../assets/images/sucessImge.png";
import classes from "./Subscription.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagedataFunction } from "../../../utilities/language";

const ConfirmationModal = (props) => {
  const dispatch = useDispatch();
  const [modalToggle, setModalToggle] = useState(true);
  const rootRef = React.useRef(null);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [dispatch, !ln]);

  function closeModal() {
    setModalToggle(false);
    props.closeModal(false);
  }

  return (
    <div>
      {ln ? (
        <Modal
          open={modalToggle}
          onClose={() => closeModal()}
          aria-labelledby="server-modal-title"
          data-testid="register_modal"
          aria-describedby="server-modal-description"
          sx={{
            display: "flex",
            height: 480,
            marginTop: 10,
            justifyContent: "center",
          }}
          container={() => rootRef.current}
        >
          <Box
            sx={{
              position: "relative",
              bgcolor: "background.paper",
              borderRadius: 1,
              boxShadow: (theme) => theme.shadows[5],
            }}
            style={{
              width: "max-content",
              minWidth: "460px",
              height: "fit-content",
            }}
            className={classes.loginModalWidth}
          >
            <Button
              variant="text"
              sx={{ minWidth: "unset" }}
              className={classes.closebtn}
              onClick={() => closeModal()}
            >
              <MdClose />
            </Button>
            <div className={classes.addMembersuccess}>
              <img
                src={successimage}
                alt="success"
                width="auto"
                height="auto"
              />
              <Typography className={classes.imagePageText1}>
                Subscription {props.from === "upgrade" ? "Upgrade" : "Renew"}
              </Typography>
              <Typography className={classes.successPageSubText}>
                your Subscription has been{" "}
                {props.from === "upgrade" ? "upgrad" : "renew"}ed
                Successfully..!
              </Typography>
              <Button
                variant="contained"
                onClick={() => closeModal()}
                style={{
                  textTransform: "capitalize",
                  width: "125px",
                  padding: "11px 24px",
                }}
              >
                okay
              </Button>
            </div>
          </Box>
        </Modal>
      ) : null}
    </div>
  );
};

export default ConfirmationModal;
