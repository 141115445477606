import React from "react";
import { Button, Link } from "@mui/material";
import "../../../assets/css/style.css";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  return (
    <div>
      {/* <Confetti width={window.innerWidth} height={window.innerHeight} /> */}
      <div className="success">
        <h2>Payment Successful!</h2>
        <p>Your Plan has Activated</p>
        <Button variant="text" onClick={() => navigate("/")}>
          Home
        </Button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
