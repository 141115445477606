import React, { useState } from "react";
import {
  Modal,
  Button,
  InputAdornment,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { MdClose } from "react-icons/md";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Box from "@mui/material/Box";
import classes from "./myteam.module.css";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { ADD_DEPARTMENT } from "../../../api-calls/api-urls";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CirclePicker, ChromePicker } from "react-color";
import { getLanguagedataFunction } from "../../../utilities/language";
import AlertBox from "../../../components/AlertBox";

const AddDepartmentModal = (props) => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const [openColourPalete, setOpenColourPalete] = useState(false);
  const [modalToggle, setModalToggle] = useState(true);
  const [success, setSuccess] = useState();
  const rootRef = React.useRef(null);
  const [department, setDepartment] = useState();
  const [selectedColor, setSelectedColor] = useState();
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const handleColour = (color) => {
    console.log(color);
    setSelectedColor(color.hex);
  };

  function closeModal(data) {
    props.modalClose(false, data);
    setModalToggle(false);
  }

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Change the duration (in milliseconds) as per your requirement

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showAlert]);

  useEffect(() => {
    if (props.department) {
      setDepartment(props.department.department_name);
      setSelectedColor(props.department.colour);
    }
  }, []);
  const handleSubmit = async () => {
    try {
      const userEntry = {
        department_name: department,
        colour: selectedColor,
      };
      if (props.department) {
        const { data } = await authApi.put(
          `${ADD_DEPARTMENT}/${props.department._id}`,
          userEntry,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );
        console.log(data);
      } else {
        const { data } = await authApi.post(ADD_DEPARTMENT, userEntry, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        console.log(data);
      }
      closeModal();
    } catch (err) {
      console.log(err.response.data.message);
      setError(err.response.data.message);
      setShowAlert(true);
    }
  };
  return (
    <div>
      {ln ? (
        <Modal
          open={modalToggle}
          onClose={() => closeModal()}
          aria-labelledby="server-modal-title"
          data-testid="register_modal"
          aria-describedby="server-modal-description"
          sx={{
            display: "flex",
            marginTop: 10,
            justifyContent: "center",
          }}
          container={() => rootRef.current}
        >
          <Box
            sx={{
              position: "relative",
              bgcolor: "background.paper",
              borderRadius: 1,
              boxShadow: (theme) => theme.shadows[5],
            }}
            style={{ width: "460px", height: "max-content" }}
            className={classes.loginModalWidth}
          >
            <div className={classes.AddMemberHeading}>
              <Typography>{ln.create_department}</Typography>
              <Button
                variant="text"
                sx={{ minWidth: "unset" }}
                className={classes.closebtn}
                onClick={() => closeModal()}
              >
                <MdClose />
              </Button>
            </div>
            <ValidatorForm useref="form" onSubmit={handleSubmit}>
              <div className={classes.depFormContainer}>
                <div className="col-md-12">
                  <div className="mb-3">
                    <Typography
                      htmlFor="address1ControlTextarea"
                      className={`${classes.form_lable}`}
                    >
                      {ln.department_name}*
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      id="address1ControlTextarea"
                      value={department}
                      fullWidth
                      onChange={(e) => setDepartment(e.target.value)}
                      size="small"
                      validators={["required"]}
                      errorMessages={["Department Name is required"]}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "13px",
                      width: "100%",
                    }}
                  >
                    <Typography htmlFor="" className={`${classes.form_lable}`}>
                      {ln.department_colour}*
                    </Typography>
                    <Typography
                      className={classes.choseOtherColoutTrxt}
                      onClick={() => setOpenColourPalete(true)}
                    >
                      Choose other colour
                    </Typography>
                  </div>
                  {openColourPalete ? (
                    <ChromePicker
                      onChange={handleColour}
                      color={selectedColor}
                      disableAlpha
                    />
                  ) : (
                    <CirclePicker
                      onChange={handleColour}
                      width="100%"
                      color={selectedColor}
                      circleSize={32}
                    />
                  )}
                </div>
                <div
                  className={`${classes.addDepartmentModal} ${classes.addMemberModalbtnSection}`}
                >
                  <Button variant="outlined" onClick={() => closeModal()}>
                    {ln.cancel}
                  </Button>
                  <Button variant="contained" type="submit">
                    {ln.add}
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </Box>
        </Modal>
      ) : null}
      {showAlert && error && (
        <AlertBox type="error" message={error} stateName={setShowAlert} />
      )}
    </div>
  );
};

export default AddDepartmentModal;
