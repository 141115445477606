import React, { useCallback, useEffect, useState } from "react";
import classes from "./index.module.css";
import Carousel from "react-material-ui-carousel";
import "../assets/css/style.css";
import "../assets/css/bootstrap.min.css";
import { Typography } from "@mui/material";

const items = [
  {
    name: " Manage your medium or small business",
    discription: "Lorem ipsum dolor sit amet conse ctetur. Nulla egestas dui ",
  },
  {
    name: " Manage your medium or small business",
    discription: "Lorem ipsum dolor sit amet conse ctetur. ",
  },
  {
    name: " Manage your medium or small business",
    discription: "Lorem ipsum dolor sit amet conse ctetur. Nulla egestas dui ",
  },
];

function App() {
  // State to programmatically set active child
  const [activeChild, setActiveChild] = useState(0);

  // Basically items = [1, 2, 3, 4]

  // The Keypress Event Handler
  // const changeChild = useCallback(
  //   (e) => {
  //     if (e.key === "ArrowLeft") {
  //       setActiveChild((a) => (a - 1 < 0 ? items.length - 1 : a - 1));
  //     } else if (e.key === "ArrowRight") {
  //       setActiveChild((a) => (a + 1 > items.length - 1 ? 0 : a + 1));
  //     }
  //   },
  //   [items]
  // );

  // Set and cleanup the event listener
  // useEffect(() => {
  //   document.addEventListener("keydown", changeChild);

  //   return function cleanup() {
  //     document.removeEventListener("keydown", changeChild);
  //   };
  // });

  return (
    <div style={{ height: "100%" }}>
      <Carousel
        index={activeChild} // <-- This controls the activeChild
        autoPlay={false} // <-- You probaly want to disable this for our purposes
        navButtonsAlwaysInvisible
        className="bg-auth"
        activeIndicatorIconButtonProps={{
          style: {
            color: "#fff",
            width: "20px",
            height: "10px",
            backgroundColor: "#fff",
            borderRadius: "5px",
          },
        }}
      >
        {items.map((i, index) => {
          return (
            <div key={index} className="testi-contain">
              <Typography className={classes.imageCoroselHeader}>
                {i.name}
              </Typography>
              <p className="fs-15 text-white mt-2 mb-0">{i.discription}</p>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}

export default App;
