export const actionTypes = {
  PAGE_LOADER: "PAGE_LOADER",
  REGISTERED_USER: "REGISTERED_USER",
  LOGGEDIN_USER: "REGISTERED_USER",
  OTP_MOBILE: "OTP_MOBILE",
  TEAMS_LIST: "TEAMS_LIST",
  EDIT_TEAM: "EDIT_TEAM",
  DEPARTMENT_LIST: "DEPARTMENT_LIST",
  IMAGE_LIST: "IMAGE_LIST",
  LOCATION_LIST: "LOCATION_LIST",
  AUDIO_LIST: "AUDIO_LIST",
  LOCATION_STATUS_FILTER: "LOCATION_STATUS_FILTER",
  LOCATION_TIME_FILTER: "LOCATION_TIME_FILTER",
  ONE_TEAM_MEMBER: "ONE_TEAM_MEMBER",
  HEADER_NAME: "HEADER_NAME",
  LANGUAGE_JSON: "LANGUAGE_JSON",
  SAVE_TEAM_MEMBER_STATUS_FILTER: "SAVE_TEAM_MEMBER_STATUS_FILTER",
  TEAM_MEMBER_ATTENDANCE_FILTER: "TEAM_MEMBER_ATTENDANCE_FILTER",
  SAVE_AVAILABLE_SUBC: "SAVE_AVAILABLE_SUBC",
  REFFERAL_HISTORY: "REFFERAL_HISTORY",
  CURRENT_USER: "CURRENT_USER",
  USER_COMPANY: "USER_COMPANY",
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
  LOCATION_MEMBERS_FILTER: "LOCATION_MEMBERS_FILTER",
  LOCATION_RANGE_FILTER: "LOCATION_RANGE_FILTER",
  SAVE_GROUPS: "SAVE_GROUPS",
  SAVE_CONTACT_LIST: "SAVE_CONTACT_LIST",
  SAVE_MESSAGES: "SAVE_MESSAGES",
  SAVE_HOLIDAYS_LIST: "SAVE_HOLIDAYS_LIST",
  NOTIFICATION_LIST: "NOTIFICATION_LIST",
  SAVE_BLOGS_LIST_DATAS: "SAVE_BLOGS_LIST_DATAS",
  SAVE_USER_SAVED_BLOGS_LIST_DATAS: "SAVE_USER_SAVED_BLOGS_LIST_DATAS",
  TEAM_MEMBERS_FILTER: "TEAM_MEMBERS_FILTER",
  VIEW_ALL: "VIEW_ALL",
  SORT_NAME: "SORT_NAME",
  SAVE_RECEIVED_MESSAGES: "SAVE_RECEIVED_MESSAGES",
  BLOGSPAGE_MESSAGES_SAVE: "BLOGSPAGE_MESSAGES_SAVE",
  SAVE_LAST_MESSAGE_IDS: "SAVE_LAST_MESSAGE_IDS",
  SELECTED_CHAT_MEMBER: "SELECTED_CHAT_MEMBER",
  COMPANY_ALL_ADDRESS: "COMPANY_ALL_ADDRESS",
  ADD_ADDRESS_SUCCESS: "ADD_ADDRESS_SUCCESS",
  ADD_ADDRESS_FAILURE: "ADD_ADDRESS_FAILURE",
  DELETE_ADDRESS_SUCCESS: "DELETE_ADDRESS_SUCCESS",
  DELETE_ADDRESS_FAILURE: "DELETE_ADDRESS_FAILURE",
  TEAM_MEMBERS_LIST: "TEAM_MEMBERS_LIST",
  MAP_MARKER_LIST: "MAP_MARKER_LIST",
};
